export default {
  //header
  headTop: 'NUEVO CLIENTE {symbol} DE DESCUENTO',
  registNow: 'Registrarse ahora',
  liveChart: 'Chat en directo',
  liveChartInfo: 'Si tiene alguna duda antes de comprar, póngase en contacto con nuestros operadores en línea para obtener más información.',
  creatTickets: 'Crear una consulta de postventa',
  creatTicketsInfo: 'Si necesita ayuda con asuntos relacionadas con la postventa, envíe un ticket.',
  currencyInfo: '¡Enviamos nuestros pedidos a todo el mundo! Si desea ver los precios en una moneda diferente, cambie la tienda.',
  billedIn: 'Su pedido se facturará en:',
  trackMyOrder: 'Rastrea mi pedido',
  orderNum: 'Número de pedido',
  account: "Cuenta",
  orders: 'Pedidos',
  search: 'Búsqueda',
  wishlist: 'Mi lista de deseos',
  changeCurr: 'Cambiar moneda',
  hotSearch: 'Búsquedas popular',
  occasion: 'Ocasión',
  trendPro: 'Productos de tendencia',
  prom: 'Baile de graduación',
  homecoming: 'Homecoming',
  evening: 'NOCHE',
  bridesmaids: 'Dama de honor',
  wedding: 'Boda',
  lang: "Idioma",
  country: "País",
  currency: "Moneda",
  couRegion: "País/Región",
  english: 'Inglés',
  spanish: 'Español',
  france: 'Francés',
  store: 'Tienda',
  //home
  shopFullCo: 'COMPRAR COLECCIÓN COMPLETA',
  top: 'Top',
  categories: 'Categorías',
  slimProm: 'Vestidos ajustado',
  alineProm: 'Vestidos línea A',
  bridesmaid: 'Dama de honor',
  moms: 'MADRE',
  flowerGirl: 'PAJECITAS',
  headpieces: 'Tocados',
  swatches: 'Muestra de fábrica',
  motherOf: 'Vestidos de madrina 2024',
  motherInfo: 'Puedes crear la mezcla perfecta y jugar con los tejidos para conseguir un ambiente de ensueño.',
  new: 'Nuevo',
  arrivals: 'Llegado',
  arrivalsInfo: "Todo está en los detalles. ¿Cuál es tu estilo?",
  viewAllDress: 'Ver todos los vestidos',
  bridesmaidsMix: 'Dama de honor Mix & Match',
  bridesmaidsMixInfo: 'Nuestros colores están totalmente surtidos para que mezclar y combinar resulte muy fácil.',
  inspiration: 'BUSCANDO INSPIRACIÓN',
  accessoires: 'Accesorios',
  accessoiresInfo: 'Accesorios ultra femeninos para tu gran día.',
  weddShoes: 'Zapatos de boda',
  shoesInfo: 'Comodidad y elegancia,Detalles y estilo',
  fabrics: 'Muestras & Fábricas',
  fabricsInfo: 'Te ayudará a aprender cómo queda un color concreto',
  gallery1: 'Galería',
  gallery2: 'de estilos',
  galleryInfo: 'Clientes de STACEES de todo el mundo comparten con nosotros sus momentos de estilo personal.',
  galleryInfo1: 'Los clientes de STACEES comparten momentos maravillosos con nosotros.',
  product: 'VER PRODUCTO',
  loadMore: 'CARGAR MÁS',
  viewMore: 'VER MÁS',
  viewLess: 'VER MENOS',
  chat: 'Chatear con nosotros',
  chatInfo: 'Nuestro equipo de atención al cliente está disponible y puede responder cualquier pregunta.',
  customiz: 'Personalización',
  customizInfo: 'Ofrecemos servicios personalizados, talla personalizada o diseño exclusivo para su vestido.',
  affordable: 'Lujo asequible',
  affordableInfo: 'Puede estar seguro de que su inversión en cualquiera de nuestros vestidos siempre merecerá la pena.',
  fastShip: 'Envío rápido',
  fastShipInfo: 'Tenemos más de 100 vestidos que se envían en 48 horas.',
  //footer
  about: 'Acerca de',
  aboutSta: 'Acerca de STACEES',
  shipping: 'Información de envío',
  searches: 'Búsquedas',
  blog: 'Blog de STACEES',
  help: 'Ayuda',
  faq: 'Preguntas frecuentes',
  return: 'Devoluciones y reembolso',
  track: 'Rastrear de pedido',
  tailor: 'Proyecto a medida',
  customer: 'Atención al cliente',
  SizeGuide: 'Guía de tamaños',
  colourGuide: 'Guía de color',
  contactUs: 'Contáctenos',
  paymentMethod: 'Opciones de pago',
  studentDiscount: 'Descuento para estudiantes y trabajadores clave',
  ShopByCate: 'Comprar por categoría',
  promDress: 'Vestidos de graduación',
  homeComDress: 'Vestidos para homecoming',
  weddingDress: 'Vestidos de novia',
  bridesmaidDress: 'Vestidos de dama de honor',
  motherBride: 'Vestidos de madrina',
  weddingGuest: 'Vestidos de invitadas de boda',
  flowerGirlDress: 'Vestidos de pajecitas',
  reserved: 'Reservados todos los derechos.',
  conditions: 'Términos y condiciones',
  privacyPolicy: 'Política de privacidad',
  aboutUs: 'Sobre Nosotras',
  emailAddr: 'Correo Electrónico',
  enterEmailAddr: 'Introduzca la dirección de email',
  signTip: 'Regístrese para recibir correos electrónicos y obtenga {symbol} de descuento en cualquier pedido.',
  signTip1: 'REGÍSTRESE A NUESTROS BOLETINES',
  copyRight: 'Copyright',
  signTit: 'Introduzca su correo electrónico para suscribirse al boletín informativo',
  subscribInfo: '¡Gracias por su suscripción! Disfrute comprando en nuestra tienda.',
  prefrence: 'Elige tu estilo favorito.',
  //list
  home: 'Inicio',
  ships48: 'ENVÍO EN 48 HORAS',
  sort: 'Ordenar',
  popular: 'Más popular',
  recommend: 'Recomendar',
  newArrival: 'Recién llegado',
  lPrice: 'Precio(ascendente)',
  hPrice: 'Precio(descendente)',
  filter: 'Filtrar',
  filterBy: 'Filtrar por',
  price: 'Precio',
  clearAll: 'Borrar todo',
  off: 'DTO.',
  apply: 'APLICAR',
  Color: 'Color',
  Colors: 'Colores',
  '2024 Prom Dresses': `Vestidos de graduación {name}`,
  'Long Prom Dresses': 'Vestidos de graduación largos',
  'Ball Gown Prom Dresses': 'Vestidos de graduación corte de baile ',
  'Sexy Prom Dresses': 'Vestidos de graduación sexys',
  'Lace Prom Dresses': 'Vestidos de graduación de encaje',
  'Off the Shoulder Prom Dresses': 'Vestidos de graduación con hombros descubiertos',
  'Two Piece Prom Dresses': 'Vestidos de graduación de dos piezas',
  '2024 Wedding Dresses': `Vestidos de novia {name}`,
  'Beach Wedding Dresses': 'Vestidos de novia de playa',
  'Lace Wedding Dresses': 'Vestidos de novia de encaje ',
  'Wedding Veils': 'Velos de novia',
  'Evening Dresses': 'Vestidos de noche',
  '2024 Evening Dresses': `Vestidos de noche {name}`,
  'Long Evening Dresses': 'Vestidos de noche largos',
  'Homecoming Dresses 2024': `Vestidos para homecoming {name}`,
  'Cocktail Dresses': 'Vestidos de cóctel',
  'Wedding Pumps': 'Zapatos de boda',
  "Women's Shoes": "Zapatos de mujer",
  "Girls' Shoes": "Zapatos de niña",
  "Dance Shoes": "Zapatos de danza",
  nextPage: 'Página<br/>siguiente',
  searchResFor: 'Resultados de búsqueda para',
  sortBy: 'Ordenar por',
  searchResult: 'Lo sentimos, no hay ningún resultado para su búsqueda.',
  products: "Productos",
  proNotFound: 'Producto no encontrado',
  searchFor: 'Buscar artículos',
  'Short Prom Dresses': 'Vestidos de graduación cortos',
  'Sparkly Prom Dresses': 'Vestidos de graduación brillantes',
  'Mermaid Prom Dresses': 'Vestidos de graduación de sirena',
  'Formal & Evening Dresses': 'Vestidos formales y de noche',
  'A-Line Wedding Dresses': 'Vestidos de novia de línea A',
  'Mermaid Wedding Dresses': 'Vestidos de novia de sirena',
  'Ball-Gown Wedding Dresses': 'Vestidos de novia',
  'Plus Size Wedding Dresses': 'Vestidos de novia tallas grandes',
  'Reception Dresses': 'Vestidos de recepción',
  'Colored Wedding Dresses': 'Vestidos de novia de colores',
  'Veils': 'Velos',
  'Long Mother of the Bride Dresses': 'Vestidos de madrina largos',
  'Short Mother of the Bride Dresses': 'Vestidos de madrina cortos ',
  'Tea Length Mother of the Bride Dresses': 'Vestidos de madrina hasta la tibia',
  'Plus Size Mother of the Bride Dresses': 'Vestidos de madrina talla grande',
  'Mother of the Bride Dresses With Jackets & Wraps': 'Vestidos de madrina con chaquetas y chales',
  'Mother of the Bride Dresses With Sleeves': 'Vestidos de madrina con mangas',
  'Long Bridesmaid Dresses': 'Vestidos de dama de honor largos',
  'High-Low Bridesmaid Dresses': 'Vestidos de dama de honor altos-bajos',
  'Short Bridesmaid Dresses': 'Vestidos de dama de honor cortos',
  'Tea-Length Bridesmaid Dresses': 'Vestidos de dama de honor hasta la tibia',
  'Mix & Match Bridesmaid Dresses': 'Vestidos de dama de honor Mix & Match',
  'Junior Bridesmaid Dresses': 'Vestidos de damita de honor',
  'Fabric Swatch': 'Muestras de fábrica',
  girlsDress: 'Vestidos de pajecitas',
  partyShoes: 'zapatos de fiesta',
  jacketsWraps: 'chaquetas y chales',
  jelwery: 'joyería del fiesta de boda',
  promShoes: 'zapatos de graduación',
  fashionJelwery: 'joyería de moda',
  //product
  ships48s: 'Envío en 48 horas',
  reviews: 'Comentarios',
  descript: 'DESCRIPCIÓN',
  details: 'DETALLES',
  modelInfo: 'INFORMACIÓN DEL MODELO',
  shipRetrun: 'ENVÍO Y DEVOLUCIÓN',
  proTime: 'Tiempo de producción',
  proTimeInfo: 'El plazo de producción estimado es de 2 semanas antes del envío de los artículos. También se puede solicitar una producción urgente, que está disponible por una tarifa adicional no reembolsable.',
  shipTimeSt: 'Tiempo de envío-Envío estándar',
  shipTimeInfo: 'El plazo de envío es de 3 a 10 días, en función de la ubicación y el método de envío. Se proporcionará una opción de tiempo de envío en la caja. ',
  seeMore: 'Ver más',
  authentic: '100% <span>auténtico</span>',
  cusSizem: 'Medida <span>personalizada</span>',
  freeGarm: 'Portatrajes <span>Gratis</span>',
  highQam: 'ALTA <span>CALIDAD</span>',
  highQa: 'ALTA CALIDAD',
  recently: 'VISTOS RECIENTEMENTE',
  easyRet: 'DEVOLUCIONES FÁCILES',
  proInfo1: 'Diseñado por STACEES, 100% como en la foto.',
  proInfo2: 'Tamaño personalizada o diseño exclusivo para tu vestido.',
  proInfo3: 'Te ofrecemos una bolsa de ropa gratis cuando compras este vestido.',
  proInfo4: 'Nuestros vestidos son a la vez elegantes y cómodos.',
  proInfo5: 'Plazo de devolución de 14 días',
  retPolicy: 'Política de devoluciones',
  retPolicyInfo1: 'Las devoluciones sin preocupaciones están disponibles para tamaños estándar en un plazo de 14 días. Los tamaños personalizados son venta final.',
  retPolicyInfo2: ' Asegúrate de que estén en su estado original: sin usar, sin lavar, sin modificaciones , sin daños, limpios, sin pelusa ni pelo y con las etiquetas intactas y pegadas.',
  allColors: 'Todos los colores',
  inStockCol: 'Colores en stock',
  colorChart: 'Tabla de colores',
  "As Picture": "Como en la foto",
  size: 'Tamaño',
  pleaseSel: 'Por favor seleccione',
  customSize: 'Medida personalizada',
  thisReq: 'Este es un campo obligatorio.',
  sizeGuide: 'Guía de tallas',
  sizeChart: 'Tabla de tallas',
  toMeasure: 'Cómo medir',
  custom: 'Personalizado',
  inch: 'inch',
  inches: 'INCH',
  centimeters: 'CM',
  orderSwat: 'Pedir muestras',
  aviala: 'Disponibilidad',
  inStock: 'En stock',
  proAvailable: 'Producción urgente disponible',
  additional: 'Requisitos adicionales',
  additionalInfo: 'Instrucciones especiales sobre la personalización o manipulación (opcional)',
  addBag: 'AÑADIR A LA CESTA',
  orderToday: 'Ordenar hoy, ',
  getIt: 'consíguelo',
  tailTime: 'Plazo de confección',
  shipTime: 'Tiempo de envío',
  processTime: 'Tiempo de procesamiento',
  day: 'Días',
  orderTodyInfo: 'Este vestido es hecho a pedido. Así sea que seleccione una talla estándar o personalizada, nuestros costureros elaboran cada vestido por pedido.',
  completeLook: 'COMPLETA EL LOOK',
  toCart: 'AÑADIR A LA CESTA',
  viewFull: 'Ver todos los detalles',
  sizeOrder: 'TALLA PEDIDA',
  proLove: "CREEMOS QUE TE ENCANTARÁ",
  cusReview: 'COMENTARIOS DE CLIENTES',
  bestSal: 'los más vendidos',
  shareWith: 'Compartir con',
  customInfo1: '¿Buscas un ajuste perfecto? Para un adicional ',
  customInfo2: ', Nuestros sastres utilizarán sus medidas exactas para crear un vestido hecho a medida para usted.',
  passwd6Char: 'Por favor introduzca 6 o más caracteres.',
  passwdConsist: '¡Las dos contraseñas ingresadas deben ser consistentes!',
  verfied: 'Comprador verificado',
  allPhoto: 'Ver todas las fotos de los clientes',
  overFit: 'Generalmente ajustado',
  rating: 'Calificación',
  'Small': 'Pequeño',
  'Slightly Small': 'Ligeramente pequeño',
  'Right': 'Right',
  'Slightly Large': 'Ligeramente grande',
  'Large': 'Grande',
  overallRate: 'Calificación general',
  customPhoto: 'Fotos de clientes',
  backPro: 'Volver a la página del producto',
  basedOn: 'Basado en',
  shareLook: 'Comparte tu look para conseguir',
  shareLook1: '¡Comparte tu look para conseguir',
  shareDiscount: 'de descuento',
  sharePhoto: 'Compartir foto/video',
  allReviews: 'Todos los comentarios',
  writeReview: 'Deje su comentario',
  fit: 'En forma',
  viewAll: 'Ver Todos',
  photos: 'Fotos',
  end: 'Fin',
  share: 'Compartir',
  qty: 'Qty',
  waistInfo: 'Encuentre la cintura natural, Esta es la parte más estrecha de la cintura, Normalmente alrededor de 1 inch(aprox. 2,5 cm)por encima del ombligo,Mantenga la cinta ligeramente suelta para permitir el espacio para respirar.',
  bustInfo: '¡ No se refiere a la talla de sujetador ! Utilice un sujetador sin relleno (el vestido tendrá un sujetador incorporado), Suelte los brazos a los lados, Tire de la cinta a través de la parte más completa del busto.',
  floorInfo: 'Póngase de pie con los pies  juntos, Mida con los pies descalzos,Empieza en el hueco entre las clavículas y tira de la cinta hacia el suelo. ',
  hipInfo: 'Encuentre la parte más ancha de las cadera, Normalmente alrededor de 7 pulgadas  (aprox. 18cm) por debajo de la cintura natural, La cinta debe rozar alrededor del trasero.',
  extraInfo: 'Para vestidos hasta el suelo en talla personalizada¡Elige la opción de longitud extra para adaptarse a la altura de tus tacones! es decir, para tacones de 2 pulgadas (5 cm),Seleccione "+2 pulgadas" (5 cm). Para medidas planas, ingrese "+0".',
  hemInfo: 'Póngase de pie con los pies descalzos juntos.Mida desde el centro de la clavícula hasta donde debe llagar el dobladillo delantero del vestido.',
  heightInfo: 'Póngase de pie con los pies  juntos,Mida con los pies descalzos, Empieza en la parte superior de la cabeza y tira de la cinta en línea recta hasta el suelo.',
  armholeInfo: 'Para tomar la medida de su sisa, debe envolver la cinta métrica sobre la parte superior de su hombro y alrededor de debajo de su axila.',
  armText1: "Esta es una medida alrededor de la parte más ancha de la parte superior del brazo.",
  descripts: 'Descripciones',
  processReturn: 'Tiempo de procesamiento y devoluciones',
  shipAddit: 'El tiempo de envío demora entre 1 y 10 días hábiles adicionales, según la ubicación. El tiempo de envío se proporcionará al momento del pago.',
  returns: 'Devoluciones',
  requestReturn: 'Solicitar devolución dentro de los 14 días siguientes a la recepción.',
  image: 'Imagen',
  max5Image: 'Imágenes de 5 MB como máximo.',
  confirm: 'Confirmar',
  selectSize: 'Por favor seleccione talla.',
  copyUrl: 'Copiar URL',
  oftenBought: 'Comprado a menudo con',
  failed: 'FALLIDO',
  //sizechart
  Bust: 'Busto',
  Waist: 'Cintura',
  Hips: 'Caderas',
  floor: 'Desde el cuello hasta el suelo',
  extra: 'Extra Length',
  htoHem: 'Desde el hueco hasta el dobladillo',
  toHem: 'Desde el hueco hasta el dobladillo(frente)',
  bareFoot: 'Altura (pie descalzo)',
  armhole: 'Sisa',
  armWidth: 'Ancho del brazo',
  footLen: 'Longitud del pie',
  toAnkle: 'Desde el hueco hasta el tobillo',
  toTea: 'Desde el hueco hasta el té',
  toKnee: 'Desde el hueco hasta la rodilla',
  ShoulderWidth: 'Ancho de hombros traseros',
  sleeveLen: 'Longitud de la manga',
  armscye: 'brazo',
  armCir: 'Circunferencia del brazo',
  child: 'Niña',
  //view bag
  viewBag: 'VER CESTA',
  checkout: 'Tramitar pedido',
  shopBag: 'Cesta',
  bag: 'Cesta',
  summary: 'Resumen de Pedido',
  subtotal: 'Subtotal',
  total: 'Total',
  goToCart: 'IR AL CARRITO',
  proceedCheck: 'PASAR POR LA CAJA',
  item: 'artículo',
  items: 'artículos',
  secureCheck: 'TRAMITAR PEDIDO SEGURO ',
  yourDiscount: 'Su código de promoción o descuento :',
  coupon: ' Cupón',
  couponCode: 'Código de cupón',
  useCoupon: 'Usar código de cupón',
  couponEnter: 'Por favor ingrese el código de cupón.',
  couponSuccess: '¡Le felicitamos! Usted tiene un cupón para ',
  couponError: 'Lo sentimos, ese código de cupón no válido.',
  cancel: 'Cancelar',
  weAccept: 'Aceptamos',
  safe: 'Seguridad y protección',
  safeInfo: 'Su información está siempre protegida.',
  saveWish: 'Guardar para lista de artículos deseados',
  removeBag: '¿Estás seguro de eliminar este artículo de tu cesta?',
  saveWishSure: '¿Estás seguro de guardar este artículo en tu lista de artículos deseados?',
  yes: 'Sí',
  bagTit: 'Cesta en STACEES',
  wishListSucc: 'El artículo ha sido añadido a tu lista de artículos deseados.',
  youLove: "¡TE ENCANTARÁN ESTOS ARTÍCULOS!",
  charMax: 'Caracteres máximos',
  bagEmpty: 'Su cesta está vacío',
  continueShopping: 'Continuar la compra',
  myBag: 'Mi cesta',
  itNotHave: "Pero no tiene por qué ser...",
  youMayAlso: 'También te puede gustar',
  remove: 'Eliminar',
  //checkout
  placeOrder: 'HACER UN PEDIDO',
  pay: 'PAGAR',
  orderComp: 'PEDIDO COMPLETADO',
  contact: 'Información de contacto',
  guestSign: 'Tramitar pedido como invitado o ',
  signIn: 'INICIAR SESIÓN',
  email: 'Correo electrónico',
  firstName: 'Nombre',
  lastName: 'Apellido',
  address: 'Dirección',
  apartment: 'Apartamento, edificio,etc. (Opcional)',
  countryRegion: 'Reino Unido',
  region: 'Estado/Provincia/Región(Opcional)',
  regionSel: 'Estado/Provincia/Región',
  city: 'Ciudad',
  postCode: 'Código postal/ZIP',
  gb: 'GB',
  phone: 'Teléfono',
  creatAcc: 'Crear cuenta (opcional)',
  save: 'GUARDAR',
  setDelivAddr: 'Establecer como dirección de envío por defecto',
  emailTip: "Le enviaremos la <br>confirmación de su pedido aquí.",
  phoneTip: "En caso de que necesitemos comunicarnos con<br> usted acerca de su pedido.",
  passwd: 'Contraseña Password',
  confirmPasswd: 'Confirmar la contraseña',
  addNewAddr: 'AÑADIR UNA NUEVA DIRECCIÓN',
  emailRequire: 'La dirección de correo electrónico es requerida.',
  enterEmail: 'Por favor, introduzca correo electrónico.',
  validEmail: 'Por favor, introduzca correo electrónico válida.',
  passwdRequire: 'Se requiere contraseña.',
  minChar: 'mínima de 6 caracteres',
  confirmPassRequire: 'Es necesario confirmar la contraseña.',
  passNoMatch: 'Contraseña no coincide',
  firstNameVerify: 'Tu Nombre debe contener por lo menos 2 caracteres.',
  lastNameVerify: 'Tu Apellido debe contener por lo menos 2 caracteres.',
  shippAddrVerify: 'La longitud mínima de la dirección de envío es de 5 caracteres.',
  countryVerify: 'El país es requerido.',
  cityVerify: 'La longitud mínima del nombre de ciudad es 3 caracteres.',
  regionVerify: ' Estado/Provincia/Región es requerido.',
  ziPostVerify: 'Código postal/ZIP es requerido.',
  phone6Verify: 'Tu número de teléfono debe contener por lo menos 6 dígitos.',
  passwd6Verify: 'Tu contraseña debe contener por lo menos 6 dígitos.',
  shipMethod: 'Métodos de envío',
  shipMd: 'Método de envío',
  standShip: 'Envío estándar',
  free: 'Gratis',
  estDelive: 'Entrega prevista',
  expShip: 'Envíos expeditos',
  duty: 'Seguro de aranceles',
  shipInsure: 'Seguro de envío',
  dutyTip: "Si compra un seguro de aranceles e impuestos y a su paquete se le cobran aranceles e impuestos adicionales al momento de la entrega, comuníquese con nuestro servicio de atención al cliente y le compensaremos los aranceles e impuestos que haya pagado.",
  shipTip: "El seguro de envío ofrece protección y seguridad premium para sus artículos valiosos durante el envío internacional. Reenviaremos su paquete de inmediato sin cargo adicional si se informa que se perdió o se dañó.",
  editBag: 'Editar la cesta',
  shipFee: 'Tarifa de envío',
  picture: "Foto",
  edit: 'Editar',
  delete: 'Borrar',
  delAddress: '¿Seguro que desea borrar esta dirección?',
  saveShipp: 'Por favor, guarde tu dirección de envío.',
  sizeDetail: 'Detalles de tamaño',
  addrBook: 'Directorio',
  default1: 'Por defecto',
  selected: 'Seleccionado',
  credit: 'Tarjetas de Crédito o Débito',
  creditNote: 'Nota : Por motivos de seguridad, no guardaremos ninguno de los datos de su tarjeta de crédito.',
  paymentTrouble: '¿Tiene problemas con <br> el pago?',
  paymentText: 'Puede contactarnos para obtener asistencia con el pago a través de :',
  openWait: 'Apertura, por favor espere...',
  failedPurchase: '¡Compra fallida! Por favor complete el pago nuevamente.',
  eml: 'Correo electrónico',
  //login
  signRegister: 'IDENTIFICARSE/REGISTRARSE',
  register: 'REGISTRARSE',
  newTo: 'NUEVO EN STACEES',
  join: 'UNIRSE',
  now: 'NOW',
  forgetPasswd: '¿Ha olvidado la contraseña',
  forgottenPasswdHead: 'Contraseña olvidada',
  forgottenPasswd: '¿Contraseña olvidada',
  or: 'o',
  signFackbook: 'Iniciar sesión con Facebook',
  signGoogle: 'Iniciar sesión con Google',
  iAccept: 'Acepto los',
  signFor: 'Regístrese para obtener un cupón de <span>{symbol}</span> en su cuenta.',
  accountPrivacy: 'Al crear una cuenta, usted acepta la <a class="udline" href="{hrefPrivarcy}" rel="external nofollow">Política de privacidad</a> de Stacees.com',
  orContinue: 'o seguir con',
  bySignIn: 'Al iniciar sesión, acepta nuestra <a href="{hrefPrivarcy}" rel="external nofollow">Política de privacidad</a> <br/>y <a href="{hrefTerms}" rel="external nofollow">Términos de servicio</a>.',
  clickJoinNow: `Al hacer clic en "Unirse ahora", acepta los <a href="{hrefTerms}" rel="external nofollow">Términos y condiciones</a> y la <a href="{hrefPrivarcy}" rel="external nofollow">Política de privacidad</a> de STACEES.`,
  //forgot
  veriCode: 'Código Verificación',
  codeReq: 'Se necesita código.',
  codeVerify: 'Por favor, introduzca el código Please enter Code',
  codeError: 'Código error Code is error',
  send: 'ENVIAR',
  forgotTxt1: "¿Cómo restablecer la contraseña?",
  forgotTxt2: "Escriba su dirección de correo electrónico de registro en la casilla de arriba y recibirá un correo con instrucciones para cambiar su contraseña.",
  forgotTxt3: "Escriba su dirección de correo electrónico",
  forgotTxt4: "Compruebe su correo electrónico",
  forgotTxt5: "Haga clic en el enlace para restablecer la contraseña",
  forgotTxt6: "Iniciar sesión con la nueva contraseña",
  forgotTxt7: "Se ha enviado un enlace para restablecer su contraseña a",
  forgotTxt8: "Los correeos pueden sufrir un retraso de unos minutos.",
  forgotTxt9: "Si no ha recibido un correo，compruebe sus carpetas de correo no deseado.",
  forgotTxt10: "Si sigues sin recibir el correo después de solicitar el restablecimiento de la contraseña, espera 24 horas y ",
  forgotTxt11: "vuelve a intentarlo",
  forgotTxt12: 'Introduce tu dirección de correo electrónico y te enviaremos un enlace para restablecer tu contraseña.Por seguridad, los enlaces de inicio de sesión son válidos por 30 minutos.',
  resetPass: 'Restablecer contraseña',
  createNewPass: 'CREAR UNA NUEVA CONTRASEÑA',
  newPassInfo: 'Gracias por volver. Introduzca los detalles de su nueva contraseña a continuación.',
  newPassTxt: "Su contraseña ha sido modificada.Inicie sesión nuevamente y estará listo para comenzar.",
  newPassRequire: 'Se requiere una nueva contraseña.',
  passMin6: 'La longitud de la contraseña debe tener un mínimo de 6 caracteres.',
  oldPassRequire: 'Se requiere la contraseña antigua.',
  passAgain: 'Por favor, introduzca su contraseña nuevamente.',
  submit: 'ENVIAR',
  back: 'Atrás',
  //member
  welcomeTo: 'BIENVENIDO A',
  yourAcct: 'Tu cuenta',
  hi: 'Hola',
  myOrders: 'Mis pedidos',
  myCoupons: 'Mis cupones',
  myReviews: 'Mis comentarios',
  myWishlist: 'Mi lista de deseos',
  myTickets: 'Consultas Postventa',
  myProfile: 'Mi perfil',
  changePassword: 'Cambiar contraseña',
  signOut: 'cerrar sesíon',
  orderEmpty: 'SU PEDIDO ESTÁ VACÍO',
  orderNo: 'El número de pedido',
  date: 'FECHA',
  status: 'Estado',
  open: 'Abierto',
  pending: 'Pendiente',
  closed: 'Cancelado',
  processing: 'Procesamiento',
  canceled: 'Cancelado',
  shipped: 'Enviado',
  completed: 'Completado',
  awaitPaym: 'Esperando pago',
  viewDetail: 'Ver detalles',
  reorder: 'PEDIR DE NUEVO',
  ticketServ: 'Servicio de Consultas Postventa',
  payNow: 'PAGA AHORA',
  cancelOrder: '¿Estás seguro de que deseas cancelar el pedido?',
  shipAddr: 'Dirección de envío',
  billAddr: 'Dirección de facturación',
  delivery: 'OPCIONES DE ENTREGA',
  paymentMeth: 'Opciones de pago',
  subTot: 'Subtotal',
  grandTotal: 'Suma total',
  wishlistEmpty: '¡Su lista de deseos está vacía!',
  dateOfBirth: 'FECHA DE NACIMIENTO',
  gender: 'Sexo',
  ms: 'Sra',
  mr: 'Sr',
  mPrivacy: 'Privacidad',
  saveChanges: 'GUARDAR CAMBIOS',
  firNameRequire: 'El nombre es requerido.',
  lastNameRequire: 'El apellido es requerido.',
  optional: 'Opcional',
  oldPassword: 'Contraseña antigua',
  newPassword: 'Nueva contraseña',
  changed: 'CAMBIAR',
  enterPass: 'Por favor, introduzca su contraseña.',
  ticketId: 'ID de Consulta Postventa',
  type: 'Tipo',
  lastUpdate: 'Última actualización',
  changeSucc: '¡Cambiado con éxito!',
  saveSucc: '¡Guardado con éxito!',
  brith: 'Elija cumpleaños',
  //ticket
  creatTicket: 'Crear una consulta de postventa',
  backOrder: 'Volver al pedido',
  selectTopic: 'Seleccione un tema',
  backTicket: 'Volver a la lista de consulta postventa',
  problemSoved: 'Su problema ha sido resuelto. Este  consulta postventa ha sido cerrado',
  addAPhoto: 'Añadir una foto',
  photoTip: 'Máximo <span class="cr-3c">3</span> fotos.Utilice únicamente archivos <span class="cr-3c">JPG/PNG/GIF/BMP. </span>El tamaño de cada foto individual no puede exceder <span class="cr-3c">8MB.</span>',
  conversReq: 'Se requiere conversación.',
  photoTipReq: 'Máximo 3 fotos.Utilice únicamente archivos JPG/PNG/GIF/BMP. El tamaño de cada foto individual no puede exceder 8MB',
  ticketReceive: 'Su consulta postventa ha sido recibido, le responderemos en breve.',
  produ: 'Producto',
  orderTxt1: 'Los pedidos cancelados dentro de las 12 horas posteriores a la confirmación del pago serán elegibles para un reembolso parcial consistente en el costo total de envío y el 90% del precio de compra del producto.',
  orderTxt2: 'Los pedidos cancelados dentro de las 12 a 24 horas posteriores a la confirmación del pago serán elegibles para un reembolso parcial consistente en el costo total de envío y el 80% del precio de compra del producto.',
  orderTxt3: 'Los pedidos cancelados dentro de las 24 a 48 horas posteriores a la confirmación del pago serán elegibles para un reembolso parcial que consiste en el costo total de envío y el 50% del precio de compra del producto.',
  orderTxt4: 'Los pedidos cancelados más de 48 horas después de la confirmación del pago serán elegibles para un reembolso parcial únicamente del costo total de envío.',
  orderTxt5: 'Una vez que su pedido haya sido enviado, ya no podrá cancelarse.',
  phoneNum: 'Número de teléfono',
  message: 'Mensaje',
  messageTip: 'Escribe tu mensaje con detalle en 5-1000 caracteres ...',
  messageReq: 'El mensaje es requerido.',
  photoCode: 'Por favor, introduzca el código de verificación que aparece en la imagen : *',
  ticketBtn: 'Enviar Consultas Postventa ',
  topicReq: 'El tema es requerido.',
  yourNameRequ: 'Su nombre es requerido.',
  codeRequ: 'Se requiere código de verificación.',
  verCodeErr: 'El código de verificación es un error',
  'Dispute': 'Disputar',
  'Color deviation': "Desviación de color",
  "Size issues": "Problemas de tamaño",
  "Late for delivery": "Retraso en la entrega",
  "Differ from the pictures": "Diferencia con las fotos",
  "Quality deviation": "Desviación de calidad",
  "Customs&Tax": "Aduanas e impuestos",
  "Shipping problem": "Problema de envío",
  "Other problems": "Otros problemas",
  'Products cancellation': 'Cancelación de productos',
  'Event was canceled': 'Evento fue cancelado',
  'Worry about the quality': 'Preocuparse por la calidad',
  'Time is not enough': 'El tiempo no es suficiente',
  'Ordered wrong items': 'Pedí artículos incorrectos',
  'Found better service from other shops': 'Encontraste un mejor servicio en otras tiendas',
  'Order modification': 'Modificación de pedido',
  'Style': 'Estilo',
  'Delivery date': 'Fecha de entrega',
  'Others': 'otros',
  'Size': 'Tamaño',
  'Address': 'Dirección',
  addConver: 'Añadir una conversación',
  proInfo: 'Información del producto',
  loadFailed: 'Error al cargar, por favor actualice la página.',
  //review
  viewReview: 'Ver comentario',
  yourName: 'Su nombre',
  reviewTit: 'Título de su comentario',
  reviewTitTip: 'Dinos qué piensas',
  yourReview: 'Su comentario',
  yourReviewReq: 'Se requiere su comentario.',
  commentsTip1: 'CONSEJOS:\n¿Está satisfecho con su compra? \nPuede describir el producto en términos de calidad, estilo, aptitud, material, etc.',
  addPhoto: 'Añadir fotos (opcional)',
  toUpload: 'Haga clic aquí para subir',
  nameReq: 'El nombre es requerido.',
  reviewTitReq: 'El título de su comentario es requerido.',
  reviewReq: 'Se requiere comentario.',
  imageMax5: '5 fotos máximo, 5 MB máximo por foto.',
  revHeadlineReq: 'El título del comentario es requerido.',
  ProCommentsReq: 'Se requieren comentarios sobre el producto.',
  ReviewsEmpty: 'EL COMENTARIO ESTÁ VACÍO',
  //coupon
  allCoupons: 'Todos los cupones',
  availableCoupons: 'Cupones disponibles',
  expiredCoupons: 'Cupones caducados',
  expiredeEmpty: 'Sus cupones caducados están vacíos.',
  availableEmpty: 'Sus cupones disponibles están vacíos.',
  newRegistDiscount: 'Descuento para nuevos registrados',
  orderOver: 'pedido superior a',
  expires: 'Vence',
  copyAndUse: 'Copiar y utilizar en la página de tramitar pedido',
  code: 'Código',
  copy: 'Copiar',
  successCopy: '¡Copia exitosa!',
  //Order tracking
  trackYourOrder: 'Rastrear su pedido',
  sigleOrder: 'Buscar un solo pedido',
  orderInfo: 'Introduzca su número de pedido y su correo electrónico para ver los detalles de su pedido.',
  orderQues: '¿Dónde puedo encontrar esto?',
  orderTrackInfo: 'Nuestro número de pedido está incluido en el correo electrónico que recibió cuando realizó su pedido. Si ya no tiene este correo electrónico,<a class="cr-bd" href="{hrefContact}">comuníquese con nosotros</a>.',
  orderHistory: 'Ver historial de pedidos',
  signAccount: 'Acceda a su cuenta para ver todos sus pedidos.',
  orderRequire: 'El número de pedido es requerido.',
  trackText: 'Si ya tiene una cuenta, identifíquese para comprobar su pedido.',
  trackingOrder: 'Rastrear pedido',
  orderTrack: 'Rastrear pedido',
  trackOrderInfo1: 'Si desea consultar más información sobre su pedido, puede registrarse con la dirección de correo electrónico ',
  trackOrderInfo2: 'Si tienes más preguntas, puedes <a href="{url}">crear un ticket</a> en su cuenta.',
  trackShip: 'Seguir el envío',
  placed: 'Realizado',
  material: 'Preparación del material',
  inProduct: 'En producción',
  quality: 'Inspección de calidad',
  parcel: 'paquete',
  shippedBy: 'Enviado por ',
  trackNumb: 'Número de rastreo:',
  trackinfo: 'Este pedido ha sido enviado por transportista, se espera que la información de seguimiento se actualice dentro de 1-3 días.',
  paid: 'Pagado',
  shipment: 'Envío',
  take: 'Llevar',
  dayArrive: 'días para llegar',
  complete: 'Completo',
  //gallery
  note: 'Nota :',
  sahreNote: '1 vídeo como máximo, 50 MB como máximo por vídeo,<br/>o 5 fotos máximo, 5 MB máximo por foto.',
  sahreNoteReq: '1 vídeo como máximo, 50 MB como máximo por vídeo,o 5 fotos máximo, 5 MB máximo por foto.',
  next: 'Siguiente',
  name: 'Nombre',
  revHeadline: 'Título del comentario',
  ProComments: 'Comentarios sobre el producto',
  commentsTip: '(¿Está satisfecho con su compra? Puede describir el producto desde la calidad, el estilo, la forma física, el material, etc.)',
  shareSucTxt1: '¡GRACIAS ENCANTADOR!',
  shareSucTxt2: '¡Verificaremos dos veces la foto solo para asegurarnos de que todo esté bien!',
  shareSucTxt3: 'Su foto se publicará después de la aprobación.¡Gracias, cariño!',
  continueUpload: 'SEGUIR SUBIENDO',
  viewGall: 'VER GALERÍA',
  uploadVideoReq: 'Por favor utilice únicamente de video MP4 o  imagen JPG/PNG/JPEG/BMP. No cargue imágenes y videos al mismo tiempo.',
  shareStory: 'Compartir su historia',
  all: 'Todo',
  emptyRefresh: '¿Vacío? Por favor, actualice la página',
  shopLook: 'COMPRAR EL LOOK',
  photosReq: 'Se requieren fotos.',
  //about
  ourStory: 'nuestra historia',
  about1: 'Establecida en 2012, STACEES es una tienda en línea de vestidos de novia, vestidos para eventos especiales, vestidos para fiestas de boda y accesorios. Los clientes pueden explorar una amplia selección en línea y elegir sus vestidos favoritos con gran satisfacción. Nos comprometemos a proporcionar a nuestros clientes productos de alta calidad y servicios de alta calidad.',
  designServ: 'EDISEÑO Y SERVICIO',
  about2: 'En STACEES diseñamos y desarrollamos cada vestido con un corte boutique. Ajustamos cada prenda a medida para asegurarnos de que sea exactamente lo que deseas. Nos encargamos de cada pequeño detalle, incluso una cremallera. Una vez que realices tu pedido en STACEES, el tejido se corta y cose a mano, de esta manera reducimos los residuos y nuestro impacto en el medio ambiente, todo sin costo adicional para ti.',
  about3: 'La calidad del vestido generalmente comienza desde el momento de la selección de la tela. Ponemos mucho esfuerzo en este proceso. Comparamos varios materiales, teniendo en cuenta el color, la suavidad, la comodidad y la durabilidad, para asegurarnos de que no sea rígido ni parezca barato. La silueta perfecta se combina con la tela más adecuada para exudar sofisticación y encanto.',
  about4: 'Cada chica merece disfrutar del servicio y la calidad de STACEES. Para ofrecer precios más bajos y competitivos, desafiamos el modelo de negocio tradicional de la industria al tener nuestra propia producción, marca y canales de marketing, para que puedas obtener el vestido de tus sueños de alta calidad al precio más asequible.',
  milestiones: 'nuestros hitos',
  sustainability: 'Sostenibilidad y responsabilidad social',
  about5: 'En los últimos años, STACEES ha adoptado la sostenibilidad, reconociendo la importancia de la responsabilidad ambiental en la moda. La marca ha introducido materiales y prácticas ecológicas en su proceso de producción, reduciendo su huella de carbono mientras mantiene los altos estándares de calidad que sus clientes esperan. Además, STACEES ha participado en iniciativas filantrópicas, apoyando causas que empoderan a las mujeres y promueven la diversidad.',
  staGirls: 'CHICAS DE STACEES',
  about6: "Como habrás visto, estamos muy preocupados por la experiencia de compra de ropa, y nos enorgullece cada hermoso momento para cada chica. Estas encantadoras fotos y las narrativas sinceras que las acompañan son las joyas de nuestra corona y la fuerza impulsora detrás de nuestros servicios. Aquí, cada foto es un testigo de alegría, y cada palabra refleja nuestro compromiso con tu satisfacción.",
  stayConnent: `Mantengámonos conectados`,
  about7: `Únete a nuestra comunidad: síguenos en las redes sociales para conocer nuestras últimas colecciones y anuncios, y etiqueta a @STACEESOFFICIAL para que podamos ver cómo estilizas tus artículos favoritos. ¡No podemos esperar para conocerte!`,
  about8: 'Fundada en 2012, llena de visión ilimitada y amor por la moda, con la máxima búsqueda de la belleza, decidimos crear nuestra marca: ¡STACEES!',
  about9: 'Expansión al Reino Unido.',
  about10: 'Establecimiento de nuestra propia fábrica, equipada con sofisticados sistemas ERP.',
  about11: 'Fortalecimiento del control de calidad y mejora del centro de atención al cliente para mejorar la experiencia del cliente.',
  about12: "STACEES introdujo materiales y prácticas ecológicas en su proceso de producción, reduciendo su huella de carbono mientras mantiene los altos estándares de calidad que sus clientes esperan. ",
  about13: 'Con planes de expansión continua de productos, avances tecnológicos y un compromiso más profundo con la sostenibilidad.',
  about14: 'Lanzamiento de la aplicación en línea de STACEES. Actualizaciones sobre las últimas noticias, promociones especiales y eventos de STACEES.',
  about15: 'Nuestra aplicación se lanzará en 2025.',
  about16: 'Contamos con nuestra propia producción, marca, marketing y canales de venta, rompiendo así con el modelo de negocio tradicional de la industria. Tradicionalmente, la industria de la vestimenta formal ha estado descentralizada, con diferentes participantes responsables de diferentes pasos del proceso. Hemos integrado estos pasos bajo el paraguas de Stacees para simplificar el proceso, agilizar la entrega y hacer que los precios sean más transparentes.',
  //shipping info
  shipPolic:'Políticas de envío',
  shipInfor:'Información de envío',
  shipInfo1: 'Regiones de envío, métodos de envío, tiempo y gasto de envío e información adicional sobre el proceso de envío de STACEES ',
  shipInfo2: 'Nuestros productos pueden entregarse en todo el mundo. Enviamos nuestros productos mediante los siguientes transportistas: UPS, DHL, TNT, FedEx, etc.El tiempo de envío varía en función de la distancia de envío. La eficacia de los transportistas locales también afecta al plazo de entrega. El tiempo de envío desde nuestras fábricas hasta su destino es de entre 2 y 15 días laborables. El tiempo exacto de envío depende de los estándares del transportista (UPS, DHL, TNT, FedEx, etc.) y del método de envío que elija. Por lo general, el tiempo y el coste de envío a diferentes zonas son los siguientes:',
  shipInfo3: 'África',
  shipInfo4: 'Países insulares en el océano',
  shipInfo5: 'Reino Unido',
  shipInfo7: 'Otros países de Europa',
  shipInfo8: 'EE.UU. y Canadá',
  shipInfo9: 'Otros países de Norteamérica',
  shipInfo10: 'Otros países de Sudamérica',
  shipInfo11: 'Australia',
  shipInfo12: 'Otros países de Oceanía',
  shipInfo14: 'Los días arriba indicados son todos laborables. Durante los días festivos importantes, el número de fletes del aeropuerto aumentará y el transporte se retrasará. Si necesita su pedido con urgencia, póngase en contacto con nosotros con antelación e infórmenos de la fecha que necesita. Lo organizaremos para usted lo antes posible.',
  shipInfo15: 'TARIFA DE ENVÍO: Nuestros gastos de envío dependen del peso total de tu pedido y del país de destino de los vestidos.',
  shipInfo16: 'Antes de enviar su pedido, el sitio STACEES debe procesar sus pedidos y pasar estrictas pruebas de control de calidad en todos sus proyectos. Por lo tanto, el plazo de entrega total es diferente del plazo de envío por separado',
  shipInfo17: 'Producción y plazos de entrega',
  shipInfo18: 'Tiempo total de entrega = Tiempo de procesamiento + Tiempo de envío',
  shipInfo19: 'Si su pedido contiene varios artículos, se enviarán juntos.',
  shipInfo20: 'En general, dependiendo de la complejidad de los vestidos.El tiempo de procesamiento difiere de una categoría a otra. Por favor refiérase a la siguiente tabla para un procesamiento más preciso para estimar mejor cuándo recibirá su pedido:',
  shipInfo23: 'El tiempo de procesamiento específico depende de los artículos. El plazo de entrega de accesorios, muestras y algunos artículos depende de nuestro inventario y puede ser de 24 horas cuando tenemos existencias suficientes.',
  shipInfo24: 'Procesaremos su pedido en un plazo de 24 horas e informaremos a nuestros sastres para que comiencen a confeccionar los vestidos para usted. Por lo tanto, si desea cancelar el pedido, comuníquese con nosotros en un plazo de 24 horas y le cobraremos la cancelación del pedido por más de 24 horas, ya que la tela utilizada en el vestido ha sido cortada. Para conocer la política de cancelación detallada, consulte nuestra página de <a href="{hrefOrderCan}">Cancelación de pedidos</a>.',
  shipInfo26: 'También ofrecemos un servicio acelerado para clientes que necesitan un vestido con urgencia. Puedes optar por el servicio de pedido urgente para algunos estilos. Puedes elegir la fecha de llegada en la opción de nuestra página de productos. Cuando elijas una fecha muy temprana, el sistema calculará automáticamente el costo adicional de la producción urgente para tu vestido y habrá un cartel visible en el precio. Sin embargo, si recibes el vestido en una fecha muy tardía, te daremos un descuento del 5%. Para tener tiempo suficiente para hacerte un vestido más ajustado y sofisticado, es mejor que hagas tu pedido con anticipación.',
  shipInfo27: 'Además, si no recibe su pedido en el plazo elegido, le ofreceremos un reembolso limitado.',
  shipRegion: 'Regiones de envío',
  shipCost: 'Gastos de envío',
  from: 'Desde',
  shipInfo28: 'Países de Europa',
  rushOrder: 'Pedido urgente',
  category: 'Categoría',
  specialOccasion: 'Vestidos para ocasiones especiales',
  motherTheBride: 'Vestidos de madrina',
  accessories: 'Accesorios',
  shoes: 'Zapatos',
  swatch: 'Muestras',
  shipp: 'Envío',
  shipInform: 'Información de envío',
  //faq
  faqs: 'Preguntas frecuentes',
  askQuest: 'Preguntas frecuentes',
  livechat: 'Chat en directo',
  payment: "Pago",
  paymentCoupons: "Pagos y cupones",
  sizeFit: 'Tallas y ajuste',
  addition: 'Información adicional',
  faq1: '¿Cómo puedo consultar el estado de mi pedido y hacer un seguimiento de mi pedido?',
  faq2: "Una vez que hayas realizado tu pedido, puedes consultar su estado en cualquier momento en '<a class='myOrder' href='javascript:void(0)'>Mi cuenta</a>'. Podrás ver el paso actual de tu pedido. Alternativamente, puedes utilizar la función '<a href={hrefTrack}>Rastreo de pedido</a>': simplemente introduce tu número de pedido y dirección de correo electrónico en el cuadro que aparece en la página y te mostrará el estado exacto de tu pedido.",
  faq3: 'Si tienes alguna pregunta sobre el estado de tu pedido, también puedes enviarnos un correo electrónico a <a href="mailto:service@stacees.com">service@stacees.com</a> o contactarnos a través de <a href="javascript:void(0)" class="livechat">LiveChat</a> para más ayuda.',
  faq4: '¿Puedo modificar mi pedido? ',
  faq5: 'Para asegurarnos de que cada pedido cumpla con tus expectativas, siempre te enviamos un correo electrónico de confirmación para verificar los detalles. Aún puedes hacer cambios en tu pedido dentro de un cierto plazo. Sin embargo, para garantizar una entrega oportuna, los pedidos se procesarán después de 12 horas desde el correo electrónico de confirmación, o una vez que no se hayan enviado consultas.',
  faq6: 'Por favor, entiende que una vez que el pedido ha sido procesado, no puede modificarse fácilmente debido a la programación, el abastecimiento de materiales y los costos de sastrería.',
  faq7: '¿Puedo cancelar mi pedido después de que haya sido enviado?',
  faq8: 'No podemos modificar ni cancelar tu pedido después de que haya sido enviado. No obstante, estaremos encantados de ayudarte con el proceso de devolución una vez que recibas tus artículos (dentro de nuestra política de devoluciones).',
  faq9: '¿Por qué no recibí el correo electrónico de confirmación de Stacees?',
  faq10: 'Nuestros correos electrónicos de confirmación se envían automáticamente desde nuestro sistema. Si no recibes un correo electrónico de confirmación dentro de las 12 horas posteriores a la realización de tu pedido (aunque el pago haya sido cargado a tu tarjeta de crédito o cuenta de PayPal), por favor contáctanos de inmediato. Verificaremos tu pedido en nuestro sistema y reenviaremos el correo electrónico de confirmación, el cual incluirá tu número de pedido y los detalles.<br/>Aquí hay algunas posibles razones por las que no hayas recibido el correo:',
  faq11: '<span>1. Dirección de Correo Electrónico Incorrecta:</span> Si la dirección de correo electrónico ingresada durante el registro es incorrecta o rara vez se usa, nuestro correo de confirmación puede no haberte llegado. Por favor, asegúrate de que la dirección de correo electrónico proporcionada sea precisa y monitoreada regularmente.',
  faq12: '<span>2. Filtros de Correo Electrónico:</span> Algunos sistemas de correo pueden clasificar erróneamente nuestros correos como basura o spam. Antes de contactarnos, por favor revisa tu carpeta de correo no deseado o spam, ya que existe una alta probabilidad de que nuestro correo haya sido redirigido allí.',
  faq14: '¿Puedo agregar o quitar artículos de mi pedido?',
  faq15: '<span>Para eliminar/cancelar:</span><br/>Si desea eliminar un artículo de su pedido, asegúrese de que la solicitud esté dentro de la política de cancelación y comuníquese con nuestro equipo de atención al cliente para procesarla. Estamos dispuestos a ayudar a nuestros clientes a resolver el problema.',
  faq16: `<span>Para agregar un artículo:</span><br/>Entendemos perfectamente que a veces es difícil tomar una decisión, pero en este momento, nuestro sistema no es lo suficientemente avanzado como para que podamos agregar artículos a pedidos existentes. Si lo desea, puede realizar un nuevo pedido por separado con el producto que desea.`,
  faq17: '¿Puedo cancelar mi pedido?',
  faq18: `Todos nuestros artículos se fabrican a pedido, incluidos los artículos de tamaño estándar. Si desea cancelar un pedido por cualquier motivo, consulte nuestra política de cancelación a continuación: <a href="{hrefOrderCan}">Política de cancelación de pedidos</a>`,
  faq13: `Si confirma la cancelación del pedido, no dude en comunicarse con nosotros a través de <a href="javascript:void(0)" class="livechat">LiveChat</a> o envíe un correo electrónico a <a href="mailto:service@stacees.com">service@stacees.com</a> para enviar su solicitud de cancelación. Estamos aquí para ayudarlo.`,
  faq19: 'Soy una novia y necesito encontrar un vestido para mis damas de honor, ¿hay algo que deba saber antes de realizar el pedido?',
  faq20: '<span>Asegúrese de tener suficiente tiempo para realizar el pedido con anticipación</span><br/>Dése tiempo suficiente para elegir y tomar una decisión, asegúrese de que el estilo/color del vestido se adapte al tema del evento. Entendemos profundamente que es un gran día para ti, así que tómate tu tiempo y no dudes en contactarnos para obtener más ayuda.',
  faq21: `<span>Pídele a cada dama de honor que se tome una medida rápida</span><br/>Aunque nuestro cliente puede solicitar la devolución de su pedido, esperar a que se entregue el paquete de devolución también consumirá algo de tiempo y energía. Para evitar que el tamaño del vestido no se ajuste, esperamos profundamente que puedas tomar sus medidas para compararlas con nuestra tabla de tallas en línea para encontrar qué tamaño podría quedar mejor. Si no sabes qué talla debes pedir, contáctanos para obtener más ayuda, te ayudaremos a encontrar qué talla te quedará mejor.`,
  faq22: '<span>Recuerda comprar los productos al mismo tiempo</span><br/>Ya sea que hagas un pedido completo para todos los vestidos de las damas de honor o que las damas de honor compren su vestido por separado por sí mismas. Asegúrate de que la tela utilizada para estos vestidos sea del mismo lote.<br/>Porque la tela a veces se agota y necesitamos reponerla. Cada vez que se tiñe el producto, el color puede variar ligeramente. Si no sabe qué lote se utilizará para su pedido, comuníquese con nosotros para obtener más detalles. Lo ayudaremos a pedirle al sastre que los haga en el mismo lote para evitar que el color sea diferente.',
  faq23: '¿Dónde puedo obtener el código de descuento?',
  faq24: `Para nuestros nuevos clientes, después de que se registren, les proporcionaremos un código de descuento de $5 en su cuenta. Puede encontrarlo en '<a class="coupons" href="javascript:">Mis cupones</a>.'`,
  faq25: `También nos hemos asociado con Student Beans para ofrecer descuentos exclusivos a nuestros clientes. Puede hacer clic en el siguiente enlace para consultar más detalles: <a class="student" href="javascript:">https://www.stacees.com/us-es/student-discount</a>`,
  faq26: '¿Dónde puedo usar el cupón?',
  faq27: 'Complete el cupón en el cuadro de código de cupón que se muestra en la página de pago y luego aplíquelo. Todos los cupones se pueden usar allí.',
  faq28: '¿Puedo usar varios cupones en un pedido?',
  faq29: 'Lo sentimos, cada pedido solo puede aplicar un cupón a la vez. ',
  faq30: '¿Qué tipo de tarjetas se pueden usar para pagar? ',
  faq31: 'La mayoría de las tarjetas de crédito y débito se pueden pagar fácilmente aquí. Aceptamos tarjetas de crédito, PayPal y pagos a plazos, etc.<br/>Consulte: <a href="{hrefPayment}">https://www.stacees.com/us-es/payment-methods</a>',
  faq32: `¿Puedo obtener más descuentos en pedidos múltiples o al por mayor?`,
  faq33: 'Hemos hecho todo lo posible para fabricar la mayoría de los vestidos a un precio razonable y asequible. Para que cada niña tenga su estilo favorecedor en su evento, nos complace hacer un trato en los pedidos al por mayor según la cantidad. Para obtener más detalles sobre los descuentos, comuníquese con nosotros en línea a través del <a href="javascript:void(0)" class="livechat">LiveChat</a> o envíenos un correo electrónico a: <a href="mailto:service@stacees.com">service@stacees.com</a>.',
  faq34: '¿Cuánto tiempo suele tardar en llegar mi pedido?',
  faq35: 'El tiempo estimado de llegada (ETA) = tiempo de confección + tiempo de envío.<br/>En la página de pago, verá un plazo de entrega estimado para su pedido, que también se indicará en el correo electrónico de confirmación del pedido. Además, encontrará el tiempo estimado de llegada para su área y el tiempo estimado de confección en la página de detalles del producto.',
  faq36: '¿Qué métodos de envío puedo elegir?',
  faq37: 'En la página de pago, ofrecemos 2 métodos de envío diferentes para nuestros clientes.<br/><span>1. Envío estándar</span><br/><span>2. Envíos expeditos</span>',
  faq38: `Recuerda que el tiempo estimado de transporte solo está disponible para EE. UU. y puede variar según el país. (Nota: El envío acelerado no está disponible para pedidos que incluyan Zapatos, Bolsos, Accesorios o Muestras/Telas. Si tu carrito contiene productos hechos por encargo y artículos “Envío en 48 HORAS” o una mezcla de productos urgentes y no urgentes, el envío acelerado no estará disponible en la página de pago).`,
  faq39: `<span>¿Qué significa “Envío en 48 HORAS”?</span>Nuestro sastre confeccionará tu vestido y lo enviará dentro de las 48 horas, pero ten en cuenta que después de ser enviado, el envío aún requiere tiempo. (Ten en cuenta que para los productos "Envío en 48 HORAS", no ofrecemos envío acelerado, ya que es el envío más rápido disponible).`,
  faq40: 'El pedido es para un evento muy urgente al que debo asistir. ¿Qué debo hacer?',
  faq41: 'El equipo de Stacees está comprometido con cada cliente y dispuesto a ayudar en todo lo posible. Si tu evento es pronto y aún deseas recibir el vestido, contáctanos por <a href="javascript:void(0)" class="livechat">LiveChat</a> en nuestro sitio web o por correo electrónico a <a href="mailto:service@stacees.com">service@stacees.com</a>. Indícanos la fecha de tu evento y el número de tu pedido (o el número SKU del vestido al que te refieres).',
  faq42: '¿Pueden entregar a un apartado de correos?',
  faq43: 'Recuerda que solo podemos entregar a apartados de correos mediante envío estándar, NO envío acelerado. Asegúrate de ingresar la dirección correcta para evitar problemas con la entrega.',
  faq44: `¿Qué hago si mi país no está listado en la página de pago?`,
  faq45: 'En este momento, no podemos enviar nuestros productos a países que no estén listados. Sin embargo, si realmente deseas un vestido de Stacees, puedes comprarlo en cualquier momento y enviarlo a un amigo o familiar en un país al que podamos hacer envíos, y luego pedirles que te lo envíen. Solo ten en cuenta que tenemos un tiempo limitado para devoluciones.',
  faq50: 'Mi paquete fue devuelto al remitente. ¿Qué debo hacer?',
  faq51: 'Tu pedido puede haber sido devuelto debido a una dirección de envío incorrecta o información del destinatario inválida. Puedes contactar con el transportista correspondiente, como USPS, DHL, etc., o por favor, comunícate con nosotros a través de <a href="javascript:void(0)" class="livechat">LiveChat</a> o por correo electrónico a <a href="mailto:service@stacees.com">service@stacees.com</a> para más ayuda. ',
  faq52: `¿Por qué mi pedido aparece como entregado, pero no lo he recibido?`,
  faq53: `Si tu paquete aparece como entregado pero no lo has recibido, estaremos encantados de proporcionarte consejos útiles para ayudarte a encontrar el paquete:`,
  faq54: `<span>-Revisa tu buzón, patio trasero, puerta principal o garaje.<br/>
  -Revisa con tus vecinos, oficina de arrendamiento o área de recepción central.<br/>
  -Pregunta a otros miembros de tu hogar, compañeros de trabajo o vecinos que puedan haber recibido el paquete cuando no estabas disponible.<br/>
  -Contacta con el equipo de entrega local para consultar sobre el paquete.</span>`,
  faq55: 'Si aún no puedes localizar tu paquete, por favor contacta con nosotros a través de <a href="javascript:void(0)" class="livechat">LiveChat</a> o por correo electrónico a <a href="mailto:service@stacees.com">service@stacees.com</a> para más ayuda.',
  faq56: 'Si mi pedido se retrasa, ¿qué debo hacer?',
  faq57: 'Si no recibiste el vestido dentro del plazo indicado en el recibo, comunícate con nosotros a través de <a href="javascript:void(0)" class="livechat">LiveChat</a> o por correo electrónico a <a href="mailto:service@stacees.com">service@stacees.com</a> para obtener más ayuda.',
  faq58: 'Durante el envío, ¿puedo cambiar mi dirección de entrega por otra diferente?',
  faq59: `Ten en cuenta que una vez que tu pedido se haya enviado y esté en tránsito, no podremos actualizar la dirección de envío. El paquete se enviará a la dirección original que proporcionaste.<br/>Puedes intentar comunicarte con la empresa de mensajería para verificar si pueden ayudarte a cambiar la dirección de entrega.`,
  faq60: '¿Se me cobrará una tarifa de aduana?',
  faq61: 'La mayoría de nuestros artículos no incurren en cargos adicionales de aduana. Sin embargo, cada país tiene diferentes regulaciones respecto a las tarifas aduaneras. Recomendamos a nuestros clientes seleccionar el seguro de aduanas en la página de pago al realizar el pedido.',
  faq62: '¿Qué tipo de empresa de mensajería utilizarán para mi pedido?',
  faq63: 'Por lo general, enviaremos el paquete a través de USPS, UPS, Yodel (solo en el Reino Unido), DHL, FedEx, etc. La empresa de mensajería para tu pedido se selecciona automáticamente en función de la dirección de envío y no se puede cambiar.',
  faq64: 'Usé la dirección incorrecta para mi pedido, ¿qué debo hacer?',
  faq65: `Si ingresaste la dirección incorrecta al realizar el pedido, es importante cambiar tu dirección lo antes posible después de realizar el pedido. Por favor, comunícate con nosotros a través de <a href='javascript:void(0)' class='livechat'>LiveChat</a> y envíanos la dirección correcta.<br/>(Por favor, ten en cuenta que cada vez que un cliente realiza un pedido, nuestro sistema enviará automáticamente el correo de confirmación, el cual incluirá la información básica del pedido).`,
  faq66: '¿Puedo devolver el vestido cuando lo reciba?',
  faq67: 'Si cambias de opinión sobre el pedido, por favor, envía la solicitud de devolución dentro de los 14 días posteriores a la recepción del pedido. Ten en cuenta que estos son días calendario, por lo que los fines de semana también se consideran. Además, este período de devolución se refiere al plazo para enviar tu solicitud; siempre que la envíes dentro de este plazo, el tiempo de procesamiento no se incluye. Luego, te asistiremos durante todo el proceso hasta que el problema se resuelva satisfactoriamente.',
  faq68: '¿Puedo devolver el vestido cuando lo reciba?',
  faq69: 'Puedes crear una solicitud de devolución dentro de los 14 días posteriores a la recepción de tu pedido. Por favor, proporciona el motivo de la devolución junto con cualquier fotografía relevante para su verificación. Nuestro equipo de servicio te responderá lo antes posible. Si no tienes cuenta, por favor, "<a href="{hrefLogin}">Regístrate</a>" en nuestro sitio web con el correo electrónico que utilizaste para realizar el pedido, y luego podrás "Crear una consulta postventa". Sigue la imagen:',
  faq70: 'He devuelto el vestido, ¿cuándo puedo esperar recibir el reembolso?',
  faq71: 'Realizaremos el reembolso al recibir el vestido que devuelvas. Generalmente, recibirás el reembolso dentro de 2-5 días.',
  faq72: '¿Dónde debo devolver mi vestido?',
  faq73: 'Por favor, contacta con nuestro departamento de atención al cliente y solicita la devolución de tu pedido. No devuelvas el paquete directamente a la dirección que aparece en nuestro sitio web o en el paquete, ya que no es nuestra dirección de devolución.',
  faq74: '¿Puedo devolver el vestido y solicitar un cambio o una modificación?',
  faq75: `Lamentablemente, actualmente no ofrecemos un servicio de cambio o modificación para ningún artículo. Devuelve el pedido y realiza uno nuevo. <br/>
Sugerimos a nuestros clientes que lleven el vestido a un sastre local para que realice la modificación en lugar de devolverlo a nosotros, lo que podría ser más rápido. <br/>
También ofrecemos un reembolso limitado por la modificación de todos los vestidos. Puedes ponerte en contacto con nuestro departamento de posventa enviando una consulta postventa.`,
  faq76: '¿Puedo devolver un vestido de talla personalizada?',
  faq77: `Todos los pedidos de talla personalizada se confeccionan a medida según tus especificaciones. No podemos revender un pedido de talla personalizada a otro cliente. Por lo tanto, no aceptaremos devoluciones ni reembolsos de pedidos de talla personalizada a menos que haya un problema de calidad. `,
  faq78: '¿Todos los vestidos tienen un sujetador incorporado?',
  faq79: "Sí, para brindar soporte y protección al busto, el equipo de Stacees incluirá un sujetador incorporado en cada vestido, excepto en los <a href='{hrefFlower}'>vestidos de pajecitas</a>.",
  faq80: '¿Puedo personalizar la parte superior e inferior del vestido con diferentes telas/colores?',
  faq81: ' Stacees tiene su propio equipo de sastrería senior y podemos confeccionar el vestido según tus requisitos específicos. Para garantizar que el vestido esté confeccionado exactamente como se describe, los clientes deben proporcionar sus requisitos en la sección de notas adicionales en la página del vestido. Sería de gran ayuda si pudieras enviarnos fotos de referencia por correo electrónico.',
  faq82: '¿Puedo solicitar un vestido que no sea de Stacees?',
  faq83: `Sí, nuestros clientes pueden enviar una solicitud de sastrería personalizada a través del siguiente enlace:<br/> 
  <a href="{hrefTailor}">https://www.stacees.com/us-es/project-tailor-made</a><br/> 
   Nuestro sastre revisará tu solicitud y te enviará un presupuesto por correo electrónico.`,
  faq84: '¿Cómo puedo asegurarme de que la tela y el color funcionen perfectamente para mí?',
  faq85: `Entendemos que cada vestido es importante y que elegir la tela y el color adecuados es fundamental para que destaques. Ten en cuenta que el color puede variar levemente debido a las diferencias en la configuración del monitor, la iluminación y el entorno.<br/>
Para asegurarte de que estás satisfecha con la tela y el color, te recomendamos pedir muestras de tela en nuestro sitio web. Aquí tienes el enlace:<br/>
<a href="{hrefFabric}">https://www.stacees.com/us-es/list/muestras-de-telas</a>`,
  faq86: '¿Cómo puedo quitar las arrugas?    ',
  faq87: `Nuestros vestidos llegan doblados en su bolsa, por lo que pueden tener arrugas al momento de la llegada. Si tienes algo de tiempo antes de tu evento, muchas de las arrugas desaparecerán naturalmente si cuelgas el vestido.<br/>
   Para preparar el vestido para el gran día, evita usar una plancha, ya que podría dañar la tela. El vapor es la mejor manera de eliminar las arrugas.<br/> 
   Si no tienes un vaporizador, puedes llevar el vestido a una tintorería para un vapor profesional. Alternativamente, puedes probar este truco: cuelga el vestido en tu baño y corre un baño caliente para ayudar a liberar las arrugas.`,
  faq88: '¿Cómo limpio el vestido?',
  faq89: 'Debido a que nuestros vestidos están hechos con telas delicadas, recomendamos limpiarlos en seco si necesitas que se limpien. No recomendamos lavarlos a máquina.Cuando los lleves a la tintorería, asegúrate de informar al personal para que maneje las cuentas y otras decoraciones con cuidado. El vestido debe colgarse y limpiarse con un paño, y no debe lavarse completamente en agua. Después de la limpieza, debe ser secado y planchado al vapor.',
  faq90: '¿Cómo puedo asegurarme de elegir la talla correcta que me quede bien?',
  faq91: `Los diferentes sitios pueden usar diferentes estándares de tallas, por lo que es mejor que primero te midas. Proporcionamos una tabla de tallas, y puede haber diferencias con respecto a tu talla habitual, así que por favor revisa la tabla de tallas en la página del vestido y compárala con tus medidas para encontrar la talla que mejor te quede. Si no estás seguro de qué talla elegir, no dudes en contactarnos a través de <a href="javascript:void(0)" class="livechat">LiveChat</a> o por correo electrónico a <a href="mailto:service@stacees.com">service@stacees.com</a> para más ayuda.`,
  faq92: '¿Puedo recibir mi vestido sin sastrería?',
  faq93: 'Todos nuestros vestidos son hechos a pedido, y no mantenemos inventario. Por lo tanto, necesitamos tiempo para hacer cada vestido de alta calidad para ti. Esperamos que puedas entender esto amablemente.',
  faq94: '¿Los vestidos son fieles a la talla?',
  faq95: 'Como parte del equipo de Stacees, nos enorgullece asegurarnos de que todos nuestros vestidos son fieles a la talla. La mejor manera de elegir la talla correcta para ti es tomar tus medidas consultando las imágenes a continuación, etiquetadas como "<a href="{hrefSize}">Cómo medir</a>."',
  faq96: '¿Por qué hay diferentes tablas de tallas en el sitio web? ',
  faq97: 'Proporcionamos diferentes tablas de tallas para distintos tipos de vestidos, así que por favor consulta la tabla de tallas en la página del artículo. Además, tenemos clientes de Reino Unido, Estados Unidos y otros países. La tabla de tallas también mostrará las tallas del Reino Unido y de EE. UU., así que revísala cuidadosamente.<br/>Si tienes alguna pregunta sobre el ajuste o necesitas ayuda para seleccionar tu talla, no dudes en contactarnos.',
  faq98: '¿Cómo restablezco mi contraseña?',
  faq99: `Simplemente sigue las instrucciones de "<a href='javascript:void(0)' class='forgot'>Contraseña olvidada</a>". Por razones de seguridad, no podemos enviarte tu contraseña por correo electrónico, recibirás un enlace para restablecer la contraseña por correo electrónico, sigue ese enlace para restablecer tu contraseña. Si no recibes el correo, por favor contáctanos, proporcionaremos retroalimentación al departamento técnico.`,
  faq100: '¿Tengo que ser miembro para comprar aquí?',
  faq101: 'Puedes hacer un pedido como invitado. Sin embargo, tener una cuenta facilita el seguimiento de tus pedidos, guardar direcciones frecuentes y enviar consultas postventas. Puedes crear una cuenta fácilmente en solo un minuto.',
  faq102: '¿Cómo cambio la dirección de correo electrónico de mi cuenta?',
  faq103: 'Para garantizar la seguridad de tu cuenta, no se permite cambiar la dirección de correo electrónico registrada. Sin embargo, puedes indicarnos la dirección de correo electrónico que te gustaría usar, y nosotros la actualizaremos por ti y la registraremos para futuros pedidos.',
  faq104: 'Quiero eliminar mi cuenta. ¿Qué debo hacer?',
  faq105: `Respetamos la decisión de cada cliente. Por favor, contáctanos a través de <a href="javascript:void(0)" class="livechat">LiveChat</a> o por correo electrónico a <a href="mailto:service@stacees.com">service@stacees.com</a>, y te ayudaremos con el proceso.`,
  faq106: `¿Por qué no puedo ver mi pedido en mi cuenta?`,
  faq107: `Necesitas iniciar sesión con la dirección de correo electrónico correcta que utilizaste para realizar el pedido. Una vez que inicies sesión con la misma dirección de correo electrónico, tu pedido debería aparecer en la lista de "<a href='javascript:void(0)' class='myOrder'>Mis Pedidos</a>". Si aún no puedes encontrarlo, por favor contáctanos a través de <a href="javascript:void(0)" class="livechat">LiveChat</a> o por correo electrónico a <a href="mailto:service@stacees.com">service@stacees.com</a>. Te ayudaremos a resolver el problema.`,
  faq108: '¿Cuándo debería hacer mi pedido?',
  faq109: `Para bodas y eventos formales<br/>
    Para garantizar que tengas tiempo suficiente para la entrega y los ajustes necesarios, te recomendamos que realices tu pedido 2 meses antes de la boda o el evento formal al que planeas asistir. Esto garantizará que haya tiempo suficiente para la producción, la entrega y las modificaciones, que a menudo se requieren con la vestimenta formal.`,
  faq110: 'Esto también puede ayudar a evitar problemas causados por posibles demoras resultantes de eventos imprevisibles, como desastres naturales o problemas con el transportista.',
  faq111: `Ten en cuenta que los tiempos de producción a veces pueden demorar más, especialmente entre enero y marzo. Si los tiempos de producción aumentan después de que se haya realizado tu pedido, no afectará tu pedido. Solo se verán afectados los pedidos nuevos.`,
  faq112: ' ¿Cómo puedo cambiar el sitio web al idioma/moneda que se usa en mi área?    ',
  faq113: `Para brindar un mejor servicio a nuestros clientes, hemos creado sitios web independientes para cada área. Si haces clic accidentalmente en el sitio web incorrecto, no te preocupes y sigue los pasos a continuación para cambiarlo. Para nuestro sitio web, simplemente puedes hacer clic en el ícono del globo terráqueo en la esquina superior izquierda de la página y seleccionar el país correspondiente. `,
  faq114: 'Para usuarios de dispositivos móviles, sigue los pasos a continuación para cambiar el idioma y la moneda.<br/>Primero, en la página principal, haz clic en el menú.',
  faq115: 'Luego verás una lista que incluye todos los productos que vendemos, desliza la lista, y en la parte inferior de la lista verás la etiqueta del país, el idioma y la moneda.<br/>Haz clic en ellos para cambiarlos.',
  faq116: 'Ten en cuenta que tanto la dirección de envío como la de facturación utilizadas al realizar el pedido deben estar en el mismo país. Por ejemplo, si estás realizando un pedido desde el sitio web del Reino Unido, ambas direcciones deben estar ubicadas en el Reino Unido. Si tu dirección de facturación está en otro país, intenta usar tu dirección de envío como dirección de facturación.',
  faq46: `¿Aún no puedes resolver el problema?`,
  faq47: `No te preocupes, tenemos dos formas de brindarte más ayuda.`,
  faq48: `Nuestro servicio en línea 24/7 puede responder rápidamente a tus preguntas y estará encantado de ayudarte.`,
  faq49: `Puedes enviarnos un correo electrónico y nos pondremos en contacto contigo dentro de las 24 horas.`,
  chatUs: 'chatea con nosotros',
  chatNow: 'Chatear ahora',
  emailUs: 'Envíanos un correo electrónico',
  //Return & Refund
  refundTit: 'Devoluciones , reembolso y cambios',
  refundTit1: 'POLÍTICA DE DEVOLUCIÓN DE 14 DÍAS',
  refundTit2: 'Cómo iniciar una devolución',
  refundTit3: 'DEVOLUCIONES Y REEMBOLSOS',
  creataTicket: 'Crear una consulta postventa',
  submitReturn: 'ENVIAR INFORMACIÓN DE DEVOLUCIÓN',
  emailReply: 'Respuesta por correo electrónico',
  returnApproved: 'Devolución aprobada',
  retrunItem: 'REEMBOLSAR EL/LOS ARTÍCULO(S)',
  initiateCont1: '<a class="anim-a" href="{hrefLogin}">Inicia sesión</a> en tu cuenta de Stacees. Si no tienes una cuenta, por favor <a class="anim-a" href="{hrefLogin}">"Regístrate"</a> en nuestro sitio web con el correo electrónico que utilizaste para realizar el pedido.',
  initiateCont2: `Encuentra 'Mis Pedidos', selecciona el pedido que deseas devolver y luego haz clic en 'Servicio de consultas postventa'.`,
  initiateCont3: 'Selecciona el(los) artículo(s) que deseas devolver e indica el motivo, la condición, la resolución y procede a enviarlo. ',
  initiateCont4: 'Una vez que hayas creado la solicitud con éxito, te responderemos por correo electrónico dentro de las 24 horas. Por favor, revisa tu correo. ',
  initiateCont5: 'Si tu devolución es aprobada, te proporcionaremos una dirección. Por favor, devuelve el artículo lo antes posible.',
  initiateCont6: 'Cuando recibamos el paquete y verifiquemos que está en buen estado, devolveremos el dinero a tu cuenta original.',
  startReturn: 'INICIAR UNA DEVOLUCIÓN',
  ReturnPolicy: 'Política general de devoluciones',
  retRefund: 'DEVOLUCIONES Y REEMBOLSOS FÁCILES',
  retRefundCont1: "Gracias por comprar en STACEES.",
  retRefundCont2: "Si no está completamente satisfecho con su pedido, estamos aquí para ayudarlo. Le reembolsaremos el importe íntegro de su compra, incluidos los gastos de envío de todos los artículos dañados, defectuosos o enviados por error. Si no está satisfecho con su compra, no dude en ponerse en contacto con nosotros lo antes posible para comenzar a procesar su devolución y reembolso.",
  retRefundCont3: "Condiciones de devolución",
  retRefundCont4: "Solo se pueden devolver las mercancías dentro de los 14 días siguientes a su recepción. Recomendamos a nuestros clientes que examinen el contenido de su envío en cuanto lo reciban. Una vez que esté seguro de que los artículos están dañados o han sido enviadoserróneamente, inicie lo antes posible el proceso de devolución o reembolso.",
  retRefundCont5: "Por favor, póngase en contacto con nuestro departamento de atención al cliente y solicite la devolución de su pedido. No devuelva el paquete directamente a la dirección que figura en nuestro sitio web o en el paquete, que no es nuestra dirección de devolución.",
  retRefundCont6: "Para poder optar a la devolución o el reembolso, el artículo debe estar en su estado original. No se aceptarán devoluciones de artículos usados, desgastados, lavados, alterados o dañados. Asegúrese de dejar las etiquetas originales en los artículos si desea devolverlos.",
  retRefundCont7: "Todos nuestros artículos se fabrican por encargo. Esto significa que no stock artículos adicionales en nuestra tienda.Una vez que realice su pedido, éste se fabricará específicamente para usted. Por lo tanto, no aceptaremos devoluciones de productos que se ajusten a su pedido.Nuestra política de devoluciones solo contempla daños o envíos erróneos de artículos.",
  retRefundCont8: "Cuando nos envíe su devolución, asegúrese de conservar el recibo de envío y la información de seguimiento. No nos hacemos responsables de los artículos devueltos que se envíen erróneamente, se dañen o se pierdan durante el envío.",
  retRefundCont9: "Cuando reciba un artículo que considere defectuoso,artículos dañados o mal enviados, tome imágenes del paquete original. Esto nos ayudará a acelerar el proceso de devolución, reembolso parcial o reembolso completo.",
  retRefundCont10: "Una vez que recibamos sus artículos devueltos, inspeccionaremos su pedido y le notificaremos que los hemos recibido.Una vez procesada y aprobada la devolución, iniciaremos el proceso de reembolso a través del método de pago original. Dependiendo de las políticas del emisor de su tarjeta, puede esperar recibir su abono en unos días.",
  retRefundCont11: "Para todos los pedidos de tamaño estándar/personalizado",
  retRefundCont12: "Para todos los pedidos de selección de estilo/tamaño",
  retRefundCont13: "Ajustes y compensar",
  retRefundCont14: "Muestras de fábrica, artículos personalizados, accesorios y pedidos urgentes.",
  retRefundCont15: "Para todos los artículos en venta final",
  retRefundCont16: "Artículos dañados, defectuosos o mal enviados",
  retRefundCont17: "Asuntos  de tamaño y ajuste",
  retRefundCont18: "Desviación de tamaño",
  retRefundCont19: "Desajuste de color",
  retRefundCont20: "Asuntos hechas a mano",
  retRefundCont21: "Política de Intercambios",
  retRefundCont22: "Cómo tramitar su devolución, reembolso o compensar",
  retRefundCont23: "Política de cancelación",
  retRefundCont24: "STACEES tramitará el reembolso íntegro, más los gastos de envío de todos los productos dañados, defectuosos o enviados erróneamente. Si no está satisfecho con su pedido, por favor tome imágenes del pedido original para que podamos procesar su reembolso.",
  retRefundCont25: "Si cambias de opinión sobre el pedido, por favor procesa la solicitud de devolución dentro de los 14 días siguientes a la recepción del pedido. Ten en cuenta que estos son días naturales, por lo que también se consideran los fines de semana. Por lo tanto, lo mejor para ti es que te pongas en contacto con nuestro departamento de atención al cliente lo antes posible para procesar tu devolución.",
  retRefundCont26: "Todos los artículos que no presenten daños deben devolverse en su estado original. No aceptamos la devolución de artículos desgastados, lavados, alterados o dañados. Asegúrese también de enviar todos los artículos devueltos con sus etiquetas originales. Es posible que no admitamos devoluciones de artículos que no cumplan estas condiciones o nuestra política de devoluciones.",
  retRefundCont27: "Una vez que hayamos recibido su solicitud de devolución y hayamos acusado recibo, tardaremos hasta 7 días laborables en procesar su devolución, cambio o reembolso. Tenga en cuenta que el tiempo que se tarda en recibir su reembolso depende de las políticas del emisor de su tarjeta o de su banco. Por lo general, la mayoría de los emisores de tarjetas tardan entre 7 y 14 días laborables en procesar un reembolso.",
  retRefundCont28: "Todos los pedidos se hacen según sus especificaciones. Esto significa, esencialmente, que una vez completado el pedido, será para usted y solo para usted. No podemos revender un pedido a medida a otro cliente. Por lo tanto, no procesaremos devoluciones ni reembolsos de pedidos hechos a medida a menos que haya un problema de calidad. Antes de realizar su pedido, asegúrese de revisar y confirmar los detalles sobre el color, el estilo y las medidas en un plazo de 24 horas tras finalizar su pedido.",
  retRefundCont29: "Si hay un problema de calidad, tome fotografías del envío original y comuníquese con nuestro servicio de atención al cliente para solicitar una devolución. Le notificaremos una vez que se haya procesado su solicitud y se podrá procesar una devolución o un reembolso dentro de los siete días posteriores a la recepción e inspección de su pedido.",
  retRefundCont30: `Por favor, comprenda que todos nuestros vestidos se hacen a pedido, lo que significa que los vestidos que pide son especiales para usted. Ponemos nuestro corazón en cada vestido, los vestidos no se pueden reponer ni revender si se devuelven. Por este motivo, no podemos aceptar pedidos de "compre muchos y quédese con uno". Para reducir el desperdicio ambiental y de recursos, no sugerimos pedidos de "compre muchos y quédese con uno". Una vez que se confirme su pedido, consideraremos que necesita todos los artículos de su pedido y seguiremos estrictamente nuestra política de devolución y reembolso.`,
  retRefundCont31: `Si desea conservar su pedido y realizar los ajustes, podemos ofrecerle una compensacíon. Entendemos que a veces es necesario realizar pequeños ajustes en su pedido para conseguir el ajuste perfecto. Por eso, ofrecemos una compensación limitada por arreglos en todos los vestidos. Esto incluye tanto las tallas estándar como los vestidos a medida. Sólo tienes que enviarnos una copia del recibo del sastre y tu número de pedido. Estos son nuestros límites de reembolso para los vestidos a medida en libras esterlinas :`,
  retRefundCont32: `Para vestidos con un precio de €99 o menos: límite de reembolso de €35`,
  retRefundCont33: `vestidos con precios entre €100 y €199 - Límite de compensación €50`,
  retRefundCont34: `Para todos los vestidos de más de €200: límite de compensación  €75`,
  retRefundCont35: "Después de una venta final, no podemos procesar devoluciones, reembolsos o cambios para todas las muestras de fábrica, artículos personalizados, velos o accesorios.",
  retRefundCont36: "No reembolsaremos los gastos de envío de los pedidos urgentes a menos que el pedido sea defectuoso. Si recibe un , pedido defectuoso, puede ponerse en contacto con nosotros, y vamos a inspeccionar y procesar el gasto de envío urgente tan pronto como sea posible.",
  retRefundCont37: "No podremos procesar ninguna devolución o reembolso de artículos de venta final.",
  retRefundCont38: `Tiene derecho a recibir un reembolso completo si recibe artículos defectuosos, dañados o enviados erróneamente. Sin embargo, si cree que los artículos se dañaron durante el envío, debe proporcionar la documentación de "Prueba de daños" de la empresa de mensajería. Deberá incluirla junto con las imágenes o los artículos cuando envíe su solicitud de devolución.`,
  retRefundCont39: "Para permitir ligeras modificaciones, nuestros sastres reservan tela adicional en las costuras de todos los vestidos. Entendemos que a veces un vestido terminado puede variar aproximadamente una pulgada en cualquier dirección de las medidas especificadas. Esto le permitirá hacer alteraciones para que el vestido le quede perfecto. ",
  retRefundCont40: "Si la talla de tu vestido difiere de las especificaciones de tu pedido por más de una pulgada, puedes acudir a un sastre local para hacer los ajustes. En este caso, te reembolsaremos hasta el 40% del precio de compra del producto (excluyendo los costos de envío). Si eliges esta opción, solicita un recibo a tu sastre y envíanos una copia para procesar el reembolso.",
  retRefundCont41: "En cuanto a la falta de coincidencia de color, tenga en cuenta que puede haber una ligera desviación de color debido a la configuración de la pantalla de su ordenador o dispositivo móvil. No obstante, si está convencido de que el color no es el que pidió, póngase en contacto con el Servicio de Atención al Cliente para ver si es posible una devolución o un reembolso.",
  retRefundCont42: "La mayoría de nuestros vestidos son hechos a mano por nuestros experimentados sastres. Por lo tanto, pueden surgir problemas menores durante la personalización. Sin embargo, estos vestidos no se consideran defectuosos ni dañados para fines de devoluciones o cambios.",
  retRefundCont43: "Le ofrecemos algunos consejos para hacer frente a estos problemas.",
  retRefundCont44: "1. Hilos. Utilice tijeras para cortarlo, por favor tenga cuidado.",
  retRefundCont45: "2. Arrugas. Planche la ropa. Planche el lado opuesto (lado interior) de las prendas con perlas, bordados u otros adornos.",
  retRefundCont46: "3. Ligera marca. Esta marca la suelen dejar los sastres cuando usan un bolígrafo borrable con agua para hacer sus trabajos. Es fácil de limpiar frotando suavemente con agua.",
  retRefundCont47: "¿Quieres cambiar tu vestido por una talla o un artículo diferente? Desafortunadamente, actualmente no ofrecemos un servicio de cambio para ningún producto. Todos nuestros vestidos se confeccionan por encargo. Por lo tanto, no tenemos vestidos adicionales disponibles para cambio. Si quieres un artículo diferente, por favor devuelve el pedido y realiza uno nuevo desde el principio.",
  retRefundCont48: "1. Cree un ticket en su lista de pedidos dentro de los 14 días siguientes a la recepción de su pedido. Por favor, incluya el motivo de la devolución y fotografías para su verificación. Por favor, siga la imagen :",
  retRefundCont49: "2. Una vez que le hayamos enviado el permiso para devolver la mercancía, también le facilitaremos una dirección de devolución a través de Tickets. Todo lo que tiene que hacer es imprimir la dirección y adjuntarla/ponerla dentro del paquete con la mercancía que desea devolver.",
  retRefundCont50: "3. Una vez que haya enviado el paquete con éxito, notifique a nuestro representante de atención al cliente que su envío está en camino. También debe proporcionarle el número de seguimiento del envío proporcionado por su oficina postal local.",
  retRefundCont51: "4. Cuando recibamos su pedido devuelto, se lo notificaremos por correo electrónico. Inspeccionaremos el artículo y procesaremos el reembolso/devolución en un plazo de 3 a 5 días laborables. Si su devolución no se ajusta a nuestra política de devoluciones y reembolsos, es posible que no podamos tramitar el reembolso. El artículo debe estar en condiciones satisfactorias. Una vez aceptado su reembolso, puede esperar su abono en un plazo de 7 a 15 días laborables a través del método de pago original. Tenga en cuenta que el plazo de tramitación depende de la entidad emisora de su tarjeta o de su banco.",
  retRefundCont52: "Lamentablemente, debido a la situación actual, el reembolso tardará entre 3 y 5 días adicionales en procesarse. Gracias por su paciencia y comprensión.",
  retRefundCont53: "Condiciones",
  retRefundCont54: "* El artículo debe estar sin usar, sin lavar, sin alterar, sin daños y con las etiquetas originales adheridas. No podemos procesar la devolución de ningún artículo defectuoso.",
  retRefundCont55: "* Nos reservamos el derecho a rechazar la devolución si se recibe en un estado inaceptable o sin nuestro conocimiento previo.",
  retRefundCont56: "* No seremos responsables de artículos devueltos que se pierdan o dañen, conserve el recibo con la información de seguimiento.",
  retRefundCont57: `Todos nuestros artículos se fabrican bajo pedido, incluidos los artículos de tamaño estándar. Si desea cancelar un pedido por cualquier motivo, consulte nuestra política de cancelación a continuación: <a class="bold" href="{hrefOrderCan}">Política de cancelación de pedidos</a>.`,
  //project tailor made
  tailor1: '<p>¿Cuántas veces has encontrado ese vestido perfecto en las redes sociales pero no sabías dónde comprarlo? O quizás </p><p>has encontrado el vestido de tus sueños y luego has descubierto que el vendedor no tiene tu talla ni hace envíos a tu país.</p>',
  tailor2: '<label>Sabemos lo difícil que puede resultar </label><span>encontrar el outfit ideal para una ocasión especial.</span>',
  tailor3: '<p>Ya sea que estés comprando para un cumpleaños, un baile de graduación, una graduación o cualquier otra ocasión </p><p>memorable, solo contarán los mejores vestidos. Por eso creamos nuestro innovador servicio de vestidos a </p><p>medida, para que puedas tener un vestido tan especial como la ocasión.</p>',
  tailor4: '¿Cómo funciona nuestro servicio?',
  tailor5: '<p>Con nuestro sistema inteligente, puedes subir una foto de cualquier </p><p>vestido que encuentres en línea. Así, la próxima vez que estés </p><p>navegando por Pinterest y encuentres un vestido hermoso, guarda </p><p>la imagen y cárgala mediante la función de carga de imágenes. </p><p>Podemos recrear cualquier vestido que veas en línea, incluso si </p><p>es del sitio de un competidor.</p>',
  tailor6: '<label>Los beneficios de elegir un </label><span>vestido a medida</span>',
  tailor7: '¿Por qué elegir un vestido a medida en lugar de una prenda fabricada en serie?',
  tailor8: '<p>En primer lugar, muchos vestidos de calle no son favorecedores porque no están </p><p>diseñados para tu tipo de cuerpo o altura. Sin embargo, un vestido a medida </p><p>promete un ajuste perfecto, ya que la prenda está diseñada y confeccionada </p><p>según tus medidas exactas.</p>',
  tailor9: '<p>En segundo lugar, no es ningún secreto que muchos vestidos fabricados en serie utilizan tejidos de baja calidad. </p><p>Estos materiales pueden picar e irritar y no duran mucho. Por lo tanto, lo más probablees que solo te pongas el </p><p>vestido una vez. Sin embargo, nosotros confeccionamos todos nuestros vestidos a medida con tejidos de la mejor calidad para garantizar la máxima durabilidad y comodidad.</p>',
  tailor10: 'Además de cargar la foto, deberá proporcionarnos algunos datos para recibir un presupuesto preciso para la creación del vestido.',
  tailor11: 'La forma de tu cuerpo/figura. Puedes indicarnos tu tipo de cuerpo, por ejemplo, pera, manzana, reloj de arena, o tu figura corporal en general, como delgada, con curvas o atlética.',
  tailor12: 'La ocasión en la que planeas usar el vestido.',
  tailor13: 'Puede realizar cualquier solicitud adicional o deseo de personalización para que el vestido sea muy especial y único.',
  tailor14: 'enviar un hecho a medida',
  writeTailor: 'Escribe un  hecho a medida',
  dressPic: 'Fotos de vestidos',
  content: 'Contenido',
  contReq: 'El contenido es requerido.',
  tailor15: 'Una vez que cargue sus fotos y proporcione los detalles anteriores, nuestros representantes de <p>servicio evaluarán la información y se comunicarán con usted para ofrecerle una cotización.</p>',
  tailor16: 'Máximo 5 imágenes, 5 MB cada una. Formato: jpeg/jpg/png/bmp ¡Se agradecen las fotos nítidas!',
  tips: 'Consejos',
  pleaseLogin: 'Por favor, Identificarse',
  success: '¡Éxito!',
  tailor17: `Sabemos lo que buscas.`,
  //size chart
  specOccasion: 'Ocasiones especiales',
  juniorBrid: 'Damita de honor',
  jackets: 'Chaquetas/Chales',
  sizeTxt1: 'A continuación se muestran las tablas de tallas de los diferentes productos. Encuentra la talla adecuada para ti',
  sizeTxt2: "Es importante que conozcas tu propia talla a la hora de elegir un vestido. Solo conociendo tus medidas personales podrás tener un vestido que te quede mejor. Esto se debe a que cada diseñador puede utilizar únicamente sus propias reglas para definir las tallas de los vestidos. Por ejemplo, lo que podría ser una talla 6 en tu prenda de uso diario, podría ser una talla 10 en la misma talla en nuestros vestidos o en los vestidos de otro diseñador.",
  sizeTxt3: 'Por favor, compruebe el busto, la cintura y las caderas al elegir la talla.',
  sizeTxt28: 'Para conocer sus medidas, consulte Cómo medir',
  sizeTxt4: 'Tabla de tallas estándar de vestidos',
  sizeTxt5: 'Tabla de tallas estándar de busto, cintura y cadera',
  sizeTxt6: 'Tabla de longitud de vestidos (desde el hueco hasta el dobladillo)',
  sizeTxt7: 'Algunos estilos añaden comodidad.Si se encuentra entre dos tallas, le sugerimos que llame y hable con un estilista o encargue el vestido en talla personalizada.',
  sizeTxt8: 'La medida del hueco al dobladillo para vestidos de largo hasta el suelo de tamaño estándar incluye 2 pulgadas adicionales para adaptarse a tacones altos.',
  sizeTxt9: '¿Tiene problemas para elegir la talla o requisitos adicionales sobre la talla? <a href="{hrefContact}">Contáctenos.</a>',
  sizeTxt10: 'Tabla de tallas de vestidos',
  sizeTxt11: 'Tabla de tallas de vestidos de talla grande',
  miniShort: 'Mini<span>/Corto</span>',
  kneeLen: 'Hasta la rodilla',
  teaLen: 'Hasta la tibia',
  ankleLen: 'Hasta el tobillo',
  floorLen: 'Hasta el suelo',
  dressLen: 'Longitud del vestido',
  reference: 'Sólo para su referencia',
  eurSize: 'Talla de Europa',
  usSize: 'US Talla',
  euSize: 'EU Talla',
  ukSize: 'UK Talla',
  auSize: 'AU Talla',
  cupSize: 'Tamaño de la copa',
  shoulder: 'Hombro',
  toBust: 'Hombro al busto',
  sizeTxt12: "Por favor, comprueba el busto, la cintura y las caderas al elegir la talla. Para saber las medidas de tu niña, consulta cómo medir.",
  sizeTxt13: "Vestidos de pajecita",
  sizeTxt14: "La talla depende de los artículos. Comprueba la talla exacta en la página de detalles del producto.",
  sizeTxt15: 'Tabla de tallas de vestidos de pajecita',
  sizeTxt16: 'Tabla de tallas de vestidos de damita de honor',
  sizeTxt17: 'Tabla de tallas de chaqueta y chal',
  sizeTxt18: 'La tabla de tallas es sólo una guía general. Las medidas específicas varían con cada estilo.',
  sizeTxt19: 'Tabla de tallas de zapatos para hombre y mujer',
  sizeTxt20: 'Tabla de tallas de zapatos de danza',
  sizeTxt21: 'El tamaño de los vestidos de damita de honor depende de los artículos.Por favor, consulte la página de detalles del producto para conocer el tamaño exacto.',
  bustText1: "Esta es una medida de la parte más ancha de su busto o circunferencia corporal en el busto.Es una medida corporal que mide la circunferencia del torso de una mujer a la altura de los senos.",
  bustText2: "Envuelva la cinta alrededor de la parte más ancha de su busto y céntrela en su espalda de manera que quede nivelada en todo su perímetro.",
  bustText3: "<strong>*Consejos:</strong><br/>¡No se refiere a la talla de sujetador!<br/>Tus brazos deben estar relajados y caídos a los lados.Usa el sostén que piensas usar con tu vestido cuando tomes esto.",
  waistText1: "Esta es una medida de su cintura natural, o la parte más pequeña de su cintura.",
  waistText2: "Envuelva la cinta métrica alrededor de su cintura natural, manteniéndola paralela al piso. Inclínese hacia un lado para encontrar la depresión natural en su torso. Este es el tamaño natural de tu cintura.",
  hipsText1: "Esta es la medida de la circunferencia de la parte más completa de los glúteos.",
  hipsText2: 'Envuelva la cinta métrica alrededor de la parte más amplia de sus nalgas, que generalmente está entre 7 y 9 pulgadas por debajo de su cintura natural. Asegúrate de que la cinta métrica esté siempre paralela al suelo.',
  hemText1: "Esta es la distancia desde tu hueco hasta el dobladillo del vestido.",
  hemText2: "Pídele a un amigo que coloque la cinta métrica en tu hueco y jálala hasta obtener el largo de falda que desees.",
  hemText3: "<strong>*Consejos:</strong><br/>Para tomar esta medida, póngase de pie con los pies juntos.<br/>Definimos la longitud de la rodilla como el área justo debajo de las rótulas.<br/>Medir demasiado alto puede resultar en un vestido demasiado corto.<br/>Si se trata de un vestido largo hasta el suelo, asegúrese de medir sin usar zapatos.",
  shoulderText1: "Esta es la distancia desde el borde del hombro izquierdo hasta el hueso prominente del cuello ubicado en el centro de la parte posterior del cuello y continúa hasta el borde del hombro derecho.",
  shoulderText2: 'Coloque la cinta métrica en la "parte superior" de los hombros. Mida desde el borde del hombro izquierdo hasta el hueso prominente del cuello ubicado en el centro de la nuca y continúe hasta el borde del hombro derecho.',
  sleeveText1: "Esta es la medida desde la costura del hombro hasta donde quieres que termine la manga.",
  sleeveText2: "Mida desde la costura del hombro hasta el largo de manga deseado con el brazo relajado a su costado para obtener la mejor medida posible.",
  sleeveText3: "<strong>*Consejos:</strong><br/>Medir con el brazo ligeramente doblado.",
  armscyeText1: "Esta es la medida de tu sisa.",
  armscyeText2: "<strong>*Consejos:</strong><br/>Para medir el contorno del brazo, debe enrollarse alrededor de la parte superior del hombro y luego por debajo de la axila.",
  armText2: "<strong>*Consejos:</strong><br/>Medir con el músculo relajado.",
  sizeTxt22: 'Por favor siga cuidadosamente nuestro ',
  sizeTxt23: ' Guía para tomar medidas.',
  sizeTxt24: 'Tabla de tallas de boleros',
  sizeTxt25: 'Tabla de tallas de boleros de talla grande',
  sizeTxt26: 'Tabla de tallas de sujetadores',
  sizeTxt27: 'La medida de hueco a suelo de nuestros vestidos estándar ya incluye 2 inches (aprox.5 cm) adicionales para adaptarse a tacones altos.',
  measureSize: 'Medidas Tamaño',
  underBust: 'Bajo el busto',
  bustGap: 'Brecha de busto fluctuante',
  oneSize: 'Talla única',
  adults: 'Adultos',
  measureGuide: 'Guía de medidas',
  measFeet: 'Mide tus pies',
  measFeetInfo: `Póntelos calcetines que usarás con los zapatos y mide tus pies por la tarde (los pies suelen hincharse durante el día). Distribuye el peso uniformemente antes de medir.`,
  step: 'PASO',
  stepTxt1: 'Párate derecho sobre una superficie dura con el talón contra la pared y un trozo de papel en blanco pegado al piso, al ras de la pared debajo de tu pie.',
  stepTxt2: 'Pídele a alguien que marque la parte más larga de tu pie (conocida como longitud del talón a la punta) en el papel con un bolígrafo o un lápiz, o mídete tú mismo si es necesario.',
  stepTxt3: 'Usa una regla para medir la longitud del talón a la punta que marcaste para cada pie.',
  //color chart
  "Dark Navy": "Azul marino oscuro",
  "Navy Blue": "Azul Marino",
  "Dusty Blue": "Azul polvoriento",
  "Ink Blue": "Azul tinta",
  "Stormy": "Tormenta",
  "Slate Blue": "Azul Pizarra",
  "Royal Blue": "Azul real",
  "Ocean Blue": "Azul océano",
  "Ice Blue": "Azul hielo",
  "Blue": "Azul",
  "Light Blue": "Azul Claro",
  "Sky Blue": "Celeste",
  "Mist": "Niebla",
  "Cabernet": "Cabernet",
  "Burgundy": "Borgoña",
  "Red": "Rojo",
  "Mulberry": "Mora",
  "Rust": "Bermellón",
  "Watermelon": "Sandía",
  "Papaya": "Papaya",
  "Sunset": "Puesta de sol",
  "Peach": "Melocotón",
  "Light Pink": "Rosa claro",
  "Pearl Pink": "Rosa perla",
  "Petal": "Rosa Palo",
  "Blushing Pink": "Rubor Rosa",
  "Candy Pink": "Rosa caramelo",
  "Coral": "Coral",
  "Dusty Pink": "Rosa Polvoriento ",
  "Dusty Rose": "Rosa polvorienta ",
  "Peacock": "Verde pavo real",
  "Blush": "Sonrojo",
  "Tahiti": "Tahití",
  "Lilac": "Lila",
  "Lavender": "Lavanda",
  "Dusk": "Dusk",
  "Wisteria": "Glicina",
  "Mauve": "Mauve",
  "Vintage-Mauve": "Malva Clásico",
  "Vintage Mauve": "Malva Clásico",
  "Orchid": "Orquídea",
  "Grape": "Uva",
  "Fuchsia": "Fucsia",
  "Regency": "Violeta",
  "Plum": "Ciruela",
  "Chocolate": "Chocolate",
  "Dark Green": "Verde oscuro",
  "Teal": "Cerceta",
  "Emerald": "Esmeralda",
  "Hunter Green": "Verde Militar",
  "Olive": "Verde oliva",
  "Celadon": "Celadón",
  "Lime Green": "Verde lima",
  "Dusty Sage": "Salvia Polvoriento ",
  "Sage": "Salvia",
  "Mint Green": "Verde menta",
  "Turquoise": "Turquesa",
  "Spa": "Balneario",
  "Jade": "Jade",
  "Beige": "Beis",
  "Yellow": "Amarillo",
  "Daffodil": "Narciso",
  "Champagne": "Champán",
  "Gold": "Oro",
  "Orange": "Naranja",
  "Marigold": "Caléndula",
  "Tangerine": "Mandarina",
  "Taupe": "Taupe",
  "Mushroom": "Seta",
  "Silver": "Plata",
  "White": "Blanco",
  "Ivory": "Marfil",
  "Potrobello": "Color tabaco",
  "Steel Grey": "Gris Acero",
  "Black": "Negro",
  'colorNote': `Tenga en cuenta que las fotografías pueden ser ligeramente diferentes del artículo real en términos de color, debido a la iluminación, las sombras y las mejoras de la fotografía durante la sesión fotográfica. Los ajustes de gama de colores, brillo y contraste de la pantalla de la computadora a veces también generan confusión.`,
  "Chiffon": "Gasa",
  "Organza": "Organdí de seda",
  "Satin": "Satén",
  "Taffeta": "Tafetán",
  "Elastic Satin": "Satén elástico",
  "Tulle": "Tul",
  "Lace": "Encaje",
  "Jersey": "Jersey",
  "Charmeuse": "Tela Charmeuse",
  "Moss": "Musgo",
  //contact us
  contactUs1: "¿NECESITAS AYUDA?",
  contactUs2: "Agradecemos todas las preguntas sobre compras, pedidos y servicio posventa. Tenemos representantes amables listos para ayudarlo.",
  contactUs3: `Si tiene alguna pregunta, visite primero nuestras <a href="{hrefFaq}">preguntas frecuentes</a>. El centro de ayuda ofrece respuestas a las preguntas más comunes.`,
  contactUs4: "Si estás considerando una compra",
  contactUs5: "En caso de tener alguna pregunta o necesitar más información antes de realizar un pedido, puede contactarnos en cualquier momento a través del Livechat en la parte inferior derecha de la página.Si nuestros representantes de Livesupport no están disponibles, puede enviarnos un correo electrónico las 24 horas del día, los 7 días de la semana y nos comunicaremos con usted lo antes posible.",
  contactUs6: "Si ya has realizado una compra",
  contactUs7: "El departamento de servicio está aquí para ayudarlo con consultas sobre sus pedidos en procesamiento actual y los artículos que ya recibió. Tenga en cuenta que todas las consultas se manejan minuciosamente, ninguna solicitud queda sin respuesta, tratamos de responder cada solicitud dentro de un día hábil.",
  contactUs8: "Crear un ticket",
  contactUs9: "Horario de trabajo: de 1:00 a 11:00 UTC",
  contactUs10: 'En STACEES, estamos orgullosos de ofrecer productos de la más alta calidad y brindar el mejor servicio al cliente. También entendemos que a veces pueden surgir imprevistos y siempre estamos aquí para ayudarte. Aquí tienes algunas preguntas frecuentes para tu referencia. ',
  contactUs18: "Por favor, no envíe ningún artículo de devolución a la dirección anterior, las devoluciones enviadas a esta dirección no pueden ser procesadas.",
  contactUs19: 'Si necesita devolver un producto, póngase en contacto con el servicio de atención al cliente y  <a class="tickets" href="javascript:">envíe una consulta de postventa</a>.',
  preSale: 'PREVENTA',
  afterSale: 'POSTVENTA',
  whatsapp: 'Whatsapp',
  addressList: [
    'Información sobre la oficina de Hong Kong',
    'Empresa : DIY FASHION LIMITED',
    'Número de registro : 1994949',
    'Dirección : La Unidad A7, 12º Piso, Edificio Astoria, 34 Calle Ashley, Tsim Sha Tsui, Kowloon, Hong Kong. (No es la dirección del devoluciones)',
  ],
  creturn: 'Devolución',
  trackOrder: 'Rastrear pedido',
  cdelivery: 'Entrega',
  promPaymt: 'Promoción/Pago',
  prodSize: 'Productos/Talla',
  oyherQuery: 'CUALQUIER OTRA CONSULTA',
  contactF3: 'Puedes crear una solicitud de devolución dentro de los 14 días posteriores a la recepción de tu pedido. Por favor, proporciona el motivo de la devolución junto con cualquier fotografía relevante para la verificación. Nuestro equipo de servicio te responderá lo antes posible. Si no tienes una cuenta, por favor "Regístrate" en nuestro sitio web con el correo electrónico que utilizaste para realizar el pedido, y luego podrás "Crear una consulta postventa". Por favor, sigue la imagen:',
  //payment methods
  paymentInfo1: '¡Disfrute de una experiencia de compra segura y fácil en stacees.com!',
  paymentInfo2: 'Aceptamos los siguientes métodos de pago. Puedes elegir el que más te guste :',
  creditDebit: 'Tarjeta de Crédito o Débito :',
  paymentT1: 'Aceptamos los siguientes métodos de pago',
  paymentT2: 'También aceptamos pagos en cuotas',
  paymentT3: '¿Es seguro realizar el pago aquí?',
  paymentT4: 'La seguridad de la información que envías es una de nuestras mayores prioridades. Estamos verificados por el Sistema de Validación Bancaria Internacional y PayPal, supervisados ​​bajo sus términos y políticas. Aquí tendrás la experiencia de compra más segura.',
  paymentT14: 'Quiero pagar, pero ¿por qué se rechazó el pago?',
  paymentT5: 'Creemos que todos los clientes disfrutarán de una compra segura y conveniente aquí, ya que ofrecemos la opción de pago más popular, PayPal, tarjeta de crédito/débito, pagos en cuotas. Pero, desafortunadamente, si tu pago se rechaza, no te preocupes, lo tenemos cubierto. Hemos enumerado algunas razones por las que puede fallar el pago. Verifica lo siguiente:',
  paymentT6: `1. El nombre de usuario en la tarjeta, el número de tarjeta, el período de validez, el CVV2 y otra información relacionada con la tarjeta de crédito se completaron incorrectamente.<br/>Debes corregir la información incorrecta e ingresarla nuevamente.`,
  paymentT7: `2. La tarjeta de crédito del comprador ha caducado.<br/>Debes utilizar otra tarjeta.`,
  paymentT8: `3. El saldo de la tarjeta de crédito del comprador es insuficiente.<br/>Comunícate con tu banco para recargar y renovar los fondos.`,
  paymentT9: `4. El banco indica que la ubicación del comprador es una zona de alto riesgo.<br/>Comunícate con nuestro representante de atención al cliente y también puedes realizar el pago mediante PayPal, Western Union y transferencia bancaria.`,
  paymentT10: `5. El sistema de control de riesgos de la empresa de pago indica que la información relevante del comprador es de alto riesgo, lo que evita la transacción y reduce la posibilidad de robo de la tarjeta.<br/>Debido a que el cliente ha tenido registros de devoluciones de cargos o quejas recientemente, su pago no está autorizado, comunícate con el servicio de tu banco para obtener más información. Puede deberse a la gran cantidad de dinero. Luego, debes realizar el proceso de verificación.`,
  paymentT11: `Si aún tienes otras preguntas sobre cómo realizar el pago, infórmanos lo antes posible. Estamos dispuestos a ayudarte con eso. Gracias por tu tiempo y espero poder recibir noticias tuyas pronto.`,
  paymentT12: 'Si accidentalmente realizas el mismo pedido varias veces, ¿qué debo hacer?',
  paymentT13: 'No te preocupes por esto, puedes tomar el número de pedido o la dirección de correo electrónico que utilizaste para realizar el pedido para comunicarte con nosotros a través del <a href="javascript:void(0)" class="livechat">LiveChat</a> en línea o por correo electrónico: <a href="mailto:service@stacees.com">service@stacees.com</a>. Te ayudaremos a resolver esto.',
  //terms conditions
  overview: 'Resumen',
  termCont1: `Este sitio web está gestionado por DIY FASHION LIMITED y/o sus entidades afiliadas FEELTIMES LIMITED, filial en el Reino Unido (denominadas colectivamente "DIY FASHION LIMITED", "nosotros", "nos" o "nuestro", también conocido como stacees.com. En todo el sitio, los términos "nosotros", "nos" y "nuestro" se refieren a DIY FASHION LIMITED. DIY FASHION LIMITED  ofrece este sitio web, incluyendo toda la información, herramientas y servicios disponibles en este sitio para usted, el usuario, condicionado a la aceptación de todos los términos, condiciones, políticas y avisos aquí establecidos. En todo el sitio, el término “STACEES” se refiere a DIY FASHION LIMITED.`,
  termCont2: "Al visitar nuestro sitio web y/o comprarnos, usted está participando en nuestro servicio y acepta quedar vinculado por los siguientes términos y condiciones ('Términos del Servicio', 'Términos') incluidos estos términos y condiciones adicionales y las políticas a las que se hace referencia en el presente documento y/o disponibles mediante hipervínculo. Estas Condiciones del servicio se aplican a todos los usuarios del sitio, incluidos, entre otros, los usuarios que son navegadores, proveedores, clientes, comerciantes y/o colaboradores de contenidos.Lea atentamente estas condiciones de uso antes de acceder o utilizar nuestro sitio web. Al acceder o utilizar cualquier parte del sitio web, acepta quedar vinculado por estas condiciones de servicio. Si no acepta todos los términos y condiciones de este acuerdo, no podrá acceder al sitio web ni utilizar ninguno de los servicios. Si estas condiciones del servicio se consideran una oferta, su aceptación se limita expresamente a estas condiciones del servicio.Cualquier nueva función o herramienta añadida a la tienda actual también estará sujeta a las Condiciones del servicio. Puede consultar la versión más actualizada de las Condiciones del servicio en cualquier momento en esta página. Nos reservamos el derecho a actualizar, cambiar o sustituir cualquier parte de estas Condiciones del servicio mediante la publicación de actualizaciones y/o cambios en nuestra página web. Es su responsabilidad revisar esta página periódicamente para comprobar si se han producido cambios. El uso continuado o el acceso al sitio web después de la publicación de cualquier cambio constituye su aceptación de dichos cambios.",
  termCont3: "LEY APLICABLE: Estos términos de servicio y cualquier acuerdo por separado mediante el cual le proporcionemos se regirán e interpretarán de conformidad con las leyes de Hong Kong.",
  termCont4: "Condiciones de la tienda en línea",
  termCont5: "Al aceptar estas términos de servicio, usted declara que es mayoría de edad en su estado o provincia de residencia, o que ha alcanzado la mayoría de edad en su estado o provincia de residencia y que nos ha dado permiso para permitir que cualquiera de los miembros menores de su familia utilice el sitio.",
  termCont6: "No puede utilizar nuestros productos para ningún propósito ilegal o no autorizado ni puede, en el uso del Servicio, violar ninguna ley en su jurisdicción (incluidas, entre otras, las leyes de derechos de autor).",
  termCont7: "No debe transmitir ningún malware, virus o código de naturaleza destructiva.",
  termCont8: "La violación o incumplimiento de cualquiera de las términos dará lugar a la rescisión inmediata de sus servicios.",
  termCont9: "Condiciones generales",
  termCont10: "Nos reservamos el derecho a negar el servicio a cualquier persona en cualquier momento y por cualquier motivo.",
  termCont11: "Usted entiende que su contenido (excluida la información de la tarjeta de crédito) puede transmitirse sin cifrar e implica lo siguiente: (a) transmisiones a través de varias redes, (b) cambios para ajustarse y adaptarse a los requisitos técnicos de las redes o dispositivos de conexión. La información de las tarjetas de crédito siempre se encripta durante su transmisión a través de una red.",
  termCont12: "Usted acepta no reproducir, duplicar, copiar, vender, revender o explotar ninguna parte del Servicio, uso del Servicio o acceso al Servicio o cualquier contacto en el sitio web a través del cual se brinda el servicio, sin nuestro expreso permiso por escrito.",
  termCont13: "Los títulos utilizados en este acuerdo se incluyen únicamente para conveniencia y no limitarán ni afectarán de otro modo estos términos.",
  termCont14: "Exactitud, integridad y puntualidad de la información",
  termCont15: "No nos hacemos responsables si la información disponible en este sitio no es precisa, completa o actual. El material de este sitio se proporciona únicamente con fines de información general y no debe confiarse en él ni utilizarse como única base para tomar decisiones sin consultar fuentes de información primarias, más precisas, más completas o más actuales. Cualquier confianza depositada en el material de este sitio corre por su cuenta y riesgo.",
  termCont16: "Este sitio web puede contener cierta información histórica. Dicha información no es actual y se proporciona únicamente como referencia. Nos reservamos el derecho a modificar el contenido de este sitio en cualquier momento, pero no tenemos la obligación de actualizar ninguna información de nuestro sitio. Usted acepta que es su responsabilidad supervisar los cambios en nuestro sitio.",
  termCont17: "Modificaciones al servicio y precios",
  termCont18: "Los precios de nuestros productos están sujetos a cambios sin previo aviso. Nos reservamos el derecho a modificar o interrumpir el servicio (o cualquier parte del mismo) en cualquier momento y sin previo aviso. No seremos responsables ante usted ni ante terceros por ninguna modificación, cambio de precio, suspensión o interrupción del servicio.",
  termCont19: "Productos o servicios",
  termCont20: "Algunos productos o servicios pueden estar disponibles exclusivamente en línea a través de nuestro sitio web. Estos productos o servicios pueden tener cantidades limitadas y están sujetos a devolución o cambio únicamente de acuerdo con nuestra Política de devoluciones.",
  termCont22: `Hemos hecho todo lo posible para mostrar los colores y las imágenes de nuestros productos con la mayor exactitud posible. No podemos garantizar que la visualización de cualquier color en el monitor de su ordenador sea exacta.`,
  termCont23: "Nos reservamos el derecho, pero no estamos obligados, a limitar las ventas de nuestros productos o Servicios a cualquier persona, región geográfica o jurisdicción. Podemos ejercer este derecho caso por caso. Nos reservamos el derecho a limitar las cantidades de cualquier producto o servicio que ofrezcamos. Todas las descripciones de productos o precios de productos están sujetos a cambios en cualquier momento sin previo aviso, a nuestra entera discreción,a nuestra entera discreción.Nos reservamos el derecho a interrumpir cualquier producto en cualquier momento. Cualquier oferta de cualquier producto o servicio proporcionado en el Sitio es nula donde esté prohibida.",
  termCont24: "No garantizamos que la calidad de los productos, servicios, información u otro material comprado u obtenido por usted cumplirá con sus expectativas, o que cualquier error en el Servicio será corregido.",
  termCont25: "Exactitud de la información sobre facturación y cuentas",
  termCont26: "Nos reservamos el derecho a rechazar cualquier pedido. Podemos, a nuestra entera discreción, limitar o cancelar el número de compras por persona, por hogar o por pedido.Estas restricciones pueden incluir pedidos realizados por o bajo la misma cuenta de cliente, con la misma tarjeta de crédito y/o utilizando la misma dirección de facturación y/o envío. Si modificamos o cancelamos un pedido, intentaremos notificárselo poniéndonos en contacto con la dirección de correo electrónico y/o la dirección de facturación o el número de teléfono que nos haya facilitado al realizar el pedido. Nos reservamos el derecho a limitar o prohibir los pedidos que, a nuestro juicio, parezcan haber sido realizados por un comerciante, revendedor o distribuidor.",
  termCont27: "Usted se compromete a proporcionar información actual, completa y precisa sobre la compra y la cuenta para todas las compras realizadas en nuestra tienda. También se compromete a actualizar puntualmente su cuenta y otra información, como su dirección de correo electrónico, su número de tarjeta de crédito y la fecha de caducidad, para que podamos completar su transacción y contactar con usted cuando sea necesario.",
  termCont28: 'Para obtener más detalles, consulte nuestra <a class="cr-bd" href="{hrefReturn}">política de devoluciones.</a>',
  termCont29: "Enlaces de terceros",
  termCont30: "Determinados contenidos, productos y servicios disponibles a través de nuestros Servicios pueden incluir material de terceros.",
  termCont31: "Los enlaces de terceros en el sitio web pueden dirigirle a sitios web de terceros que no están afiliados a nosotros. No somos responsables de comprobar o evaluar su contenido o exactitud, y no garantizamos ni aceptaremos responsabilidad alguna por materiales o sitios web de terceros, ni por otros materiales, productos o servicios de terceros.",
  termCont32: "No nos hacemos responsables de ningún daño o perjuicio relacionado con la compra o el uso de bienes, servicios, recursos, contenidos o cualquier otra transacción realizada en relación con sitios web de terceros. Revise detenidamente las políticas y prácticas de terceros y asegúrese de comprenderlas antes de realizar cualquier transacción. Las quejas, reclamaciones, inquietudes o preguntas relacionadas con productos de terceros deben dirigirse a dichos terceros.",
  termCont33: "Comentarios de los usuarios,Retroalimentación y otros opiniones",
  termCont34: "Si, a petición nuestra, envía ciertos comentarios específicos (por ejemplo, entradas) o, si no lo solicitamos, envía ideas, sugerencias, recomendaciones, propuestas, planes u otros materiales, ya sea en línea, por correo electrónico, por correo postal o de otro modo (colectivamente, ‘comentarios’), usted acepta que podamos editar, reproducir, distribuir, traducir y utilizar de otro modo cualquier comentario que nos envíe en cualquier medio y en cualquier momento sin restricciones. No estamos obligados a (1) mantener la confidencialidad de ningún comentario; (2) compensarle por ningún comentario; o (3) responder a ningún comentario.",
  termCont35: "Podemos, pero no estamos obligados a, supervisar, editar o eliminar el contenido que determinemos, a nuestra entera discreción, que es ilegal, ofensivo, amenazante, calumnioso, difamatorio, pornográfico, obsceno o de otra manera objetable o que viole los derechos de propiedad intelectual de cualquier parte o estas términos de servicio.",
  termCont36: "Usted acepta que sus comentarios no violarán ningún derecho de terceros, incluidos los derechos de autor, marca registrada, privacidad, personalidad u otros derechos personales o de propiedad. Asimismo, acepta que sus Comentarios no contendrán material difamatorio o de otro modo ilegal, abusivo u obsceno, ni contendrán virus informáticos u otro software malicioso que pueda afectar de algún modo al funcionamiento de los Servicios o de cualquier sitio web relacionado. No utilizará una dirección de correo electrónico falsa, ni se hará pasar por otra persona que no sea usted, ni nos engañará a nosotros o a terceros en cuanto al origen de los Comentarios. Usted acepta plena responsabilidad por cualquier comentario que publique y por su exactitud. No nos hacemos responsables de los comentarios publicados por usted o por terceros.",
  termCont37: "Información personal",
  termCont38: "La información personal que envíe a través de la Tienda está sujeta a nuestra Política de Privacidad.",
  termCont39: "Errores, inexactitudes y omisiones",
  termCont40: "Ocasionalmente puede haber información en nuestro sitio o en el Servicio que contenga errores tipográficos, inexactitudes u omisiones que puedan estar relacionadas con descripciones de productos, precios, promociones, ofertas, gastos de envío de productos, tiempos de tránsito y disponibilidad. Nos reservamos el derecho de corregir cualquier error, inexactitud u omisión, y de cambiar o actualizar la información o cancelar pedidos si cualquier información en el Servicio o en cualquier sitio web relacionado es inexacta en cualquier momento sin previo aviso (incluso después de que usted haya enviado su pedido).",
  termCont41: "No tenemos obligación alguna de actualizar, modificar o aclarar la información de los Servicios o de cualquier sitio web relacionado, incluida, a título meramente enunciativo y no limitativo, la información sobre precios, salvo que así lo exija la ley. Si no se aplica una fecha de actualización o actualización especificada en los Servicios o en cualquier sitio web relacionado, se considerará que toda la información de los Servicios o de cualquier sitio web relacionado ha sido modificada o actualizada.",
  termCont42: "Usos prohibidos",
  termCont43: "Además de otras prohibiciones establecidas en las términos de servicio, no podrá utilizar el Sitio ni su Contenido: (a) para ningún fin ilícito; (b) para solicitar a otros que realicen o participen en ningún acto ilícito; (c) para infringir cualquier estatuto, norma, ley u ordenanza local internacional, federal, provincial o estatal; (d) para infringir o violar nuestros derechos de propiedad intelectual o los derechos de propiedad intelectual de otros; (e) para discriminar por motivos de género, orientación sexual, religión, raza, etnia, raza, edad, nacionalidad o discapacidad, acosar, abusar, insultar, herir, difamar, calumniar, menospreciar, intimidar o discriminar; (f) enviar información falsa o engañosa; (g) cargar o transmitir virus o cualquier otro tipo de código malicioso que afecte o pueda afectar de algún modo a la funcionalidad o al funcionamiento de los Servicios o de cualquier sitio web relacionado, otro sitio web o Internet; (h) recopilar o rastreo de información personal sobre otros; (i) spamming, phishing, pharming, pretexting, spidering, web-crawling o crawling; (j) para cualquier fin obsceno o inmoral; (k) para interferir o eludir las funciones de seguridad de los Servicios o de cualquier sitio web relacionado, otro sitio web o Internet. Nos reservamos el derecho a dar de baja al usuario por infringir cualquier uso prohibido de los Servicios o de cualquier sitio web relacionado.",
  termCont44: "Descargo de reponsabilidad; Limitación de responsabilidad",
  termCont45: "No garantizamos que el uso de nuestro servicio sea ininterrumpido, puntual, seguro o libre de errores.",
  termCont46: "No garantizamos que los resultados que puedan obtenerse del uso de los Servicios sean precisos o fiables.",
  termCont47: "Usted acepta que podemos eliminar los Servicios de vez en cuando indefinidamente o cancelar los Servicios en cualquier momento sin previo aviso.",
  termCont48: "Usted acepta expresamente que el uso o la imposibilidad de usar el servicio es por su cuenta y riesgo. el servicio y todos los productos y servicios que se le entreguen a través del servicio (a menos que indiquemos expresamente lo contrario) se proporcionan «tal cual» y «según disponibilidad» para su uso, sin declaraciones, garantías o condiciones expresas o implícitas de ningún tipo, incluidas todas las garantías o condiciones implícitas de comerciabilidad, calidad comercial, idoneidad para un fin determinado, durabilidad, titularidad y no infracción. ",
  termCont49: "En ningún caso DIY FASHION LIMITED, nuestros directores, funcionarios, empleados, afiliados, agentes, contratistas, internos, proveedores, prestadores de servicios o licenciantes serán responsables por cualquier lesión, pérdida, reclamo, o cualquier daño directo, indirecto, incidental, punitivo, especial o consecuente de cualquier tipo, incluyendo, sin limitación, pérdida de beneficios, pérdida de ingresos, pérdida de ahorros, pérdida de datos, costos de reemplazo, o cualquier daño similar, ya sea basado en contrato, agravio (incluyendo negligencia), responsabilidad objetiva o de otro tipo, derivada de su uso de cualquiera de los servicios o cualquier producto obtenido mediante el servicio, o por cualquier otra reclamación relacionada de alguna manera con su uso del servicio o cualquier producto, incluyendo, pero no limitado a, cualquier error u omisión en cualquier contenido, o cualquier pérdida o daño de cualquier tipo incurridos como resultado del uso del servicio o cualquier contenido (o producto) publicado, transmitido o puesto a disposición a través del servicio, incluso si se advierte de su posibilidad. Dado que algunos estados o jurisdicciones no permiten la exclusión o la limitación de responsabilidad por daños consecuenciales o incidentales, en dichos estados o jurisdicciones, nuestra responsabilidad se limitará al máximo permitido por la ley.",
  termCont50: "indemnizar",
  termCont51: "Usted acepta indemnizar, defender y eximir de responsabilidad a DIY FASHION LIMITED y a nuestras matrices, subsidiarias, afiliados, socios, funcionarios, directores, agentes, contratistas, licenciantes, proveedores de servicios, subcontratistas, proveedores, pasantes y empleados, indemnes de cualquier reclamo o demanda. incluyendo honorarios razonables de abogados, realizados por cualquier tercero debido a o que surjan de su incumplimiento de estos términos de servicio o de los documentos que incorporan por referencia, o su violación de cualquier ley o los derechos de un tercero.",
  termCont52: "Divisibilidad",
  termCont53: "Si se determina que alguna disposición de estas condiciones del servicio es ilegal, nula o inaplicable, dicha disposición seguirá siendo aplicable en la máxima medida permitido por la legislación aplicable, y la parte inaplicable se considerará separada de estas condiciones del servicio.  Dicha determinación no afectará a la validez y aplicabilidad de las demás disposiciones restantes.",
  termCont54: "Terminación",
  termCont55: "Las obligaciones y responsabilidades de las partes incurridas antes de la fecha de terminación sobrevivirán a la terminación de este acuerdo para todos los efectos.",
  termCont56: "Estos términos de servicio son efectivas a menos y hasta que usted o nosotros las rescindamos. Puede rescindir estas Condiciones del servicio en cualquier momento notificándonos que ya no desea utilizar nuestros Servicios, o cuando deje de utilizar nuestro sitio.",
  termCont57: "Si a nuestro juicio usted incumple, o sospechamos que ha incumplido, cualquier término o disposición de estas Condiciones de servicio, también podremos rescindir este acuerdo en cualquier momento sin previo aviso y usted seguirá siendo responsable de todos los importes adeudados hasta la fecha de rescisión inclusive; y/o en consecuencia podremos denegarle el acceso a nuestros Servicios (o a cualquier parte de los mismos).",
  termCont58: "No desprestigio. Durante el Plazo y después de él, usted acepta no realizar ninguna acción que tenga como objetivo, o que se pueda esperar razonablemente que dañe, a la Compañía o su reputación o que se pueda esperar razonablemente que genere publicidad no deseada o desfavorable para la Compañía en caso de que no haya recibido respuesta de nosotros durante al menos 4 días hábiles. Si viola este término, nos reservamos el derecho de rescindir el contrato sin compensación alguna.",
  termCont59: "Acuerdo completo",
  termCont60: "El hecho de que no ejerzamos o hagamos valer cualquier derecho o disposición de estas Condiciones del servicio no constituirá una renuncia a dicho derecho o disposición.",
  termCont61: "Estos términos de servicio y cualquier política o regla operativa publicada por nosotros en este sitio o con respecto al servicio constituyen el acuerdo y entendimiento completo entre usted y nosotros y rigen su uso del Servicio, reemplazando cualquier acuerdo, comunicación y propuesta anterior o contemporánea, ya sea oral o escrita, entre usted y nosotros (incluidas, entre otras, cualquier versión anterior de los Términos de Servicio).",
  termCont62: "Cualquier ambigüedad en la interpretación de estas condiciones del servicio no se interpretará en detrimento de la parte redactora.",
  termCont63: "Cambios en las Términos de servicio",
  termCont64: "Puede consultar la versión más actualizada de las Condiciones del servicio en cualquier momento en esta página. ",
  termCont65: "Nos reservamos el derecho, a nuestra entera discreción, de actualizar, cambiar o reemplazar cualquier parte de estos términos de servicio mediante la publicación de actualizaciones y cambios en nuestro sitio web.",
  termCont66: "Es su responsabilidad revisar esta página periódicamente para comprobar si se han producido cambios. El uso continuado o el acceso al sitio web después de la publicación de cualquier cambio constituye su aceptación de dichos cambios.",
  termCont67: "¿Preguntas sobre los términos?",
  //privacyPolicy
  privacyCont1: `Este sitio web está gestionado por DIY FASHION LIMITED y/o sus entidades afiliadas FEELTIMES LIMITED, filial en el Reino Unido (denominadas colectivamente "DIY FASHION LIMITED", "nosotros", "nos" o "nuestro", también conocido como stacees.com.Nos comprometemos a proteger y respetar la privacidad de sus datos personales. Esta declaración de privacidad explica cómo STACEES recopila, utiliza, transfiere y divulga sus datos. Se aplica a los datos recogidos cuando usted utiliza nuestros sitios web, cuando interactúa con nosotros a través de las redes sociales, el correo electrónico o el teléfono, o cuando participa en nuestros concursos o eventos. Incluye :`,
  privacyCont2: 'Los datos personales que recogemos',
  privacyCont3: 'Cómo recogemos sus datos',
  privacyCont4: 'Cómo utilizamos sus datos',
  privacyCont5: 'Preferencias de marketing, publicidad y cookies',
  privacyCont6: 'Enlaces a otros sitios web y terceros',
  privacyCont7: 'Cómo compartimos sus datos',
  privacyCont8: 'Sus derechos',
  privacyCont9: 'Cambios a esta política de privacidad',
  privacyCont10: 'Cómo contactarnos',
  privacyCont11: 'Quién es STACEES',
  privacyCont12: 'STACEES es uno de los principales minoristas de moda en línea. Diseñamos, producimos, comercializamos y vendemos ropa a mujeres  consumidoras de prácticamente todos los países del mundo.',
  privacyCont13: `STACEES (denominado colectivamente "STACEES", "nosotros", "nos"y "nuestro" en este aviso de privacidad) es el controlador y responsable de sus datos personales recogidos a través del sitio web www.stacees.com (el "sitio web").`,
  privacyCont14: 'Somos responsables de supervisar los detalles de la protección de datos en relación con las cuestiones cubiertas por esta declaración de privacidad,nuestros Información detallada en la sección ‘Cómo contactarnos’ al final de este aviso.',
  privacyCont15: 'Nuestro compromiso contigo',
  privacyCont16: 'Nos tomamos muy en serio la protección de sus datos personales y los trataremos de forma justa, lícita y transparente. Esta declaración de privacidad describe los datos personales que recopilamos sobre usted y la forma en que los utilizamos.',
  privacyCont17: 'Solo recogeremos y utilizaremos sus datos personales para los siguientes fines :',
  privacyCont18: 'Cumplir con su(s) pedido(s)',
  privacyCont19: 'mantenerle al día de las últimas ofertas y tendencias',
  privacyCont20: 'ofrecerle una mejor experiencia de compra',
  privacyCont21: 'ayúdanos a hacer que nuestro marketing sea más relevante para ti y sus intereses.',
  privacyCont22: 'mejorar nuestros servicios',
  privacyCont23: 'cumplir nuestras responsabilidades legales',
  privacyCont24: 'Cómo mantenemos sus datos seguros',
  privacyCont25: 'Contamos con las salvaguardas organizativas y las medidas de seguridad adecuadas para proteger sus datos de pérdidas accidentales, del uso o acceso no autorizado, de su alteración o divulgación.',
  privacyCont26: 'La comunicación entre su navegador y nuestro sitio web utiliza una conexión cifrada segura cuando se trata de sus datos personales. Ciframos sus datos y los almacenamos en servidores seguros ocultos tras un cortafuegos.',
  privacyCont27: 'Requerimos que cualquier tercero contratado para procesar sus datos personales en nuestro nombre disponga de medidas de seguridad para proteger sus datos y tratarlos de conformidad con la ley.',
  privacyCont28: 'En el desafortunado caso de que se produzca una violación de los datos personales, se lo notificaremos a usted y a cualquier autoridad supervisora aplicable cuando así lo requiera la ley.',
  privacyCont30: 'Por datos personales se entiende cualquier información sobre una persona a partir de la cual esta pueda ser identificada. No incluye los datos anonimizados, en los que se ha suprimido la identidad y la información identificativa.',
  privacyCont31: 'Aunque nuestro sitio web está diseñado para un público general, no recopilaremos conscientemente datos de niños menores de 13 años ni les venderemos productos. Si usted es menor de 13 años, no está autorizado a utilizar o enviar sus datos al sitio web.',
  privacyCont32: 'Se recogen los siguientes grupos de datos personales:',
  privacyCont33: '<strong>Los datos de Identidad</strong> incluyen información como: nombre, apellido, cargo, fecha de nacimiento (opcional), descripción personal (opcional), foto (opcional) y sexo (opcional).',
  privacyCont34: '<strong>Los datos de contacto</strong> incluyen la siguiente información:dirección de correo electrónico, dirección de facturación, dirección de envío, ubicación, país, número de teléfono, número de socio del programa de fidelización, ID de Amazon (si inicia sesión con Amazon) e ID de redes sociales (si inicia sesión mediante redes sociales).',
  privacyCont35: '<strong>Los datos financieros</strong> incluyen información como: detalles de la tarjeta de pago o información de la cuenta de PayPal.',
  privacyCont36: '<strong>Los datos de transacción</strong> incluyen información como los detalles de sus compras y el cumplimiento de sus pedidos (como número de cesta, número de pedido, subtotal, título, divisa, descuentos, envío, número de artículos, número de producto, precio de artículo único, categoría, impuestos, etc.); pagos realizados entre usted y nosotros y detalles de otros productos y servicios que haya obtenido de nosotros; correspondencia o comunicaciones con usted respecto a sus pedidos y detalles de cualquier recompensa y bonificación concedida.',
  privacyCont37: '<strong>Los datos técnicos</strong> incluyen información como: detalles de los dispositivos que utiliza para acceder a nuestros servicios, su dirección de protocolo de Internet (IP), datos de inicio de sesión, su nombre de usuario y contraseña, tipo y versión de navegador, configuración de zona horaria y ubicación, tipos y versiones de complementos del navegador, sistema operativo y plataforma.',
  privacyCont38: '<strong>Los datos de perfil</strong> incluyen la siguiente información: su compra o pedido, intereses en productos y estilos, preferencias, comentarios y respuestas a encuestas.',
  privacyCont39: '<strong>Los datos de uso</strong> incluyen la siguiente información: cómo y cuándo utiliza nuestro sitio web/aplicación, cómo navega por él, qué busca; estadísticas de rendimiento del sitio web/aplicación, tráfico, ubicación, registros web y otros datos de comunicaciones; actividades del programa de fidelización; detalles de cualquier otro producto y servicio de STACEES que utilice.',
  privacyCont40: '<strong>Los datos de marketing y comunicaciones</strong> incluyen información como: sus preferencias para recibir marketing nuestro y de terceros y sus preferencias de comunicación.',
  privacyCont41: 'También recopilamos, utilizamos y compartimos <strong>Datos Agregados</strong>, como estadísticas o datos demográficos, para cualquier fin. Los datos agregados pueden derivarse de los datos personales, pero no se consideran datos personales,ya que <strong>no</strong> revelan ni directa ni indirectamente la identidad de las personas. Por ejemplo, podemos agregar sus datos de uso para calcular el porcentaje de usuarios que acceden a una función específica del sitio web. Sin embargo, si combinamos o conectamos los datos agregados con sus datos personales de modo que puedan identificarle directa o indirectamente, trataremos los datos combinados como datos personales y los utilizaremos de conformidad con el presente aviso de privacidad.',
  privacyCont43: 'Podemos recopilar datos personales sobre usted de las siguientes maneras :',
  privacyCont44: '<strong>Interacciones directas</strong> - puede facilitarnos sus datos de identidad, de contacto, financieros, de transacciones, de perfil y de marketing y comunicaciones (tal y como se han descrito anteriormente) rellenando formularios, introduciendo información en línea o manteniendo correspondencia con nosotros por correo postal, teléfono, correo electrónico, teléfono o de cualquier otra forma.Esto incluye los datos personales que nos facilite, por ejemplo, cuando:',
  privacyCont45: 'Crear una cuenta o comprar productos en nuestro sitio web;',
  privacyCont46: 'Suscríbete a nuestro boletín, foros de discusión, sitios de redes sociales o crea listas de deseos ;',
  privacyCont47: 'Participar en un concurso ;',
  privacyCont48: 'Ingresar en un programa de fidelización de STACEES;',
  privacyCont49: 'Completar una encuesta voluntaria de investigación de mercado ;',
  privacyCont50: 'Póngase en Contáctenos para realizar una consulta o notificar un problema (por teléfono, correo electrónico, redes sociales o servicio de mensajería) ;',
  privacyCont51: 'Utilizar la función "recomendar a un amigo" de nuestro sitio web; o',
  privacyCont52: 'Cuando inicia sesión en nuestro sitio web a través de las redes sociales.',
  privacyCont53: '<strong>Tecnologías o interacciones automatizadas</strong> – al interactuar con nuestro sitio web, podemos recopilar automáticamente los siguientes tipos de datos (todos ellos descritos anteriormente):Datos técnicos sobre su dispositivo, datos de uso sobre su comportamiento y patrones de navegación, así como datos de contacto al realizar tareas.Los pedidos realizados a través de nuestro sitio web quedan sin cumplimentar, por ejemplo, si el pedido está incompleto o se abandona la cesta de la compra. Recopilamos estos datos utilizando cookies, registros de servidor y otras tecnologías similares.',
  privacyCont54: '<strong>Terceros</strong> – podemos datos personales sobre usted de varios terceros,incluidos:',
  privacyCont55: 'Datos técnicos de terceros, incluidos proveedores de análisis como Google. Consulte más información en la sección "Preferencias de marketing, anuncios y cookies".',
  privacyCont56: 'Datos técnicos de las redes de afiliados a través de las cuales ha accedido a nuestro sitio web ;',
  privacyCont57: 'Datos de identidad y contacto de plataformas de redes sociales cuando inicia sesión en nuestro sitio web utilizando dichas plataformas de redes sociales;',
  privacyCont58: 'Datos de contacto, financieros y de transacciones de proveedores de servicios técnicos, de pago y de entrega.',
  privacyCont60: 'Base jurídica del tratamiento de sus datos personales',
  privacyCont61: 'Solo recogeremos y trataremos sus datos personales cuando tengamos una base jurídica para hacerlo. Como responsables del tratamiento de datos, la base jurídica de nuestra recogida y uso de sus datos personales varía en función de la forma y la finalidad para la que los hayamos recogido.',
  privacyCont62: 'Solo recopilaremos sus datos personales cuando :',
  privacyCont63: 'tenemos su consentimiento para hacerlo, o',
  privacyCont64: 'necesitamos sus datos personales para ejecutar un contrato con usted. Por ejemplo, para procesar un pago suyo, tramitar su pedido o proporcionarle asistencia como cliente en relación con un pedido, o',
  privacyCont65: 'el procesamiento es de nuestro interés legítimo y no se ve anulado por sus derechos, o',
  privacyCont66: 'tenemos la obligación legal de recoger o revelar sus datos personales.',
  privacyCont67: 'Uso de sus datos personales',
  privacyCont68: 'STACEES utiliza sus datos personales para respaldar una variedad de actividades diferentes. Estas se enumeran en la siguiente tabla junto con los tipos de datos utilizados y las bases legales en las que nos basamos para procesarlos, incluidos, cuando corresponda, nuestros intereses legítimos. Tenga en cuenta que podemos procesar sus datos personales utilizando más de una base legal, según la actividad específica involucrada. Comuníquese con nosotros si necesita detalles sobre la base legal específica en la que nos basamos para procesar sus datos personales cuando se haya establecido más de una base legal en la siguiente tabla.',
  privacyCont69: 'Propósito/Actividad',
  privacyCont70: 'Tipo de datos',
  privacyCont71: 'Base legítima para el tratamiento, incluidos los intereses legítimos',
  privacyCont72: 'Para crear una cuenta y registrarle como nuevo cliente (directamente o a través de las redes sociales).',
  privacyCont73: '• Identidad',
  privacyCont74: '• Contacto',
  privacyCont75: '• Firmar un contrato con usted',
  privacyCont76: 'Para procesar y entregar su pedido, incluyendo: registrar los detalles de su pedido; mantenerlo informado sobre el estado del pedido; procesar pagos y reembolsos, cobrar el dinero que nos debe; y ayudar a prevenir y detectar fraudes.',
  privacyCont77: '• Financiero',
  privacyCont78: '• Transacción',
  privacyCont79: '• Necesario para nuestros intereses legítimos (por ejemplo, para recuperar deudas que se nos deben)',
  privacyCont80: 'Para gestionar nuestra relación con usted, lo que incluye: proporcionarle cualquier información, productos y servicios que nos solicite; notificarle cambios en nuestros servicios, términos y condiciones o aviso de privacidad; pedirle que deje una opinión o responda a una encuesta.',
  privacyCont81: '• Perfil',
  privacyCont82: '• Marketing y Comunicaciones',
  privacyCont83: '• Necesario para nuestros intereses legítimos (mantener actualizados nuestros registros y estudiar cómo utilizan los clientes nuestros productos y servicios).',
  privacyCont84: 'Para permitirle participar en un concurso, evento, encuesta o recibir una recompensa por comprar con nosotros.',
  privacyCont85: '• Uso',
  privacyCont86: '• Necesario para nuestros intereses legítimos (para estudiar cómo utilizan los clientes nuestros productos y servicios, desarrollarlos y hacer crecer nuestro negocio)',
  privacyCont87: '• Si decide participar en un concurso o evento para la ejecución de un contrato con usted',
  privacyCont88: 'Para administrar, proteger y mejorar nuestro negocio y nuestro sitio web/aplicación, incluyendo: resolución de problemas, análisis de datos, pruebas, mantenimiento del sistema, asistencia, análisis de datos, elaboración de informes y alojamiento de datos; configuración de opciones predeterminadas para usted, como el idioma y la moneda.  ',
  privacyCont89: '• Técnico',
  privacyCont90: '• Necesario para nuestros intereses legítimos (para administrar nuestro negocio, proporcionar servicios administrativos y de TI, seguridad de la red y detectar y prevenir fraudes)',
  privacyCont91: '• Necesario para cumplir con una obligación legal',
  privacyCont92: 'Para ofrecerle contenidos pertinentes del sitio web, anuncios en línea e información; y medir la eficacia de la publicidad proporcionada.',
  privacyCont93: '• Necesario para nuestros intereses legítimos(para estudiar cómo utilizan los clientes nuestros productos y servicios, desarrollarlos, hacer crecer nuestro negocio y fundamentar nuestra estrategia de marketing)',
  privacyCont94: 'Para utilizar el análisis de datos para : mejorar nuestro sitio web, productos, servicios, marketing, relaciones y experiencias de los clientes;',
  privacyCont29: 'y para fines de investigación de mercado, estadísticos y de encuestas.',
  privacyCont95: '• Necesario para nuestros intereses legítimos(para definir tipos de clientes para nuestros productos y servicios, mantener nuestro sitio web actualizado y relevante, desarrollar nuestro negocio e informar nuestra estrategia de marketing)',
  privacyCont96: 'Para recomendarle productos, servicios, descuentos y ofertas que puedan ser de su interés, incluido el envío de dicha información por correo electrónico, correo postal o SMS.',
  privacyCont97: '• Necesario para nuestros intereses legítimos(para desarrollar nuestros productos y servicios y hacer crecer nuestro negocio) o Consentimiento.',
  privacyCont98: "Ver más detalles en la sección 'Preferencias de Marketing, Publicidad y Cookies'",
  privacyCont99: 'Para informarle o recordarle por correo electrónico cualquier tarea realizada a través de nuestro sitio web que quede sin completar, como pedidos incompletos o cestas abandonadas.',
  privacyCont100: '• Necesario para nuestros intereses legítimos (para mejorar la experiencia de compra de nuestros clientes)',
  privacyCont101: 'Solo utilizaremos sus datos personales para los fines para los que los recogimos, a menos que consideremos razonablemente que necesitamos utilizarlos para otro motivo y que este es compatible con el fin original. Si deseamos utilizar sus datos personales para un fin no relacionado, se lo notificaremos y le explicaremos la base jurídica que nos permite hacerlo. Podemos tratar datos personales sin su consentimiento cuando así lo exija o permita la ley.',
  privacyCont102: 'Si tiene alguna pregunta sobre cómo STACEES utiliza cualquiera de sus datos personales, contáctenos a service@stacees.com o chatee con nosotros.',
  privacyCont103: 'Cuánto tiempo mantenemos sus datos',
  privacyCont104: 'No conservaremos sus datos personales más tiempo del necesario para los fines para los que se facilitaron y para cumplir nuestras obligaciones legales. Si lo desea, puede solicitar información más detallada sobre los periodos de tiempo durante los que conservamos los datos.',
  privacyCont106: 'Marketing - sus preferencias',
  privacyCont107: 'Podemos enviarle comunicaciones de marketing y ofertas promocionales :',
  privacyCont108: 'si ha abierto una cuenta con nosotros o nos ha comprado productos, o se ha inscrito en una promoción o evento, y no ha elegido no recibir ese marketing (de acuerdo con sus preferencias, como se explica más adelante);',
  privacyCont109: 'por correo electrónico si se ha suscrito a los boletines informativos por correo electrónico ;',
  privacyCont110: 'si nos ha proporcionado sus datos al participar en un concurso y ha dado su consentimiento para recibir este tipo de marketing (de acuerdo con sus preferencias, como se explica a continuación).',
  privacyCont111: 'Podemos utilizar sus datos de Identidad, Contacto, Técnicos, Transaccionales, de Uso, de Perfil y de Marketing y Comunicaciones para formarnos una opinión sobre lo que creemos que puede gustarle, o lo que puede ser de su interés, y para enviarle detalles de productos y ofertas que pueden ser relevantes para usted.',
  privacyCont112: 'Le preguntaremos sobre sus preferencias para recibir comunicaciones de marketing por correo electrónico, correo postal, SMS y otros canales de comunicación.',
  privacyCont113: 'De vez en cuando, también podemos insertar en su pedido publicidad de bienes, servicios u ofertas de otras empresas de terceros que puedan ser de su interés.',
  privacyCont114: 'Con respecto a las comunicaciones marketing de terceros, obtendremos su consentimiento expreso antes de compartir sus datos personales con terceros con fines de marketing.',
  privacyCont115: 'Siempre tendrá pleno control sobre sus preferencias de marketing. Si no desea seguir recibiendo información comercial nuestra (o de terceros, en su caso) en cualquier momento :',
  privacyCont116: `Puede cancelar su suscripción o “excluirse” utilizando el botón para cancelar la suscripción y siguiendo el enlace incluido en el pie de página de cualquier correo electrónico de marketing; o <a class="font_red" href="{hrefLogin}">los titulares de cuentas pueden retirar su consentimiento simplemente iniciando sesión en mi cuenta y editando sus “Preferencias de contacto”.</a>`,
  privacyCont117: 'Tramitaremos todas las solicitudes de exclusión lo antes posible, pero tenga en cuenta que, debido a la naturaleza de nuestros sistemas IT informáticos y servidores, pueden transcurrir unos días hasta que se ejecute cualquier solicitud de exclusión.',
  privacyCont118: 'Cookies',
  privacyCont119: 'Nuestro sitio web utiliza cookies para distinguirle de otros usuarios y hacer un seguimiento de sus visitas. Nos ayudan a ofrecerle la mejor experiencia cuando navega por nuestro sitio web y a introducir mejoras en el mismo. También nos ayudan a nosotros y a nuestras redes publicitarias a mostrarle publicidad relevante para usted y sus intereses.',
  privacyCont120: 'Puede configurar su navegador para que rechace todas las cookies o algunas de ellas, o para que le avise cuando los sitios web instalen cookies o accedan a ellas. Si desactiva o rechaza las cookies, tenga en cuenta que algunas partes de nuestro sitio web pueden resultar inaccesibles o no funcionar correctamente.',
  privacyCont121: 'Anuncios en línea',
  privacyCont122: 'Utilizamos publicidad en línea para mantenerle informado de nuestras actividades y ayudarle a encontrar nuestros productos. Al igual que muchas empresas, podemos dirigirle banners y anuncios de STACEES cuando utilice otros sitios web y aplicaciones, basándonos en sus datos de contacto, técnicos, de uso y de perfil. Para ello, utilizamos diversas redes de marketing digital e intercambios publicitarios, así como una serie de tecnologías publicitarias, como balizas web, píxeles, etiquetas publicitarias, cookies e identificadores móviles. También usamos servicios específicos ofrecidos por algunos sitios y redes sociales, como el servicio de audiencia personalizada de Facebook.',
  privacyCont123: 'Utilizamos Herramientas publicitarias de  analíticos y objetivo',
  privacyCont124: "Utilizamos herramientas publicitarias de  analíticos y objetivo para mostrarle contenido web relevante en nuestros sitios web y anuncios en línea en otros sitios web y aplicaciones (como se ha descrito anteriormente), para ofrecerle contenido relevante en las comunicaciones de marketing (cuando proceda) y para medir la eficacia de los anuncios publicados. Por ejemplo, utilizamos herramientas como Google Analytics para analizar los datos de la publicidad basada en intereses de Google y/o los datos de audiencia de terceros (por ejemplo, edad, estado civil, acontecimientos vitales, sexo e intereses) para orientar y mejorar nuestras campañas de marketing, estrategias de marketing y contenido del sitio web. También podemos utilizar herramientas proporcionadas por terceros, como Facebook, Adroll, Springbot, Criteo y Bing, para realizar tareas similares utilizando sus datos de contacto, técnicos, de uso y de perfil.",
  privacyCont125: 'Para excluirse de la publicidad dirigida, debe deshabilitar sus "cookies" en la configuración de su navegador (consulte la Política de cookies para obtener más detalles) o cancelar la configuración de anuncios de terceros correspondientes. Por ejemplo, puede inhabilitar las funciones de publicidad gráfica de Google.',
  privacyCont126: 'La Digital Advertising Alliance( que incluye a empresas como Google, Responsys y Facebook) ofrece una herramienta llamada WebChoices que le permite escanear rápidamente su ordenador o dispositivo móvil para averiguar qué empresas participantes han habilitado anuncios personalizados para su navegador y ajuste las preferencias de su navegador en consecuencia.',
  privacyCont127: 'Si desea más información sobre los datos recogidos por estos terceros o sobre el modo en que se utilizan los datos, póngase en contáctenos.',
  privacyCont129: 'Nuestro sitio web puede incluir enlaces hacia y desde los sitios web de nuestras redes asociadas, anunciantes y afiliados, o hacia plataformas de redes sociales. Si sigue un enlace a cualquiera de estos sitios web, tenga en cuenta que estos sitios web tienen sus propias políticas de privacidad y que no aceptamos ninguna responsabilidad por estas políticas. Consulte estas políticas antes de enviar cualquier dato personal a sus sitios web.',
  privacyCont131: 'Podemos revelar y compartir sus datos personales con las partes que se indican a continuación :',
  privacyCont132: 'cuando nos haya dado su consentimiento para ello. Por ejemplo, si ha dado su consentimiento para recibir material de marketing de terceros, o en relación con productos y servicios de terceros (incluidos los de marca compartida o promocionados conjuntamente), podremos transmitir sus datos a los terceros pertinentes con el fin de enviarle dichas comunicaciones comerciales;',
  privacyCont133: 'Contratamos a proveedores de servicios externos para suministrar sistemas IT y programas informáticos y alojar nuestros sitios web;',
  privacyCont134: 'servicios de procesamiento de pagos de terceros (incluidos Authorize.net, PayPal, Braintree y Card Connect) para procesar su pago a nosotros. No almacenamos los datos de su tarjeta de crédito/débito;',
  privacyCont135: 'proveedores de servicios externos que contratamos para entregar los bienes que usted ha pedido;',
  privacyCont136: 'proveedores de servicios externos que contratamos para enviar correos electrónicos y correo postal en nuestro nombre, incluso en relación con pedidos incompletos o cestas abandonadas, o comunicaciones de marketing, para proporcionar servicios de limpieza de datos y para proporcionar servicios de marketing y publicidad;',
  privacyCont137: 'proveedores de análisis y motores de búsqueda que nos ayudan a mejorar y optimizar nuestro sitio web;',
  privacyCont138: 'está visitando una red de filiales de nuestro sitio web;',
  privacyCont139: 'a cualquier tercero al que podamos optar por vender, transferir o fusionar partes de nuestro negocio o nuestros activos. Alternativamente, podemos intentar adquirir otros negocios o fusionarnos con ellos. Si se produce un cambio en nuestro negocio, los nuevos propietarios pueden utilizar sus datos personales de la misma manera que se establece en este aviso de privacidad.',
  privacyCont140: 'Para proteger a nuestros clientes y nuestro sitio web contra el fraude y el robo, podemos compartir los datos personales necesarios para realizar comprobaciones de identidad y los datos personales que obtenemos al realizar comprobaciones de identidad (incluidos los datos relativos a su edad, nombre y ubicación), junto con la información de la cuenta, con organizaciones (incluidos los organismos encargados de hacer cumplir la ley), que participan en la prevención y detección del fraude y la reducción del riesgo crediticio. Tenga en cuenta que estos terceros pueden conservar un registro de la información que les proporcionamos con este fin;',
  privacyCont141: 'Si tenemos la obligación de revelar o compartir sus datos personales para cumplir alguna obligación legal;o',
  privacyCont142: 'a nuestros asesores profesionales, incluidos abogados, banqueros, auditores y aseguradoras que prestan servicios de consultoría, banca, jurídicos, de seguros y contabilidad. ',
  privacyCont143: 'Sus datos y los países fuera de Europa',
  privacyCont144: 'Los datos personales que recopilamos de usted pueden transferirse legalmente a través de las fronteras utilizando los mecanismos previstos por la ley y a destinos fuera del Espacio Económico Europeo ("EEE") y almacenarse en ellos.También puede ser tratada por empleados que trabajen para nosotros o para uno de nuestros proveedores fuera del EEE. Dicho personal puede participar, entre otras cosas, en la ejecución de su pedido, el tratamiento de sus datos de pago y la prestación de servicios de asistencia. Tomaremos todas las medidas necesarias para garantizar que sus datos se traten de forma segura y conforme al presente aviso de privacidad.',
  privacyCont145: 'Siempre que transfiramos datos personales fuera del EEE, garantizaremos un grado similar de protección de los mismos, asegurándonos de que existen las salvaguardas adecuadas, tal y como exige la ley. Esto puede incluir el uso de cláusulas contractuales específicas aprobadas por la Comisión Europea que otorgan a los datos personales la misma protección que tienen en Europa. Puede obtener más información al respecto aquí  http://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX:32010D0087',
  privacyCont146: 'Póngase en contacto con nosotros si desea obtener más información sobre los países a los que podemos transferir datos personales y el mecanismo específico que utilizamos al transferir sus datos personales fuera del EEE.',
  privacyCont148: 'Usted tiene varios derechos en virtud de la legislación sobre privacidad de datos. Esto incluye, en determinadas circunstancias, el derecho a :',
  privacyCont155: 'Contra la toma de decisiones automatizada',
  privacyCont156: `A continuación  se describen  brevemente cada uno de estos derechos  detalle.Si desea ejercer alguno de estos derechos, envíenos un correo electrónico a privacy@Stacees.com o chatee con nosotros.`,
  privacyCont157: 'Solicitar acceso a sus datos personales',
  privacyCont158: 'Tiene derecho a obtener una copia de los datos personales que tenemos sobre usted y cierta información relacionada con nuestro procesamiento de sus datos personales.',
  privacyCont159: 'Solicitar la corrección de sus datos personales',
  privacyCont160: 'Tiene derecho a que se corrijan sus datos personales si son inexactos o están incompletos. Puede actualizar sus datos personales en cualquier momento accediendo a su cuenta y actualizándolos directamente, o enviándonos un correo electrónico a privacy@stacees.com o chateando con nosotros.',
  privacyCont161: 'Solicitar la supresión de sus datos personales',
  privacyCont162: 'Esto le permite solicitar que STACEES elimine sus datos personales, cuando no exista una buena razón para que sigamos procesándolos. Sin embargo, tenga en cuenta que es posible que no siempre podamos cumplir con su solicitud de eliminación por motivos legales específicos que se le notificarán, si corresponde, en el momento de su solicitud.',
  privacyCont163: 'Solicitar la restricción del tratamiento de sus datos personales',
  privacyCont164: 'Usted tiene derecho a solicitar a STACEES que suspenda el tratamiento de sus datos personales en determinados supuestos, por ejemplo, si desea que establezcamos la exactitud de los datos, o si se ha opuesto a que utilicemos sus datos, pero necesitamos verificar si tenemos motivos legítimos imperiosos para utilizarlos. Cuando el tratamiento está restringido, podemos conservar información suficiente sobre usted para garantizar que la restricción se respeta en el futuro.',
  privacyCont165: 'Solicitar la transmisión de sus datos personales',
  privacyCont166: 'Tiene derecho a obtener una copia digital de sus datos personales o a solicitar que se transmitan a otra empresa.  Tenga en cuenta que este derecho solo se aplica a los datos automatizados que usted haya aceptado inicialmente que utilicemos, o cuando hayamos utilizado estos datos para celebrar un contrato con usted.',
  privacyCont167: 'Contra del tratamiento de sus datos personales',
  privacyCont168: 'Tiene derecho a oponerse al tratamiento de sus datos personales cuando consideremos que tenemos un interés legítimo en dicho tratamiento (como se ha explicado anteriormente). También tiene derecho a oponerse a que procesemos sus datos personales con fines de marketing directo. En algunos casos, podemos demostrar que tenemos motivos legítimos imperiosos para tratar sus datos que prevalecen sobre sus derechos y libertades.',
  privacyCont169: 'Contra la toma de decisiones y el análisis automatizados',
  privacyCont170: 'Tiene derecho a oponerse al tratamiento automatizado de sus datos personales sin intervención humana. Este tipo de tratamiento está permitido como parte de nuestro contrato con usted, siempre que se hayan tomado las medidas de protección adecuadas o que hayamos obtenido su consentimiento expreso.',
  privacyCont171: 'Intentaremos responder a todas las solicitudes legítimas en el plazo de un mes. No obstante, ocasionalmente podemos tardar más de un mes si su solicitud es especialmente compleja o si ha realizado varias solicitudes. En tal caso, se lo notificaremos y le mantendremos informado. Es posible que necesitemos solicitarle información específica para confirmar su identidad y garantizar su derecho a ejercer cualquiera de los derechos mencionados. Se trata de una medida de seguridad para garantizar la confidencialidad de los datos personales y evitar que se revelen a personas que no tengan derecho a recibirlos.',
  privacyCont172: 'Derecho a presentar una denuncia',
  privacyCont173: `Si tiene alguna duda o queja sobre la forma en que procesamos sus datos, envíenos un correo electrónico directamente a service@stacees.com o chatee con nosotros. También tiene derecho a presentar una reclamación ante la OIC (organismo de control de la protección de datos en el Reino Unido). Sin embargo, le agradeceríamos que nos diera la oportunidad de resolver sus dudas antes de ponerse en contacto con la OIC, por lo que le rogamos que se ponga en contacto con nosotros en primera instancia.`,
  privacyCont175: 'De vez en cuando podemos cambiar este aviso de privacidad. Si se produce algún cambio significativo, publicaremos las actualizaciones en nuestro sitio web y aplicaciones, o se lo comunicaremos por correo electrónico.',
  privacyCont176: `Agradecemos sus comentarios y estaremos encantados de responder a cualquier pregunta que pueda tener sobre sus datos. Envíe cualquier pregunta, comentario o solicitud de más información a service@stacees.com o chatee con nosotros. Este Aviso de privacidad se actualizó por última vez el 22 de septiembre de 2022.`,
  //mix-match-bridesmaid
  mixMatchTit: 'Vestidos de dama de honor en colores y estilos Mix & Match',
  maxBrid: 'VESTIDOS DE DAMA DE HONORMIX & MATCH',
  maxBrid1: 'CÓMO:  DAMAS DE HONOR MIX & MATCH',
  maxBrid2: "<p>A la hora de elegir el atuendo para la fiesta de boda, puede parecer que todo el, mundo tiene una </p><p>opinión.Si quieres complacer a toda la tripulación (a la vez que te ves muy elegante), considera los </p><p>vestidos de dama de honor desparejados.Cuando se ejecuta correctamente, vestidos de dama de </p><p>honor no coincidentes ayudarán a su equipo se destacan, Aquí, cubrimos todo lo que necesita saber </p><p>acerca de la coordinación impecable vestidos de dama de honor no coincidentes.Además, hemos incluido una guía de compra con los colores más populares.</p>",
  maxBrid3: 'comprar vestidos de dama de honor',
  maxBrid4: 'Estilo diferente (¡Mismo color!)',
  maxBrid5: 'El tipo más común de fiesta nupcial mix and match presenta diferentes estilos de vestidos pero del mismo color. Es perfecto cuando te enamoras de un color de boda, pero quieres el popular look desparejado. Esta opción es la más fácil de coordinar y permitirá a tus damas de honor tener una opción. Permitir que tus damas de honor elijan su propio estilo hará que se sientan más cómodas y seguras.Después de todo, ¡los diferentes estilos favorecen a diferentes tipos de cuerpo!',
  maxBrid6: `comprar por color`,
  maxBrid7: 'Diferente color (¡Mismo estilo!)',
  maxBrid8: 'Cuando no puedes elegir un solo color, ¿por qué no elegir varios? Esta opción es más fácil de coordinar que una mezcla de diferentes estilos y colores de vestidos, ya que no hay riesgo de que los diseños desentonen. Simplemente elige un estilo de vestido que favorezca a todas las damas de honor de tu séquito. Por lo general, una silueta en forma de A funciona mejor. A partir de ahí, especifica los colores que pueden usar, ¡pero asegúrate de que haya una distribución uniforme! No quieres que todas menos una de las damas de honor combinen. Recomendamos que la novia especifique qué color debe usar cada persona del grupo.',
  maxBrid9: 'Compra este estilo',
  maxBrid10: 'Vestidos de diferentes colores y estilos',
  maxBrid11: 'Entonces, quieres que tus niñas usen vestidos de dama de honor en diferentes estilos y colores. ¡No te preocupes! Si bien esta opción requiere más trabajo, también brindará el aspecto más exclusivo. Primero, deberás determinar qué colores planeas usar. Recomendamos de 3 a 4 y asegurarte de que se complementen entre sí. Por ejemplo, mezcla tonos y matices de un color, como azul, rosa o violeta. A continuación, debes seleccionar el estilo de los vestidos. Si bien quieres que los vestidos favorezcan a cada una de tus niñas, cada vestido también debe complementarse entre sí. Así que intenta que las siluetas, las telas o los detalles coincidan. De lo contrario, terminarás con una alineación que se verá demasiado recargada o caótica. La mejor opción es presentar siluetas, cinturas, escotes o tirantes similares.',
  maxBrid12: 'Consejos para coordinar vestidos de dama de honor desparejados ',
  maxBrid13: 'Incluso si tu objetivo es una fiesta nupcial con damas de honor desparejadas, quieres que parezca bien pensada, no desordenada. Sigue estos consejos para que tus damas de honor parezcan intencionadas y cuidadosamente planificada.',
  maxBrid14: 'Ata tus vestidos de dama de honor con un detalle común',
  maxBrid15: "<p>Elegir vestidos que tengan detalles comunes es una de </p><p>las mejores formas de garantizar un look cohesivo. </p><p>Puede tratarse de telas a juego (gasa, tul, terciopelo), </p><p>escotes similares, colores a juego, siluetas similares, etc.  </p><p>Necesitas al menos uno o dos detalles que combinen, </p><p>details that match, otherwise, you risk a chaotic vibe </p><p>caótico que no sea agradable a la vista.</p>",
  maxBrid16: "<p>Siempre recomendamos usar vestidos del mismo largo. </p><p>¡Combinar vestidos cortos y largos rara vez funciona! </p><p>Especialmente si tenemos en cuenta que cada largo </p><p>aporta un estilo diferente. Los vestidos largos suelen </p><p>considerarse más formales, mientras que los cortos son </p><p>un poco más informales. No queremos que nuestras </p><p>chicas parezcan confundidas con la ocasión.</p>",
  maxBrid17: 'Piensa bien en la paleta de colores de tu boda',
  maxBrid18: `<p>Al seleccionar los colores que usarán tus damas de </p><p>honor, no te limites a combinar una paleta de colores. </p><p>Algunos colores no se mezclan, como los tonos cálidos </p><p>y fríos. Busca fotos de inspiración y <a href="{hrefFabric}" class="color_pink">pide muestras</a> para </p><p>probar cómo se verán los colores del vestido uno al lado </p><p>del otro. Una vez que hayas encontrado la paleta de </p><p>colores perfecta para tu boda, es más fácil que nunca </p><p>comprar tus vestidos de dama de honor en línea en STACEES.</p>`,
  maxBrid19: '¡Dale a tus damas de honor amplia orientación!',
  maxBrid20: "<p>Es importante que, al permitir que tus damas de honor </p><p>mezclen y combinen colores y/o estilos, les des una </p><p>orientación. Debes crear un tablero de inspiración o </p><p>fotos de muestra que puedas compartir y que les den </p><p>una idea del estilo que buscas. No esperes que tus </p><p>damas de honor encuentren un vestido (que te </p><p>encante) por sí mismas. Tú eres responsable de </p><p>coordinar todo. Una de las mejores formas de ayudarlas </p><p>es recomendarles tus sitios web favoritos.</p>",
  maxBrid21: 'Beneficios de los vestidos de dama de honor desparejados',
  maxBrid22: 'Los vestidos de dama de honor desparejados tienen muchas ventajas. La primera, como ya hemos mencionado, es que tus chicas sabrán qué estilos favorecen más a sus cuerpos. También podrán vestirse según su estilo personal, ya sea modesto o más sexy. Así se sentirán cómodas y seguras de sí mismas. Además, y esto es igual de importante, les permitirá cierta flexibilidad en cuanto al precio. No todo el mundo puede permitirse el mismo vestido. Permitir que tus hijas elijan su propio vestido significa que pueden ajustarse a su presupuesto, sin culpas ni presiones.',
  maxBrid23: 'Sabemos que crear una fiesta nupcial dispareja es más fácil de decir que de hacer. Para inspirarte, echa un vistazo a estas bodas reales en Kennedy Blue para ver los colores y estilos que puedes incorporar a tu boda.',
  maxBrid24: 'Conclusión',
  maxBrid25: "Ahora te damos algunas sugerencias serias para que las tengas en cuenta. ¡Es hora de tomar una decisión! ¿Prefieres un vestido de dama de honor desigual o del mismo estilo? Ponte en contacto con nosotros por correo electrónico:",
  maxBrid26: " , dinos qué piensas y estaremos encantados de ayudarte.",
  maxBrid27: `Compra por esta mezcla de colores`,
  shopBy: 'Comprar por colores',
  shopByTxt1: `Encuentra colores que complementen la paleta de tu boda o mézclalos y combínalos.`,
  shopByTxt2: `Hola chica, ¿estás ocupada comprando vestidos de dama de honor por colores? Echa un vistazo a la colección de vestidos de dama de honor de STACEES. Como fabricante profesional de vestidos desde hace más de una década, STACEES ofrece a las mujeres vestidos de primera calidad con un diseño fabuloso y detalles impecables. No solo disponemos de modelos de todas las tallas, sino también de una variedad inimaginable de colores.Disponemos de los colores habituales para los vestidos de dama de honor, como el rosa o el morado. Sin embargo, también ofrecemos a nuestros clientes una selecta muestra de colores para que sus chicas puedan destacar entre todas.Entendemos la dificultad de echar un vistazo a cientos de tiendas de vestidos de dama de honor en línea para encontrar solo uno o dos diseños. Para ser sinceros, no hay muchas tiendas que ofrezcan vestidos de dama de honor que garanticen tanto el aspecto como la calidad.Aunque estés satisfecha con el diseño y todo lo demás, luego te sentirás intimidada por el precio increíblemente alto. STACEES es una de las mejores tiendas de vestidos de dama de honor y está decidida a atender a todas las futuras novias. Así, no hay necesidad de angustiarse por nada nupcial.Lo único que tienes que hacer es elegir un par de vestidos de dama de honor de colores elegantes para tus amigas y familiares y dejarnos el resto a nosotros. Somos profesionales. Y nunca defraudamos a nuestros clientes.`,
  shopBridDress: 'Comprar todos los vestidos de dama de honor {color}',
  colorSwatch: `Muestras de color`,
  shopNow: 'Comprar',
  allPinks: 'Todos los rosas',
  allBlues: 'Todos los azules',
  allYellows: 'Todos los amarillos',
  allGreens: 'Todos los verdes',
  allOranges: 'Todos los naranjas',
  allPurples: 'Todos los púrpuras',
  allReds: 'Todos los rojos',
  allGreys: 'Todos los grises',
  //most loved
  mostLovedTit: 'Vestidos de novia favoritos',
  mostLoved1: 'Clásico Vintage',
  mostLoved2: "Vestidos de novia vintage para una apariencia <span> atemporal que siempre estará de moda.</span>",
  mostLoved3: 'Boho romántico',
  mostLoved4: 'Estos diseños son sexy y sofisticados con un <span>elegante encaje y una espalda abierta.</span>',
  mostLoved5: 'Glamour moderno',
  mostLoved6: 'Los vestidos de novia glamorosos suelen <span>tener algunos detalles que dejan sin aliento.</span>',
  mostLoved7: `Se seleccionan cuidadosamente los colores <span>para que sea muy fácil combinarlos.</span>`,
  mostLoved8: 'Ver más estilos',
  mostLoved9: 'Mix & Match para <span>damas de honor</span>',
  //Order Cancellation
  orderTit: 'Cancelación de pedido',
  orderCan1: 'Entendemos verdaderamente lo importante que es encontrar el vestido perfecto para tu ocasión especial y estamos aquí para ayudarte durante todo este proceso. Una vez que realizas tu pedido, comenzamos a procesarlo de inmediato para garantizar la máxima calidad y una entrega oportuna.',
  orderCan2: 'Dado que cada vestido se fabrica cuidadosamente bajo pedido, los materiales no pueden reutilizarse una vez que comienza la producción. Por esta razón, te recomendamos que nos informes lo antes posible si necesitas hacer algún cambio o cancelación',
  orderCan3: 'Si tu pedido aún se encuentra en la etapa de procesamiento, es posible que puedas cancelarlo.Sin embargo, una vez que pase a la etapa de envío, las cancelaciones ya no serán posibles. Si necesitas cancelar tu pedido, simplemente contáctanos a través del chat en directo. Nuestro operador estará encantado de ayudarte a cancelar o cambiar tu pedido a tiempo. Alternativamente, puedes enviarnos un correo electrónico con información sobre tu pedido.',
  orderCan4: `Si deseas cancelar tu pedido, por favor inicia sesión en tu cuenta, ve a "<a class="udline myorder" href="{hrefOrder}">Mis pedidos</a>", haz clic en "Crear una consulta postventa", completa los campos obligatorios y envíalo. <br/>Nuestro equipo de atención al cliente se pondrá en contacto contigo lo antes posible para confirmar y ayudarte con la cancelación.`,
  orderCan5: 'Los pedidos cancelados dentro de las 12 horas posteriores a la confirmación del pago serán elegibles para un reembolso parcial consistente en el costo total de envío y el 90% del precio de compra del producto.',
  orderCan6: 'Los pedidos cancelados dentro de las 12 a 24 horas posteriores a la confirmación del pago serán elegibles para un reembolso parcial consistente en el costo total de envío y el 80% del precio de compra del producto.',
  orderCan7: 'Los pedidos cancelados dentro de las 24 a 48 horas posteriores a la confirmación del pago serán elegibles para un reembolso parcial que consiste en el costo total de envío y el 50% del precio de compra del producto.',
  orderCan8: 'Más de 48 horas',
  orderCan9: 'Los pedidos cancelados más de 48 horas después de la confirmación del pago serán elegibles para un reembolso parcial únicamente del costo total de envío.',
  orderCan10: 'Una vez que su pedido haya sido enviado, ya no podrá cancelarse.',
  orderCan11: 'Si desea cancelar su pedido, inicie sesión en <a class="udline myorder" href="javascript:void(0)">mis pedidos</a>, seleccione el pedido con el que necesita ayuda y solicite la cancelación. O haga clic en <a class="udline" href="{hrefContact}">contáctenos</a> to proceed.',
  orderCan12: 'Acción',
  orderCan13: "Marcos de tiempo",
  orderCan14: 'Importe reembolsado',
  orderCan16: 'Gastos de envío',
  orderCan17: 'Cancelación',
  orderCan18: 'Dentro de las 12 horas de la confirmación del pedido',
  orderCan19: 'Dentro de las 12-24 horas de la confirmación del pedido',
  orderCan20: 'Dentro de las 24-48  horas de la confirmación del pedido',
  orderCan21: 'Más de 48 horas, no se envía',
  orderCan22: 'ninguno',
  orderCan23: 'Pedido enviado',
  //success
  check: 'Tramitar pedido',
  checkoutWait1: 'Por favor espere un momento',
  successInfo1: '¡Pedido recibido!',
  successInfo2: 'Por favor revise su correo electrónico dentro de 30 minutos.',
  successInfo3: "Por favor, revise y responda el correo electrónico de confirmación para asegurarse de que la información sobre su pedido sea correcta. El correo electrónico de confirmación se le enviará dentro de los 30 minutos. Si nunca recibe el correo electrónico de confirmación, revise el correo no deseado o contáctenos a través de",
  backHome: 'Volver a inicio',
  pageNotFond: 'página no encuentra',
  //title
  logInTit: 'Identificarse o crear una cuenta',
  myOrderTit: 'Mis pedidos en STACEES',
  myCouponsTit: 'Mis cupones en STACEES',
  myAccTit: 'Mi cuenta en STACEES',
  myProfileTit: 'Mi perfil en STACEES',
  myAddressTit: "Directorio en STACEES",
  myTicketTit: 'Consultas Postventa en STACEES',
  myPasswdTit: 'Mi contraseña en STACEES',
  myReviewTit: 'Mis comentarios en STACEES',
  createTicketTit: 'Crear un ticket en STACEES',
  shopColorTit: 'Comprar vestidos de dama de honor por color',
  shopColorKword: 'colores de vestidos de dama de honor, vestidos de dama de honor azul polvoriento, vestidos de dama de honor rosa polvoriento, vestidos de dama de honor color borgoña, vestidos de dama de honor azules, vestidos de dama de honor rosas, vestidos de dama de honor verde esmeralda, vestidos de dama de honor rojos, vestidos de dama de honor púrpura, vestidos de dama de honor amarillos, colores de vestidos de dama de honor, blanco, negro, vestidos de dama de honor por color, vestidos de dama de honor oro, vestidos de dama de honor champán',
  shopColorDesc: 'Comprar vestidos de dama de honor y batas de dama de honor por color. Encuentra el vestido de dama de honor perfecto en más de 75 colores.To match your wedding color palette.Combina la paleta de colores de tu boda. Puede obtener muestras de colores de telas y ver los colores en persona para obtener ejemplos del mundo real sobre cómo coordinar los colores de una boda.',
  homeTit: 'Vestidos y accesorios de moda de STACEES para ocasiones especiales | Vestidos de dama de honor | Vestidos de boda | Vestidos de graduación | Vestidos de noche y más en stacees.com',
  homeKeyword: 'Vestidos de boda, vestidos de novia, vestidos de fiesta, vestidos de noche, vestidos de dama de honor,  vestidos para ocasiones especiales de tienda',
  homeDesc: 'Ofrecemos vestidos de novia, vestidos de noche, vestidos de graduación, vestidos de fiesta, vestidos de cóctel, vestidos de dama de honor y vestidos de pajecitas en nuestra tienda en línea.¡Grandes descuentos en accesorios de boda a la venta! En nuestro sitio web encontrará una gran variedad de vestidos de gran venta a precios razonables.',
  styleGalleryDesc: 'Grandes colecciones de vestidos de novia, vestidos de dama de honor, vestidos de graduación, vestidos para homecoming, zapatos y más en STACEES.¡Mira nuestra galería de estilos y encuentra el vestido de tus sueños aquí!',
  studentbeans: 'Studentbeans',
  searchTit1: 'Comprar todo {name}',
  searchTit2: 'Único {name}',
  searchTit3: 'Envío y devoluciones gratis. {name}',
  searchTit4: 'Venta caliente {name}',
  searchTit5: 'Varios {name} Convertirse en alta costura',
  searchTit6: 'Todo sobre mi {name}',
  searchTit7: 'Varios impresionantes {name} Ideas',
  searchTit8: 'Inesperado {name} Diseño',
  searchTit9: 'El mejor lugar para comprar disponible {name}',
  searchTit10: '{name} En línea ',
  searchTit11: 'Compra lo mejor {name} en línea',
  searchDesc1: `Proporcionar excelente {name} en STACEES con estilos y tamaños inesperados. Haz que el cumpleaños de tu hija sea muy especial con el outfit perfecto.`,
  searchDesc2: `Compra {name} en STACEES.Elige el look perfecto para tu día con estas opciones exquisitas y fluidas.`,
  searchDesc3: `Compra nuestra increíble colección {name}en línea en una variedad de colores, diseños, tamaños y estilos.`,
  searchDesc4: `¡Tienes suerte! Ahora compra impresionantes {name} , y viste a tu madre con STACEES, disponibles en diferentes colores y estilos.`,
  searchDesc5: `Mira lo que vistieron esas mujeres con estilo cuando se casaron sus hijos . {name} tiene un papel importante en cada boda.`,
  searchDesc6: `STACEES proporciona {name} que puede adaptarse a cualquier estilo o tamaño.`,
  searchDesc7: `Como ocurre con la moda nupcial, {name} no tiene muchas reglas. ¡Vamos! Compra venta caliente {name} en STACEES.`,
  searchDesc8: `Descubre lo único {name} de STACEES y sé el invitado perfecto en ese día tan especial.`,
  mixKeyWord: 'mezclar y combinar vestidos de dama de honor,la dama de honor se separa,vestido de dama de honor convertible,vestidos de dama de honor desparejados,vestidos de dama de honor del mismo color en diferentes estilos',
  mixDesc: '¿Cómo mezclar y combinar vestidos de dama de honor con colores, tejidos y estilos?¡Eche una mirada única a nuestra colección de vestidos y conjuntos de dama de honor y aprenda a crear tonos de boda sombríos para darle a su vestido de novia un estilo, color o tono diferente!',
  orderDetailTit: 'Ver detalles en STACEES',
  paymentTit: 'Pago exito',
  emailReceive: '¿Por favor ayúdanos a saber qué correos te gustaría recibir?',
  subscribe: 'suscripción',
  //accessoires
  accessInfo: 'Joyas y accesorios modernos y ultra femeninos para tu gran día y mucho después.',
  wrapShawls: 'Chaquetas y chales',
  bridalGloves: 'Guantes de novia',
  hats: 'Sombreros',
  handbags: 'Bolsos de mano',
  fabricSwatch: 'Muestra De Fábrica',
  hairAccess: 'Accesorios para el cabello',
  sashesBelts: 'Fajas & cinturones',
  weddJewelery: 'Joyas de boda & fiesta',
  weddFlowers: 'Flores de boda',
  weddPett: 'Enaguas de boda',
  accessTit: 'Tendencias y estilos de accesorios para bodas y ocasiones especiales',
  accessKeyWd: 'Accesorios para bodas, accesorios para ocasiones especiales',
  accessDesc: 'Si busca inspiración en accesorios para un próximo evento o boda, compre las mejores tendencias en accesorios de moda, los estilos más populares y los estilos más nuevos de velos, tocados, bolsos de mano, alfileres, peines, joyas y más en STACEES para combinar con su vestido ahora. Alta calidad y envío rápido',
  ok: 'Ok',
  weddPartyDress: 'Vestidos de boda',
  GiftsDecor: 'Regalos & decoración',
  ticketStatus: {
    any: 'Cualquiera',
    new: 'Nuevo',
    open: 'Abierto',
    pending: 'Pendiente',
    solved: 'Resuelto',
    closed: 'Cancelado',
  },
  promLink:'/list/vestidos-de-graduacion',
  homecomLink:'/list/vestidos-para-homecoming',
  weddLink:'/list/vestidos-de-novia',
  brideLink:'/list/vestidos-de-dama-de-honor',
  motherLink:'/list/vestidos-de-madrina',
  guestLink:'/list/vestidos-de-invitadas-de-boda',
  flowerLink:'/list/vestidos-de-pajecitas',
  fabricLink:'/list/muestras-de-fabrica',
  shoesLink:'/list/zapatos-de-moda',
  handbagLink:'/list/bolsos',
  piecesLink:'/list/tocados',
  jacketsLink:'/list/chaquetas-chales',
  weddShoesLink:'/list/zapatos-de-boda',
  bridalLink:'/list/velos-de-novia',
  danceLink:'/list/zapatos-de-danza',
  jewelryLink:'/list/joyas',
  hatsLink:'/list/sombreros',
  evenLink:'/list/vestidos-de-noche',
  // how-afterpay-works
  joinNow: 'Únete ahora',
  afterpayTit1: 'Todo lo que necesitas es',
  afterpayCont1: 'Una tarjeta de crédito o débito de EE. UU.',
  afterpayCont2: 'Ser mayor de 18 años',
  afterpayCont3: 'Una dirección de residente en EE. UU.',
  afterpayTit2: 'Ver tu pedido de Afterpay',
  afterpayCont4: `Después de que tu pedido sea procesado, puedes iniciar sesión en tu cuenta de Afterpay para ver tus pedidos, realizar pagos y gestionar los detalles de tu cuenta.`,
  afterpayCont5: `Hacer clic en el siguiente enlace te llevará al sitio web de nuestro socio tercero, Afterpay.`,
  afterpayTit3: 'Preguntas frecuentes',
  afterpayCont6: '¿No ves tu pregunta aquí? Visita la página completa de preguntas frecuentes de Afterpay. <br/>Puedes comunicarte con afterpay en cualquier momento en <a href="https://help.afterpay.com/hc/en-us" target="_blank">https://help.afterpay.com/hc/en-us</a> o descargando la <a href="https://www.afterpay.com/en-US/mobile-app" target="_blank">aplicación de afterpay</a>.',
  afterpayTit4: 'Cómo funciona Afterpay',
  afterpayCont7: 'Decisión de aprobación instantánea',
  afterpayCont8: 'No hay formularios de solicitud largos y tediosos.Afterpay lo hace rápido y fácil para realizar el pago',
  afterpayCont9: 'Compra ahora.Paga después.',
  afterpayCont10: 'Paga tu compra en cuatro cuotas iguales, cada dos semanas',
  afterpayCont11: 'Nada adicional que pagar',
  afterpayCont12: 'Realiza los pagos a tiempo y solo el precio de tu compra',
  afterpayCont13: 'Accede a tu cuenta de Afterpay',
  afterpaybtn: 'Cargar más publicaciones',
  afterpayFaqT1: '¿Qué son las cuotas de Afterpay?',
  afterpayFaqT2: '¿Cómo uso las cuotas de Afterpay?',
  afterpayFaqT3: '¿Dónde puedo usar las cuotas de Afterpay?',
  afterpayFaqT4: '¿Puedo usar las cuotas de Afterpay si soy un cliente internacional?',
  afterpayFaqT5: '¿Cómo funciona el calendario de pagos?',
  afterpayFaqT6: `¿Qué pasa si no puedo pagar una cuota de Afterpay?`,
  afterpayFaqT7: '¿Cuándo se entregarán mis productos si utilizo las cuotas de Afterpay?',
  afterpayFaqT8: '¿Cuál es el límite de gasto en una transacción?',
  afterpayFaqT9: '¿Qué productos no se pueden comprar con las cuotas de Afterpay?',
  afterpayFaqT10: '¿Cómo devuelvo o cambio un producto comprado con las cuotas de Afterpay?',
  afterpayFaqT11: '¿Dónde puedo obtener más información sobre Afterpay?',
  afterpayFaqC1: 'Cuotas de Afterpay es un servicio que te permite realizar compras ahora y pagarlas en cuatro pagos iguales cada dos semanas, sin ningún interés.',
  afterpayFaqC2: 'Simplemente compra en línea, agrega artículos a tu carrito y procede al pago como de costumbre. Al momento de pagar, elige Cuotas de Afterpay como tu método de pago. Los nuevos clientes deberán registrarse en Afterpay y proporcionar los detalles de pago como es habitual; los clientes recurrentes solo necesitan iniciar sesión para realizar tu compra. ¡Así de fácil!',
  afterpayFaqC3: 'Por favor, ten en cuenta que todos los artículos en tu carrito deben ser elegibles para las Cuotas de Afterpay. Puede aplicarse un monto mínimo de compra y debes cumplir con requisitos adicionales de elegibilidad para calificar.',
  afterpayFaqC4: `Sujeto a los requisitos de elegibilidad y disponibilidad de Afterpay, las cuotas de Afterpay solo se pueden aplicar a las compras realizadas en el sitio web Jomalone.com.`,
  afterpayFaqC5: 'Las cuotas de Afterpay solo están disponibles para clientes con una dirección de facturación en EE. UU. y un número de teléfono móvil de EE. UU. Los clientes con direcciones de facturación internacionales no podrán configurar una cuenta con Afterpay.',
  afterpayFaqC6: 'Todos los clientes deben realizar su primer pago al momento de la compra, y los tres pagos restantes se deducirán automáticamente cada dos semanas de su método de pago elegido. Si eliges realizar pagos adicionales antes de las fechas de pago programadas, puedes hacerlo a través de tu cuenta de Afterpay. Puedes iniciar sesión en tu cuenta de Afterpay para ver tu cronograma de pagos y realizar un pago antes de la fecha de vencimiento si así lo deseas.',
  afterpayFaqC7: 'Si no hay fondos disponibles o no realizas tu pago en la fecha de vencimiento, se pueden aplicar cargos por pago atrasado.<a href="{payLink}">Haz clic aquí</a> para conocer los términos completos.',
  afterpayFaqC8: 'Los pedidos de Afterpay se entregan según nuestro plazo de envío estándar después de que completes tu pedido en línea.<br/>Visita nuestra página de envío para obtener más información haciendo clic <a href="{payLink}">aquí</a>.',
  afterpayFaqC9: 'Sí, se aplican límites de valor de transacción a las compras realizadas en Jomalone.com con cuotas de Afterpay.',
  afterpayFaqC10: 'Afterpay no está disponible para compras de tarjetas de regalo. Si estás comprando tarjetas de regalo junto con productos elegibles para Afterpay, deberás realizar dos pedidos separados.',
  afterpayFaqC11: 'Si cambiaste de opinión, puedes devolver tu compra de Afterpay en tienda o por correo para obtener un reembolso. Por favor, asegúrate de que tu devolución o cambio cumpla con nuestra política de devoluciones aquí. Afterpay será notificado de tu devolución y procesará los reembolsos correspondientes. Por favor, no devuelvas tu compra a Afterpay.',
  afterpayFaqC12: 'Si crees que hay un error o problema con el pago de tu compra de Afterpay, por favor contacta al servicio de atención al Cuotas de Afterpay is only available to customers with a US billing address within the US and a US mobile phone number. Customers with international billing addresses will not be able to set up an account with Afterpay.',
  afterpayFaqC13: 'Si deseas obtener más información sobre Afterpay, visita el sitio web de Afterpay www.afterpay.com para obtener una lista completa de preguntas frecuentes, términos y la Política de privacidad de Afterpay, que se encuentra en <a href="{payLink}">https://www.afterpay.com/privacy-policy</a>.',
  afterpayFaqC14: 'Si tienes alguna pregunta sobre tu cuenta de Afterpay, por favor contacta a Afterpay directamente a través del formulario web en <a href="{payLink}">https://help.afterpay.com/hc/en-us</a>.',
  //klura
  tryIt: '¡PRUÉBALO AHORA!',
  kluraBan11: 'Compra ahora,',
  kluraBan12: 'paga después con',
  kluraBan2: 'Nos hemos asociado con Klarna para brindarte una mejor experiencia de compra.',
  kluraTxt1: 'Es fácil y sencillo',
  kluraTxt2: 'Divide tu compra en 4 pagos para que puedas distribuir el costo de tu compra en el tiempo. Ingresa la tarjeta de crédito o débito de tu elección para pagos automáticos cada dos semanas. Sin intereses ni cargos adicionales cuando pagas a tiempo.',
  kluraTxt3: 'Cuatro pagos sin intereses',
  kluraTxt4: `Klarna te ayuda a mejorar tu experiencia de compra. Cuando eliges Klarna al momento de pagar, tendrás la opción de comprar ahora y pagar después por tu compra. Es comprar como debería ser. Sin esfuerzo, seguro y divertido.`,
  kluraTxt5: 'Cómo comprar con Klarna.',
  kluraTxt6: 'Agrega artículos a tu carrito y dirígete a la caja.',
  kluraTxt7: 'Selecciona Klarna al momento de pagar para pagar como quieras tu compra.',
  kluraTxt8: 'Administra tus pedidos y pagos en la aplicación de Klarna.',
  kluraTxt9: 'La mejor manera de comprar.',
  kluraTxt10: 'Consulta todas tus compras en un solo lugar, paga los saldos pendientes y mucho más en la aplicación de Klarna. También puedes iniciar sesión en tu cuenta de Klarna en <a href="https://app.klarna.com/login" target="_blank">https://app.klarna.com/login</a>. Si necesitas ayuda, nuestro servicio de atención al cliente está a tu disposición. Klarna',
  kluraTxt11: 'Seguro y confiable.',
  kluraTxt12: 'Klarna utiliza las medidas de seguridad y protección más recientes para proteger tu información y evitar compras no autorizadas.',
  kluraTxt13: 'Acerca de Klarna.',
  kluraTxt14: 'Estamos en una misión para transformar la experiencia de compra, elevando todo el proceso desde la navegación hasta la adquisición. Lo hacemos dándote los beneficios de la comodidad, ventajas e inspiración.',
  kluraTxt15: `Fundada en Estocolmo, Suecia, Klarna es una de las empresas de más rápido crecimiento en Europa y un proveedor líder de pagos alternativos. Contamos con 3,500 empleados en 17 países, y estamos liderando el camino de los pagos alternativos, sirviendo a 90 millones de consumidores y 250,000 soluciones de pago para minoristas.`,
  kluraTxt17: `¡Siempre estamos aquí para ayudarte!`,
  kluraTxt18_1: '¿No ves tu pregunta aquí?',
  kluraTxt18_2: 'Consulta la página completa de preguntas frecuentes de Klarna. <br/>Puedes ponerte en contacto con Klarna en cualquier momento en <a href="https://www.klarna.com/us/customer-service/" target="_blank">https://www.klarna.com/us/customer-service/</a> o descargando la <a href="https://www.klarna.com/us/klarna-app/" target="_blank">app de Klarna</a>.',
  kluraFaq1: '¿Qué sucede si hago una devolución?',
  kluraFaq2: `Si necesitas devolver parte o la totalidad de tu pedido, Klarna te enviará un nuevo estado de cuenta. Simplemente sigue las instrucciones de devolución del minorista y asegúrate de tomar nota del número de seguimiento. Inicia sesión en tu cuenta de Klarna, selecciona "informar una devolución" y pausaremos tu estado de cuenta. Tan pronto como el minorista procese tu devolución, enviaremos una factura ajustada.`,
  kluraFaq3: `Algo está mal con mi pedido. ¿Aún tengo que pagarlo?`,
  kluraFaq4: `No necesitas pagar por los productos que recibas dañados, rotos o defectuosos. Sigue las instrucciones de disputa del minorista y "informa un problema" en tu cuenta de Klarna para pausar tu estado de cuenta hasta que se resuelva el problema. Tan pronto como el minorista registre tu devolución, el reembolso será procesado en un plazo de 5 a 7 días hábiles.`,
  kluraFaq5: 'No he recibido mi pedido. ¿Qué sucede con mi estado de cuenta?',
  kluraFaq6: `No necesitas pagar el estado de cuenta hasta que hayas recibido tu pedido. Contacta al minorista para obtener una actualización sobre la entrega, inicia sesión en tu cuenta de Klarna y "informa un problema" para pausar tu estado de cuenta hasta que hayas recibido tu pedido.`,
  kluraFaq7: 'Mi estado de cuenta es incorrecto. ¿Qué debo hacer?',
  kluraFaq8: `Si tu estado de cuenta no coincide con los detalles de tu pedido, por favor contacta directamente al minorista para corregir tu estado de cuenta. Inicia sesión en tu cuenta de Klarna e "informa un problema" para pausar tu estado de cuenta hasta que los detalles hayan sido corregidos.`,
  //paypal
  paypalTit: 'Cómo funciona PayPal',
  paypalTxt1: 'PAGA EN 4 PLAZOS, SIN INTERESES',
  paypalTxt2: 'Paga en 4 plazos sin intereses en pedidos entre $30 y $1500*. El préstamo durará un total de 6 semanas. El primer pago se deberá realizar en el momento de la compra, seguido de 3 pagos adicionales cada dos semanas. "Pagar en 4 plazos" es una forma de crédito, por lo que debes considerar cuidadosamente si puedes asumir los pagos. Puede no ser adecuado para todos. Consulta los términos aquí.',
  paypalTxt3: '¿Cómo uso Paga en 4 plazos de PayPal?',
  paypalTxt4: 'Haz tu pedido y dirígete a la caja',
  paypalTxt5: 'Selecciona PayPal como opción de pago, inicia sesión en tu cuenta de PayPal y solicita "Paga en 4 plazos',
  paypalTxt6: 'Si eres aprobado, realiza tu pedido y ¡listo! Se cobrará un pago inicial y PayPal cargará automáticamente los siguientes pagos cada dos semanas.',
  paypalTxt7: 'Lo que necesitas para comenzar:',
  paypalTxt8: 'Un método de pago elegible para los pagos automáticos',
  paypalTxt9: 'Ser mayor de edad en el estado de residencia de los EE. UU.',
  paypalTxt10: 'Residir en un estado de EE. UU. elegible, consulta los términos a continuación para más detalles.',
  paypalTxt11: 'Una cuenta con PayPal al día para poder solicitar Paga en 4 plazos',
  paypalTxt12: '¿Cómo aparecerá mi transacción Paga en 4 plazos de PayPal en mi actividad de PayPal?',
  paypalTxt13: 'Cada transacción de Paga en 4 plazos de PayPal aparecerá en tu feed de actividad de PayPal como el monto total de la compra bajo el nombre del comerciante correspondiente como una "compra". Esto es para reflejar el monto total inicial de la compra y no refleja el plan de pago en cuotas de Paga en 4 plazos de PayPal ni ningún pago ya realizado con tu método de pago elegible. Al usar Paga en 4 plazos de PayPal , PayPal paga al comerciante por adelantado y divide el monto de la compra original en 4 pagos sin intereses. El pago inicial se procesa en la fecha de la compra y los siguientes tres pagos se realizan automáticamente cada dos semanas. El pago inicial y los tres pagos posteriores también se reflejarán como transacciones de pago separadas en tu actividad de PayPal y representan los reembolsos reales realizados para el monto total de la compra.',
  paypalTxt14: '¿Qué es Paga en 4 plazos de PayPal?',
  paypalTxt15: 'Paga en 4 plazos de PayPal es un pago sin intereses que te permite dividir tu compra total de $30 a $1500 en 4 pagos, con el primer pago debido en el momento de la compra y los pagos posteriores cada dos semanas. Es una excelente manera de distribuir el costo de los productos a lo largo del tiempo, y puede ayudarte a gestionar tu presupuesto de manera más efectiva. Ten en cuenta que Paga en 4 plazos es una forma de crédito, así que considera cuidadosamente si puedes asumir los pagos.',
  paypalTxt16: '¿Afectará mi solicitud de Paga en 4 plazos de PayPal a mi puntaje de crédito?',
  paypalTxt17: 'Como parte de tu solicitud de Paga en 4 plazos, algunos clientes pueden requerir una verificación de crédito suave; sin embargo, no tendrá ningún impacto en tu puntaje de crédito.',
  paypalTxt18: '¿Hay alguna tarifa por demora asociada con Paga en 4 plazos de PayPal?',
  paypalTxt19: 'No hay tarifas de solicitud ni tarifas por demora al elegir pagar con Paga en 4 plazos de PayPal.',
  paypalTxt20: '¿Por qué aparece una autorización pendiente en mi actividad de PayPal para mi transacción de Paga en 4 plazos de PayPal?',
  paypalTxt21: 'Cuando realizas una compra con Paga en 4 plazos de PayPal, colocaremos una retención de pre-autorización sobre tu método de pago elegible por un monto igual al pago inicial o una cuarta parte de tu monto total de compra. Esto aparecerá como una autorización pendiente en la actividad de tu cuenta de PayPal y también en la institución financiera asociada con tu método de pago. Una vez que completes tu compra con Paga en 4 plazos de PayPal y el comerciante envíe tus productos, la autorización pendiente será retirada y el pago inicial será retirado de tu método de pago. Si no completas tu compra con Paga en 4 plazos de PayPal, la autorización pendiente será automáticamente anulada por PayPal dentro de las 72 horas siguientes a la retención de pre-autorización.',
  paypalTxt22: `¿Por qué no se procesó mi pago?`,
  paypalTxt23: `Hay varias razones por las que PayPal puede no haber podido completar tu pago programado, incluyendo si no había fondos suficientes en tu tarjeta de débito, tarjeta de crédito o cuenta bancaria, o si tu tarjeta no ha sido confirmada. Si necesitas cambiar o actualizar tu método de pago, puedes hacerlo iniciando sesión en tu cuenta de PayPal, yendo a Paga en 4 plazos de PayPal, y seleccionando el plan de Paga en 4 plazos de PayPal que deseas cambiar. Allí, puedes hacer clic en "Cambiar" bajo "Método de pago" para actualizar tu información o elegir una nueva forma de pago.`,
  paypalTxt24: 'Todavía tengo preguntas sobre Paga en 4 plazos, ¿cómo puedo ponerme en contacto?',
  paypalTxt25: `Visita el <a href="https://www.paypal.com/us/cshelp/contact-us" target="_blank">servicio de atención al cliente</a> de PayPal para conocer las formas de contactar con ellos o haz clic <a href="https://www.paypal.com/credit-presentment/lander/modal?payer_id=XJ6G8848FDW9L&offer=PAY_LATER_SHORT_TERM&buyer_country=US" target="_blank">aquí</a> para más información sobre cómo comprar ahora y pagar después con PayPal.`,
  paypalTxt26: 'Consulta la página completa de preguntas frecuentes de PayPal. <br/>Puedes contactar con PayPal en cualquier momento en <a href="https://www.paypal.com/" target="_blank">https://www.paypal.com/</a> o descargando la <a href="https://www.paypal.com/mobile-app/" target="_blank">aplicación de PayPal</a>.',
  //clearpay
  clearpayTit1: 'Cómo funciona Clearpay',
  clearpayCont8: 'No hay formularios largos ni tediosos para solicitar. Clearpay hace que el proceso de pago sea rápido y fácil.',
  clearpayTit2: 'Ver tu pedido Clearpay',
  clearpayCont4: `Después de que tu pedido sea procesado, puedes iniciar sesión en tu cuenta de Clearpay para ver tus pedidos, realizar pagos y gestionar los detalles de tu cuenta.`,
  clearpayCont5: `Acceder a la cuenta Clearpay`,
  clearpayFaq1: '¿Qué es Clearpay?',
  clearpayFaq2: 'Clearpay es un servicio que te permite hacer compras ahora y pagarlas en cuatro pagos realizados cada 2 semanas sin intereses.',
  clearpayFaq3: '¿Cómo uso Clearpay?',
  clearpayFaq4: `Simplemente compra en línea, agrega artículos a tu carrito de compras y procede al pago como de costumbre. En el proceso de pago, elige 'Instalaciones de Clearpay' como tu método de pago. Los clientes nuevos deberán registrarse en Clearpay y proporcionar los detalles de pago como de costumbre, mientras que los clientes recurrentes solo deben iniciar sesión para realizar su compra. ¡Así de fácil!`,
  clearpayFaq5: 'Por favor, ten en cuenta que todos los artículos en tu carrito de compras deben ser elegibles para las cuotas de Afterpay. Puede aplicarse un monto mínimo de compra y debes cumplir con requisitos adicionales para calificar.',
  clearpayFaq6: '¿Dónde puedo usar Clearpay? ',
  clearpayFaq7: 'Clearpay solo se puede aplicar a compras realizadas en el sitio web stacees.com.',
  clearpayFaq8: '¿Puedo usar Clearpay si soy un cliente internacional? ',
  clearpayFaq9: '"Clearpay solo se ofrece a nuestros clientes que tienen una dirección de facturación en el Reino Unido.',
  clearpayFaq10: '¿Cómo funciona el calendario de pagos de Clearpay?',
  clearpayFaq11: 'Los clientes de Clearpay realizan su primer pago en el momento de la compra, y los tres pagos restantes se deducen automáticamente cada 2 semanas de su método de pago seleccionado.',
  clearpayFaq12: 'Si deseas realizar pagos adicionales no programados antes de su vencimiento, puedes iniciar sesión en Clearpay para realizar los pagos anticipadamente. Clearpay ajustará la cantidad adeudada en consecuencia.',
  clearpayFaq13: 'Puedes iniciar sesión en tu cuenta de Clearpay en cualquier momento para ver tu calendario de pagos o realizar un pago antes de la fecha de vencimiento.',
  clearpayFaq14: `¿Qué pasa si no puedo pagar una cuota de Clearpay?`,
  clearpayFaq15: 'En cuanto no realices un pago, Clearpay te impedirá hacer más compras con Clearpay.',
  clearpayFaq16: 'Clearpay te dará hasta las 11 p.m. del día siguiente para hacer el pago, y si no lo realizas, se te cobrará una tarifa por pago atrasado.',
  clearpayFaq17: 'Esto será una tarifa inicial de £6 y una tarifa adicional de £6 si el pago no se realiza dentro de los 7 días.',
  clearpayFaq18: 'Las tarifas por demora no superarán el 25% del total del pedido.',
  clearpayFaq19: 'Por favor, consulta el acuerdo de compra de Pagos de Clearpay <a href=\"{purchHref}\">aquí</a>.',
  clearpayFaq20: '¿Cuándo se entregarán mis artículos si uso Clearpay?',
  clearpayFaq21: 'Los pedidos de Clearpay se entregan dentro de los plazos de entrega estándar de stacees.com.',
  clearpayFaq22: '¿Existe un límite para lo que puedo gastar en una sola transacción de Clearpay?',
  clearpayFaq23: 'Sí, tu compra total (excluyendo los costos de entrega) debe ser superior a £15 y menos de £800.',
  clearpayFaq24: '¿Puedo usar otro método de pago con Clearpay?',
  clearpayFaq25: 'Sí, puedes usar códigos promocionales y tarjetas de regalo según corresponda con Clearpay. ',
  clearpayFaq26: '¿Qué productos no son elegibles para Clearpay?',
  clearpayFaq27: 'Clearpay no está disponible para compras de tarjetas de regalo. Si tu pedido contiene tarjetas de regalo y otros productos elegibles, deberás comprar las tarjetas de regalo por separado.',
  clearpayFaq28: '¿Cómo devuelvo o cambio un artículo comprado con Clearpay?',
  clearpayFaq29: 'Las devoluciones de Clearpay siguen nuestro proceso estándar de devoluciones. Ten en cuenta que los reembolsos para Clearpay serán procesados desde <a href=\"http://www.stacees.com\" target=\"_blank\">http://www.stacees.com</a> a Clearpay. No podemos ofrecer un intercambio o reembolso en efectivo. Tan pronto como se procese el reembolso, se reflejará en tu cuenta en línea de Clearpay. ',
  clearpayFaq30: '¿Dónde puedo obtener más información sobre Clearpay?',
  clearpayFaq31: 'Visita el sitio web de Clearpay aquí para una lista completa de preguntas frecuentes y consulta la política de rivacidad de Clearpay aquí.',
  clearpayFaq32: '* Se te proporcionará tu calendario de pagos. Si no realizas un pago a tiempo, se te cobrará una tarifa por demora de £6 con una tarifa adicional de £6 después de 7 días si el pago sigue sin pagarse.',
  clearpayFaq33: '* Otros factores pueden ser considerados que podrían reducir esta cifra, como ser un cliente nuevo o tu historial de pagos como cliente.',
  
}
