import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueMeta from 'vue-meta'
import i18n from './i18n'
import Bridge from './utils/JSbridge.js'
import ScrollMagic from 'scrollmagic';
import { formatUrlCDN } from './utils/utils-img'

Vue.use(VueMeta)
Vue.config.productionTip = false
Vue.prototype.$bridge = Bridge
Vue.prototype.$ScrollMagic = ScrollMagic;

import css from 'swiper/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper, { css })

const domainToLanguage = {
  'en': 'en',
  'fr': 'fr',
  'es': 'es',
};
const currentDomain = window.location.pathname.split('/')[1];
const defaultLanguage = domainToLanguage[currentDomain] || 'en';
Vue.prototype.$curLang = defaultLanguage

import  {
  Form, FormItem, Input, Radio, RadioGroup, Rate, Select,
  Option, Popconfirm, Card, Button, Popover, InputNumber,
  Row, Col, Drawer, Pagination, Collapse, CollapseItem,
  Dialog, Divider, Slider,
  Message, DatePicker,Tooltip ,Checkbox,CheckboxGroup,Table,TableColumn,Image,Tabs,TabPane,Switch,Loading 
} from 'element-ui'; // 引入element组件
// import 'element-ui/lib/theme-chalk/index.css'; // 引入element样式



const components = [
  Rate, Radio, RadioGroup, Form, FormItem, Input,
  Select, Option, Popconfirm, Card, Button, Popover,
  InputNumber, Row, Col, Drawer, Pagination, Collapse, CollapseItem,
  Dialog, Divider, Slider, Message, DatePicker,Tooltip,Checkbox,CheckboxGroup,Table,TableColumn,Image,Tabs,TabPane,Switch 
]
components.forEach(item=>{
  Vue.component(item.name,item)
})
// Vue.use(ElementUI)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeCreate(){
    Vue.prototype.$bus = this
  }
}).$mount('#app')

const formatLangUrl = (v) => {
  let prefix = ''
  if (defaultLanguage == 'fr' || defaultLanguage == 'es') {
    prefix += '/'+defaultLanguage
  }
  return `${prefix}${v}`
}

// inject('formatLangUrl', formatLangUrl)
Vue.prototype.$formatLangUrl = formatLangUrl
// 多语言路由格式化
// Vue.filter('formatLangUrl', formatLangUrl)
/**
   * 将字符中的us-es替换为es
   */
const replaceLangUrl = (v) => {
  return v.replace('us-es', 'es').replace('en', 'us')
}

// inject('replaceLangUrl', replaceLangUrl)
Vue.prototype.$replaceLangUrl = replaceLangUrl

Vue.prototype.$FormatImgUrl = (value, params = {}) => {
  return formatUrlCDN(value, params)
}