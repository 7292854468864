// 一些小工具集合


// 使用js实现复制功能
export function copyTxt(str) {
  let target = document.createElement('div')
  target.id = 'tempTarget'
  target.style.opacity = '0'
  target.innerText = str
  document.body.appendChild(target)
  window.getSelection().removeAllRanges()

  let result = true
  try {
    let range = document.createRange()
    range.selectNode(target)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
    document.execCommand('copy')
    window.getSelection().removeAllRanges()
  } catch (e) {
    result = false
  }

  target.parentElement.removeChild(target)
  return result
}

// 图片链接转base64
export function getBase64Image(imgUrl) {
  return new Promise((resolve, reject) => {
    var image = new Image()
    image.src = imgUrl
    image.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, image.width, image.height);
      var ext = image.src.substring(image.src.lastIndexOf(".") + 1).toLowerCase();
      var dataURL = canvas.toDataURL("image/" + ext);

      resolve(dataURL)
    }
  })
}

// 将字符串用crc32编码为一串数字
export function crc32(a) {
  var c = function () {
    for (var d = 0, f = new Array(256), g = 0; 256 != g; ++g) {
      d = g, d = 1 & d ? -306674912 ^ d >>> 1 : d >>> 1, d = 1 & d ? -306674912 ^ d >>> 1 : d >>> 1, d = 1 & d ? -306674912 ^ d >>> 1 : d >>> 1, d = 1 & d ? -306674912 ^ d >>> 1 : d >>> 1, d = 1 & d ? -306674912 ^ d >>> 1 : d >>> 1, d = 1 & d ? -306674912 ^ d >>> 1 : d >>> 1, d = 1 & d ? -306674912 ^ d >>> 1 : d >>> 1, d = 1 & d ? -306674912 ^ d >>> 1 : d >>> 1, f[g] = d
    }
    return 'undefined' != typeof Int32Array ? new Int32Array(f) : f
  }(),
    b = function (g) {
      for (var j, k, h = -1, f = 0, d = g.length; f < d;) {
        j = g.charCodeAt(f++), j < 128 ? h = h >>> 8 ^ c[255 & (h ^ j)] : j < 2048 ? (h = h >>> 8 ^ c[255 & (h ^ (192 | j >> 6 & 31))], h = h >>> 8 ^ c[255 & (h ^ (128 | 63 & j))]) : j >= 55296 && j < 57344 ? (j = (1023 & j) + 64, k = 1023 & g.charCodeAt(f++), h = h >>> 8 ^ c[255 & (h ^ (240 | j >> 8 & 7))], h = h >>> 8 ^ c[255 & (h ^ (128 | j >> 2 & 63))], h = h >>> 8 ^ c[255 & (h ^ (128 | k >> 6 & 15 | (3 & j) << 4))], h = h >>> 8 ^ c[255 & (h ^ (128 | 63 & k))]) : (h = h >>> 8 ^ c[255 & (h ^ (224 | j >> 12 & 15))], h = h >>> 8 ^ c[255 & (h ^ (128 | j >> 6 & 63))], h = h >>> 8 ^ c[255 & (h ^ (128 | 63 & j))])
      }
      return h ^ -1
    };
  return b(a) >>> 0
}

// 获取滚动条高度
export function utilGetScrollTop() {
  let scrollTopNum = 0
  if (document.documentElement && document.documentElement.scrollTop) {
    scrollTopNum = document.documentElement.scrollTop
  } else if (document.body) {
    scrollTopNum = document.body.scrollTop
  }
  return scrollTopNum
}