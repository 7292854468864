/*
  图片分配cdn域名
    传参内：
      pictureType: 图片类型，为压缩图片使用
 */

import { crc32 } from './utils'

// 给图片分配cdn域名
export function formatUrlCDN(str, params = {}) {
  if (!str) return ''

  let cdnCount = 6 // cdn地址共六个
  let crcNum = crc32(str)
  return `https://cdn-${crcNum % cdnCount + 1}.stacees.co.uk/${str}`
}
