export default {
  //header
  headTop: 'NOUVEAU CLIENT {symbol} DE RÉDUCTION',
  registNow: "Inscrivez-vous maintenant",
  liveChart: 'Chat en direct',
  liveChartInfo: "Si vous avez des questions avant de faire un achat, discutez avec nos opérateurs en ligne pour obtenir plus d'informations.",
  creatTickets: 'Soumettre un Ticket',
  creatTicketsInfo: "Si vous avez besoin d'aide sur les problèmes après-vente, veuillez soumettre un ticket.",
  currencyInfo: 'Nous expédions nos commandes dans le monde entier ! Changez de boutique si vous souhaitez voir dans une devise différente.',
  billedIn: 'Votre commande sera facturée en:',
  trackMyOrder: 'Suivre ma commande',
  orderNum: 'Numéro de commande',
  account: "Compte",
  orders: 'Commandes',
  search: 'Rechercher',
  wishlist: 'Liste de souhaits',
  changeCurr: 'Changer de devise',
  hotSearch: 'Recherche à chaud',
  occasion: 'Occasion',
  trendPro: 'Produits tendance',
  prom: 'BAL',
  homecoming: 'ROBE DE FÊTE DE LA RENTÉE',
  evening: 'SOIRÉE',
  bridesmaids: "Demoiselle d'honneur",
  wedding: 'Mariage',
  lang: "Langue",
  country: "Pays",
  currency: "Devise",
  couRegion: "Pays/Région",
  english: 'Anglais',
  spanish: 'Espagnol',
  france: 'france',
  store: 'Magasin',
  //home
  shopFullCo: 'ACHETER TOUTE LA COLLECTION',
  top: 'Top',
  categories: 'Catégories',
  slimProm: 'ROBES DE BAL FOURREAU',
  alineProm: 'Robe de bal trapèze',
  bridesmaid: "Demoiselle d'honneur",
  moms: 'Mère',
  flowerGirl: 'Filles de fleur',
  headpieces: 'Coiffes',
  swatches: 'Échantillons/nuancier',
  motherOf: 'Robe de mère de la mariée 2024',
  motherInfo: 'Vous pouvez créer le mélange parfait et jouer avec les tissus pour une ambiance de mariage de rêve.',
  new: 'Nouvelle',
  arrivals: 'Collection',
  arrivalsInfo: "Tout est dans les détails! Quel est votre style?",
  viewAllDress: 'Voir Toutes Les Robes',
  bridesmaidsMix: "Mix & Match des Demoiselles d'honneur",
  bridesmaidsMixInfo: "Nos couleurs sont soigneusement assorties pour faciliter le mix & match.",
  inspiration: "À LA RECHERCHE D'INSPIRATION",
  accessoires: 'Accessoires',
  accessoiresInfo: 'Des accessoires ultra féminins pour votre grand jour',
  weddShoes: 'Chaussures de Mariage',
  shoesInfo: 'Confort & Elégance, Détails & Style',
  fabrics: 'Échantillons & Tissus',
  fabricsInfo: 'Vous aidera à apprendre à quoi ressemble une couleur spécifique',
  gallery1: 'Galerie',
  gallery2: 'de Styles',
  galleryInfo: 'Les clients de STACEES du monde entier partagent avec nous leurs moments de style personnels.',
  galleryInfo1: 'Les clients de STACEES partagent avec nous de merveilleux moments.',
  product: 'VOIR LES PRODUITS',
  loadMore: 'CHARGER PLUS',
  viewMore: 'VOIR PLUS',
  viewLess: 'VOIR MOINS',
  chat: 'Discutez avec nous',
  chatInfo: 'Notre équipe de service client est à votre écoute et peut répondre à toutes vos questions.',
  customiz: 'Personnalisation',
  customizInfo: 'Nous fournissons des services personnalisés, une taille personnalisée ou un design exclusif pour votre robe.',
  affordable: 'Luxe abordable',
  affordableInfo: "Vous pouvez être sûre que votre investissement dans l'une de nos robes en vaudra toujours la peine.",
  fastShip: 'Expédition rapide',
  fastShipInfo: 'Nous avons plus de 100 robes qui sont expédiées sous 48 heures.',
  //footer
  about: 'À propos de nous',
  aboutSta: 'À Propos de STACEES',
  shipping: 'Info de livraison',
  searches: 'Recherches Populaires',
  blog: 'Blog de STACEES',
  help: 'Aide',
  faq: 'F.A.Q.',
  return: 'Retour et remboursement',
  track: 'Suivi de commande',
  tailor: 'Projet personnalisé',
  customer: 'Service client',
  SizeGuide: 'Guide des tailles',
  colourGuide: 'Guide des couleurs',
  contactUs: 'Contactez-nous',
  paymentMethod: 'Méthodes de paiement',
  studentDiscount: 'Réduction pour étudiants et diplômés',
  ShopByCate: 'Acheter par catégorie',
  promDress: 'Robes de bal',
  homeComDress: 'Robes de fête de la rentrée',
  weddingDress: "Robes de mariée",
  bridesmaidDress: "Robes de demoiselle d'honneur",
  motherBride: "Robes de mère de la mariée",
  weddingGuest: "Robes d'invitée à un mariage",
  flowerGirlDress: `Robes de fille d'honneur`,
  reserved: 'Tous les droits sont réservés.',
  conditions: 'Conditions générales',
  privacyPolicy: 'Politique de confidentialité',
  aboutUs: 'À propos de nous',
  emailAddr: 'Adresse e-mail',
  enterEmailAddr: "Entrer l'adresse e-mail",
  signTip: 'Inscrivez-vous aux e-mails et obtenez {symbol} de réduction sur toute commande.',
  signTip1: 'INSCRIVEZ-VOUS À NOTRE NEWSLETTER',
  copyRight: "Droits d'auteur",
  signTit: 'Enter email for newsletter signup',
  subscribInfo: 'Merci de vous être abonné ! Profitez de vos achats dans notre boutique.',
  prefrence: 'Veuillez sélectionner une préférence de style.',
  //list
  home: 'Accueil',
  ships48: 'EXPÉDIÉ EN 48H',
  sort: 'Trier par',
  popular: 'Plus Populaire',
  recommend: 'Recommandé',
  newArrival: 'Nouvelle Collection',
  lPrice: 'Prix décroissant',
  hPrice: 'Prix croissant',
  filter: 'Filtre',
  filterBy: 'Filtrer par',
  price: 'Prix',
  clearAll: 'Tout effacer',
  off: 'de réduction',
  apply: 'APPLIQUER',
  Color: 'Couleur',
  Colors: 'couleurs',
  '2024 Prom Dresses': `Robes de bal {name}`,
  'Long Prom Dresses': 'Robes de bal longues ',
  'Ball Gown Prom Dresses': 'Robes de bal marquise',
  'Sexy Prom Dresses': 'Robes de bal sexy',
  'Lace Prom Dresses': 'Robes de bal en dentelle',
  'Off the Shoulder Prom Dresses': 'Robes de bal à épaule dénudée',
  'Two Piece Prom Dresses': 'Robe de bal deux pièces',
  '2024 Wedding Dresses': `Robes de mariée {name}`,
  'Beach Wedding Dresses': 'Robes de mariée plage',
  'Lace Wedding Dresses': 'Robes de mariée en dentelle',
  'Wedding Veils': 'Voiles de mariage',
  'Evening Dresses': 'Robes de soirée',
  '2024 Evening Dresses': `Robes de soirée {name}`,
  'Long Evening Dresses': 'Robes de soirée longues',
  'Homecoming Dresses 2024': `Robes de fête de la rentrée {name}`,
  'Cocktail Dresses': 'Robes de cocktail',
  'Wedding Pumps': 'Escarpins de mariage',
  "Women's Shoes": "Chaussures de femmes",
  "Girls' Shoes": "Chaussures de filles",
  "Dance Shoes": "Chaussures de dance",
  nextPage: 'Page<br/>suivante',
  searchResFor: 'Résultats de recherche pour',
  sortBy: 'Trier par',
  searchResult: "Désolé, votre recherche n'a donné aucun résultat.",
  products: "Des produits",
  proNotFound: 'Produit non trouvé',
  searchFor: 'Rechercher des articles',
  'Short Prom Dresses': 'Robes de bal courtes',
  'Sparkly Prom Dresses': 'Robes de bal scintillantes',
  'Mermaid Prom Dresses': 'Robes de bal sirène',
  'Formal & Evening Dresses': 'Robes de soirée et de cérémonie',
  'A-Line Wedding Dresses': 'Robes de mariée coupe A',
  'Mermaid Wedding Dresses': 'Robes de mariée sirène',
  'Ball-Gown Wedding Dresses': 'Robes de mariée de style robe de bal',
  'Plus Size Wedding Dresses': 'Robes de mariée grande taille',
  'Reception Dresses': 'Robes de réception',
  'Colored Wedding Dresses': 'Robes de mariée colorées',
  'Veils': 'Voiles',
  'Long Mother of the Bride Dresses': 'Robes longues pour la mère de la mariée',
  'Short Mother of the Bride Dresses': 'Robes courtes pour la mère de la mariée',
  'Tea Length Mother of the Bride Dresses': 'Robes de mère de la mariée longueur thé',
  'Plus Size Mother of the Bride Dresses': 'Robes de mère de la mariée grande taille',
  'Mother of the Bride Dresses With Jackets & Wraps': 'Robes de mère de la mariée avec vestes et châles',
  'Mother of the Bride Dresses With Sleeves': 'Robes de mère de la mariée avec manches',
  'Long Bridesmaid Dresses': "Robes de demoiselle d'honneur longues",
  'High-Low Bridesmaid Dresses': "Robes de demoiselle d'honneur hautes-basses",
  'Short Bridesmaid Dresses': "Robes de demoiselle d'honneur courtes",
  'Tea-Length Bridesmaid Dresses': "Robes de demoiselle d'honneur longueur thé",
  'Mix & Match Bridesmaid Dresses': "Mélangez et assortissez les robes de demoiselle d'honneur",
  'Junior Bridesmaid Dresses': "Robes de demoiselle d'honneur junior",
  'Fabric Swatch': 'Échantillon de tissu',
  girlsDress: "robes de demoiselles d'honneur",
  partyShoes: 'chaussures de fête',
  jacketsWraps: 'Vestes et châles',
  jelwery: 'bijoux de fête de mariage',
  promShoes: 'chaussures de bal',
  fashionJelwery: 'bijoux fantaisie',
  //product
  ships48s: 'Expédié en 48h',
  reviews: 'Avis',
  descript: 'DESCRIPTION',
  details: 'DÉTAILS',
  modelInfo: 'INFO SUR LE MODÈLE',
  shipRetrun: 'EXPÉDITION ET RETOUR',
  proTime: 'Temps de production',
  proTimeInfo: "Le temps de production estimé est de 2 semaines avant l'expédition des articles. Production urgente est disponible moyennant des frais supplémentaires non remboursables.",
  shipTimeSt: "Délai d'expédition—Expédition standard",
  shipTimeInfo: "L'EXPÉDITION est de 3 à 10 jours supplémentaires selon l'emplacement et la méthode d'expédition. L'option de délai d'expédition sera fournie à la caisse ",
  seeMore: 'Voir plus',
  authentic: '100% <span>Authentique</span>',
  cusSizem: 'Mensurations <span>sur </span><span>mesure</span>',
  freeGarm: 'Housse <span>Robe </span><span>Gratuite</span>',
  highQam: 'Haute <span>qualité</span>',
  highQa: 'Haute-qualité',
  recently: 'VU RÉCEMMENT',
  easyRet: 'RETOURS FACILES',
  proInfo1: "Conçu par STACEES, 100% identique à l'image.",
  proInfo2: 'Taille personnalisée ou design exclusif pour votre robe.',
  proInfo3: 'Nous offrons une housse robe gratuite lorsque vous achetez cette robe.',
  proInfo4: 'Nos robes sont à la fois élégantes et confortables.',
  proInfo5: 'Période de retour de 14 jours',
  retPolicy: 'Politique de retour',
  retPolicyInfo1: 'Les retours sans souci sont disponibles pour les tailles standard dans les 14 jours. Les tailles personnalisées sont la vente finale.',
  retPolicyInfo2: "Veuillez vous assurer qu'ils sont dans leur état d'origine - non portés, non lavés, non altérés, non endommagés, propres, exempts de peluches et de cheveux et avec des étiquettes intactes et attachées.",
  allColors: 'Toutes les couleurs',
  inStockCol: 'Couleurs en stock',
  colorChart: 'Nuancier',
  "As Picture": "Comme sur l'image",
  size: 'Taille',
  pleaseSel: 'Veuillez sélectionner',
  customSize: 'Mensurations sur mesure',
  thisReq: 'Ceci est un champ obligatoire.',
  sizeGuide: 'Guide des tailles',
  sizeChart: 'Tableau Des Tailles',
  toMeasure: 'Comment mesurer',
  custom: 'Mensurations sur mesure',
  inch: 'inch',
  inches: 'INCH',
  centimeters: 'CM',
  orderSwat: 'Commander des échantillons',
  aviala: 'Disponibilité',
  inStock: 'En stock',
  proAvailable: 'Production urgente disponible',
  additional: 'Exigences supplémentaires',
  additionalInfo: 'Instructions spéciales sur la personnalisation ou la manipulation (facultatif)',
  addBag: 'AJOUTER AU PANIER',
  orderToday: "Commander aujourd'hui,",
  getIt: `l'obtenir`,
  tailTime: 'Temps de confection',
  shipTime: 'Délai de livraison',
  processTime: 'Délai de traitement',
  day: 'jours',
  orderTodyInfo: 'Cette robe est faite sur commande. Que vous choisissiez une taille standard ou des mesures personnalisées, nos tailleurs confectionnent chaque robe sur commande.',
  completeLook: 'COMPLÉTEZ LE LOOK',
  toCart: 'AJOUTER AU PANIER',
  viewFull: 'Afficher Tous les Détails',
  sizeOrder: 'TAILLE COMMANDÉE',
  proLove: "NOUS PENSONS QUE VOUS ALLEZ ADORER",
  cusReview: 'AVIS DES CLIENTS',
  bestSal: 'MEILLEURES VENTES',
  shareWith: 'Partager avec',
  customInfo1: "À la recherche d'une robe idéale? Pour ",
  customInfo2: " de plus, nos tailleurs utiliseront vos mesures exactes pour vous confectionner une robe sur mesure.",
  passwd6Char: 'Veuillez entrer 6 caractères ou plus.',
  passwdConsist: 'Le mot de passe à deux entrées doit être cohérent !',
  verfied: 'Acheteur vérifié',
  allPhoto: 'Voir toutes les photos des clients',
  overFit: 'Ajustement général',
  rating: 'Grade',
  'Small': 'Petit',
  'Slightly Small': 'Légèrement petit',
  'Right': 'Juste',
  'Slightly Large': 'Légèrement grand',
  'Large': 'Grand',
  overallRate: 'Note Globale',
  customPhoto: 'Photos des clients',
  backPro: 'Retour à la page produit',
  basedOn: 'Basé sur',
  shareLook: 'Partagez votre look pour obtenir',
  shareLook1: 'Partagez votre look pour obtenir',
  shareDiscount: 'de réduction',
  sharePhoto: 'Partager une Photo/Vidéo',
  allReviews: 'Tous les commentaires',
  writeReview: 'Écrire un Commentaire',
  fit: 'Adapter',
  viewAll: 'Voir Tout',
  photos: 'Photos',
  end: 'Fin',
  share: 'Partager',
  qty: 'Qté',
  waistInfo: "Trouvez la taille naturelle, c'est la plus petite partie de la taille, généralement environ 1 pouce (environ 2,5 cm) au-dessus du nombril, gardez le mètre à ruban légèrement lâche pour permettre de respirer.",
  bustInfo: "Ce n'est PAS votre taille de soutien-gorge ! Portez un soutien-gorge non rembourré (votre robe aura un soutien-gorge intégré), détendez les bras sur les côtés, tirez le mètre à ruban sur la partie la plus pleine du buste.",
  floorInfo: "Tenez-vous droit avec pieds joints, mesurez pieds nus, commencez par l'espace creux entre les clavicules et tirez le mètre à ruban vers le sol.",
  hipInfo: 'Trouvez la partie la plus large des hanches, généralement environ 7 pouces (environ 18 cm) sous la taille naturelle, le mètre à ruban doit effleurer autour des fesses.',
  extraInfo: `Pour les robes longues au sol en taille personnalisée, choisissez l'option de longueur supplémentaire pour s'adapter à la hauteur de vos talons ! c'est-à-dire pour les talons de 2 pouces (5 cm), sélectionnez "+2 pouces" (5 cm), pour les chaussures plates, veuillez entrer "+0".`,
  hemInfo: "Tenez-vous droit avec pieds nus ensemble. Mesurez à partir du centre de la clavicule jusqu'à l'endroit où l'ourlet avant de la robe doit toucher.",
  heightInfo: 'Tenez-vous droit avec pieds joints, mesurez pieds nus, commencez par le haut de la tête et tirez le mètre à ruban vers le sol.',
  armholeInfo: 'Afin de prendre la mesure de votre cage de bras, vous devez enrouler le ruban à mesurer sur le haut de votre épaule et sous votre aisselle.',
  armText1: "Il s'agit d'une mesure autour de la partie la plus large de votre bras.",
  descripts: 'Descriptions',
  processReturn: 'Délai de traitement et retours',
  shipAddit: "Le délai de livraison prend 1 à 10 jours ouvrables supplémentaires selon l'emplacement. Les délais d'expédition seront fournis à la caisse.",
  returns: 'Retours',
  requestReturn: 'Demandez un retour dans les 14 jours après réception.',
  image: 'Image',
  max5Image: 'Images de 5 Mo maximum.',
  confirm: 'Confirmer',
  selectSize: 'Veuillez sélectionner la taille.',
  copyUrl: 'copierUrl',
  oftenBought: 'Souvent acheté avec',
  failed: 'ÉCHOUÉ',
  //sizechart
  Bust: 'Tour de poitrine',
  Waist: 'Tour de taille',
  Hips: 'Tour de hanches',
  floor: 'Du creux au sol',
  htoHem: "Du creux à l'ourlet",
  toHem: "Du creux à l'ourlet (devant)",
  extra: 'Longueur supplémentaire',
  bareFoot: 'Hauteur (pieds nus)',
  armhole: 'CAGE DE BRAS',
  armWidth: 'Largeur de Bras',
  footLen: 'Longueur du pied',
  toAnkle: 'Creux du Cou à la Cheville',
  toTea: 'Creux du Cou au Mollet',
  toKnee: 'Creux du Cou au Genou',
  ShoulderWidth: `LARGEUR D'ÉPAULE DU DOS`,
  sleeveLen: 'LONGUEUR DES MANCHES',
  armscye: 'Armoiries',
  armCir: 'CIRCONFÉRENCE DU BRAS',
  child: 'Enfant',
  //view bag
  viewBag: 'VOIR LE SAC',
  checkout: 'VÉRIFIER',
  shopBag: 'Panier',
  bag: 'Mon Panier',
  summary: 'Récapitulatif',
  subtotal: 'Sous-total',
  total: 'Total',
  goToCart: 'Aller au panier',
  proceedCheck: 'PASSER À LA CAISSE',
  item: 'articles',
  items: 'articles',
  secureCheck: 'PAIEMENT SÉCURISÉ',
  yourDiscount: 'Votre code promotionnel ou de réduction:',
  coupon: 'Coupon',
  couponCode: 'Code de coupon',
  useCoupon: 'Utilisez le code du coupon',
  couponEnter: 'Veuillez saisir le code du coupon.',
  couponSuccess: 'Toutes nos félicitations! Vous avez un coupon de',
  couponError: "Désolé, ce coupon n'est pas valide.",
  cancel: 'Annulez',
  weAccept: 'Nous acceptons',
  safe: 'Sûr & Sécurisé',
  safeInfo: 'Vos informations sont toujours protégées.',
  saveWish: 'Enregistrer dans la liste de souhaits',
  removeBag: "Êtes-vous sûr d'enregistrer cet article dans votre liste de souhaits ?",
  saveWishSure: "Êtes-vous sûr d'enregistrer cet article dans votre liste de souhaits ?",
  yes: 'Oui',
  bagTit: 'Sac shopping chez STACEES',
  wishListSucc: "L'article a été ajouté à votre liste de souhaits.",
  youLove: "VOUS ALLEZ ADORER CES ARTICLES!",
  charMax: 'Personnages Max',
  bagEmpty: 'VOTRE PANIER EST VIDE',
  continueShopping: 'Continuez vos achats',
  myBag: 'Mon sac',
  itNotHave: "Mais ce n'est pas forcément le cas...",
  youMayAlso: 'Vous aimerez peut-être aussi',
  remove: 'Supprimer',
  //checkout
  placeOrder: 'PASSER LA COMMANDE',
  pay: 'PAYER',
  orderComp: 'COMMANDE TERMINÉE',
  contact: 'Information de contact',
  guestSign: `Commander en tant qu'invité ou`,
  signIn: 'CONNECTEZ-VOUS',
  email: 'E-mail',
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  address: 'Adresse',
  apartment: 'Appartement, suite, etc. (Facultatif)',
  countryRegion: 'Pays Région',
  region: 'État/Province/Région (facultatif)',
  regionSel: 'État/Province/Région',
  city: 'Ville',
  postCode: 'Code postal',
  gb: 'Go',
  phone: 'Téléphone',
  creatAcc: 'Créer un Compte (facultatif)',
  save: 'SAUVEGARDER',
  setDelivAddr: 'Définir comme adresse de livraison par défaut',
  emailTip: "Nous vous enverrons votre confirmation de commande ici.",
  phoneTip: "Au cas où nous aurions besoin de vous contacter au sujet de votre commande.",
  passwd: 'Mot de passe',
  confirmPasswd: 'Confirmez le mot de passe',
  addNewAddr: 'AJOUTER UNE NOUVELLE ADRESSE',
  emailRequire: "L'adresse e-mail est requise.",
  enterEmail: "Entrez une adresse d'e-mail s'il vous plaît.",
  validEmail: "S'il vous plaît, mettez une adresse email valide",
  passwdRequire: 'Mot de passe requis.',
  minChar: '6 caractères minimum',
  confirmPassRequire: 'Confirmer le mot de passe est requis.',
  passNoMatch: 'Le mot de passe ne correspond pas',
  firstNameVerify: 'Votre prénom doit contenir un minimum de 2 caractères.',
  lastNameVerify: 'Votre nom de famille doit contenir un minimum de 2 caractères.',
  shippAddrVerify: 'Votre adresse de livraison doit comporter au moins 5 caractères.',
  countryVerify: 'Le pays est obligatoire.',
  cityVerify: 'Votre ville doit comporter au moins 3 caractères.',
  regionVerify: 'State/Province/Region is required.',
  ziPostVerify: 'Le code postal est requis.',
  phone6Verify: 'Votre numéro de téléphone doit comporter au moins 6 chiffres.',
  passwd6Verify: 'Votre mot de passe doit comporter au moins 6 chiffres.',
  shipMethod: 'Méthodes de livraison',
  shipMd: 'Méthodes de livraison',
  standShip: 'Expédition standard',
  free: 'Gratuit',
  estDelive: 'Délai de livraison estimé',
  expShip: 'Expédition Accélérée',
  duty: 'Assurance de douane',
  shipInsure: "Assurance d'expédition",
  dutyTip: "Si vous achetez une assurance droits et taxes et que votre colis est facturé des droits et taxes supplémentaires à la livraison, veuillez contacter notre Service Client et nous compenserons les droits et taxes que vous avez payés.",
  shipTip: "L'assurance d'expédition offre une protection et une sécurité optimales pour vos objets de valeur lors d'expéditions internationales. Nous réexpédierons votre colis immédiatement sans frais supplémentaires s'il est signalé perdu ou endommagé.",
  editBag: 'Modifier le panier',
  shipFee: "Frais d'expédition",
  picture: "Image",
  edit: 'Modifier',
  delete: 'Supprimer',
  delAddress: 'Voulez-vous vraiment supprimer cette adresse?',
  saveShipp: 'Veuillez enregistrer votre adresse de livraison',
  sizeDetail: 'Détails de taille',
  addrBook: "Carnet d'adresses",
  default1: 'Par défaut',
  selected: 'Sélectionné',
  credit: 'Carte de crédit ou de débit',
  creditNote: 'Remarque : Pour des raisons de sécurité, nous ne sauvegarderons aucune de vos données de carte de crédit.',
  paymentTrouble: 'VOUS AVEZ DES PROBLEMES <br> DE PAIEMENT?',
  paymentText: 'Vous pouvez nous contacter pour une aide au paiement via :',
  openWait: "En train d'ouvrir, veuillez patienter...",
  failedPurchase: 'Achat échoué ! Veuillez effectuer à nouveau le paiement.',
  eml: 'E-mail',
  //login
  signRegister: 'CONNECTEZ-VOUS/INSCRIVEZ-VOUS',
  register: 'INSCRIVEZ-VOUS',
  newTo: 'NOUVEAU CHEZ STACEES',
  join: 'Inscrivez-vous',
  now: 'NOW',
  forgetPasswd: 'Mot de passe oublié',
  forgottenPasswdHead: 'Mot de passe oublié',
  forgottenPasswd: 'Mot de passe oublié',
  or: 'ou',
  signFackbook: 'Connectez-vous avec Facebook',
  signGoogle: 'Connectez-vous avec Google',
  iAccept: "J'accepte les",
  signFor: 'Inscrivez-vous pour un coupon de <span>{symbol}</span> sur votre compte.',
  accountPrivacy: 'En créant un compte, vous acceptez la <a class="udline" href="{hrefPrivarcy}" rel="external nofollow">Politique de confidentialité</a> de STACEES.com',
  orContinue: 'ou continuer avec',
  bySignIn: 'En vous connectant, vous acceptez notre <a href="{hrefPrivarcy}" rel="external nofollow">Politique de confidentialité</a> <br/>et nos <a href="{hrefTerms}" rel="external nofollow">Conditions de service</a>.',
  clickJoinNow: `En cliquant sur 'Rejoindre maintenant', vous acceptez les <a href="{hrefTerms}" rel="external nofollow">Conditions générales</a> et la <a href="{hrefPrivarcy}" rel="external nofollow">Politique de confidentialité</a> de STACEES .`,
  //forgot
  veriCode: 'Le code de vérification',
  codeReq: 'Le code est requis.',
  codeVerify: 'Veuillez saisir le code',
  codeError: 'Le code est une erreur',
  send: 'ENVOYER',
  forgotTxt1: "Comment réinitialiser votre mot de passe ?",
  forgotTxt2: "Veuillez saisir votre adresse e-mail d'inscription dans la case ci-dessus et vous recevrez un e-mail contenant des instructions pour modifier votre mot de passe.",
  forgotTxt3: "Saisissez votre adresse e-mail",
  forgotTxt4: "Vérifiez votre messagerie",
  forgotTxt5: "Cliquez sur le lien pour réinitialiser le mot de passe",
  forgotTxt6: "Connectez-vous avec votre nouveau mot de passe",
  forgotTxt7: "Un lien pour réinitialiser votre mot de passe a été envoyé à",
  forgotTxt8: "Les e-mails peuvent subir un retard de quelques minutes.",
  forgotTxt9: "Si vous n'avez pas reçu d'e-mail, veuillez vérifier vos dossiers de courrier indésirable et de spam.",
  forgotTxt10: "Si vous ne recevez toujours pas l'e-mail après avoir demandé la réinitialisation du mot de passe, attendez 24 heures,",
  forgotTxt11: "puis réessayez",
  forgotTxt12: 'Entrez votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe. Pour des raisons de sécurité, les liens de connexion sont valables 30 minutes.',
  resetPass: 'Réinitialiser le mot de passe',
  createNewPass: 'CRÉE UN NOUVEAU MOT DE PASSE',
  newPassInfo: "Merci d'être revenu. Veuillez saisir les détails de votre nouveau mot de passe ci-dessous.",
  newPassTxt: "Votre mot de passe a été changé. Connectez-vous à nouveau et vous êtes prêt à partir",
  newPassRequire: 'Le nouveau mot de passe est requis.',
  passMin6: 'Le mot de passe doit comporter au moins 6 caractères.',
  oldPassRequire: "L'ancien mot de passe est requis.",
  passAgain: 'Veuillez entrer votre mot de passe à nouveau.',
  submit: 'SOUMETTRE',
  back: 'retour',
  //member
  welcomeTo: 'BIENVENUE SUR',
  yourAcct: 'Votre compte',
  hi: 'Salut',
  myOrders: 'Mes commandes',
  myCoupons: 'Mes coupons',
  myReviews: 'Mes avis',
  myWishlist: 'Ma liste d\'envies',
  myTickets: 'Mes tickets',
  myProfile: 'Mon profil',
  changePassword: 'Changer le mot de passe',
  signOut: 'Se déconnecter',
  orderEmpty: 'VOTRE COMMANDE EST VIDE',
  orderNo: 'Numéro de commande',
  date: 'Date',
  status: 'Statut',
  open: 'Ouvert',
  pending: 'En attente',
  closed: 'Fermé',
  processing: 'Traitement',
  canceled: 'Annulé',
  shipped: 'Expédié',
  completed: 'Complété',
  awaitPaym: 'En attente de paiement',
  viewDetail: 'Voir les détails',
  reorder: 'COMMANDEZ A NOUVEAU',
  ticketServ: 'Service de tickets',
  payNow: 'PAYEZ MAINTENANT',
  cancelOrder: 'Etes-vous sûr de vouloir annuler la commande',
  shipAddr: 'adresse de livraison',
  billAddr: 'canceledDe Facturation',
  delivery: 'OPTIONS DE LIVRAISON',
  paymentMeth: 'Mode de paiement',
  subTot: 'Sub-total',
  grandTotal: 'Grand Total',
  wishlistEmpty: 'VOTRE LISTE DE SOUHAITS EST VIDE',
  dateOfBirth: 'DATE DE NAISSANCE',
  gender: 'Genre',
  ms: 'Mme/Mlle',
  mr: 'M',
  mPrivacy: 'Confidentialité',
  saveChanges: 'SAUVEGARDER LES MODIFICATIONS',
  firNameRequire: 'Le prénom est requis.',
  lastNameRequire: 'Nom de famille est requis.',
  optional: 'Facultatif',
  oldPassword: 'ANCIEN MOT DE PASSE',
  newPassword: 'NOUVEAU MOT DE PASSE',
  changed: 'MODIFIÉ',
  enterPass: 'Veuillez entrer votre mot de passe.',
  ticketId: 'ID du ticket',
  type: 'Type',
  lastUpdate: 'Dernière mise à jour',
  changeSucc: 'changé avec succès!',
  saveSucc: 'Enregistré avec succès!',
  brith: "Choisissez l'anniversaire",
  //ticket
  creatTicket: "Créer un ticket",
  backOrder: 'Retour à la commande',
  selectTopic: 'Choisissez un sujet',
  backTicket: 'Retour à la liste des billets',
  problemSoved: 'Votre problème a été résolu. Ce ticket a été fermé',
  addAPhoto: 'Ajouter une photo',
  photoTip: `Maximum de <span class="cr-3c">3</span> photos. Ne fournissez que des fichiers <span class="cr-3c">JPEG, GIF ou PNG </span>. La taille d'une photo individuelle ne peut pas dépasser <span class="cr-3c">8 Mo.</span>`,
  conversReq: 'La conversation est nécessaire.',
  photoTipReq: "Maximum de 3 photos. Ne fournissez que des fichiers JPEG, GIF ou PNG . La taille d'une photo individuelle ne peut pas dépasser 8 Mo.",
  ticketReceive: 'Votre ticket a été reçu, nous vous répondrons bientôt.',
  produ: 'Produit',
  orderTxt1: "Les commandes annulées dans les 12 heures suivant la confirmation du paiement seront éligibles à un remboursement partiel comprenant les frais d'expédition complets et 90% du prix d'achat du produit.",
  orderTxt2: "Les commandes annulées dans les 12 à 24 heures suivant la confirmation du paiement seront éligibles à un remboursement partiel comprenant les frais d'expédition complets et 80 % du prix d'achat du produit.",
  orderTxt3: "Les commandes annulées dans les 24 à 48 heures suivant la confirmation du paiement donneront droit à un remboursement partiel comprenant les frais d'expédition complets et 50 % du prix d'achat du produit.",
  orderTxt4: "Les commandes annulées au-delà de 48 heures après la confirmation du paiement seront éligibles à un remboursement partiel des frais d'expédition complets uniquement.",
  orderTxt5: 'Une fois votre commande expédiée, elle ne peut plus être annulée.',
  phoneNum: 'Numéro de téléphone',
  message: 'Message',
  messageTip: 'Veuillez écrire votre message détaillé en 5-1000 caractères …',
  messageReq: 'Un message est requis.',
  photoCode: 'Veuillez entrer le code de vérification sur la photo: *',
  ticketBtn: 'Soumettre le ticket',
  topicReq: 'Le sujet est obligatoire',
  yourNameRequ: 'Votre nom est requis.',
  codeRequ: 'Le code de vérification est requis.',
  verCodeErr: 'Le code de vérification est une erreur',
  'Dispute': 'Différend',
  'Color deviation': "Déviation de couleur",
  "Size issues": "Problèmes de taille",
  "Late for delivery": "En retard pour la livraison",
  "Differ from the pictures": "Différent des photos",
  "Quality deviation": "Écart de qualité",
  "Customs&Tax": "Sur commande et Taxes",
  "Shipping problem": "Problème d'expédition",
  "Other problems": "D'autres problèmes",
  'Products cancellation': 'Annulation de produits',
  'Event was canceled': "L'événement a été annulé",
  'Worry about the quality': 'Se soucier de la qualité',
  'Time is not enough': 'Le temps ne suffit pas',
  'Ordered wrong items': 'Mauvais articles commandés',
  'Found better service from other shops': "Trouvé un meilleur service dans d'autres boutiques",
  'Order modification': 'Modification de la commande',
  'Style': 'Style',
  'Delivery date': 'La date de livraison',
  'Others': 'Autres',
  'Size': 'Taille',
  'Address': 'Adresse',
  addConver: 'Ajouter une conversation',
  proInfo: 'Informations sur le produit',
  loadFailed: 'Loading failed, please refresh the page',
  //review
  viewReview: "Voir l'Avis",
  yourName: 'Votre nom',
  reviewTit: 'Titre de votre commentaires',
  reviewTitTip: 'Dites-nous ce que vous pensez',
  yourReview: 'Votre avis',
  yourReviewReq: 'Votre avis est requis.',
  commentsTip1: 'CONSEILS:\n Êtes-vous satisfait de votre achat? \nVous pouvez décrire le produit en termes de qualité, de style, de fitness, de matériel, etc.',
  addPhoto: 'Ajouter des photos (facultatif)',
  toUpload: 'Cliquez ici pour télécharger',
  nameReq: 'Le nom est requis.',
  reviewTitReq: 'Le titre de votre avis est requis.',
  reviewReq: 'Des avis sont requis.',
  imageMax5: '5 images max, 5 Mo max par image.',
  revHeadlineReq: "Le gros titre de l'avis est requis.",
  ProCommentsReq: 'Les commentaires sur le produit sont requis.',
  ReviewsEmpty: 'LES AVIS SONT VIDES',
  //coupon
  allCoupons: 'Tous les coupons',
  availableCoupons: 'Coupons disponibles',
  expiredCoupons: 'Coupons expirés',
  expiredeEmpty: 'Vos coupons expirés sont vides.',
  availableEmpty: 'Vos coupons disponibles sont vides.',
  newRegistDiscount: 'Nouvel escompte enregistré',
  orderOver: 'commande supérieure à',
  expires: 'Expire',
  copyAndUse: 'copier et utiliser dans la page de paiement',
  code: 'CODE',
  copy: 'COPIE',
  successCopy: 'Copie réussie!',
  //Order tracking
  trackYourOrder: 'Suivi de votre commande',
  sigleOrder: 'Consulter une seule commande',
  orderInfo: 'Veuillez entrer votre numéro de commande et votre adresse E-mail pour voir les détails de votre commande.',
  orderQues: 'Où puis-je trouver cela ?',
  orderTrackInfo: `Notre numéro de commande est inclus dans l'e-mail que vous avez reçu lorsque vous avez passé votre commande. Si vous n'avez plus cet email, <a class="cr-bd" href="{hrefContact}">contactez-nous</a>,s'il vous plaît?`,
  orderHistory: "Voir l'historique des commandes",
  signAccount: 'Connectez-vous à votre compte pour voir toutes vos commandes.',
  orderRequire: 'Le numéro de commande est requis.',
  trackText: 'Si vous avez déjà un compte, connectez-vous pour vérifier votre commande.',
  trackingOrder: 'Suivi de commande',
  orderTrack: 'Suivi des commandes',
  trackOrderInfo1: "Si vous souhaitez vérifier plus d'informations sur votre commande, vous pouvez vous inscrire avec l'adresse e-mail",
  trackOrderInfo2: `Si vous avez d'autres questions, vous pouvez <a href="{url}">créer un ticket</a> dans votre compte.`,
  trackShip: 'Suivi de livraison',
  placed: 'Mis',
  material: 'Materielle préparation',
  inProduct: 'En production',
  quality: 'Contrôle de la qualité',
  parcel: 'colis',
  shippedBy: 'Expédié par',
  trackNumb: 'Numéro de suivi',
  trackinfo: 'This order has been shipped by carrier, tracking information is expected to be updated within 1-3 days.',
  paid: 'Payé',
  shipment: 'Expédition',
  take: 'Prendre',
  dayArrive: 'jours pour arriver',
  complete: 'Complet',
  //gallery
  note: 'Note:',
  sahreNote: '1 vidéo max, 50 Mo max par vidéo,<br/>ou 5 images max, 5 Mo max par image.',
  sahreNoteReq: '1 vidéo max, 50 Mo max par vidéo,ou 5 images max, 5 Mo max par image.',
  next: 'SUIVANT',
  name: 'Nom',
  revHeadline: "Titre de l'avis",
  ProComments: 'Commentaires sur le produit',
  commentsTip: '(Êtes-vous satisfait de votre achat? Vous pouvez décrire le produit en termes de qualité, de style, de fitness, de matériel, etc.)',
  shareSucTxt1: 'MERCI LOVELY !',
  shareSucTxt2: 'Nous revérifierons la photo juste pour nous assurer que tout va bien!',
  shareSucTxt3: 'Votre photo sera publiée après approbation. Merci!',
  continueUpload: 'CONTINUER LE TÉLÉCHARGEMENT',
  viewGall: 'VOIR LA GALERIE',
  uploadVideoReq: `Veuillez fournir uniquement des fichiers vidéo MP4 ou des fichiers image JPG / PNG / JPEG / BMP, ne téléchargez pas d'images et de vidéos en même temps.`, //1
  shareStory: 'Partagez votre histoire',
  all: 'TOUT',
  emptyRefresh: 'Vide ? Veuillez actualiser la page',
  shopLook: 'ACHETER LE LOOK',
  photosReq: 'Des photos sont requises.',
  //about
  ourStory: 'Notre histoire',
  about1: "Fondée en 2012, STACEES est un détaillant en ligne de robes de mariée, de robes pour événements spéciaux, de robes pour les fêtes de mariage et d'accessoires. Les clients parcourent une large sélection en ligne et choisissent leurs robes préférées avec une grande satisfaction. Nous nous engageons à fournir à nos clients des produits et des services de haute qualité.",
  designServ: 'Conception et Service',
  about2: "Chez Stacees, nous concevons et développons, coupons chaque robe en boutique, nous adaptons chaque vêtement pour nous assurer qu'il correspond exactement à vos envies, nous prenons soin de chaque petit détail, même d'une fermeture éclair, une fois que vous avez passé votre commande chez Stacees, le tissu est coupé et cousu à la main, de cette façon nous réduisons les déchets et notre impact sur l'environnement, le tout sans frais supplémentaires pour vous.",
  about3: "La qualité d'une robe commence généralement dès le moment du choix du tissu. Nous mettons beaucoup d'efforts dans ce processus. Nous avons comparé plusieurs matériaux, en tenant compte de la couleur, de la douceur, du confort et de la durabilité, pour nous assurer qu'ils ne sont pas rigides ou qu'ils ne semblent pas bon marché. La silhouette parfaite est associée au tissu le plus parfait pour dégager sophistication et charme.",
  about4: "Chaque fille mérite de profiter du service et de la qualité de STACEES. Afin de proposer des prix plus bas et plus compétitifs, nous remettons en question le modèle commercial de l'industrie traditionnelle en possédant nos propres canaux de production, de marque et de marketing, afin que vous puissiez obtenir un prix élevé -robe de rêve de qualité au prix le plus abordable.",
  milestiones: 'nos jalons',
  sustainability: 'Durabilité et responsabilité sociale',
  about5: "Ces dernières années, STACEES a adopté la durabilité, reconnaissant l'importance de la responsabilité environnementale dans la mode. La marque a introduit des matériaux et des pratiques respectueux de l'environnement dans son processus de production, réduisant ainsi son empreinte carbone tout en maintenant les normes de qualité élevées attendues par ses clients. De plus, STACEES s'est engagée dans des initiatives philanthropiques, soutenant des causes qui autonomisent les femmes et favorisent la diversité.",
  staGirls: 'LES FILLES STACEES',
  about6: "Comme vous l'avez peut-être vu, nous sommes très soucieux de l'expérience d'achat de vêtements et nous sommes fiers de chaque beau moment pour chaque fille.Ces photos pleines de charme et les récits sincères qui les accompagnent sont les joyaux de notre couronne et le moteur de nos services. Ici, chaque photo est un témoignage de joie et chaque mot reflète notre engagement pour votre plaisir.",
  stayConnent: `Restons connectés`,
  about7: `Rejoignez notre communauté - suivez-nous sur les réseaux sociaux pour découvrir nos dernières collections et annonces et taguez @STACEESOFFCIAL pour que nous puissions voir comment vous portez vos articles préférés. Nous avons hâte de vous rencontrer !`,
  about8: "Créé en 2012, plein de vision illimitée et d'amour pour la mode, avec la poursuite ultime de la beauté, nous avons décidé de créer notre marque - STACEES !",
  about9: 'Élargissement au Royaume-Uni.',
  about10: 'Usine installée en 2015 et équipée de systèmes ERP sophistiqués.',
  about11: "Renforcer le contrôle qualité et améliorer le centre de service client pour améliorer l'expérience client.",
  about12: "STACEES a introduit des matériaux et des pratiques respectueux de l'environnement dans son processus de production, réduisant ainsi son empreinte carbone tout en maintenant les normes de qualité élevées attendues par ses clients. ",
  about13: "Avec des projets d'expansion continue des produits, des avancées technologiques et des engagements renforcés en matière de durabilité",
  about14: 'Candidature en ligne STACEES 2024. Mise à jour sur les dernières nouvelles, promotions spéciales et événements STACEES.',
  about15: 'Notre application sera lancée en 2025.',
  about16: "Nous disposons de nos propres canaux de production, de marque, de marketing et de vente, rompant ainsi avec le modèle commercial traditionnel de l'industrie. Traditionnellement, l'industrie de la robe de soirée a été décentralisée, avec différents participants responsables des différentes étapes du processus. Nous avons intégré ces étapes sous la marque Stacees parapluie pour rendre le processus plus simple, la livraison plus rapide et les prix plus transparents.",
  //shipping info
  shipPolic:"Politiques d'expédition",
  shipInfor:'Informations sur la livraison',
  shipInfo1: "Régions d'expédition, méthodes d'expédition, temps d'expédition et frais d'expédition et informations supplémentaires sur le processus d'expédition de STACEES",
  shipInfo2: "Nos produits peuvent être livrés dans le monde entier. Nous expédions nos produits en utilisant les transporteurs suivants : (UPS, DHL, TNT, Fedex, etc.). Le délai d'expédition varie en fonction de la distance d'expédition. Et l'efficacité des transporteurs locaux affecte également le délai de livraison. Il faut environ 2 à 15 Jours de travail pour la livraison de nos usines à votre destination. Le délai d'expédition exact dépend des normes du transporteur (UPS, DHL, TNT, Fedex, etc.) et de la méthode d'expédition que vous choisissez. Habituellement, le temps et le coût d'expédition vers différentes zones sont les suivants.",
  shipInfo3: 'Afrique',
  shipInfo4: "Pays insulaires sur l'océan",
  shipInfo5: 'Royaume-Uni',
  shipInfo7: "Autres pays d'Europe",
  shipInfo8: 'Les États-Unis et le Canada',
  shipInfo9: "Autres pays d'Amérique du Nord",
  shipInfo10: "Autres pays d'Amérique du Sud ",
  shipInfo11: 'Australie',
  shipInfo12: "Autres pays d'Océanie",
  shipInfo14: "Les jours ci-dessus sont tous des jours de travail, pendant les grandes vacances, le nombre de fret aéroportuaire augmentera et le transport sera retardé. Si vous avez besoin de votre commande de toute urgence, veuillez nous contacter à l'avance et nous informer de la date dont vous avez besoin. Nous allons l'  arranger pour vous dès que possible.",
  shipInfo15: "LES FRAIS D'EXPÉDITION : Nos frais d'expédition dépendent du poids total de votre commande et du pays dans lequel les robes seront expédiées.",
  shipInfo16: "Avant que votre commande ne soit expédiée, le site STACEES doit traiter vos commandes et passer des tests de contrôle de qualité stricts sur tous vos projets.Par conséquent, le délai de livraison total est différent du délai d'expédition séparé.",
  shipInfo17: 'Délai de production et de livraison',
  shipInfo18: "Délai de livraison total = Délai de traitement + Délai d'expédition",
  shipInfo19: 'Si votre commande contient plusieurs articles, ils seront expédiés ensemble.',
  shipInfo20: "En général, selon la complexité des robes. Le temps de traitement diffère d'une catégorie à l'autre. Veuillez vous référer au tableau ci-dessous pour un traitement plus précis afin de mieux estimer quand vous recevrez votre commande :",
  shipInfo23: 'Le temps de traitement spécifique dépend des articles. Le délai de livraison des accessoires, des échantillons et de certains articles dépend de notre inventaire et ils peuvent être expédiés sous 24 heures lorsque nous avons un stock suffisant.',
  shipInfo24: 'Nous traiterons votre commande dans les 24 heures et informerons nos tailleurs de commencer à confectionner des robes pour vous. Par conséquent, si vous souhaitez annuler la commande, veuillez nous contacter dans les 24 heures et nous facturerons l\'annulation de la commande pendant plus de 24 heures, car le tissu utilisé dans la robe a été coupé. Pour connaître la politique d\'annulation détaillée, veuillez consulter notre page <a href="{hrefOrderCan}">Annulation de commande</a>.',
  shipInfo26: "Nous offrons également un service accéléré pour les clients qui ont besoin d'une robe de toute urgence, vous pouvez le service de commande urgente pour certains styles. Vous pouvez choisir la date d'arrivée sur l'option de notre page produit. Lorsque vous choisissez une date très précoce, le système calculera automatiquement le coût supplémentaire de la production urgente de votre robe et il y aura un signe visible sur le prix. Cependant, si vous recevez la robe très tardivement, nous vous accorderons une remise de 5 %. Afin d'avoir suffisamment de temps pour vous confectionner une robe plus ajustée et plus sophistiquée, vous feriez mieux de passer votre commande tôt.",
  shipInfo27: 'De plus, si vous ne recevez pas votre commande dans le délai que vous avez choisi, nous vous proposerons un remboursement limité.',
  shipRegion: "Régions d'expédition",
  shipCost: "Frais d'expédition",
  from: 'À partir de',
  shipInfo28: 'Pays en Europe',
  rushOrder: 'Commande urgente',
  category: 'Catégorie',
  specialOccasion: 'Robes pour occasions spéciales',
  motherTheBride: 'Robes de mère de la mariée',
  accessories: 'Accessoires',
  shoes: 'Chaussures',
  swatch: 'Échantillon',
  shipp: 'Expédition',
  shipInform: 'Informations sur la livraison',
  //faq
  faqs: 'FAQ',
  askQuest: 'Questions fréquemment posées',
  livechat: 'Livechat',
  payment: "Paiement",
  paymentCoupons: "Paiements et coupons",
  sizeFit: 'Taille et coupe',
  addition: 'Informations supplémentaires',
  faq1: `Comment puis-je vérifier l'état de ma commande et suivre ma commande?`,
  faq2: `Une fois votre commande passée, vous pouvez vérifier l'état de la commande à tout moment dans « <a class='myOrder' href='javascript:void(0)'>Mon compte</a> ». Vous pourrez voir l'étape actuelle de votre commande. Alternativement, vous pouvez utiliser la fonction « <a href={hrefTrack}>Suivi de commande</a> » : saisissez simplement votre numéro de commande et votre adresse e-mail dans la case affichée sur la page, et elle affichera le statut exact de votre commande.`,
  faq3: `Si vous avez des questions sur le statut de votre commande, vous pouvez également nous envoyer un e-mail à <a href="mailto:service@stacees.com">service@stacees.com</a> ou nous contacter via <a href="javascript:void(0)" class="livechat">LiveChat</a> pour obtenir de l'aide.`,
  faq4: 'Puis-je modifier ma commande ? ',
  faq5: `Pour garantir que chaque commande réponde à vos attentes, nous vous envoyons toujours un e-mail de confirmation pour vérifier les détails. Vous pouvez toujours apporter des modifications à votre commande dans un certain délai. Cependant, pour garantir une livraison rapide, les commandes seront traitées 12 heures après l'e-mail de confirmation ou une fois qu'aucune question n'aura été soumise.`,
  faq6: `Veuillez comprendre qu'une fois qu'une commande est traitée, elle ne peut pas être facilement modifiée en raison de la planification, de l'approvisionnement en matériaux et des coûts de personnalisation.`,
  faq7: 'Puis-je annuler ma commande après son expédition?',
  faq8: 'Nous ne pouvons pas modifier ou annuler votre commande après son expédition. Néanmoins, nous serons heureux de vous aider dans le processus de retour une fois que vous aurez reçu vos articles (dans le cadre de notre politique de retour)',
  faq9: `Pourquoi n'ai-je pas reçu mon e-mail de confirmation de Stacees?`,
  faq10: `Nos e-mails de confirmation sont automatiquement envoyés par notre système. Si vous ne recevez pas d'e-mail de confirmation dans les 12 heures suivant votre commande (même si le paiement a été débité de votre carte de crédit ou de votre compte PayPal), veuillez nous contacter immédiatement. Nous vérifierons votre commande dans notre système et renverrons l'e-mail de confirmation, qui comprendra votre numéro de commande et les détails.<br/>Voici quelques raisons possibles pour lesquelles vous n'avez peut-être pas reçu l'e-mail:`,
  faq11: `<span>1. Adresse e-mail incorrecte :</span> Si l'adresse e-mail saisie lors de l'inscription est incorrecte ou rarement utilisée, notre e-mail de confirmation peut ne pas vous parvenir. Veuillez vous assurer que l'adresse e-mail fournie est exacte et régulièrement contrôlée.`,
  faq12: '<span>2. Filtres de messagerie :</span> Certains systèmes de messagerie peuvent classer par erreur nos e-mails comme indésirables ou spam. Avant de nous contacter, veuillez vérifier votre dossier de courrier indésirable ou de spam, car il y a de fortes chances que notre e-mail y ait été redirigé.',
  faq14: 'Puis-je ajouter ou supprimer des articles de ma commande ?',
  faq15: `<span>Pour la suppression/annulation :</span><br/>Si vous souhaitez supprimer un article de votre commande, veuillez vous assurer que la demande est conforme à la politique d'annulation et veuillez contacter notre service client pour traiter le problème. Nous sommes disposés à aider notre client à résoudre le problème.`,
  faq16: `<span>Pour ajouter un article :</span><br/>Nous comprenons parfaitement qu'il est parfois difficile de prendre une décision, mais à l'heure actuelle, notre système n'est tout simplement pas assez avancé pour que nous puissions ajouter des articles aux commandes existantes. Si vous le souhaitez, vous pouvez passer une nouvelle commande séparée avec le produit que vous souhaitez.`,
  faq17: 'Puis-je annuler ma commande?',
  faq18: `Tous nos articles sont fabriqués sur commande, y compris les articles de taille standard. Si vous souhaitez annuler une commande pour une raison quelconque, veuillez vous référer à notre politique d'annulation ci-dessous : <a href="{hrefOrderCan}">Commande- Politique d'annulation</a>`,
  faq13: `Si vous confirmez l'annulation de la commande, n'hésitez pas à nous contacter via <a href="javascript:void(0)" class="livechat">LiveChat</a> ou par e-mail à <a href="mailto:service@stacees.com">service@stacees.com</a> pour soumettre votre demande d'annulation, nous sommes là pour vous aider.`,
  faq19: `Je suis une mariée et je dois trouver une robe pour mes demoiselles d'honneur. Y a-t-il quelque chose que je devrais savoir avant de commander?`,
  faq20: `<span>Assurez-vous d'avoir suffisamment de temps pour passer la commande à l'avance </span><br/> Donnez-vous suffisamment de temps pour choisir et prendre une décision, assurez-vous que le style/la couleur de la robe correspondra au thème de l'événement. Nous comprenons parfaitement que c'est un grand jour pour vous, alors prenez votre temps et n'hésitez pas à nous contacter pour obtenir plus d'aide.`,
  faq21: `<span>Demandez à chaque demoiselle d'honneur de prendre une mesure rapide </span><br/> Même si nos clients peuvent demander à retourner leur commande, attendre que le colis de retour soit livré prendra également du temps et de l'énergie. Afin d'éviter que la taille de la robe ne corresponde pas, nous espérons sincèrement que vous pourrez prendre leurs mesures pour les comparer avec notre tableau des tailles en ligne afin de trouver la taille qui leur conviendra le mieux. Si vous ne savez pas quelle taille commander, veuillez nous contacter pour plus d'aide, nous vous aiderons à trouver la taille qui vous conviendra le mieux.`,
  faq22: `<span>N'oubliez pas d'acheter les produits en même temps</span><br/>Quoi que vous passiez une commande complète pour toutes les robes de demoiselles d'honneur ou que les demoiselles d'honneur achètent leur robe séparément par elles-mêmes. Veuillez vous assurer que le tissu utilisé pour ces robes sera du même lot.<br/>Parce que le tissu sera parfois en rupture de stock et nous devrons le réapprovisionner. À chaque fois, le processus de teinture rendra légèrement la couleur différente. Si vous ne savez pas quel lot sera utilisé pour votre commande, veuillez nous contacter pour obtenir plus de détails, nous vous aiderons à demander au tailleur de les fabriquer dans le même lot pour éviter que la couleur ne soit différente.`,
  faq23: 'Où puis-je obtenir le code de réduction?',
  faq24: `Pour nos nouveaux clients, après leur inscription, nous fournirons un code de réduction de 5 $ sur leur compte. Vous pouvez le trouver sous « <a class="coupons" href="javascript:">Mes coupons</a> ».`,
  faq25: `Nous nous sommes également associés à Student Beans pour offrir des réductions exclusives à nos clients. Vous pouvez cliquer sur le lien ci-dessous pour en savoir plus : <a class="student" href="javascript:">https://www.stacees.com/fr/student-discount</a>`,
  faq26: 'Où puis-je utiliser le coupon?',
  faq27: `Veuillez remplir le coupon dans la case du code de coupon qui s'affiche sur la page de paiement, puis l'appliquer. Tous les coupons peuvent y être utilisés.`,
  faq28: 'Puis-je utiliser plusieurs coupons dans une seule commande?',
  faq29: `Désolé, chaque commande ne peut appliquer qu'un seul coupon à la fois.`,
  faq30: 'Quel type de cartes peut être utilisé pour payer? ',
  faq31: 'La plupart des cartes de crédit et de débit sont faciles à payer ici. Nous acceptons les cartes de crédit, PayPal et les paiements échelonnés, etc.<br/>Veuillez vous référer à : <a href="{hrefPayment}">https://www.stacees.com/fr/payment-methods</a>',
  faq32: `Puis-je bénéficier d'une remise supplémentaire sur des commandes multiples ou en gros ?`,
  faq33: 'Nous avons fait de notre mieux pour fabriquer la plupart des robes à un prix raisonnable et abordable. Pour que chaque fille ait son style flatteur lors de son événement, nous sommes heureux de faire une affaire sur les commandes groupées en fonction du montant et de la quantité. Pour plus de détails sur les remises, veuillez nous contacter en ligne par <a href="javascript:void(0)" class="livechat">LiveChat</a> ou par e-mail: <a href="mailto:service@stacees.com">service@stacees.com</a>.',
  faq34: 'Combien de temps faut-il généralement pour recevoir ma commande?',
  faq35: `Le temps estimé d'arrivée (ETA) = Délai de Confection + Temps d'expédition.<br/>Sur la page de paiement, vous verrez une fenêtre de livraison estimée pour votre commande, qui sera également notée dans votre e-mail de confirmation de commande. De plus, vous trouverez l'heure d'arrivée estimée pour votre région et le temps de confection estimé sur la page des détails du produit.`,
  faq36: `Quelles méthodes d'expédition puis-je choisir?`,
  faq37: `Sur la page de paiement, nous proposons 2 méthodes d'expédition différentes à nos clients.<br/><span>1.Livraison standard</span><br/><span>2.Livraison expéditive</span>`,
  faq38: `Veuillez noter que le délai de transport estimé n'est disponible que pour les États-Unis. Le délai peut différer selon les pays.(Remarque : l'expédition accélérée n'est pas disponible pour les commandes contenant des chaussures, des sacs, des accessoires ou des échantillons/tissus. Si votre panier contient un mélange d'articles fabriqués sur commande et « Expédié sous 48h », ou une combinaison d'articles urgents et non urgents, l'expédition accélérée ne sera pas disponible sur la page de paiement.)`,
  faq39: `<span>Que signifie « Expédié sous 48h »</span><br/>Notre tailleur confectionnera votre robe et l'expédiera dans les 48 heures, mais veuillez comprendre qu'après l'expédition, l'expédition a encore besoin de temps.(Veuillez noter que pour les produits «Expédié sous 48h», nous ne proposons pas l'expédition accélérée car c'est déjà l'expédition la plus rapide)`,
  faq40: 'La commande concerne un événement très urgent auquel je dois assister. Que dois-je faire?',
  faq41: `L'équipe Stacees est dévouée à chaque client et est prête à aider autant que possible. Si votre événement est très proche et que vous espérez toujours recevoir la robe, veuillez nous contacter via <a href="javascript:void(0)" class="livechat">LiveChat</a> sur notre site Web ou par e-mail à <a href="mailto:service@stacees.com">service@stacees.com</a>. Assurez-vous de nous indiquer la date de votre événement et votre numéro de commande(ou le numéro de SKU de la robe auquel vous faites référence).`,
  faq42: 'Pouvez-vous livrer à une boîte postale?     ',
  faq43: `Veuillez noter que nous ne pouvons livrer qu'aux boîtes postales via l'expédition standard, PAS l'expédition accélérée. Veuillez vous assurer de saisir l'adresse réelle pour éviter que le colis ne soit pas livrable.`,
  faq44: `Et si aucun pays n'est répertorié sur la page de paiement?`,
  faq45: `À l'heure actuelle, nous ne sommes pas en mesure d'expédier nos produits vers d'autres pays qui ne sont pas répertoriés.Cependant, si vous voulez vraiment une robe Stacees, vous pouvez en acheter une à tout moment et la faire expédier à un ami ou à un parent dans un pays où nous livrons, puis leur demander de vous l'envoyer. Gardez simplement à l'esprit que nous avons un délai de retour limité.`,
  faq50: `Mon colis a été retourné à l'expéditeur. Que dois-je faire?`,
  faq51: `Votre commande a peut-être été retournée en raison d'une adresse de livraison incorrecte ou d'informations de destinataire non valides. Vous pouvez contacter le transporteur concerné, tel que USPS, DHL, etc., ou nous contacter via  <a href="javascript:void(0)" class="livechat">LiveChat</a> ou par e-mail à <a href="mailto:service@stacees.com">service@stacees.com</a> pour obtenir de l'aide. `,
  faq52: `Pourquoi ma commande est-elle indiquée comme livrée, mais je ne l'ai pas reçue?`,
  faq53: `Si votre colis indique qu'il a été livré mais que vous ne l'avez pas reçu, nous serons heureux de vous fournir des conseils utiles pour vous aider à retrouver le colis:`,
  faq54: `<span>-Vérifiez votre boîte aux lettres, votre jardin, votre porte d'entrée ou votre garage.<br/>
  -Vérifiez auprès de vos voisins, du bureau de location ou de la zone de réception centrale.<br/>
  -Demandez à d'autres membres de votre foyer, à des collègues ou à des voisins qui ont peut-être reçu le colis lorsque vous n'étiez pas disponible.<br/>
  -Contactez l'équipe de livraison locale pour vous renseigner sur le colis.</span>`,
  faq55: `Si vous ne parvenez toujours pas à localiser votre colis, veuillez nous contacter via <a href="javascript:void(0)" class="livechat">LiveChat</a> ou par e-mail à <a href="mailto:service@stacees.com">service@stacees.com</a> pour obtenir de l'aide.`,
  faq56: 'Si ma commande est retardée, que dois-je faire?',
  faq57: `Si vous n'avez pas reçu la robe dans le délai indiqué sur le reçu, veuillez nous contacter via <a href="javascript:void(0)" class="livechat">LiveChat</a> ou par e-mail à <a href="mailto:service@stacees.com">service@stacees.com</a> pour plus d'assistance.`,
  faq58: `Pendant l'expédition, puis-je modifier mon adresse de livraison pour une autre?`,
  faq59: `Veuillez noter qu'une fois votre commande expédiée et en transit, nous ne pourrons pas mettre à jour l'adresse de livraison. Le colis sera envoyé à l'adresse d'origine que vous avez fournie.<br/>Vous pouvez essayer de contacter la société de messagerie pour vérifier si elle peut vous aider à modifier l'adresse de livraison.`,
  faq60: 'Des frais de douane me seront-ils facturés?',
  faq61: 'La plupart de nos articles ne sont pas soumis à des droits de douane supplémentaires. Cependant, chaque pays a des réglementations différentes en matière de frais de douane. Nous recommandons à nos clients de sélectionner une assurance douanière sur la page de paiement lors de la passation de la commande.',
  faq62: 'Quel type de société de messagerie utiliserez-vous pour ma commande?',
  faq63: 'Habituellement, nous expédions le colis via USPS, UPS, Yodel (Royaume-Uni uniquement), DHL, FedEx, etc. La société de messagerie pour votre commande est automatiquement sélectionnée en fonction de votre adresse de livraison et ne peut pas être modifiée.',
  faq64: `J'ai utilisé la mauvaise adresse pour la commande, que dois-je faire?`,
  faq65: `Si vous avez entré une mauvaise adresse lors du paiement, il est important de modifier votre adresse dès que possible après avoir passé votre commande, veuillez nous contacter via <a href="javascript:void(0)" class="livechat">LiveChat</a> et envoyez-nous la bonne adresse.<br/>(Veuillez noter que chaque fois que notre client passe la commande, notre système enverra automatiquement l'e-mail de confirmation, l'e-mail de confirmation comprendra les informations de base de la commande)`,
  faq66: 'Puis-je retourner la robe lorsque je la reçois?',
  faq67: `Si vous changez d'avis sur la commande, veuillez soumettre la demande de retour dans les 14 jours suivant la réception de la commande. Notez qu'il s'agit de jours calendaires, donc les week-ends sont également pris en compte.De plus, ce délai de retour fait référence au délai de soumission de votre demande, tant que vous la soumettez dans ce délai, le délai de traitement n'est pas inclus. Nous vous assisterons ensuite tout au long du processus jusqu'à ce que le problème soit résolu à votre entière satisfaction`,
  faq68: 'Comment puis-je retourner la robe?',
  faq69: `Vous pouvez créer une demande de retour dans les 14 jours suivant la réception de votre commande. Veuillez fournir la raison du retour ainsi que toutes les photographies pertinentes pour vérification.Notre équipe de service vous répondra dans les plus brefs délais. Si vous n'avez pas de compte, veuillez vous "<a href="{hrefLogin}">INSCRIVEZ-VOUS</a>" sur notre site Web à l'aide de l'e-mail que vous avez utilisé pour la commande, puis vous pourrez « Créer un ticket ». Veuillez suivre l'image :`,
  faq70: `J'ai retourné la robe, quand puis-je espérer recevoir le remboursement?`,
  faq71: 'Nous organiserons leur fonds dès réception du retour de la robe. En règle générale, vous recevrez le remboursement dans un délai de 2 à 5 jours.',
  faq72: 'Où dois-je retourner ma robe ?',
  faq73: `Veuillez contacter notre service client et demander le retour de votre commande. Veuillez ne pas renvoyer le colis directement à l'adresse indiquée sur notre site Web ou sur le colis, qui ne sont pas notre adresse de retour.`,
  faq74: 'Puis-je retourner la robe et avoir un échange/une modification?',
  faq75: `Malheureusement, nous ne proposons actuellement aucun service d'échange/modification pour aucun article. Veuillez retourner la commande et en passer une nouvelle. <br/>
Nous suggérons à nos clients d'apporter la robe chez un tailleur local pour effectuer la retouche au lieu de nous la retourner, ce qui pourrait être plus rapide.<br/> 
Nous proposons également un remboursement limité des retouches pour toutes les robes, vous pouvez contacter notre service après-vente en soumettant un ticket.`,
  faq76: 'Puis-je retourner une robe de taille personnalisée?',
  faq77: `Toutes les commandes personnalisées sont fabriquées sur mesure selon vos spécifications. Nous ne pouvons pas revendre une commande sur mesure à un autre client. Par conséquent, nous n'accepterons pas les retours ou les remboursements pour les commandes sur mesure, sauf en cas de problème de qualité.`,
  faq78: 'Est-ce que chaque robe a un soutien-gorge intégré?',
  faq79: `Oui, afin d'apporter soutien et protection à la poitrine, l'équipe Stacees inclura un soutien-gorge intégré dans chaque robe, à l'exception des <a href="{hrefFlower}">Robes de fille de fleur</a>.`,
  faq80: 'Puis-je personnaliser les parties supérieure et inférieure de la robe avec différents tissus/couleurs?',
  faq81: ' Stacees dispose de sa propre équipe de tailleurs seniors et nous pouvons fabriquer la robe selon vos besoins spécifiques. Pour garantir que la robe est confectionnée exactement comme décrite, les clients doivent fournir leurs exigences dans la section des notes supplémentaires sur la page de la robe. Il serait très utile que vous nous envoyiez des photos de référence par e-mail.',
  faq82: 'Puis-je demander une robe qui ne vient pas de Stacees?',
  faq83: `Oui, nos clients peuvent soumettre une demande de personnalisation via le lien suivant:<br/>
      <a href="{hrefTailor}">https://www.stacees.com/fr/project-tailor-made</a><br/>
      Notre tailleur examinera votre demande et vous enverra un devis par e-mail. `,
  faq84: `Comment puis-je m'assurer que le tissu et la couleur me conviendront parfaitement?`,
  faq85: `Nous comprenons que chaque robe est importante et qu'obtenir le bon tissu et la bonne couleur est essentiel pour vous aider à vous démarquer. Veuillez noter que la couleur peut varier légèrement en raison des différences dans les paramètres du moniteur, l'éclairage et l'environnement.<br/>
  Pour vous assurer que vous êtes satisfait du tissu et de la couleur, nous vous recommandons de commander des échantillons de tissu sur notre site Web. Voici le lien:<br/>
  <a href="{hrefFabric}">https://www.stacees.com/fr/list/echantillons-de-tissu</a>`,
  faq86: 'Comment enlever les plis?     ',
  faq87: `Nos robes arrivent pliées dans leur sac, elles peuvent donc avoir des plis à l'arrivée. Si vous avez un peu de temps avant votre événement, beaucoup des plis se dissiperont naturellement si vous accrochez la robe.<br/>
Pour préparer la robe pour le grand jour, évitez d'utiliser un fer à repasser, car il risque d'abîmer le tissu. La cuisson à la vapeur est le meilleur moyen d'éliminer les plis.<br/>
Si vous n'avez pas de défroisseur, vous pouvez apporter la robe chez un pressing pour un défroissage professionnel.Alternativement, vous pouvez essayer cette astuce : accrochez la robe dans votre salle de bain et faites couler un bain chaud pour aider à éliminer les plis.`,
  faq88: 'Comment nettoyer la robe?',
  faq89: `Parce que nos robes sont confectionnées avec des tissus délicats, nous vous recommandons de les nettoyer à sec si vous devez les faire nettoyer. Nous ne recommandons pas de les laver en machine.<br/>Lors du nettoyage à sec, assurez-vous d'informer le nettoyeur de manipuler les perles et autres décorations avec précaution. La robe doit être suspendue et nettoyée en profondeur, et la pièce entière ne doit pas être lavée à l'eau. Après le nettoyage, il doit être séché et cuit à la vapeur.`,
  faq90: `Comment puis-je m'assurer de choisir la bonne taille qui me conviendra?`,
  faq91: `Différents sites peuvent utiliser des normes de taille différentes, il est donc préférable de vous mesurer d'abord. Nous fournissons un tableau des tailles, et il peut y avoir des différences par rapport à votre taille habituelle, veuillez donc vérifier le tableau des tailles sur la page de la robe et le comparer avec vos mesures pour trouver la taille qui vous conviendra le mieux. Si vous n'êtes pas sûr de la taille à choisir, n'hésitez pas à nous contacter via <a href="javascript:void(0)" class="livechat">LiveChat</a> ou par e-mail à <a href="mailto:service@stacees.com">service@stacees.com</a> pour obtenir de l'aide.`,
  faq92: 'Puis-je recevoir ma robe sans couture?',
  faq93: 'Toutes nos robes sont fabriquées sur commande et nous ne gardons pas de stock. Par conséquent, nous avons besoin de temps pour fabriquer chaque robe de haute qualité pour vous. Nous espérons que vous pourrez comprendre cela.',
  faq94: 'Les robes sont-elles conformes à la taille?',
  faq95: `En tant que membre de l'équipe Stacees, nous sommes fiers de garantir que toutes nos robes sont fidèles à la taille. La meilleure façon de choisir la bonne taille pour vous est de prendre vos mesures en vous référant aux images ci-dessous intitulées "<a href="{hrefSize}">Comment mesurer</a>".`,
  faq96: 'Pourquoi existe-t-il différents tableaux de tailles sur le site web? ',
  faq97: `Nous fournissons différents tableaux de tailles pour différents types de robes, veuillez donc vous référer au tableau des tailles sur la page de l'article. De plus, nous avons des clients du Royaume-Uni, des États-Unis et d'autres pays. Le tableau des tailles indiquera également les tailles britanniques et américaines, veuillez donc le vérifier attentivement.<br/>Si vous avez des questions sur la coupe ou si vous avez besoin d'aide pour choisir votre taille, n'hésitez pas à nous contacter.`,
  faq98: 'Comment réinitialiser mon mot de passe?',
  faq99: `Suivez simplement les instructions "<a href='javascript:void(0)' class='forgot'>Mot de passe oublié</a>" Pour des raisons de sécurité, nous ne pouvons pas vous envoyer votre mot de passe par email, vous recevrez un lien de réinitialisation de mot de passe par email, suivez ce lien pour réinitialiser votre mot de passe. Si vous ne recevez pas l'email, veuillez nous contacter, nous ferons part de vos commentaires au service technique.`,
  faq100: 'Dois-je être membre pour acheter ici?',
  faq101: `Vous pouvez commander en tant qu'invité. Cependant, avoir un compte facilite le suivi de vos commandes, l'enregistrement des adresses fréquentes et l'envoi de tickets. Vous pouvez facilement créer un compte en seulement une minute.`,
  faq102: `Comment puis-je modifier l'adresse e-mail du compte?`,
  faq103: `Pour assurer la sécurité de votre compte, vous n'êtes pas autorisé à modifier l'adresse e-mail enregistrée. Cependant, vous pouvez nous indiquer l'adresse e-mail que vous souhaitez utiliser, et nous la mettrons à jour pour vous et l'enregistrerons pour les commandes futures.`,
  faq104: 'Je souhaite supprimer mon compte. Que dois-je faire?',
  faq105: `Nous respectons la décision de chaque client. Veuillez nous contacter via le <a href="javascript:void(0)" class="livechat">LiveChat</a> ou par e-mail: <a href="mailto:service@stacees.com">service@stacees.com</a>, et nous vous aiderons dans le processus.`,
  faq106: `Pourquoi ne puis-je pas voir ma commande sur mon compte?`,
  faq107: `Vous devez vous connecter avec l'adresse e-mail correcte que vous avez utilisée pour passer la commande.Une fois connecté avec la même adresse email, votre commande devrait apparaître dans la liste "<a href='javascript:void(0)' class='myOrder'>Mes commandes</a>". Si vous ne le trouvez toujours pas, veuillez nous contacter via le <a href="javascript:void(0)" class="livechat">LiveChat</a> ou par e-mail : <a href="mailto:service@stacees.com">service@stacees.com</a>. Nous vous aiderons à résoudre le problème.`,
  faq108: 'Quand dois-je commander?',
  faq109: `Pour les mariages et les événements officiels.<br/>
    Pour vous assurer de disposer de suffisamment de temps pour la livraison et les ajustements nécessaires, nous vous recommandons de passer votre commande 2 mois avant le mariage ou l'événement officiel auquel vous prévoyez d'assister.Cela garantira qu'il y aura suffisamment de temps pour la production, la livraison et les retouches, qui sont souvent nécessaires avec les tenues de soirée.`,
  faq110: `Cela peut également aider à éviter les problèmes causés par des retards potentiels résultant d'événements imprévisibles, tels que des catastrophes naturelles ou des problèmes de transporteur.`,
  faq111: `Veuillez noter que les délais de production peuvent parfois être plus longs, notamment entre janvier et mars. Si les délais de production augmentent après la passation de votre commande, cela n'aura aucune incidence sur votre commande. Seules les nouvelles commandes seront impactées.`,
  faq112: 'Comment puis-je modifier le site Web en fonction de la langue/de la devise utilisée dans ma région?     ',
  faq113: `Afin de mieux offrir un bon service à nos clients, nous avons créé des sites Web distincts pour chaque zone.Si vous cliquez accidentellement sur le mauvais site Web, ne vous inquiétez pas et suivez les étapes ci-dessous pour le modifie. Pour notre site Web, vous pouvez simplement cliquer sur l'icône du globe dans le coin supérieur gauche de la page et sélectionner le pays approprié. `,
  faq114: `Pour les utilisateurs de téléphones portables, veuillez suivre les images ci-dessous pour changer la langue/devise.<br/>Tout d'abord, sur la page principale, veuillez cliquer sur le menu.`,
  faq115: `Ensuite, vous verrez une liste comprenant tous les produits que nous vendons, faites glisser la liste, au bas de la liste, vous verrez l'étiquette du pays, de la langue et de la devise.<br/>Cliquez dessus, vous pouvez le changer.`,
  faq116: `Veuillez noter que les adresses de livraison et de facturation utilisées lors du paiement doivent être dans le même pays. Par exemple, si vous commandez sur le site Web du Royaume-Uni, les deux adresses doivent être basées au Royaume-Uni. Si votre adresse de facturation se trouve dans un autre pays, essayez d'utiliser votre adresse de livraison comme adresse de facturation.`,
  faq46: `Vous n'arrivez toujours pas à résoudre le problème ?`,
  faq47: `Ne vous inquiétez pas, nous avons deux façons de vous fournir une assistance supplémentaire.`,
  faq48: `Notre service en ligne disponible 24/7 peut répondre rapidement à vos questions et est heureux de vous aider..`,
  faq49: `Vous pouvez nous envoyer un e-mail, nous vous répondrons dans les 24 heures.`,
  chatUs: 'chattez avec nous',
  chatNow: 'Discuter maintenant',
  emailUs: 'Envoyez-nous un e-mail',
  //Return & Refund
  refundTit: 'Remboursement, Retours et Échanges',
  refundTit1: 'POLITIQUE DE RETOUR SOUS 14 JOURS',
  refundTit2: 'Comment initier un retour',
  refundTit3: 'RETOURS ET REMBOURSEMENTS',
  creataTicket: 'Créer un ticket',
  submitReturn: 'SOUMETTRE LES INFORMATIONS DE RETOUR',
  emailReply: 'réponse par e-mail',
  returnApproved: 'Retour approuvé',
  retrunItem: 'REMBOURSER LE(S) ARTICLE(S)',
  initiateCont1: `<a class="anim-a" href="{hrefLogin}">Connectez-vous</a> à votre compte Stacees. Si vous n'avez pas de compte, veuillez<a class="anim-a" href="{hrefLogin}">«Inscrivez-vouse»</a>sur notre site Web avec l'adresse e-mail que vous avez utilisée pour passer commande.`,
  initiateCont2: `Trouvez 'Mes commandes', sélectionnez la commande que vous souhaitez retourner, puis cliquez sur 'Service ticket'.`,
  initiateCont3: `Sélectionnez le ou les articles que vous souhaitez retourner et précisez la raison, l'état, la résolution, puis procédez à la soumission.`,
  initiateCont4: `Une fois que vous l'aurez créé avec succès, nous vous répondrons par e-mail dans les 24 heures. Veuillez vérifier votre e-mail.`,
  initiateCont5: `Si votre retour est approuvé, nous vous fournirons une adresse. Veuillez retourner l'article dès que possible.`,
  initiateCont6: `Lorsque nous recevons le colis et vérifions qu'il est normal, nous vous rembourserons l'argent sur votre compte d'origine.`,
  startReturn: 'DÉMARRER UN RETOUR',
  ReturnPolicy: 'Politique générale de retour',
  retRefund: 'RETOURS ET REMBOURSEMENT FACILES',
  retRefundCont1: "Merci d'avoir fait vos achats chez STACEES.",
  retRefundCont2: "Si vous n'êtes pas entièrement satisfait de votre commande, nous sommes là pour vous aider. Nous vous rembourserons intégralement votre achat, y compris les frais d'expédition pour tous les articles endommagés, défectueux ou expédiés par erreur. Si vous n'êtes pas satisfait de votre achat, n'hésitez pas à nous contacter dès que possible pour commencer à traiter votre retour et votre remboursement.",
  retRefundCont3: "Conditions de retour",
  retRefundCont4: "Vous ne pouvez retourner les marchandises que dans les 14 jours à compter du jour où vous les avez reçues. Nous conseillons à nos clients d'examiner le contenu de leur livraison dès son arrivée. Une fois que vous êtes sûr que les articles sont endommagés ou expédiés par erreur, veuillez lancer le processus de retour & remboursement dès que possible.",
  retRefundCont5: "Veuillez contacter notre service client et demander de retourner votre commande. Veuillez ne pas retourner le colis directement à l'adresse indiquée sur notre site Web ou sur le colis, qui ne sont pas notre adresse de retour.",
  retRefundCont6: "Pour être éligible au retour & remboursement, l'article doit être dans son état d'origine. Nous n'accepterons pas le retour de marchandises utilisées, portées, lavées, modifiées ou endommagées. Veuillez vous assurer de laisser les étiquettes d'origine sur les articles si vous souhaitez les retourner.",
  retRefundCont7: "Touts nos articles sont fabriqués sur commande. Cela signifie que nous ne stockons pas d'articles supplémentaires dans notre magasin. Une fois que vous aurez passé votre commande, elle sera faite spécialement pour vous. Par conséquent, nous n'accepterons pas les retours de marchandises conformes à votre commande. Notre politique de retour ne prend en compte que les dommages ou l'envoi injustifié d'articles.",
  retRefundCont8: "Lorsque vous nous expédiez votre retour, veuillez vous assurer de conserver votre reçu d'expédition et vos informations de suivi. Nous ne sommes pas responsables des marchandises retournées qui sont expédiées à tort, endommagées ou perdues pendant l'expédition.",
  retRefundCont9: "Une fois que vous recevez un article que vous pensez être défectueux, endommagé ou mal expédié, veuillez prendre des images de l'emballage d'origine. Cela nous aidera à accélérer le traitement de votre retour, remboursement partiel ou remboursement complet.",
  retRefundCont10: "Une fois que nous aurons reçu vos marchandises retournées, nous inspecterons votre commande et vous informerons que nous les avons reçues. Une fois votre retour traité et approuvé, nous lancerons le processus de remboursement via le mode de paiement d'origine. Selon les politiques de l'émetteur de votre carte, vous pouvez vous attendre à recevoir votre crédit en quelques jours.",
  retRefundCont11: "Pour toutes les commandes de taille standard/personnalisée",
  retRefundCont12: "Pour toutes les commandes de style/de sélection de taille",
  retRefundCont13: "Ajustements et remboursements",
  retRefundCont14: "Échantillons de tissus, articles personnalisés, accessoires et commandes urgentes.",
  retRefundCont15: "Pour tous les articles en vente finale",
  retRefundCont16: "Articles défectueux, endommagés ou mal expédiés",
  retRefundCont17: "Problèmes de dimensionnement et d'ajustement",
  retRefundCont18: "Déviation de taille",
  retRefundCont19: "Non-concordance des couleurs",
  retRefundCont20: "Problèmes faits à la main",
  retRefundCont21: "Politique d'échange",
  retRefundCont22: "Comment traiter votre retour ou remboursement",
  retRefundCont23: "Politique d'annulation",
  retRefundCont24: "STACEES procédera à un remboursement complet, plus des frais d'expédition pour toutes les marchandises endommagées, défectueuses ou mal expédiées. Si vous n'êtes pas satisfait de votre commande, veuillez prendre des images de la commande d'origine afin que nous puissions traiter votre remboursement.",
  retRefundCont25: "Si vous changez d'avis sur la commande, veuillez traiter la demande de retour dans les 14 jours suivant la réception de la commande. Notez qu'il s'agit de jours calendaires, les week-ends sont donc également pris en compte. Par conséquent, il est dans votre intérêt de contacter notre service client dans les plus brefs délais pour traiter votre retour.",
  retRefundCont26: "Tous les retours non endommagés doivent être renvoyés dans leur état d'origine. Nous n'acceptons pas le retour d'articles portés, lavés, altérés ou endommagés. Assurez-vous également que tous les articles retournés sont renvoyés avec leurs étiquettes d'origine. Nous ne pouvons pas émettre de retour pour les marchandises qui ne remplissent pas ces conditions ou ne respectent pas notre politique de retour.",
  retRefundCont27: "Une fois que vous avez soumis votre demande de retour et que nous en avons accusé réception, le traitement de votre retour, échange ou remboursement prendra jusqu'à 7 jours ouvrables. Veuillez noter que le délai de réception de votre crédit dépend des politiques de l'émetteur de votre carte ou de votre banque. Habituellement, il faut entre 7 et 14 jours ouvrables à la plupart des émetteurs de cartes pour traiter un remboursement.",
  retRefundCont28: "Toutes les commandes sur mesure sont faites sur mesure selon vos spécifications. Cela signifie essentiellement qu'une fois la commande terminée, ce sera pour vous et vous seulement. Nous ne pouvons pas revendre une commande sur mesure à un autre client. Par conséquent, nous ne traiterons pas les retours ou les remboursements pour les commandes sur mesure, sauf en cas de problème de qualité. Avant de passer votre commande, assurez-vous de passer en revue et de confirmer les détails concernant la couleur, le style et les mesures dans les 24 heures suivant la finalisation de votre commande.",
  retRefundCont29: "S'il y a un problème de qualité, veuillez prendre des images de l'envoi d'origine et contacter notre service clientèle pour demander un retour. Nous vous informerons une fois votre demande traitée, et un retour ou un remboursement peut être traité dans les sept jours suivant la réception et l'inspection de votre commande.",
  retRefundCont30: "Veuillez comprendre que toutes nos robes sont fabriquées sur commande, ce qui signifie que les robes que vous commandez sont spéciales pour vous. Nous mettons notre cœur dans chaque robe, les robes ne peuvent pas être réapprovisionnées ou revendues si elles sont retournées. Pour cette raison, nous ne pouvons pas accepter la commande \"acheter-plusieurs-en garder une\". Afin de réduire les déchets environnementaux et le gaspillage des ressources, nous ne suggérons pas l'ordre \"acheter-plusieurs-garder-un\". Une fois votre commande confirmée, nous penserons que vous avez besoin de tous les articles de votre commande et suivrons strictement notre politique de retour et de remboursement.",
  retRefundCont31: "Si vous souhaitez garder votre commande et faire vos ajustements, nous pouvons vous proposer un remboursement. Nous comprenons que parfois, quelques ajustements mineurs doivent être apportés à votre commande pour obtenir un ajustement parfait. Par conséquent, nous proposons un remboursement limitée des modifications pour tous les robes. Cela comprend à la fois les tailles standard et les robes sur mesure. Tout ce que vous avez à faire est de nous faire parvenir une copie du reçu du tailleur et votre numéro de commande. Voici nos limites de remboursement pour les robes sur mesure en EUR :",
  retRefundCont32: "Pour les robes dont le prix est inférieur ou égal à 99 € - Limite de remboursement de 35 €",
  retRefundCont33: "Pour les robes dont le prix est de 100 € à 199 € - Limite de remboursement de 50 €",
  retRefundCont34: "Pour toutes les robes supérieures à 200 € - Limite de remboursement 75 €",
  retRefundCont35: "Après une vente finale, nous ne pouvons pas traiter les retours, les remboursements ou les échanges pour tous les échantillons de tissu, articles personnalisés, voiles ou accessoires.",
  retRefundCont36: "Nous ne rembourserons pas les frais d'urgence sur les commandes urgentes, sauf si la commande est défectueuse.Si vous recevez une commande défectueuse, vous pouvez nous contacter, et nous inspecterons et traiterons les frais d'urgence dès que possible.",
  retRefundCont37: "Nous ne serons pas en mesure de traiter les retours ou les remboursements pour les articles en vente finale.",
  retRefundCont38: "Vous avez droit à un remboursement complet si vous recevez des articles défectueux, endommagés ou expédiés par erreur. Cependant, si vous pensez que les articles ont été endommagés lors de l'expédition, vous devez fournir la documentation \"Preuve de dommage\" de la société de livraison. Cela devrait être inclus avec des images ou des éléments lorsque vous soumettez votre demande de retour.",
  retRefundCont39: "Pour permettre de modifications légères, nos tailleurs réservent du tissu supplémentaire dans les coutures de toutes les robes. Nous comprenons que parfois une robe finie peut varier d'environ un pouce dans les deux sens des mesures spécifiées. Cela vous permettra de faire des retouches pour que la robe vous aille parfaitement.",
  retRefundCont40: "Si la taille de votre robe s'écarte de plus de 2,5 cm (1 pouce) par rapport aux spécifications de votre commande, vous pouvez faire appel à un tailleur local pour effectuer des ajustements. Dans ce cas, nous vous rembourserons jusqu'à 40 % du prix d'achat du produit (hors frais de livraison). Si vous choisissez cette option, veuillez demander un reçu à votre tailleur et nous en fournir une copie afin que nous puissions traiter le remboursement.",
  retRefundCont41: "En ce qui concerne l'incompatibilité des couleurs, veuillez noter qu'il peut y avoir un léger écart de couleur en raison des paramètres de l'écran de votre ordinateur ou de votre appareil mobile. Cependant, si vous êtes convaincu que la couleur n'est pas celle que vous avez commandée, veuillez contacter le service client pour voir si un retour ou un remboursement est possible.",
  retRefundCont42: "La plupart de nos robes sont faites à la main par nos tailleurs expérimentés. Par conséquent, des problèmes mineurs peuvent survenir lors de la personnalisation. Cependant, ces robes ne sont pas considérées comme défectueuses ou défectueuses aux fins des retours et des échanges.",
  retRefundCont43: "Nous avons quelques conseils pour vous aider à résoudre les problèmes.",
  retRefundCont44: "1. Fil. Utilisez des ciseaux pour le couper, soyez prudent.",
  retRefundCont45: "2. Plis. Repasser les vêtements. Veuillez repasser le côté opposé (côté intérieur) des vêtements avec des perles, des broderies ou d'autres embellissements",
  retRefundCont46: "3. Légère marque. Ceci est principalement causé par les tailleurs lors de l'utilisation d'un stylo effaçable à l'eau pour la confection. Il est facile à nettoyer en frottant doucement avec de l'eau.",
  retRefundCont47: "Vous souhaitez échanger votre robe contre une taille ou un article différent ? Malheureusement, nous ne proposons actuellement aucun service d'échange pour aucun produit. Toutes nos robes sont fabriquées sur commande. Par conséquent, nous n'avons pas de robes supplémentaires disponibles pour l'échange. Si vous souhaitez un article différent, veuillez retourner la commande et en démarrer une nouvelle à partir de zéro.",
  retRefundCont48: "1. Créez un ticket dans votre liste de commandes dans les 14 jours suivant la réception de votre commande. Veuillez inclure la raison du retour et des photos pour vérification. Veuillez suivre l'image :",
  retRefundCont49: "2. Une fois que nous aurons envoyé l'autorisation de retourner la marchandise, nous fournirons également une adresse de retour via Tickets. Tout ce que vous avez à faire est d'imprimer l'adresse et de la joindre/mettre à l'intérieur du colis avec les marchandises que vous souhaitez retourner.",
  retRefundCont50: "3. Une fois que vous avez expédié le colis avec succès, veuillez informer notre représentant du service clientèle que votre envoi est en route. Vous devez également leur fournir le numéro de suivi de l'envoi fourni par votre bureau de poste local.",
  retRefundCont51: "4. Dès réception de votre commande retournée, nous vous en informerons par e-mail. Nous inspecterons ensuite votre article et un remboursement/retour sera traité dans un délai de 3 à 5 jours ouvrables. Si votre retour n'est pas conforme à notre politique de retour et de remboursement, nous ne pourrons peut-être pas traiter un remboursement. L'article doit être dans un état satisfaisant. Une fois que votre remboursement est accepté, vous pouvez vous attendre à recevoir votre crédit dans les 7 à 15 jours ouvrables via le mode de paiement d'origine. Veuillez noter que le délai de traitement dépend de l'émetteur de votre carte ou de votre banque.",
  retRefundCont52: "Malheureusement, en raison de la situation actuelle, votre remboursement nécessitera 3 à 5 jours supplémentaires pour être traité. Merci pour votre patience et votre compréhension.",
  retRefundCont53: "Conditions",
  retRefundCont54: "* L'article doit être non porté, non lavé, non altéré, non endommagé et avec les étiquettes d'origine attachées. Nous ne pouvons pas traiter le retour d'un article défectueux.",
  retRefundCont55: "* Nous nous réservons le droit de refuser le retour s'il est reçu dans un état inacceptable ou sans notre connaissance préalable.",
  retRefundCont56: "* Nous ne sommes pas responsables des articles retournés qui est perdus ou endommagés, veuillez conserver le reçu avec les informations de suivi.",
  retRefundCont57: `Tous nos articles sont fabriqués sur commande, y compris les articles de taille standard. Si vous souhaitez annuler une commande pour quelque raison que ce soit, veuillez vous référer à notre politique d'annulation ci-dessous : <a class="bold" href="{hrefOrderCan}">Politique d'annulation de commande</a>`,
  //project tailor made
  tailor1: "<p>Combien de fois avez-vous vu cette robe parfaite sur les réseaux sociaux sans savoir où l'acheter ? Ou peut-être vous </p></p>avez trouvé la robe de vos rêves, pour ensuite découvrir que le vendeur ne stocke pas votre taille ou ne livre pas dans votre pays.</p>",
  tailor2: "<label>Nous savons à quel point il peut être difficile de </label><span>trouver la tenue idéale pour une occasion spéciale.</span>",
  tailor3: "<p>Et, que vous achetez pour un anniversaire, un bal, une remise de diplôme ou tout autre jour mémorable, seule </p><p>la meilleure robe fera l'affaire. C'est pourquoi nous avons créé notre service innovant de robe sur mesure </p><p>afin que vous puissiez avoir une robe aussi spéciale que l'occasion.</p>",
  tailor4: "Comment fonctionne notre service ?",
  tailor5: "Grâce à notre système intelligent, vous pouvez télécharger une photo de n'importe quel robe que vous trouvez en ligne. Ainsi, la prochaine fois que vous faites défiler Pinterest et trouvez une belle robe, enregistrez l'image et téléchargez via la fonction de téléchargement d'image. Nous pouvons recréer n'importe quelle robe que vous voyez en ligne, même si cela provient du site d'un concurrent.",
  tailor6: "LES AVANTAGES DE CHOISIR UNE ROBE SUR MESURE",
  tailor7: "Alors pourquoi choisir une robe sur mesure plutôt qu'un vêtement de série ?",
  tailor8: "Tout d'abord, de nombreuses robes de grande rue donnent une coupe peu flatteuse car elles ne sont pas créées pour votre type de corps ou votre taille. Cependant, une robe sur mesure promet un ajustement parfait car le vêtement est conçu et cousu selon vos mesures exactes.",
  tailor9: "Deuxièmement, ce n'est un secret pour personne que de nombreuses robes produites en série utilisent des tissus de mauvaise qualité. Ces matériaux peuvent provoquer des démangeaisons et des irritations et ne durent pas longtemps. Par conséquent, vous ne porterez probablement la robe qu'une seule fois. Cependant, nous produisent toutes nos robes sur mesure en utilisant des tissus de la plus haute qualité pour une durabilité et un confort maximum",
  tailor10: 'En plus de télécharger la photo, vous devrez nous fournir quelques informations pour recevoir un devis précis pour la création de la robe.',
  tailor11: 'La forme/la silhouette de votre corps. Vous pouvez nous indiquer votre type de corps, par exemple, poire, pomme, sablier ou silhouette générale, comme mince, courbée ou athlétique.',
  tailor12: "L'occasion que vous envisagez de porter la robe.",
  tailor13: 'Toute autre exigence ou souhait personnalisé doit rendre la robe encore plus spéciale et unique.',
  tailor14: 'SOUMETTRE UN SUR MESURE',
  writeTailor: 'Écrivez un texte sur mesure',
  dressPic: 'Image de robe',
  content: 'Contenu',
  contReq: 'Le contenu est requis.',
  tailor15: 'Une fois que vous avez téléchargé la photo et fourni les détails ci-dessus, notre représentant de service évaluera les informations et vous contactera pour un devis.',
  tailor16: 'Max 5 images, 5 Mo chacune. Format : jpeg/jpg/png/bmp Les photos claires sont très appréciées !',
  tips: 'Conseils',
  pleaseLogin: 'Veuillez vous connecter',
  success: 'Succès!',
  tailor17: `Nous savons ce que vous recherchez.`,
  //size chart
  specOccasion: 'Occasion spéciale',
  juniorBrid: "Demoiselle d'honneur junior",
  jackets: "Vestes/Châles",
  sizeTxt1: 'Vous trouverez ci-dessous des tableaux des tailles pour différents produits. Trouvez la taille qui vous convient',
  sizeTxt2: "Il est important de se familiariser avec votre propre taille lors du choix d'une taille de robe. Ce n'est qu'en connaissant vos mesures personnelles que vous pouvez avoir une robe qui vous va le mieux. En effet, chaque créateur ne peut utiliser que ses propres règles pour définir les tailles de robe. Par exemple, ce qui pourrait être une taille 6 dans votre vêtement de tous les jours, et pourrait être une taille 10 dans la même taille dans nos robes ou dans les robes d'un autre créateur.",
  sizeTxt3: 'Veuillez vérifier la poitrine, la taille et les hanches lors du choix de la taille.',
  sizeTxt28: 'Pour connaître vos mesures, veuillez vous référer à Comment mesurer',
  sizeTxt4: 'Tableau des tailles standard de la robe',
  sizeTxt5: 'Poitrine Taille Hanches Tableau des tailles standard',
  sizeTxt6: "Tableau de longueur de robe (creux du cou à l'ourlet)",
  sizeTxt7: "Certains styles ont une facilité ajoutée pour votre confort. Si vous êtes entre deux tailles, il est suggéré d'appeler et de parler à un styliste ou de commander la robe en taille personnalisée.",
  sizeTxt8: "La mesure creux du cou à l'ourlet pour les robes de longueur au sol de taille standard comprend 2 pouces supplémentaires pour s'adapter aux talons.",
  sizeTxt9: 'Avez-vous des problèmes avec le choix de la taille ou des exigences supplémentaires sur la taille ? <a href="{hrefContact}">Contactez-nous.</a>',
  sizeTxt10: 'Tableau des tailles',
  sizeTxt11: 'Tableau des tailles à grande taille',
  miniShort: '<div></span>Mini<span>/Court</span></div>',
  kneeLen: 'Longueur Genou',
  teaLen: 'Mollet Longueur',
  ankleLen: 'Longueur cheville',
  floorLen: 'Longueur du planche',
  dressLen: 'longueur de robe',
  reference: 'Pour votre référence seulement',
  eurSize: "Taille de l'Europe",
  usSize: 'Taille US',
  euSize: 'Taille EU',
  ukSize: 'Taille UK',
  auSize: 'Taille AU',
  cupSize: 'Cup Size',
  shoulder: 'Épaule',
  toBust: 'Épaule au buste',
  sizeTxt12: "Veuillez vérifier la poitrine, la taille et les hanches lors du choix de la taille. Pour connaître les mensurations de votre fille, veuillez vous référer à Comment mesurer",
  sizeTxt13: "Robes de fille de fleur",
  sizeTxt14: "la taille dépend des articles. Veuillez consulter la page de détails du produit pour connaître la taille exacte.",
  sizeTxt15: 'Tableau des tailles de robes de fille de fleur',
  sizeTxt16: "Tableau des tailles de robes de demoiselle d'honneur junior",
  sizeTxt17: "Tableau des tailles de vestes et d'écharpes",
  sizeTxt18: "Le tableau des tailles est fourni à titre indicatif uniquement. Les mesures spécifiques varient avec chaque style.",
  sizeTxt19: 'Tableau des tailles de chaussures pour femmes et hommes',
  sizeTxt20: 'Tableau des tailles de chaussures de danse',
  sizeTxt21: "La taille des robes de demoiselle d'honneur junior dépend des articles. Veuillez consulter la page de détails du produit pour connaître la taille exacte.",
  bustText1: "Il s'agit d'une mesure de la partie la plus complète de votre poitrine ou de votre circonférence corporelle au niveau de la poitrine. C'est une mesure corporelle qui mesure la circonférence du torse d'une femme au niveau des seins.",
  bustText2: "Enroulez le mètre à ruban autour de la partie la plus large de votre poitrine et centrez le mètre à ruban sur votre dos afin qu'il soit nivelé tout autour.",
  bustText3: "<strong>*Conseils:</strong><br/>Ce n'est pas votre taille de soutien-gorge !<br/>Vos bras doivent être détendus et le long de vos côtés.<br/>Portez le soutien-gorge que vous prévoyez de porter avec votre robe lorsque vous la prenez.",
  waistText1: "Il s'agit d'une mesure de votre tour de taille naturel, ou de la plus petite partie de votre taille.",
  waistText2: "Passez du mètre à ruban autour de la taille naturelle, en gardant le ruban parallèle au sol. Penchez-vous d'un côté pour trouver une indentation naturelle dans le torse. C'est votre taille naturelle.",
  hipsText1: "Il s'agit d'une mesure autour de la partie la plus complète de vos fesses.",
  hipsText2: 'Enroulez du mètre à ruban autour de la partie la plus large de vos hanches, qui se situe généralement entre 7 et 9 pouces sous votre taille naturelle. Gardez le ruban parallèle au sol tout autour.',
  hemText1: "C'est la distance entre votre creux du cou à l'ourlet de la robe.",
  hemText2: "Tenez le ruban à mesurer à votre creux pendant qu'un ami le tire jusqu'à la longueur que vous voulez pour votre robe.",
  hemText3: "<strong>*Conseils:</strong><br/>Lorsque vous prenez cette mesure, tenez-vous droit, les pieds joints.<br/>Nous définissons la longueur du genou comme la zone juste en dessous de vos rotules.<br/>Mesurer trop haut peut entraîner une robe trop courte.<br/>S'il s'agit d'une robe longue au sol, assurez-vous de mesurer sans porter de chaussures.",
  shoulderText1: "Il s'agit de la distance entre le bord de l'épaule gauche et l'os du cou proéminent situé au centre de l'arrière du cou, continuant jusqu'au bord de l'épaule droite.",
  shoulderText2: `Placez le mètre à ruban sur le "haut" des épaules. Mesurez du bord de l'épaule gauche jusqu'à l'os du cou proéminent situé au centre de l'arrière du cou en continuant jusqu'au bord de l'épaule droite.`,
  sleeveText1: "Il s'agit de la mesure entre la couture de votre épaule et l'endroit où vous souhaitez que votre manche se termine.",
  sleeveText2: "Mesurez de la couture de votre épaule à la longueur de manche souhaitée avec votre bras détendu à vos côtés pour obtenir la meilleure mesure possible.",
  sleeveText3: "<strong>*Conseils:</strong><br/>Mesurez avec votre bras légèrement plié.",
  armscyeText1: "C'est la mesure de votre emmanchure.",
  armscyeText2: "<strong>*Conseils:</strong><br/>Afin de prendre la mesure de votre bras, vous devez enrouler le ruban à mesurer sur le haut de votre épaule et sous votre aisselle.",
  armText2: "<strong>*Conseils:</strong><br/>Mesurez avec le muscle détendu.",
  sizeTxt22: 'Veuillez suivre attentivement notre guide ',
  sizeTxt23: ' lors de la prise de mesures.',
  sizeTxt24: "Tableau des tailles d'écharpe",
  sizeTxt25: "Tableau des tailles d'écharpe à grande taille",
  sizeTxt26: 'Tableau des tailles de soutien-gorge',
  sizeTxt27: "La mesure du creux au sol de nos robes standard comprend déjà 2 pouces (environ 5 cm) supplémentaires pour s'adapter aux talons hauts.",
  measureSize: 'Mesures Taille',
  underBust: 'Sous la poitrine',
  bustGap: 'Fluctuation Buste Gap',
  oneSize: 'Taille unique',
  adults: 'Adultes',
  measureGuide: 'Guide de mesure',
  measFeet: 'Mesurez vos pieds',
  measFeetInfo: `Portez le même type de chaussettes que vous portez avec vos chaussures et mesurez vos pieds dans l'après-midi (les pieds gonflent généralement pendant la journée). Répartissez le poids uniformément avant de mesurer.`,
  step: 'ÉTAPE',
  stepTxt1: 'Tenez-vous droit sur une surface dure avec votre talon contre le mur et un morceau de papier vierge collé au sol, contre le mur sous votre pied.',
  stepTxt2: `Demandez à quelqu'un de marquer la partie la plus longue de votre pied (appelée longueur du talon aux orteils) sur le papier avec un stylo ou un crayon, ou mesurez-vous si nécessaire.`,
  stepTxt3: 'Use a ruler to measure the heel-to toe length you marked for each foot.',
  //color chart
  "Dark Navy": "Bleu marine foncé",
  "Navy Blue": "Bleu marine",
  "Dusty Blue": "Bleu poudrée",
  "Ink Blue": "Bleu d'encre",
  "Stormy": "Orageux",
  "Slate Blue": "Bleu Ardoise",
  "Royal Blue": "Bleu royal",
  "Ocean Blue": "Océan bleu",
  "Ice Blue": "Glace bleu",
  "Blue": "Bleu",
  "Light Blue": "Bleu clair",
  "Sky Blue": "Bleu ciel",
  "Mist": "Brume",
  "Cabernet": "Cabernet",
  "Burgundy": "Bordeaux",
  "Red": "Rouge",
  "Mulberry": "Mûre",
  "Rust": "Rouillé",
  "Watermelon": "Pastèque",
  "Papaya": "Papaye",
  "Sunset": "Sunset",
  "Peach": "Pêche",
  "Light Pink": "Rose clair",
  "Pearl Pink": "Rose perle",
  "Petal": "Pétale",
  "Blushing Pink": "Rose Rougissant",
  "Candy Pink": "Rose Bonbon",
  "Coral": "Corail",
  "Dusty Pink": "Rose pâle ",
  "Dusty Rose": "Rose poudrée",
  "Peacock": "Paon",
  "Blush": "Rose Ballet",
  "Tahiti": "Tahiti",
  "Lilac": "Lilas",
  "Lavender": "Lavande",
  "Dusk": "Crépuscule",
  "Wisteria": "Glycine",
  "Mauve": "Mauve",
  "Vintage-Mauve": "Mauve-Vintage",
  "Vintage Mauve": "Mauve Vintage",
  "Orchid": "Orchidée",
  "Grape": "Raisin",
  "Fuchsia": "Fuchsia",
  "Regency": "Violet foncé",
  "Plum": "Prune",
  "Chocolate": "Chocolat",
  "Dark Green": "Vert foncé",
  "Teal": "Bleu vert",
  "Emerald": "Emeraude",
  "Hunter Green": "Vert de jungle",
  "Olive": "Olive",
  "Celadon": "Céladon",
  "Lime Green": "Vert citron",
  "Dusty Sage": "Sauge poudrée",
  "Sage": "Sage",
  "Mint Green": "Menthe verte",
  "Turquoise": "Turquoise",
  "Spa": "Spa",
  "Jade": "Jade",
  "Beige": "Beige",
  "Yellow": "Jaune",
  "Daffodil": "Jonquille",
  "Champagne": "Champagne",
  "Gold": "Or",
  "Orange": "Orange",
  "Marigold": "Souci",
  "Tangerine": "Mandarine",
  "Taupe": "Taupe",
  "Mushroom": "Champignon",
  "Silver": "Argent",
  "White": "Blanc",
  "Ivory": "Ivoire",
  "Potrobello": "Potrobello",
  "Steel Grey": "Gris acié",
  "Black": "Noir",
  colorNote: "Veuillez garder à l'esprit que les photos peuvent être légèrement différentes de l'article réel en termes de couleur, en raison de l'éclairage, de l'ombre et des améliorations de la photo lors de la séance photo. Les paramètres Gama, Luminosité et Contraste de l'écran d'ordinateur menent parfois à confusion.",
  "Chiffon": "Mousseline",
  "Organza": "Organza",
  "Satin": "Satin",
  "Taffeta": "Taffetas",
  "Elastic Satin": "Satin Élastique",
  "Tulle": "Tulle",
  "Lace": "Dentelle",
  "Jersey": "Jersey",
  "Charmeuse": "Imitation De Soie",
  "Moss": "Mousse",
  //contact us
  contactUs1: "Besoin d'aide ?",
  contactUs2: "Nous accueillons toutes les questions concernant l'achat, la commande et le service après-vente. Nous avons des représentants sympathiques prêts à vous aider.",
  contactUs3: "Si vous avez des questions, veuillez d'abord consulter notre <a href='{hrefFaq}'>FAQ</a>. Le centre d'aide fournit des réponses aux questions les plus courantes.",
  contactUs4: "Si vous envisagez un achat",
  contactUs5: "Si vous avez une question ou avez besoin de plus d'informations avant de passer une commande, vous pouvez nous contacter à tout moment via Livechat en bas à droite de la page. Si notre représentant Livesupport n’est pas disponible, vous pouvez envoyer un e-mail 24 h/24 et 7 j/7 et nous vous répondrons dans les plus brefs délais.",
  contactUs6: "Si vous avez déjà effectué un achat",
  contactUs7: "Le service après-vente est là pour vous aider avec les demandes concernant vos commandes de traitement en cours et les articles que vous avez déjà reçus. Veuillez noter que toutes les demandes sont traitées avec soin, aucune demande n'est laissée sans réponse, nous essayons de répondre à chaque demande dans un délai d'un jour ouvrable.",
  contactUs8: "Créer un billet",
  contactUs9: "Horaires d'ouverture : de 1h à 11h (UTC)",
  contactUs10: "Chez STACEES, nous sommes fiers d'offrir des produits de la plus haute qualité et de fournir les meilleurs services clients. Nous comprenons également que des choses peuvent parfois arriver et nous sommes toujours là pour vous aider. Voici quelques questions fréquemment posées pour votre référence.",
  contactUs18: "Veuillez ne pas envoyer d'articles de retour à l'adresse ci-dessus, les retours envoyés à cette adresse ne peuvent pas être traités.",
  contactUs19: 'Si vous avez besoin de retourner un produit, veuillez contacter le service client en <a class="tickets" href="javascript:">soumettant un ticket</a>.',
  preSale: 'PRÉ-VENTE',
  afterSale: 'APRÉS-VENTE',
  whatsapp: 'Whatsapp',
  addressList: [
    'Informations sur le bureau de Hong Kong',
    'Entreprise : DIY FASHION LIMITED',
    'Numéro d\'enregistrement: 1994949',
    'canceled: Unité A7, 12e étage, Astoria Building, 34, rue Ashley, Tsim Sha Tsui, Kowloon, Hong Kong. (ceci n\'est pas une adresse de retour)',
  ],
  creturn: 'retour',
  trackOrder: 'Suivre la commande',
  cdelivery: 'Livraison',
  promPaymt: 'Promotion/Paiement',
  prodSize: 'Produits/Taille',
  oyherQuery: 'TOUTE AUTRE QUESTION',
  contactF3: "Vous pouvez créer une demande de retour dans les 14 jours suivant la réception de votre commande. Veuillez fournir la raison du retour ainsi que toutes les photographies pertinentes pour vérification. Notre équipe de service vous répondra dans les plus brefs délais. Si vous n'avez pas de compte, veuillez vous « Inscrivez-vouse » sur notre site Web à l'aide de l'e-mail que vous avez utilisé pour la commande, puis vous pourrez «Créer un ticket». Veuillez suivre l'image:",
  //payment methods
  paymentInfo1: "Profitez de votre expérience d'achat sûre et pratique sur ",
  paymentInfo2: 'Nous acceptons les modes de paiement suivants. Vous pouvez choisir celui que vous préférez:',
  creditDebit: 'CARTE DE CRÉDIT / DÉBIT:',
  paymentT1: 'NOUS ACCEPTONS LES MODES DE PAIEMENT SUIVANTS',
  paymentT2: 'NOUS PROPOSONS ÉGALEMENT LE PAIEMENT EN PLUSIEURS FOIS',
  paymentT3: `EST-IL SÉCURISÉ D'EFFECTUER LE PAIEMENT ICI ?`,
  paymentT4: `La sécurité des informations soumises par nos clients est l'une de nos priorités. Nous sommes vérifiés par le système de validation bancaire international et PayPal, supervisés selon leurs conditions et politiques. Vous bénéficierez de la meilleure expérience de shopping sécurisé ici?`,
  paymentT14: 'Je veux payer, mais pourquoi le paiement est-il refusé?',
  paymentT5: `Nous pensons que chaque client appréciera les achats sûrs et pratiques ici, car nous proposons l'option de paiement la plus populaire, Paypal, carte de crédit/carte de débit, paiements échelonnés. Mais malheureusement, si votre paiement est refusé, ne vous inquiétez pas, nous vous avons ici. Nous avons répertorié quelques raisons pour lesquelles cela peut entraîner un échec de paiement. Veuillez vérifier comme ci-dessous:`,
  paymentT6: `Le nom de l'utilisateur sur la carte, le numéro de carte, la période de validité, le CVV2 et d'autres informations relatives à la carte de crédit sont renseignés de manière incorrecte.<br/>Vous devez corriger les informations erronées et les saisir à nouveau.`,
  paymentT7: `2. 2. La carte de crédit de l'acheteur a expiré.<br/>Vous devez utiliser une autre carte.`,
  paymentT8: `3. Le solde de la carte de crédit de l'acheteur est insuffisant.<br/>Veuillez contacter votre banque pour le réapprovisionnement et le renouvellement des fonds.`,
  paymentT9: `4. L'emplacement de l'acheteur est répertorié comme une zone à haut risque par la banque.<br/>Veuillez contacter notre représentant du service client et vous pouvez également effectuer le paiement par PayPal, Western Union et virement bancaire.`,
  paymentT10: `5. Les informations pertinentes de l'acheteur sont répertoriées comme à haut risque par le système de contrôle des risques de la société de paiement, ce qui empêche la transaction et réduit la possibilité de vol de carte.<br/>Parce que le client a eu récemment des enregistrements de rétrofacturation ou de réclamation Votre paiement n'est pas autorisé, veuillez contacter votre service bancaire pour plus d'informations. Cela peut être dû au montant important d'argent. Ensuite, vous devez passer par le processus de vérification.`,
  paymentT11: `Si vous avez encore d'autres questions sur la réalisation du paiement, veuillez nous en informer dès que possible. Nous sommes prêts à vous aider. Merci pour votre temps et j'espère que nous aurons bientôt de vos nouvelles.`,
  paymentT12: `J'ai accidentellement passé la même commande plusieurs fois, que dois-je faire?`,
  paymentT13: `Ne vous inquiétez pas, vous pouvez prendre le numéro de commande ou l'adresse e-mail que vous avez utilisée pour passer la commande pour nous contacter par chat en ligne ou par e-mail : <a href="mailto:service@stacees.com">service@stacees.com</a>. Nous vous aiderons à régler ce problème.`,
  //terms conditions
  overview: 'Aperçu',
  termCont1: "Ce site Web est exploité par DIY FASHION LIMITED et/ou ses entités affiliées, filiale britannique FEELTIMES LIMITED (collectivement dénommées DIY FASHION LIMITED \"nous\", \"notre\" également connu sous le nom de stacees.com. Sur l'ensemble du site, les termes \"nous\", \"notre\" et \"nos\" font référence à DIY FASHION LIMITED. DIY FASHION LIMITED propose ce site Web, y compris toutes les informations, outils et services disponibles sur ce site pour vous, l'utilisateur, sous réserve de votre acceptation de tous les termes, conditions, politiques et avis énoncés ici.Les termes \"STACEES\" sur l'ensemble du site font référence à DIY FASHION LIMITED.",
  termCont2: "En visitant notre site et/ou en achetant quelque chose chez nous, vous vous engagez dans notre \"Service\" et acceptez d'être lié par les termes et conditions suivants (\"Conditions d'utilisation\", \"Conditions\"), y compris ces termes et conditions et politiques supplémentaires référencé ici et/ou disponible par hyperlien. Ces conditions d'utilisation s'appliquent à tous les utilisateurs du site, y compris, sans s'y limiter, les utilisateurs qui sont des navigateurs, des fournisseurs, des clients, des marchands et/ou des contributeurs de contenu. Veuillez lire attentivement ces conditions d'utilisation avant d'accéder ou d'utiliser notre site Web. En accédant ou en utilisant n'importe quelle partie du site, vous acceptez d'être lié par ces conditions d'utilisation. Si vous n'acceptez pas tous les termes et conditions de cet accord, vous ne pouvez pas accéder au site Web ni utiliser aucun service. Si ces conditions d'utilisation sont considérées comme une offre, l'acceptation est expressément limitée à ces conditions d'utilisation. Toute nouvelle fonctionnalité ou tout nouvel outil ajouté à la boutique actuelle sera également soumis aux Conditions d'utilisation. Vous pouvez consulter la version la plus récente des Conditions d'utilisation à tout moment sur cette page. Nous nous réservons le droit de mettre à jour, de modifier ou de remplacer toute partie de ces conditions d'utilisation en publiant des mises à jour et/ou des modifications sur notre site Web. Il est de votre responsabilité de vérifier périodiquement cette page pour les changements. Votre utilisation continue ou votre accès au site Web après la publication de toute modification constitue une acceptation de ces modifications.",
  termCont3: "STACEES se conformera toujours à la loi de la France.",
  termCont4: "Conditions de la boutique en ligne",
  termCont5: "En acceptant ces conditions d'utilisation, vous déclarez que vous avez au moins l'âge de la majorité dans votre état ou province de résidence, ou que vous avez l'âge de la majorité dans votre état ou province de résidence et que vous nous avez donné votre consentement pour permettre à l'un des membres mineurs de votre famille d'utiliser ce site Web.",
  termCont6: "Vous ne pouvez pas utiliser nos produits à des fins illégales ou non autorisées et vous ne pouvez pas non plus, dans l'utilisation du Service, enfreindre les lois de votre juridiction (y compris, mais sans s'y limiter, les lois sur le droit d'auteur).",
  termCont7: "Vous ne devez transmettre aucun logiciel malveillant, virus ou code de nature destructrice.",
  termCont8: "Une violation ou une violation de l'une des Conditions entraînera une résiliation immédiate de vos Services.",
  termCont9: "Conditions générales",
  termCont10: "Nous nous réservons le droit de refuser le service à quiconque pour quelque raison que ce soit et à tout moment.",
  termCont11: "Vous comprenez que votre contenu (à l'exclusion des informations de carte de crédit) peut être transféré en clair et impliquer (a) des transmissions sur divers réseaux ; et (b) des changements pour se conformer et s'adapter aux exigences techniques des réseaux ou appareils de connexion. Les informations de carte de crédit sont toujours cryptées lors du transfert sur les réseaux.",
  termCont12: "Vous acceptez de ne pas reproduire, dupliquer, copier, vendre, revendre ou exploiter toute partie du Service, l'utilisation du Service, ou l'accès au Service ou tout contact sur le site Web par lequel le service est fourni, sans autorisation écrite expresse de notre part.",
  termCont13: "Les titres utilisés dans cet accord sont inclus uniquement pour des raisons de commodité et ne limiteront ni n'affecteront autrement ces Conditions.",
  termCont14: "Exactitude, exhaustivité et actualité des informations",
  termCont15: "Nous ne sommes pas responsables si les informations mises à disposition sur ce site ne sont pas exactes, complètes ou à jour. Le contenu de ce site est fourni à titre d'information générale uniquement et ne doit pas être invoqué ou utilisé comme seule base pour prendre des décisions sans consulter des sources d'information primaires, plus précises, plus complètes ou plus actuelles. Toute confiance dans le matériel de ce site est à vos risques et périls.",
  termCont16: "Ce site peut contenir certaines informations historiques. Les informations historiques ne sont pas nécessairement à jour et sont fournies à titre de référence uniquement. Nous nous réservons le droit de modifier le contenu de ce site à tout moment, mais nous n'avons aucune obligation de mettre à jour les informations sur notre site. Vous convenez qu'il est de votre responsabilité de surveiller les modifications apportées à notre site.",
  termCont17: "Modifications du service et des prix",
  termCont18: "Les prix de nos produits sont sujets à changement sans préavis. Nous nous réservons le droit à tout moment de modifier ou d'interrompre le Service (ou toute partie ou contenu de celui-ci) sans préavis à tout moment. Nous ne serons pas responsables envers vous ou envers un tiers pour toute modification, changement de prix, suspension ou interruption du Service.",
  termCont19: "Produits ou services",
  termCont20: "Certains produits ou services peuvent être disponibles exclusivement en ligne via le site Web. Ces produits ou services peuvent avoir des quantités limitées et ne peuvent être retournés ou échangés que conformément à notre politique de retour.",
  termCont22: "Nous nous sommes efforcés d'afficher le plus fidèlement possible les couleurs et les images de nos produits qui apparaissent en magasin. Nous ne pouvons pas garantir que l'affichage de n'importe quelle couleur sur votre écran d'ordinateur sera précis.",
  termCont23: "Nous nous réservons le droit, mais ne sommes pas obligés, de limiter les ventes de nos produits ou services à toute personne, région géographique ou juridiction. Nous pouvons exercer ce droit au cas par cas. Nous nous réservons le droit de limiter les quantités de tout produit ou service que nous offrons. Toutes les descriptions de produits ou les prix des produits peuvent être modifiés à tout moment sans préavis, à notre seule discrétion. Nous nous réservons le droit d'interrompre tout produit à tout moment. Toute offre pour tout produit ou service faite sur ce site est nulle là où elle est interdite.",
  termCont24: "Nous ne garantissons pas que la qualité de tout produit, service, information ou autre matériel acheté ou obtenu par vous répondra à vos attentes, ou que toute erreur dans le Service sera corrigée.",
  termCont25: "Exactitude des informations de facturation et de compte",
  termCont26: "Nous nous réservons le droit de refuser toute commande que vous passez chez nous. Nous pouvons, à notre seule discrétion, limiter ou annuler les quantités achetées par personne, par foyer ou par commande. Ces restrictions peuvent inclure des commandes passées par ou sous le même compte client, la même carte de crédit et/ou des commandes utilisant la même adresse de facturation et/ou de livraison. Dans le cas où nous modifions ou annulons une commande, nous pouvons tenter de vous en informer en contactant l'adresse e-mail et/ou l'adresse de facturation/le numéro de téléphone fournis au moment de la commande. Nous nous réservons le droit de limiter ou d'interdire les commandes qui, à notre seul jugement, semblent être passées par des concessionnaires, revendeurs ou distributeurs.",
  termCont27: "Vous acceptez de fournir des informations d'achat et de compte actuelles, complètes et exactes pour tous les achats effectués dans notre magasin. Vous acceptez de mettre à jour rapidement votre compte et d'autres informations, y compris votre adresse e-mail et vos numéros de carte de crédit et dates d'expiration, afin que nous puissions effectuer vos transactions et vous contacter si nécessaire.",
  termCont28: 'Pour plus de détails, veuillez consulter notre <a class="cr-bd" href="{hrefReturn}">Politique de retour.</a>',
  termCont29: "Liens tiers",
  termCont30: "Certains contenus, produits et services disponibles via notre Service peuvent inclure des éléments provenant de tiers.",
  termCont31: "Les liens de tiers sur ce site peuvent vous diriger vers des sites Web de tiers qui ne sont pas affiliés à nous. Nous ne sommes pas responsables de l'examen ou de l'évaluation du contenu ou de l'exactitude et nous ne garantissons pas et n'assumons aucune responsabilité pour tout matériel ou site Web de tiers, ou pour tout autre matériel, produit ou service de tiers.",
  termCont32: "Nous ne sommes pas responsables de tout préjudice ou dommage lié à l'achat ou à l'utilisation de biens, services, ressources, contenus ou de toute autre transaction effectuée en relation avec des sites Web tiers. Veuillez lire attentivement les politiques et pratiques des tiers et assurez-vous de les comprendre avant de vous engager dans une transaction. Les plaintes, réclamations, préoccupations ou questions concernant les produits tiers doivent être adressées au tiers.",
  termCont33: "Commentaires des utilisateurs, réactions et autres soumissions",
  termCont34: "Si, à notre demande, vous envoyez certaines soumissions spécifiques (par exemple, des participations à des concours) ou sans demande de notre part, vous envoyez des idées créatives, des suggestions, des propositions, des plans ou d'autres documents, que ce soit en ligne, par e-mail, par courrier postal ou autrement (collectivement, les « commentaires »), vous acceptez que nous puissions, à tout moment, sans restriction, modifier, copier, publier, distribuer, traduire et autrement utiliser sur tout support les commentaires que vous nous transmettez. Nous ne sommes et ne serons pas tenus (1) de maintenir la confidentialité des commentaires ; (2) payer une compensation pour tout commentaire; ou (3) pour répondre à tout commentaire.",
  termCont35: "Nous pouvons, mais n'avons aucune obligation, surveiller, modifier ou supprimer le contenu que nous déterminons, à notre seule discrétion, comme étant illégal, offensant, menaçant, diffamatoire, pornographique, obscène ou autrement répréhensible ou violant la propriété intellectuelle de toute partie ou les présentes conditions d'utilisation.",
  termCont36: "Vous acceptez que vos commentaires ne violent aucun droit d'un tiers, y compris le droit d'auteur, la marque, la confidentialité, la personnalité ou tout autre droit personnel ou de propriété. Vous acceptez en outre que vos commentaires ne contiennent pas de matériel diffamatoire ou autrement illégal, abusif ou obscène, ou ne contiennent aucun virus informatique ou autre logiciel malveillant qui pourrait affecter de quelque manière que ce soit le fonctionnement du Service ou de tout site Web connexe. Vous ne pouvez pas utiliser une fausse adresse e-mail, prétendre être quelqu'un d'autre que vous-même, ou nous induire en erreur ou induire des tiers en erreur quant à l'origine des commentaires. Vous êtes seul responsable des commentaires que vous faites et de leur exactitude. Nous n'assumons aucune responsabilité et n'assumons aucune responsabilité pour les commentaires publiés par vous ou par un tiers.",
  termCont37: "Informations personnelles",
  termCont38: "Votre soumission d'informations personnelles via le boutique est régie par notre politique de confidentialité.",
  termCont39: "Erreurs, inexactitudes et omissions",
  termCont40: "Parfois, il peut y avoir des informations sur notre site ou dans le Service qui contiennent des erreurs typographiques, des inexactitudes ou des omissions qui peuvent concerner les descriptions de produits, les prix, les promotions, les offres, les frais d'expédition des produits, les temps de transit et la disponibilité. Nous nous réservons le droit de corriger toute erreur, inexactitude ou omission, et de modifier ou de mettre à jour les informations ou d'annuler des commandes si des informations dans le Service ou sur tout site Web connexe sont inexactes à tout moment sans préavis (y compris après que vous ayez soumis votre commande) .",
  termCont41: "Nous n'assumons aucune obligation de mettre à jour, de modifier ou de clarifier les informations dans le Service ou sur tout site Web connexe, y compris, sans s'y limiter, les informations sur les prix, sauf si la loi l'exige. Aucune date de mise à jour ou d'actualisation spécifiée appliquée dans le Service ou sur tout site Web connexe ne doit être considérée comme indiquant que toutes les informations dans le Service ou sur tout site Web connexe ont été modifiées ou mises à jour.",
  termCont42: "Utilisations interdites",
  termCont43: "En plus des autres interdictions énoncées dans les Conditions d'utilisation, il vous est interdit d'utiliser le site ou son contenu : (a) à des fins illégales ; (b) solliciter d'autres personnes pour qu'elles accomplissent ou participent à des actes illégaux ; (c) d'enfreindre les réglementations, règles, lois ou ordonnances locales, internationales, fédérales, provinciales ou étatiques ; (d) pour enfreindre ou violer nos droits de propriété intellectuelle ou les droits de propriété intellectuelle d'autrui ; (e) pour harceler, abuser, insulter, blesser, diffamer, calomnier, dénigrer, intimider ou discriminer sur la base du sexe, de l'orientation sexuelle, de la religion, de l'origine ethnique, de la race, de l'âge, de l'origine nationale ou du handicap ; (f) pour soumettre des informations fausses ou trompeuses ; (g) pour télécharger ou transmettre des virus ou tout autre type de code malveillant qui sera ou pourra être utilisé d'une manière qui affectera la fonctionnalité ou le fonctionnement du Service ou de tout site Web connexe, d'autres sites Web ou d'Internet ; (h) pour collecter ou suivre les informations personnelles d'autrui ; (i) pour envoyer du spam, du phishing, du pharm, du prétexte, du spider, du crawl ou du scraping ; (j) à des fins obscènes ou immorales ; ou (k) pour interférer avec ou contourner les fonctionnalités de sécurité du Service ou de tout site Web connexe, d'autres sites Web ou d'Internet. Nous nous réservons le droit de mettre fin à votre utilisation du Service ou de tout site Web connexe pour violation de l'une des utilisations interdites.",
  termCont44: "Exclusion de garanties; Limitation de responsabilité",
  termCont45: "Nous ne garantissons pas, ne représentons ni ne garantissons que votre utilisation de notre service sera ininterrompue, opportune, sécurisée ou sans erreur.",
  termCont46: "Nous ne garantissons pas que les résultats pouvant être obtenus à partir de l'utilisation du service seront exacts ou fiables.",
  termCont47: "Vous acceptez que de temps à autre, nous puissions supprimer le service pour des périodes indéterminées ou annuler le service à tout moment, sans préavis.",
  termCont48: "Vous convenez expressément que votre utilisation ou votre incapacité à utiliser le service est à vos risques et périls. Le service et tous les produits et services qui vous sont fournis par le biais du service sont (sauf mention expresse de notre part) fournis « tels quels » et « tels que disponibles » pour votre usage, sans aucune représentation, garantie ou condition de quelque nature que ce soit, expresse ou implicite, y compris toutes les garanties ou conditions implicites de qualité marchande, de qualité marchande, d'adéquation à un usage particulier, de durabilité, de titre et de non-contrefaçon.",
  termCont49: "En aucun cas, DIY FASHION LIMITED, nos administrateurs, dirigeants, employés, sociétés affiliées, agents, sous-traitants, stagiaires, fournisseurs, prestataires de services ou concédants de licence ne seront responsables de toute blessure, perte, réclamation ou de tout dommage direct, indirect, accessoire, punitif, spécial. , ou dommages indirects de toute nature, y compris, sans s'y limiter, la perte de profits, la perte de revenus, la perte d'économies, la perte de données, les coûts de remplacement ou tout dommage similaire, qu'il soit fondé sur un contrat, un délit (y compris la négligence), une responsabilité stricte ou autre, découlant de votre utilisation de l'un des services ou de tout produit acheté à l'aide du service, ou pour toute autre réclamation liée de quelque manière que ce soit à votre utilisation du service ou de tout produit, y compris, mais sans s'y limiter, toute erreur ou omission dans tout contenu, ou toute perte ou dommage de quelque nature que ce soit résultant de l'utilisation du service ou de tout contenu (ou produit) publié, transmis ou autrement mis à disposition via le service, même s'il est informé de leur possibilité. Étant donné que certains États ou juridictions n'autorisent pas l'exclusion ou la limitation de responsabilité pour les dommages indirects ou accessoires, dans ces États ou juridictions, notre responsabilité sera limitée dans la mesure maximale autorisée par la loi.",
  termCont50: "Indemnité",
  termCont51: "Vous acceptez d'indemniser, de défendre et d'exonérer DIY FASHION LIMITED et notre société mère, nos filiales, sociétés affiliées, partenaires, dirigeants, administrateurs, agents, entrepreneurs, concédants, prestataires de services, sous-traitants, fournisseurs, stagiaires et employés, à l'abri de toute réclamation ou demande, y compris les honoraires raisonnables d'avocat, effectués par tout tiers en raison de ou découlant de votre violation de ces Conditions d'utilisation ou des documents auxquels ils font référence, ou de votre violation de toute loi ou des droits d'un tiers.",
  termCont52: "Divisibilité",
  termCont53: "Dans le cas où une disposition de ces Conditions d'utilisation est jugée illégale, nulle ou inapplicable, cette disposition sera néanmoins exécutoire dans toute la mesure permise par la loi applicable, et la partie inapplicable sera réputée séparée de ces Conditions d'utilisation. Service, une telle détermination n'affectera pas la validité et la force exécutoire de toute autre disposition restante.",
  termCont54: "Résiliation",
  termCont55: "Les obligations et responsabilités des parties contractées avant la date de résiliation survivront à la résiliation du présent accord à toutes fins utiles.",
  termCont56: "Ces conditions d'utilisation sont en vigueur à moins et jusqu'à ce qu'elles soient résiliées par vous ou par nous. Vous pouvez résilier ces conditions d'utilisation à tout moment en nous informant que vous ne souhaitez plus utiliser nos services ou lorsque vous cessez d'utiliser notre site.",
  termCont57: "Si, à notre seul jugement, vous ne respectez pas, ou nous soupçonnons que vous avez omis, de vous conformer à toute condition ou disposition des présentes conditions d'utilisation, nous pouvons également résilier cet accord à tout moment sans préavis et vous resterez responsable de tous les montants dus et y compris la date de résiliation ; et/ou en conséquence peut vous refuser l'accès à nos Services (ou à une partie de ceux-ci).",
  termCont58: "Non-dénigrement. Pendant la Durée et par la suite, vous vous engagez à ne prendre aucune mesure qui vise, ou dont on pourrait raisonnablement s'attendre à ce qu'elle nuise à la Société ou à sa réputation ou dont on pourrait raisonnablement s'attendre à ce qu'elle conduise à une publicité indésirable ou défavorable à la Société au cas où vous n'a pas eu de nouvelles de nous depuis au moins 4 jours ouvrés. En cas de violation de ce terme, nous nous réservons le droit de résilier le contrat sans aucune indemnité.",
  termCont59: "Accord complet",
  termCont60: "L'incapacité de notre part à exercer ou à appliquer tout droit ou disposition des présentes Conditions d'utilisation ne constitue pas une renonciation à ce droit ou à cette disposition.",
  termCont61: "Ces conditions d'utilisation et toutes les politiques ou règles de fonctionnement publiées par nous sur ce site ou en ce qui concerne le service constituent l'intégralité de l'accord et de la compréhension entre vous et nous et régissent votre utilisation du service, remplaçant tout accord, communication et proposition antérieurs ou contemporains. , qu'ils soient oraux ou écrits, entre vous et nous (y compris, mais sans s'y limiter, toute version antérieure des Conditions d'utilisation).",
  termCont62: "Toute ambiguïté dans l'interprétation des présentes conditions d'utilisation ne doit pas être interprétée contre la partie rédactrice.",
  termCont63: "Modifications des conditions d'utilisation",
  termCont64: "Vous pouvez consulter la version la plus récente des conditions d'utilisation à tout moment sur cette page.",
  termCont65: "Nous nous réservons le droit, à notre seule discrétion, de mettre à jour, de modifier ou de remplacer toute partie de ces conditions d'utilisation en publiant des mises à jour et des modifications sur notre site Web.",
  termCont66: "Il est de votre responsabilité de vérifier périodiquement notre site Web pour les changements. Votre utilisation continue ou votre accès à notre site Web ou au Service après la publication de toute modification des présentes Conditions d'utilisation constitue une acceptation de ces modifications.",
  termCont67: "Des questions sur les conditions ?",
  //privacyPolicy
  privacyCont1: `Ce site Web est fonctionné par DIY FASHION LIMITED et/ou ses entités affiliées, filiale britannique HINOVATION LTD (collectivement dénommées "DIY FASHION LIMITED ", " nous " ou " notre " également connu sous le nom de stacees.com. Nous nous engageons à protéger et à respecter la confidentialité de vos données personnelles. Cet avis de confidentialité explique comment vos données sont collectées, utilisées, transférées et divulguées par STACEES. Il s'applique aux données collectées lorsque vous utilisez nos sites Web, lorsque vous interagissez avec nous via les réseaux sociaux. médias, e-mail ou téléphone, ou lorsque vous participez à nos concours ou événements.Il comprend:`,
  privacyCont2: 'Les données personnelles que nous collectons',
  privacyCont3: 'Comment nous collectons vos données',
  privacyCont4: 'Comment nous utilisons vos données',
  privacyCont5: 'Préférences marketing, publicités et cookies',
  privacyCont6: "Liens vers d'autres sites Web et tiers",
  privacyCont7: 'Comment nous partageons vos données',
  privacyCont8: 'Vos droits',
  privacyCont9: 'Modifications de cet avis de confidentialité',
  privacyCont10: 'Comment nous contacter',
  privacyCont11: 'Qui est STACEES',
  privacyCont12: 'STACEES est une entreprise de vente au détail de mode en ligne de premier plan. Nous concevons, produisons, commercialisons et vendons des vêtements destinés aux consommatrices dans presque tous les pays du monde.',
  privacyCont13: 'STACEES (collectivement dénommés « STACEES », « nous » et « notre » dans le présent avis de confidentialité) est le contrôleur et responsable de vos données personnelles collectées via le site Web www.stacees.com (le « site Web ”).',
  privacyCont14: 'Les coordonnées de notre délégué à la protection des données chargé de superviser les questions relatives à cet avis de confidentialité, ainsi que nos coordonnées, sont indiquées dans la section « Comment nous contacter » à la fin de cet avis.',
  privacyCont15: 'Notre engagement envers vous',
  privacyCont16: 'Nous prenons la protection de vos données personnelles au sérieux et traiterons vos données personnelles de manière loyale, licite et transparente. Cet avis de confidentialité décrit les données personnelles que nous collectons à votre sujet et comment elles sont utilisées.',
  privacyCont17: "Nous ne collecterons et n'utiliserons vos données personnelles qu'aux fins suivantes :",
  privacyCont18: 'exécuter votre/vos commande(s)',
  privacyCont19: 'vous tenir au courant des dernières offres et tendances',
  privacyCont20: "vous offrir une meilleure expérience d'achat",
  privacyCont21: 'nous aider à rendre notre marketing plus pertinent pour vous et vos intérêts',
  privacyCont22: 'améliorer nos services',
  privacyCont23: 'répondre à nos responsabilités légales',
  privacyCont24: 'Comment nous protégeons vos données',
  privacyCont25: 'Nous avons mis en place des sauvegardes organisationnelles et des mesures de sécurité appropriées pour empêcher que vos données ne soient accidentellement perdues, utilisées ou consultées de manière non autorisée, modifiées ou divulguées.',
  privacyCont26: 'La communication entre votre navigateur et notre site Web utilise une connexion cryptée sécurisée partout où vos données personnelles sont impliquées. Nous chiffrons vos données et les stockons sur des serveurs sécurisés cachés derrière un pare-feu.',
  privacyCont27: "Nous exigeons de tout tiers engagé pour traiter vos données personnelles en notre nom qu'il mette en place des mesures de sécurité pour protéger vos données et traiter ces données conformément à la loi.",
  privacyCont28: "Dans le cas malheureux d'une violation de données personnelles, nous vous informerons, ainsi que tout organisme de réglementation applicable, lorsque nous serons légalement tenus de le faire.",
  privacyCont30: "Les données personnelles désignent toute information sur un individu à partir de laquelle cette personne peut être identifiée. Il n'inclut pas les données anonymisées, où l'identité et les informations d'identification ont été supprimées.",
  privacyCont31: "Bien que notre site Web soit conçu pour un public général, nous ne collecterons sciemment aucune donnée d'enfants de moins de 13 ans ni ne vendrons de produits à des enfants. Si vous avez moins de 13 ans, vous n'êtes pas autorisé à utiliser ou à soumettre vos données sur le site Web.",
  privacyCont32: 'Les groupes de données personnelles suivants sont collectés :',
  privacyCont33: "<strong>Les données d'identité</strong> comprennent des informations telles que : prénom, nom, civilité, date de naissance (facultatif), description personnelle (facultatif), photo (facultatif) et sexe (facultatif).",
  privacyCont34: "<strong>Les données de contact</strong> comprennent des informations telles que : l'adresse e-mail, l'adresse de facturation, l'adresse de livraison, le lieu, le pays, le numéro de téléphone, le numéro de membre du programme de fidélité, l'identifiant Amazon (si vous vous connectez avec Amazon) et l'identifiant du réseau social (si vous vous connectez par médias).",
  privacyCont35: '<strong>Les données financières</strong> incluent des informations telles que : les détails de la carte de paiement ou les informations du compte PayPal.',
  privacyCont36: "<strong>Les données de transaction</strong> incluent des informations telles que : les détails de vos achats et l'exécution de vos commandes (telles que le numéro de panier, le numéro de commande, le sous-total, le titre, la devise, les remises, l'expédition, le nombre d'articles, le numéro de produit, le prix de l'article unique, la catégorie, taxe, etc.) ; les paiements vers et de votre part et les détails des autres produits et services que vous avez obtenus de notre part, la correspondance ou les communications avec vous concernant vos commandes, et les détails de toutes les récompenses et bonus accordés.",
  privacyCont37: "<strong>Les données techniques</strong> comprennent des informations telles que : les détails du ou des appareils que vous utilisez pour accéder à nos services, votre adresse IP (protocole Internet), vos données de connexion, votre nom d'utilisateur et votre mot de passe, le type et la version du navigateur, le réglage et l'emplacement du fuseau horaire, le navigateur types et versions de plug-in, système d'exploitation et plate-forme.",
  privacyCont38: '<strong>Les données de profil</strong> incluent des informations telles que : les achats ou les commandes que vous avez effectués, les intérêts en matière de produits et de style, les préférences, les commentaires et les réponses aux sondages.',
  privacyCont39: "<strong>Les données d'utilisation</strong> incluent des informations telles que : comment et quand vous utilisez notre site Web/application, comment vous vous y êtes déplacé, ce que vous avez recherché ; statistiques de performances du site Web/de l'application, trafic, emplacement, blogs et autres données de communication ; les activités du programme de fidélité ; et les détails de tous les autres produits et services STACEES que vous utilisez.",
  privacyCont40: '<strong>Les données de marketing et de communication</strong> incluent des informations telles que : vos préférences en matière de marketing de notre part et de nos tiers et vos préférences de communication.',
  privacyCont41: "Nous recueillons, utilisons et partageons également des <strong>données agrégées</strong> telles que des données statistiques ou démographiques à quelque fin que ce soit. Les données agrégées peuvent être dérivées de vos données personnelles mais ne sont pas considérées comme des données personnelles car ces données ne révèlent pas directement ou indirectement votre identité. Par exemple, nous pouvons agréger vos données d'utilisation pour calculer le pourcentage d'utilisateurs accédant à une fonctionnalité spécifique du site Web. Toutefois, si nous combinons ou connectons des données agrégées à vos données personnelles afin qu'elles puissent vous identifier directement ou indirectement, nous traitons les données combinées comme des données personnelles qui seront utilisées conformément à la présente politique de confidentialité.",
  privacyCont43: 'Nous pouvons collecter des données personnelles vous concernant des manières suivantes :',
  privacyCont44: "<strong>Interactions directes</strong> – vous pouvez nous fournir vos données d'identité, de contact, financières, de transaction, de profil et de marketing et de communication (comme décrit ci-dessus) en remplissant des formulaires, en saisissant des informations en ligne ou en correspondant avec nous par courrier, téléphone, e-mail, téléphone ou autrement. Cela inclut les données personnelles que vous fournissez, par exemple, lorsque vous :",
  privacyCont45: 'Créer un compte ou acheter des produits sur notre site Internet;',
  privacyCont46: 'Abonnez-vous à notre newsletter, forums de discussion, sites de médias sociaux ou créez des listes de souhaits;',
  privacyCont47: 'Participer à un concours;',
  privacyCont48: 'Rejoignez un programme de fidélité STACEES ;',
  privacyCont49: 'Réaliser une étude de marché volontaire ;',
  privacyCont50: 'Contactez-nous pour une demande de renseignements ou pour signaler un problème (par téléphone, e-mail, réseaux sociaux ou service de messagerie) ;',
  privacyCont51: 'Utiliser la fonction « parrainer un ami » sur notre site Internet ; ou',
  privacyCont52: 'Lorsque vous vous connectez à notre site Web via les médias sociaux.',
  privacyCont53: "<strong>Technologies ou interactions automatisées</strong> – lorsque vous interagissez avec notre site Web, nous pouvons collecter automatiquement les types de données suivants (tous comme décrit ci-dessus) : données techniques sur votre équipement, données d'utilisation sur vos actions et habitudes de navigation, et données de contact où les tâches sont effectuées via notre site Web restent inachevés, tels que des commandes incomplètes ou des paniers abandonnés.Nous collectons ces données en utilisant des cookies, des journaux de serveur et d'autres technologies similaires.",
  privacyCont54: '<strong>Tiers</strong> – nous pouvons recevoir des données personnelles vous concernant de divers tiers, notamment :',
  privacyCont55: "Données techniques provenant de tiers, y compris des fournisseurs d'analyses tels que Google Veuillez consulter la section intitulée « Préférences marketing, publicités et cookies » pour plus d'informations.",
  privacyCont56: "Données techniques des réseaux affiliés par l'intermédiaire desquels vous avez accédé à notre site Internet ;",
  privacyCont57: "Données d'identité et de contact des plateformes de médias sociaux lorsque vous vous connectez à notre site Web en utilisant ces plateformes de médias sociaux ;",
  privacyCont58: 'Données de contact, financières et de transaction des fournisseurs de services techniques, de paiement et de livraison.',
  privacyCont60: 'La base juridique du traitement de vos données personnelles',
  privacyCont61: "Nous ne collecterons et ne traiterons vos données personnelles que si nous disposons d'une base légale pour le faire.En tant que responsable du traitement, la base juridique de notre collecte et de notre utilisation de vos données personnelles varie en fonction de la manière et de la finalité pour lesquelles nous les avons collectées.",
  privacyCont62: 'Nous ne collecterons vos données personnelles que lorsque :',
  privacyCont63: 'nous avons votre consentement pour le faire, ou',
  privacyCont64: 'nous avons besoin de vos données personnelles pour exécuter un contrat avec vous, par exemple pour traiter un paiement de votre part, exécuter votre commande ou fournir une assistance client liée à une commande, ou',
  privacyCont65: "le traitement est dans notre intérêt légitime et n'est pas supplanté par vos droits, ou",
  privacyCont66: "nous avons l'obligation légale de collecter ou de divulguer vos données personnelles.",
  privacyCont67: 'Utilisations faites de vos données personnelles',
  privacyCont68: "Vos données personnelles sont utilisées par STACEES pour soutenir une gamme d'activités différentes. Celles-ci sont répertoriées dans le tableau ci-dessous avec les types de données utilisées et les bases juridiques sur lesquelles nous nous appuyons pour les traiter, y compris, le cas échéant, nos intérêts légitimes. conscients que nous pouvons traiter vos données personnelles en utilisant plus d'une base légale, en fonction de l'activité spécifique en jeu. Veuillez nous contacter si vous avez besoin de détails sur le fondement juridique spécifique sur lequel nous nous appuyons pour traiter vos données personnelles lorsque plus d'un motif a été énoncées dans le tableau ci-dessous.",
  privacyCont69: 'Objectif/Activité',
  privacyCont70: 'Type de données',
  privacyCont71: "Base légale du traitement, y compris la base de l'intérêt légitime",
  privacyCont72: 'Pour créer un compte et vous enregistrer en tant que nouveau client (soit directement, soit via les réseaux sociaux).',
  privacyCont73: '• Identité',
  privacyCont74: '• Contact',
  privacyCont75: "• Exécution d'un contrat avec vous",
  privacyCont76: "Pour traiter et livrer votre commande, y compris : enregistrer les détails de votre commande ; vous tenir informé de l'état de la commande ; traiter les paiements et les remboursements, percevoir l'argent qui nous est dû ; et aider à prévenir et détecter la fraude.",
  privacyCont77: '• Financier',
  privacyCont78: '• Opération',
  privacyCont79: '• Nécessaire à nos intérêts légitimes (par exemple pour recouvrer des dettes qui nous sont dues)',
  privacyCont80: "Pour gérer notre relation avec vous, y compris : vous fournir les informations, produits et services que vous nous demandez ; vous informer des modifications apportées à nos services, conditions générales ou avis de confidentialité ; vous demander de laisser un avis ou de répondre à un sondage.",
  privacyCont81: '• Profil',
  privacyCont82: '• Marketing et communication',
  privacyCont83: '• Nécessaire à nos intérêts légitimes (pour tenir nos dossiers à jour et étudier comment les clients utilisent nos produits et services',
  privacyCont84: 'Pour vous permettre de participer à un concours, un événement, un sondage ou de recevoir une récompense pour vos achats chez nous.',
  privacyCont85: '• Utilisation',
  privacyCont86: "• Nécessaire à nos intérêts légitimes (pour étudier comment les clients utilisent nos produits et services, pour les développer et développer notre activité)",
  privacyCont87: "• Lorsque vous avez décidé de participer à un concours ou à un événement, pour l'exécution d'un contrat avec vous",
  privacyCont88: "Pour administrer, protéger et améliorer notre entreprise et notre site Web/application, y compris : le dépannage, l'analyse des données, les tests, la maintenance du système, l'assistance, l'analyse des données, la création de rapports et l'hébergement des données ; la définition d'options par défaut pour vous, telles que la langue et la devise. ",
  privacyCont89: '• Technique',
  privacyCont90: '• Nécessaire à nos intérêts légitimes (pour la gestion de notre entreprise, la fourniture de services administratifs et informatiques, la sécurité du réseau, et pour détecter et prévenir la fraude)',
  privacyCont91: '• Nécessaire pour se conformer à une obligation légale',
  privacyCont92: "Pour vous fournir un contenu de site Web, des publicités en ligne et des informations pertinents ; et mesurer l'efficacité de la publicité fournie.",
  privacyCont93: '• Nécessaire à nos intérêts légitimes (pour étudier comment les clients utilisent nos produits et services, pour les développer, pour développer notre activité et pour éclairer notre stratégie marketing)',
  privacyCont94: "Pour utiliser l'analyse de données pour : améliorer notre site Web, nos produits, nos services, notre marketing, nos relations avec nos clients et nos expériences ;",
  privacyCont29: "et à des fins d'études de marché, de statistiques et d'enquêtes.",
  privacyCont95: '• Nécessaire à nos intérêts légitimes (pour définir les types de clients pour nos produits et services, pour maintenir notre site Web à jour et pertinent, pour développer notre activité et pour éclairer notre stratégie marketing)',
  privacyCont96: 'Pour vous recommander des produits, des remises sur les services et des offres susceptibles de vous intéresser, y compris pour vous envoyer ces informations par e-mail, courrier ou SMS.',
  privacyCont97: '• Nécessaire pour nos intérêts légitimes (pour développer nos produits et services et développer notre activité) ou Consentement.',
  privacyCont98: 'Voir plus de détails dans la section "Préférences marketing, publicités et cookies"',
  privacyCont99: 'De vous informer ou de vous rappeler par email toute tâche effectuée via notre site internet et restée inachevée, telle que des commandes incomplètes ou des paniers abandonnés.',
  privacyCont100: "• Nécessaire à nos intérêts légitimes (pour améliorer l'expérience d'achat de nos clients)",
  privacyCont101: "Nous n'utiliserons vos données personnelles qu'aux fins pour lesquelles nous les avons collectées, sauf si nous estimons raisonnablement que nous devons les utiliser pour une autre raison et que cette raison est compatible avec la finalité initiale. fin, nous vous en informerons et nous vous expliquerons la base juridique qui nous permet de le faire.Nous pouvons traiter des données personnelles sans votre consentement, conformément aux règles ci-dessus, lorsque cela est requis ou autorisé par la loi.",
  privacyCont102: 'Si vous avez des questions sur la manière dont STACEES utilise vos données personnelles, veuillez nous contacter à service@stacees.com ou discuter avec nous.',
  privacyCont103: 'Combien de temps conservons-nous vos données',
  privacyCont104: 'Nous ne conserverons pas vos données personnelles plus longtemps que nécessaire aux fins pour lesquelles elles ont été fournies et pour répondre à nos obligations légales.De plus amples détails sur les périodes pendant lesquelles nous conservons les données sont disponibles sur demande.',
  privacyCont106: 'Marketing - vos préférences',
  privacyCont107: 'Nous pouvons vous envoyer des communications marketing et des offres promotionnelles :',
  privacyCont108: "si vous avez ouvert un compte chez nous ou acheté des biens chez nous, ou vous êtes inscrit à une promotion ou à un événement, et que vous n'avez pas refusé de recevoir ce marketing (conformément à vos préférences, comme expliqué ci-dessous) ;",
  privacyCont109: 'par e-mail si vous êtes abonné aux newsletters par e-mail ;',
  privacyCont110: 'si vous nous avez fourni vos coordonnées lorsque vous avez participé à un concours et que vous avez consenti à recevoir un tel marketing (conformément à vos préférences, comme expliqué ci-dessous).',
  privacyCont111: "Nous pouvons utiliser vos données d'identité, de contact, techniques, transactionnelles, d'utilisation, de profil et de marketing et de communication pour avoir une idée de ce que nous pensons que vous pourriez aimer ou de ce qui pourrait vous intéresser, et pour vous envoyer des détails sur les produits et offres susceptibles de vous intéresser.",
  privacyCont112: 'Nous vous demanderons vos préférences concernant la réception de communications marketing par e-mail, courrier, SMS et autres canaux de communication.',
  privacyCont113: "De temps à autre, nous pouvons également inclure dans votre commande des encarts publicitaires de biens, de services ou d'offres d'autres sociétés tierces susceptibles de vous intéresser.",
  privacyCont114: 'En ce qui concerne les communications marketing de tiers, nous obtiendrons votre consentement exprès avant de partager vos données personnelles avec un tiers à des fins de marketing.',
  privacyCont115: "Vous aurez toujours le plein contrôle de vos préférences marketing. Si vous ne souhaitez plus recevoir d'informations marketing de notre part (ou de tout tiers, le cas échéant) à tout moment :",
  privacyCont116: 'vous pouvez vous désabonner ou vous désabonner en utilisant le bouton de désabonnement et en suivant le lien inclus dans le pied de page de tout e-mail marketing ; ou <a class="font_red" href="{hrefLogin}">les titulaires de compte peuvent retirer leur consentement en se connectant simplement à Mon compte et en modifiant vos « Préférences de contact ».</a>',
  privacyCont117: "Nous traiterons toutes les demandes de désinscription dès que possible, mais veuillez noter qu'en raison de la nature de nos systèmes informatiques et de nos serveurs, la mise en œuvre de toute demande de désinscription peut prendre quelques jours.",
  privacyCont118: 'Cookies',
  privacyCont119: 'Notre site Web utilise des cookies pour vous distinguer des autres utilisateurs de notre site Web et pour garder une trace de vos visites. Ils nous aident à vous offrir la meilleure expérience lorsque vous naviguez sur notre site Web et à apporter des améliorations à notre site Web. Ils nous aident également, ainsi que nos réseaux publicitaires, à rendre la publicité pertinente pour vous et vos intérêts.',
  privacyCont120: "Vous pouvez configurer votre navigateur pour qu'il refuse tout ou partie des cookies du navigateur, ou pour qu'il vous avertisse lorsque des sites Web installent ou accèdent à des cookies. Si vous désactivez ou refusez les cookies, veuillez noter que certaines parties de notre site Web peuvent devenir inaccessibles ou ne pas fonctionner correctement.",
  privacyCont121: 'Annonces en ligne',
  privacyCont122: "Nous utilisons la publicité en ligne pour vous tenir au courant de ce que nous faisons et pour vous aider à trouver nos produits. Comme de nombreuses entreprises, nous pouvons vous cibler avec des bannières et des publicités STACEES lorsque vous utilisez d'autres sites Web et applications, en fonction de vos données de contact, techniques, d'utilisation et de profil. Pour ce faire, nous utilisons une variété de réseaux de marketing numérique et d'échanges d'annonces, et une gamme de technologies publicitaires telles que les balises Web, les pixels, les balises publicitaires, les cookies et les identifiants mobiles, ainsi que des services spécifiques offerts par certains sites et réseaux sociaux, tels que en tant que service d'audience personnalisé de Facebook.",
  privacyCont123: "Notre utilisation d'outils d'analyse et de publicité ciblée",
  privacyCont124: "Nous utilisons une gamme d'outils d'analyse et de publicité ciblée pour vous afficher du contenu de site Web pertinent sur notre site Web et des publicités en ligne sur d'autres sites Web et applications (comme décrit ci-dessus), vous fournir un contenu pertinent dans les communications marketing (le cas échéant) et mesurer l'efficacité de la publicité fournie. Par exemple, nous utilisons des outils tels que Google Analytics pour analyser les données publicitaires basées sur les intérêts de Google et/ou les données d'audience de tiers (telles que l'âge, l'état civil, l'événement de la vie, le sexe et les intérêts) pour cibler et améliorer nos campagnes marketing, marketing stratégies et contenu du site Web. Nous pouvons également utiliser des outils fournis par d'autres tiers, tels que Facebook, Adroll, Springbot, Criteo et Bing pour effectuer des tâches similaires, en utilisant vos données de contact, techniques, d'utilisation et de profil.",
  privacyCont125: 'Pour vous désinscrire de la publicité ciblée, vous devez désactiver vos « cookies » dans les paramètres de votre navigateur (voir la politique relative aux cookies pour plus de détails) ou vous désinscrire des paramètres publicitaires tiers concernés. Par exemple, vous pouvez désactiver les fonctionnalités de publicité display de Google.',
  privacyCont126: 'La Digital Advertising Alliance (qui comprend des sociétés telles que Google, Responsys et Facebook) fournit un outil appelé WebChoices qui peut effectuer une analyse rapide de votre ordinateur ou de vos appareils mobiles, découvrir quelles sociétés participantes ont activé les publicités personnalisées pour votre navigateur et ajuster votre préférences du navigateur en conséquence.',
  privacyCont127: 'Si vous souhaitez obtenir de plus amples informations sur les données collectées par ces tiers ou sur la manière dont les données sont utilisées, veuillez nous contacter.',
  privacyCont129: "Notre site Web peut inclure des liens vers et depuis les sites Web de nos réseaux partenaires, annonceurs et affiliés, ou vers des plateformes de médias sociaux. Si vous suivez un lien vers l'un de ces sites Web, veuillez noter que ces sites Web ont leurs propres politiques de confidentialité et que nous n'acceptons aucune responsabilité pour ces politiques. Veuillez vérifier ces politiques avant de soumettre des données personnelles sur leurs sites Web.",
  privacyCont131: 'Nous pouvons divulguer et partager vos données personnelles avec les parties indiquées ci-dessous :',
  privacyCont132: 'où vous avez consenti à ce que nous le fassions. Par exemple, si vous avez consenti à recevoir des supports marketing de tiers, ou en ce qui concerne les produits et services de tiers (y compris les produits et services comarqués ou promus conjointement), nous pouvons transmettre vos données aux tiers concernés dans le but de vous envoyer de telles communications marketing ;aux partenaires commerciaux, fournisseurs, sous-traitants et autres tiers que nous utilisons dans le cadre de la gestion de notre entreprise aux fins décrites dans le tableau ci-dessus dans la section « Comment utilisons-nous vos données », tels que :',
  privacyCont133: 'les prestataires de services tiers que nous engageons pour fournir des systèmes informatiques et des logiciels, et pour héberger notre site Web ;',
  privacyCont134: 'des services tiers de traitement des paiements (y compris Authorize.net, PayPal, Braintree et Card Connect) pour traiter votre paiement. Nous ne stockons pas les informations de votre carte de crédit/débit ;',
  privacyCont135: 'les prestataires de services tiers que nous engageons pour livrer les marchandises que vous avez commandées ;',
  privacyCont136: 'les prestataires de services tiers que nous engageons pour envoyer des e-mails et du courrier postal en notre nom, y compris en relation avec des commandes incomplètes ou des paniers abandonnés, ou des communications marketing, pour fournir des services de nettoyage des données et pour fournir des services marketing et publicitaires ;',
  privacyCont137: "les fournisseurs d'analyses et de moteurs de recherche qui nous assistent dans l'amélioration et l'optimisation de notre site Web ;",
  privacyCont138: "les réseaux affiliés par l'intermédiaire desquels vous avez accédé à notre site Internet ;",
  privacyCont139: "à tout tiers à qui nous pourrions choisir de vendre, transférer ou fusionner des parties de notre entreprise ou de nos actifs. Alternativement, nous pouvons chercher à acquérir d'autres entreprises ou à fusionner avec elles. Si un changement se produit dans notre entreprise, les nouveaux propriétaires peuvent utiliser vos données personnelles de la même manière que celle indiquée dans le présent avis de confidentialité.",
  privacyCont140: "pour protéger nos clients et notre site Web contre la fraude et le vol, nous pouvons partager les données personnelles nécessaires pour effectuer des contrôles d'identité et les données personnelles que nous obtenons en effectuant des contrôles d'identité (y compris des données relatives à votre âge, votre nom et votre emplacement), ainsi que des informations de compte , avec des organisations (y compris des organismes chargés de l'application de la loi) impliquées dans la prévention et la détection de la fraude et la réduction du risque de crédit. Veuillez noter que ces tiers peuvent conserver un enregistrement des informations que nous leur fournissons à cette fin ;",
  privacyCont141: "si nous sommes dans l'obligation de divulguer ou de partager vos données personnelles afin de nous conformer à toute obligation légale ; ou",
  privacyCont142: "à nos conseillers professionnels, notamment des avocats, des banquiers, des auditeurs et des assureurs qui fournissent des services de conseil, bancaires, juridiques, d'assurance et de comptabilité.",
  privacyCont143: 'Vos données et pays hors Europe',
  privacyCont144: "Les données personnelles que nous recueillons auprès de vous peuvent être transférées et stockées dans des destinations situées en dehors de European Economic Area (« EEA ») en utilisant des mécanismes prévus par la loi pour transférer légalement des données au-delà des frontières. Elles peuvent également être traitées par du personnel opérant en dehors de l'EEA qui travaille pour nous ou pour l'un de nos fournisseurs. Ce personnel peut être engagé, entre autres, dans l'exécution de votre commande, le traitement de vos informations de paiement et la fourniture de services d'assistance. Nous prendrons toutes les mesures nécessaires pour nous assurer que vos données sont traitées en toute sécurité et conformément à la présent Avis de Confidentialité .",
  privacyCont145: "Chaque fois que nous transférons des données personnelles en dehors de l'EEA, nous veillerons à ce qu'un degré de protection similaire leur soit accordé en veillant à ce que des garanties appropriées, comme l'exige la loi, soient en place. Cela peut inclure l'utilisation de clauses contractuelles spécifiques approuvées par la Commission européenne qui confèrent aux données personnelles la même protection qu'en Europe. Plus d'informations à ce sujet sont disponibles ici http://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX:32010D0087",
  privacyCont146: "Veuillez nous contacter si vous souhaitez plus d'informations sur les pays vers lesquels nous pouvons transférer des données personnelles et le mécanisme spécifique que nous utilisons lors du transfert de vos données personnelles en dehors de l'EEA.",
  privacyCont148: 'Vous disposez de plusieurs droits en vertu de la législation sur la protection des données. Cela inclut, dans certaines circonstances, le droit de :',
  privacyCont155: "s'opposer à la prise de décision automatisée",
  privacyCont156: "De brefs détails sur chacun de ces droits sont présentés ci-dessous. Si vous souhaitez exercer l'un de ces droits, veuillez nous envoyer un e-mail à privacy@STACEES.com ou discuter avec nous.",
  privacyCont157: "Demander l'accès à vos données personnelles",
  privacyCont158: "Vous avez le droit d'obtenir une copie des données personnelles que nous détenons à votre sujet et de certaines informations relatives à notre traitement de vos données personnelles.",
  privacyCont159: 'Demander la correction de vos données personnelles',
  privacyCont160: 'Vous avez le droit de faire rectifier vos données personnelles si elles sont inexactes ou incomplètes. Vous pouvez mettre à jour vos données personnelles à tout moment en vous connectant à votre compte et en mettant à jour vos coordonnées directement, ou en nous envoyant un e-mail à privacy@STACEES.com ou en discutant avec nous.',
  privacyCont161: "Demander l'effacement de vos données personnelles",
  privacyCont162: "Cela vous permet de demander à STACEES de supprimer vos données personnelles, lorsqu'il n'y a aucune raison valable pour que nous continuions à les traiter. Notez toutefois que nous ne pourrons pas toujours donner suite à votre demande d'effacement pour des raisons juridiques particulières qui vous seront notifiées, le cas échéant, au moment de votre demande.",
  privacyCont163: 'Demander la limitation du traitement de vos données personnelles',
  privacyCont164: "Vous avez le droit de demander à STACEES de suspendre le traitement de vos données personnelles dans certains scénarios, par exemple si vous souhaitez que nous établissions l'exactitude des données, ou si vous vous êtes opposé à notre utilisation de vos données, mais nous devons vérifier si nous avons des motifs légitimes impérieux de les utiliser. Lorsque le traitement est restreint, nous sommes autorisés à conserver suffisamment d'informations vous concernant pour garantir que la restriction sera respectée à l'avenir.",
  privacyCont165: 'Demander le transfert de vos données personnelles',
  privacyCont166: "Vous avez le droit d'obtenir une copie numérique de vos données personnelles ou de demander le transfert de vos données personnelles à une autre société. Veuillez noter cependant que ce droit ne s'applique qu'aux données automatisées que vous avez initialement autorisées à utiliser ou lorsque nous avons utilisé les données pour exécuter un contrat avec vous.",
  privacyCont167: 'Vous opposer au traitement de vos données personnelles',
  privacyCont168: 'Vous avez le droit de vous opposer au traitement de vos données personnelles lorsque nous pensons avoir un intérêt légitime à les traiter (comme expliqué ci-dessus). Vous avez également le droit de vous opposer au traitement de vos données personnelles à des fins de marketing direct. Dans certains cas, nous pouvons démontrer que nous avons des motifs légitimes impérieux de traiter vos données qui prévalent sur vos droits et libertés.',
  privacyCont169: "S'opposer à la prise de décision et au profilage automatisés",
  privacyCont170: "Vous avez le droit de vous opposer au traitement automatisé de vos données personnelles sans intervention humaine. Cette forme de traitement est autorisée lorsqu'elle est nécessaire dans le cadre de notre contrat avec vous, à condition que des garanties appropriées soient en place ou que votre consentement explicite ait été obtenu.",
  privacyCont171: "Nous essaierons de répondre à toutes les demandes légitimes dans un délai d'un mois. Parfois, cela peut nous prendre plus d'un mois si votre demande est particulièrement complexe ou si vous avez formulé plusieurs demandes. Dans ce cas, nous vous en informerons et vous tiendrons au courant. Nous pouvons avoir besoin de vous demander des informations spécifiques pour nous aider à confirmer votre identité et garantir votre droit d'exercer l'un des droits ci-dessus. Il s'agit d'une mesure de sécurité visant à garantir que les données personnelles ne soient divulguées à aucune personne qui n'a pas le droit de les recevoir.",
  privacyCont172: 'Droit de porter plainte',
  privacyCont173: "Si vous avez des préoccupations ou des plaintes concernant la manière dont nous traitons vos données, veuillez nous envoyer un e-mail directement à service@stacees.com ou discuter avec nous. Vous avez également le droit de déposer une plainte auprès de l'ICO (le régulateur de la protection des données au Royaume-Uni). Cependant, nous apprécierions avoir la possibilité de répondre à vos préoccupations avant de vous adresser à l'ICO, veuillez donc nous contacter en premier lieu.",
  privacyCont175: "De temps à autre, nous pouvons modifier cet avis de confidentialité. S'il y a des changements importants, nous publierons des mises à jour sur notre site Web, nos applications ou nous vous en informerons par e-mail.",
  privacyCont176: "Nous apprécions vos commentaires et nous sommes heureux de répondre à toutes vos questions concernant vos données. Veuillez envoyer vos questions, commentaires ou demandes d'informations supplémentaires à service@stacees.com ou discuter avec nous. Cet avis de confidentialité a été mis à jour pour la dernière fois le 22 septembre 2022.",
  //mix-match-bridesmaid
  mixMatchTit: "Mélanger et assortir les robes de demoiselle d'honneur dans les couleurs et les styles",
  maxBrid: "COMBINAISON DE DEMOISELLES D'HONNEUR",
  maxBrid1: "COMMENT: MÉLANGER ET ASSORTIR LES ROBES DE DEMOISELLES D'HONNEUR",
  maxBrid2: "Lors du choix d'une tenue de fête de mariage, il peut sembler que toutes les personnes impliquées ont une opinion. Si vous espérez plaire à toute l'équipe (tout en ayant l'air super stylée), pensez aux robes de demoiselle d'honneur assorties. Lorsqu'elles sont exécutées correctement, les robes de demoiselle d'honneur assorties aideront votre équipe à se démarquer. Ici, nous couvrons tout ce que vous devez savoir sur la coordination parfaite des robes de demoiselle d'honneur assorties. De plus, nous avons inclus un guide d'achat présentant les couleurs les plus populaires.",
  maxBrid3: "ACHETER ROBES DE DEMOISELLE D'HONNEUR",
  maxBrid4: 'STYLE DIFFÉRENT (MÊME COULEUR !)',
  maxBrid5: "Le type le plus courant de soirée nuptiale mix and match comprend différents styles vestimentaires mais de la même couleur. Il est parfait lorsque vous tombez amoureux d'une couleur de mariage mais que vous voulez le look assorti populaire. Cette option est la plus facile à coordonner et permettra à vos demoiselles d'honneur d'avoir le choix. Permettre à vos demoiselles d'honneur de choisir leur propre style leur permettra de se sentir plus à l'aise et en confiance. Après tout, différents styles flattent différents types de corps !",
  maxBrid6: 'ACHETER PAR COULEUR',
  maxBrid7: 'COULEUR DIFFÉRENTE (MÊME STYLE !)',
  maxBrid8: "Lorsque vous ne pouvez pas choisir une seule couleur, pourquoi ne pas en proposer plusieurs ? Cette option est plus facile à coordonner qu'un mélange de styles et de couleurs de vêtements différents, car il n'y a aucun risque que les designs ne se heurtent. Choisissez simplement un style vestimentaire qui flattera toutes les bonnes de votre entourage. Habituellement, une silhouette trapèze fonctionne mieux. À partir de là, précisez les couleurs qu'ils peuvent porter, mais assurez-vous qu'il y a une répartition uniforme ! Vous ne voulez pas que toutes les personnes sauf une correspondent. Nous recommandons à la mariée de spécifier la couleur que chaque personne de la fête doit porter.",
  maxBrid9: 'ACHETER CE STYLE',
  maxBrid10: 'ROBES DE DIFFÉRENTES COULEURS ET STYLE',
  maxBrid11: "Vous voulez donc que vos filles portent des robes de demoiselle d'honneur de différents styles et couleurs. Pas de soucis! Bien que cette option nécessite le plus de travail, elle donnera également le look le plus unique. Tout d'abord, vous devrez déterminer les couleurs que vous prévoyez d'utiliser. Nous recommandons 3 à 4, et veillons à ce qu'ils se complètent. Par exemple, mélangez les nuances et les teintes d'une même couleur. Comme le bleu, le rose ou le violet. Vient ensuite la sélection du style de robes. Bien que vous souhaitiez que les robes flattent chacune de vos filles, chaque robe doit également se compléter. Alors, visez des silhouettes, des tissus ou des détails assortis. Sinon, vous vous retrouverez avec une programmation qui semble trop occupée ou chaotique. Le mieux est de présenter des silhouettes, des tailles, des décolletés ou des bretelles similaires.",
  maxBrid12: "CONSEILS POUR COORDONNER LES ROBES DE DEMOISELLE D'HONNEUR ASSORTIES",
  maxBrid13: "Même lorsque votre objectif est une fête de mariage assortie, vous voulez avoir l'air réfléchi, pas jeté ensemble. Suivez ces conseils pour que vos demoiselle d'honneur assorties paraissent intentionnelles et bien planifiées.",
  maxBrid14: "Attachez vos robes de demoiselle d'honneur avec un détail commun",
  maxBrid15: "Choisir des robes qui présentent des détails communs est l'un des meilleurs moyens d'assurer un look cohérent. Il peut s'agir de tissus assortis (mousseline, tulle, velours), d'encolures similaires, de couleurs assorties, de silhouettes similaires, etc. Vous avez besoin d'au moins un ou deux détails qui correspondent, sinon vous risquez une ambiance chaotique qui fait mal aux yeux.",
  maxBrid16: "Nous recommandons toujours de s'en tenir à la même longueur de robe. Mélanger des robes courtes et longues fonctionne rarement ! Surtout si l'on considère que chaque longueur donne une ambiance différente. Les robes longues sont souvent considérées comme plus formelles, tandis que les robes courtes sont un peu plus décontractées. Vous ne voulez pas que vos filles aient l'air confuses à propos de l'occasion.",
  maxBrid17: "Soyez attentif à la palette de couleurs de votre mariage",
  maxBrid18: `Lors de la sélection des couleurs à porter pour votre fête de demoiselle d'honneur, ne vous contentez pas de jeter une palette ensemble. Certaines couleurs ne se mélangent tout simplement pas, comme les tons chauds et froids. Recherchez des photos d'inspiration et  <a href="{hrefFabric}" class="color_pink">commandez des échantillons</a> pour tester à quoi ressembleront les couleurs de la robe côte à côte. Une fois que vous avez trouvé la palette de mariage parfaite, il est plus facile que jamais d'acheter vos robes de demoiselle d'honneur en ligne sur STACEES.`,
  maxBrid19: "Donnez à vos demoiselles d'honneur une direction suffisante !",
  maxBrid20: "Il est important que lorsque vous permettez à vos filles de mélanger et assortir les couleurs et/ou les styles, vous donniez toujours la direction. Vous devriez proposer un tableau d'inspiration ou des exemples de photos que vous pouvez partager et leur donner une idée du look que vous recherchez. Ne vous attendez pas à ce que vos demoiselles d'honneur trouvent elles-mêmes une robe (que vous aimez). Vous êtes responsable de la coordination. L'une des meilleures façons de les aider est de leur recommander vos sites Web préférés.",
  maxBrid21: "Avantages des robes de demoiselle d'honneur assorties",
  maxBrid22: "Il y a tellement d'avantages à des robes de demoiselle d'honneur assorties. La première, comme nous l'avons mentionné, est que vos filles sauront quels styles flattent le mieux leur corps. Elles peuvent également s'habiller selon leur style personnel, qu'il soit modeste ou plus sexy. Cela signifie qu'ils se sentiront à l'aise et confiants. De plus, et tout aussi important, cela permettra une certaine flexibilité en matière de prix. Tout le monde ne peut pas s'offrir la même robe. Permettre à vos filles de choisir leur propre robe signifie qu'elles peuvent répondre à leur propre budget, sans aucune culpabilité ni pression.",
  maxBrid23: "Nous comprenons que créer une fête de mariage assortie est plus facile à dire qu'à faire ! Pour vous inspirer, découvrez ces vrais mariages Kennedy Blue pour les couleurs et les styles que vous pouvez intégrer à votre mariage.",
  maxBrid24: "Conclusion",
  maxBrid25: "Alors maintenant, nous vous donnons quelques suggestions sérieuses à considérer. Il est temps de prendre une décision ! Préférez-vous une robe de demoiselle d'honneur assortie ou du même style ? Veuillez nous contacter par e-mail : ",
  maxBrid26: ", dites-nous ce que vous pensez et nous serons heureux de vous aider.",
  maxBrid27: `acheter par ce mélange de couleurs`,
  shopBy: 'ACHETER PAR couleurs',
  shopByTxt1: 'Trouvez des couleurs qui complètent votre palette de mariage ou de mélange et assorti.',
  shopByTxt2: "Salut fille, occupez à acheter des robes de demoiselle d'honneur par couleur ? Découvrez la collection de robes de demoiselle d'honneur en couleur chez STACEES alors! En tant que fabricant de robes professionnel depuis plus d'une décennie, STACEES propose aux femmes des robes de qualité supérieure au design fabuleux et aux détails impeccables. Non seulement nous proposons des modèles de toutes tailles, mais également une variété inimaginable de choix de couleurs. Des couleurs courantes pour les robes de demoiselle d'honneur sont disponibles, telles que le rose ou le violet. Cependant, nous fournissons également à nos clients un échantillon de couleurs sélectionné qui permet à vos filles de se démarquer parmi toutes. Nous comprenons la difficulté de parcourir des centaines de boutiques de robes de demoiselles d'honneur en ligne uniquement pour un ou deux modèles. Pour être honnête, il n'y a pas beaucoup de boutiques de robes de demoiselle d'honneur qui garantissent à la fois le look et la qualité. Même si vous êtes satisfait du design et de tout, vous devez être intimidé par le prix incroyablement élevé plus tard. Déterminé à servir chaque future mariée de tout cœur, STACEES est l'un des meilleurs boutiques de robes de demoiselle d'honneur, ce qui signifie qu'il n'y a pas besoin de s'inquiéter de quoi que ce soit de la mariée. Tout ce que vous avez à faire est de choisir quelques robes de demoiselle d'honneur de couleur fantaisie pour vos amis et votre famille et de nous laisser le reste. Nous sommes professionnels. Et nous ne laissons jamais tomber nos clients.",
  shopBridDress: "ACHETER TOUTES LES {color} DE DEMOISELLE D'HONNEUR ROSE",
  colorSwatch: 'ÉCHANTILLONS DE COULEURS',
  shopNow: 'ACHETEZ MAINTENANT',
  allPinks: 'Tous les roses',
  allBlues: 'Tous les bleus',
  allYellows: 'Tous les jaunes',
  allGreens: 'Tous les verts',
  allOranges: 'Tous les oranges',
  allPurples: 'Tous les violets',
  allReds: 'Tous les rouges',
  allGreys: 'Tous les gris',
  //most loved
  mostLovedTit: 'Robes de mariée les plus aimées',
  mostLoved1: 'Classique Vintage',
  mostLoved2: 'Des robes de mariée vintage pour un look intemporel et toujours à la mode',
  mostLoved3: 'Bohème Romantique',
  mostLoved4: 'Ces designs sont sexy et sophistiqués avec une dentelle élégante et un dos ouvert.',
  mostLoved5: 'Glamour Moderne',
  mostLoved6: 'Les robes de mariée glamoureuses ont souvent quelques détails à couper le souffle',
  mostLoved7: "Nos couleurs sont soigneusement assorties pour faciliter le mélange et l'assortiment",
  mostLoved8: 'Afficher plus de modèles',
  mostLoved9: `Mix & Match des <span>Demoiselles d'honneur</span>`,//
  //Order Cancellation
  orderTit: 'ANNULATION DE COMMANDE',
  orderCan1: 'Nous comprenons vraiment à quel point il est important de trouver la robe parfaite pour votre occasion spéciale, et nous sommes là pour vous accompagner tout au long de ce processus. Une fois votre commande passée, nous commençons immédiatement à la traiter pour garantir la plus haute qualité et une livraison rapide.',
  orderCan2: 'Étant donné que chaque robe est soigneusement confectionnée sur commande, les matériaux ne peuvent pas être réutilisés une fois la production commencée. Pour cette raison, nous vous encourageons à nous informer dès que possible si vous devez effectuer des modifications ou des annulations.',
  orderCan3: `Si votre commande est encore en cours de traitement, il est possible de l'annuler. Cependant, une fois qu'elle passe à l'étape d'expédition, les annulations ne sont plus possibles. Si vous devez annuler votre commande, contactez-nous simplement via le chat en direct. Notre opérateur se fera un plaisir de vous aider à annuler ou à modifier votre commande à temps. Vous pouvez également nous envoyer un e-mail concernant votre commande.`,
  orderCan4: `Si vous souhaitez annuler votre commande, veuillez vous connecter à votre compte, accédez à « <a class="udline myorder" href="{hrefOrder}">Mes commandes</a> », cliquez sur « Créer un ticket », remplissez les champs obligatoires et soumettez. <br/>Notre équipe du service client vous répondra dans les plus brefs délais pour confirmer et vous aider avec votre annulation.`,
  orderCan5: "Les commandes annulées dans les 12 heures suivant la confirmation du paiement seront éligibles à un remboursement partiel comprenant les frais d'expédition complets et 90% du prix d'achat du produit.",
  orderCan6: "Les commandes annulées dans les 12 à 24 heures suivant la confirmation du paiement seront éligibles à un remboursement partiel comprenant les frais d'expédition complets et 80 % du prix d'achat du produit.",
  orderCan7: "Les commandes annulées dans les 24 à 48 heures suivant la confirmation du paiement donneront droit à un remboursement partiel comprenant les frais d'expédition complets et 50 % du prix d'achat du produit.",
  orderCan8: 'Plus de 48H',
  orderCan9: "Les commandes annulées au-delà de 48 heures après la confirmation du paiement seront éligibles à un remboursement partiel des frais d'expédition complets uniquement.",
  orderCan10: "Une fois votre commande expédiée, elle ne peut plus être annulée.",
  orderCan11: `Si vous souhaitez annuler votre commande, veuillez vous connecter à <a class="udline myorder" href="javascript:void(0)">Mes commandes</a>, sélectionnez la commande pour laquelle vous avez besoin d'aide et demandez l'annulation. Ou veuillez cliquer sur <a class="udline" href="{hrefContact}">Contactez-nous</a> pour continuer.`,
  orderCan12: 'Actions',
  orderCan13: "Limite de Temps",
  orderCan14: 'Montant Remboursé',
  orderCan16: "Frais d'expédition",
  orderCan17: 'Annulation',
  orderCan18: 'Dans les 12 heures suivant la confirmation de la commande',
  orderCan19: 'Dans les 12 à 24 heures suivant la confirmation de la commande',
  orderCan20: 'Dans les 24 à 48 heures suivant la confirmation de la commande',
  orderCan21: 'Plus de 48 heures, non expédiée',
  orderCan22: 'aucun',
  orderCan23: 'Commande expédiée',
  //success
  check: 'VÉRIFIER',
  checkoutWait1: "Patientez s'il vous plaît",
  successInfo1: 'Ordre reçu!',
  successInfo2: 'Veuillez vérifier votre e-mail dans les 30 minutes.',
  successInfo3: "Veuillez vérifier et répondre à l'e-mail de confirmation pour vous assurer que les informations concernant votre commande sont correctes.L'e-mail de confirmation vous sera envoyé dans les 30 minutes. Si vous ne recevez jamais d'e-mail de confirmation,veuillez vérifier le courrier indésirable ou contactez-nous via",
  backHome: "Retour à l'Accueil",
  pageNotFond: 'Page non trouvée',
  //title
  logInTit: 'Identifiez-vous ou créez un compte',
  myOrderTit: 'Mes commandes chez STACEES',
  myCouponsTit: 'Mes coupons chez STACEES',
  myAccTit: 'Mon compte chez STACEES',
  myProfileTit: 'Mon profil chez STACEES',
  myAddressTit: "Carnet d'adresses chez STACEES",
  myTicketTit: "Mes billets chez STACEES",
  myPasswdTit: 'Mon mot de passe chez STACEES',
  myReviewTit: 'Mes avis sur STACEES',
  createTicketTit: 'Créer un ticket sur STACEES',
  shopColorTit: "Achetez des robes de demoiselle d'honneur par couleur",
  shopColorKword: "couleurs de robes de demoiselle d'honneur, robes de demoiselle d'honneur bleu poudré, robes de demoiselle d'honneur rose poudré, robes de demoiselle d'honneur bordeaux, robes de demoiselle d'honneur bleues, robes de demoiselle d'honneur roses, robes de demoiselle d'honneur vert émeraude, robes de demoiselle d'honneur rouges, robes de demoiselle d'honneur violettes, robes de demoiselle d'honneur jaunes, couleurs de robes de demoiselle d'honneur, blanc, noir, robes de demoiselle d'honneur par couleur, robes de demoiselle d'honneur dorées, robes de demoiselle d'honneur champagne",
  shopColorDesc: "Achetez des robes de demoiselle d'honneur et des robes de demoiselle d'honneur par couleur. Trouvez les robes de demoiselle d'honneur parfaites dans plus de 75 couleurs. Pour correspondre à la palette de couleurs de votre mariage. Vous pouvez obtenir des échantillons de couleur de tissu pour voir l'une de ces couleurs en personne afin de voir de vrais exemples de la façon de coordonner les couleurs de mariage.",
  homeTit: "Robes et accessoires de mode STACEES pour occasions spéciales | Robes de demoiselle d'honneur | Robes de mariée | Robes de bal | Robes de soirée et plus sur stacees.com",
  homeKeyword: "Robes de mariée, robes de soirée, robes de soirée, robes de demoiselle d'honneur, boutique de robes pour occasions spéciales",
  homeDesc: "Nous proposons des robes de mariée, des robes de soirée, des robes de bal, des robes de soirée, des robes de cocktail, des robes de demoiselle d'honneur et des robes de demoiselle d'honneur dans notre boutique en ligne. Des accessoires de mariage à prix réduits sont en vente ! Une variété de robes à vendre à des prix raisonnables sont toutes sur notre site Web.",
  styleGalleryDesc: "De superbes collections de robes de mariée, robes de demoiselle d'honneur, robes de bal, robes de soirée, chaussures et plus encore chez STACEES. Consultez notre galerie de styles et trouvez la robe de vos rêves ici !",
  studentbeans: 'Haricots étudiants',
  searchTit1: 'Acheter tout {nom}',
  searchTit2: 'Unique {nom}',
  searchTit3: 'Livraison et retours gratuits. {nom}',
  searchTit4: 'Vente à chaud {nom}',
  searchTit5: 'Divers {nom} Être Couture',
  searchTit6: 'Tout sur mon {nom}',
  searchTit7: 'Diverses superbes {nom} Idées',
  searchTit8: 'Inattendue {nom} Conception',
  searchTit9: 'Le meilleur endroit pour acheter des produits disponibles {nom}',
  searchTit10: '{nom} en ligne',
  searchTit11: 'Achetez le meilleur {nom} en ligne',
  searchDesc1: `Fournissez un excellent {nom} chez STACEES avec des styles et des tailles inattendus. Soyez à la hauteur le jour de la fête de votre fille avec l'ensemble parfait.`,
  searchDesc2: `Achetez {nom} sur STACEES. Choisissez le look parfait pour votre journée avec ces choix exquis et fluides.`,
  searchDesc3: `Achetez notre incroyable collection de {nom} en ligne dans une variété de couleurs, de modèles, de tailles et de styles.`,
  searchDesc4: 'Vous avez de la chance ! Achetez maintenant le superbe {nom} et habillez votre mère avec STACEES, disponible dans différentes couleurs et styles.',
  searchDesc5: 'Découvrez ce que ces femmes élégantes portaient lorsque leurs enfants se sont mariés. {nom} joue un rôle important dans chaque mariage.',
  searchDesc6: `STACEES propose {nom} qui peut s'adapter à n'importe quel style ou taille.`,
  searchDesc7: `Comme pour la mode nuptiale, {nom} n'a pas beaucoup de règles. Allez-y ! Achetez les soldes {nom} chez STACEES.`,
  searchDesc8: `Discover our unique {nom} from STACEES  and be the perfect guest in the special day.`,
  mixKeyWord: "robes de demoiselle d'honneur à mélanger et assortir, robes de demoiselle d'honneur séparées, robe de demoiselle d'honneur convertible, robes de demoiselle d'honneur dépareillées, robes de demoiselle d'honneur de la même couleur et de styles différents",
  mixDesc: "Comment associer et assortir les robes de demoiselle d'honneur avec les couleurs, les tissus et les styles ? Découvrez notre collection de robes de demoiselle d'honneur et de pièces séparées pour apprendre à créer une palette de mariage ombrée et à faire en sorte que votre mariage porte différents styles, couleurs ou nuances!",
  orderDetailTit: 'Voir les détails sur STACEES',
  paymentTit: 'Succès du paiement',
  emailReceive: "S'il vous plaît, aidez-nous à savoir quels e-mails vous souhaitez recevoir ?",
  subscribe: "S'abonner",
  //accessoires
  accessInfo: 'Bijoux et accessoires modernes et ultra féminins pour votre grand jour et longtemps après.',
  wrapShawls: 'Vestes & Châles',
  bridalGloves: 'Gants de Mariée',
  hats: 'Chapeaux',
  handbags: 'Pochettes',
  fabricSwatch: 'Échantillons de Tissu',
  hairAccess: 'ACCESSOIRES DE COIFFURE',
  sashesBelts: 'RUBANS & CEINTURES',
  weddJewelery: 'BIJOUX DE MARIAGE ET DE FÊTE',
  weddFlowers: 'FLEURS DE MARIAGE',
  weddPett: 'JUPONS DE MARIAGE',
  accessTit: "Tendances et styles d'accessoires de mariage et d'occasions spéciales",
  accessKeyWd: 'Accessoires de mariage, accessoires pour occasions spéciales',
  accessDesc: "À la recherche d'inspiration pour des accessoires pour un événement ou un mariage à venir, achetez les meilleures tendances en matière d'accessoires de mode, les styles les plus populaires et les nouveaux looks de voiles, coiffes, pochettes, épingles, peignes, bijoux et plus encore chez STACEES pour assortir votre robe maintenant. Haute qualité et expédition rapide",
  ok: "d'accord",
  weddPartyDress: 'Wedding Party Dresses',
  GiftsDecor: 'Gifts & Decor',
  ticketStatus: {
    any: 'Tout',
    new: 'Nouveau',
    open: 'Ouvert',
    pending: 'En attente',
    solved: 'Résolu',
    closed: 'Fermé',
  },
  promLink:'/list/robes-de-bal',
  homecomLink:'/list/robes-de-fete-de-la-rentree',
  weddLink:'/list/robes-de-mariee',
  brideLink:'/list/robes-de-demoiselle-d-honneur',
  motherLink:'/list/robes-de-mere-de-la-mariee',
  guestLink:'/list/robes-d-invite-de-mariage',
  flowerLink:'/list/robes-de-fille-de-fleur',
  fabricLink:'/list/echantillons-de-tissu',
  shoesLink:'/list/chaussures-de-mode',
  handbagLink:'/list/sacs-a-main',
  piecesLink:'/list/coiffes',
  jacketsLink:'/list/vestes-chales',
  weddShoesLink:'/list/chaussures-de-mariage',
  bridalLink:'/list/voiles-de-mariee',
  danceLink:'/list/chaussures-de-danse',
  jewelryLink:'/list/bijoux',
  hatsLink:'/list/chapeaux',
  evenLink:'/list/robes-de-soiree',
  // how-afterpay-works
  joinNow: 'Rejoignez-nous maintenant',
  afterpayTit1: 'Tout ce dont vous avez besoin est',
  afterpayCont1: 'Une carte de crédit ou de débit américaine',
  afterpayCont2: 'Avoir plus de 18 ans',
  afterpayCont3: 'Une adresse de résident aux États-Unis',
  afterpayTit2: 'Afficher votre commande Afterpay',
  afterpayCont4: `Une fois votre commande traitée, vous pouvez vous connecter à votre compte Afterpay pour consulter vos commandes, effectuer des paiements et gérer les détails de votre compte.`,
  afterpayCont5: `Cliquez sur le lien ci-dessous pour accéder au site Web de notre partenaire tiers, Afterpay.`,
  afterpayTit3: 'Questions fréquemment posées',
  afterpayCont6: `Votre question ne figure pas ici?Consultez la page FAQ complète d'Afterpay. <br/>Vous pouvez contacter Afterpay à tout moment sur <a href="https://help.afterpay.com/hc/en-us" target="_blank">https://help.afterpay.com/hc/en-us</a> ou en téléchargeant l' <a href="https://www.afterpay.com/en-US/mobile-app" target="_blank">application Afterpay</a>.`,
  afterpayTit4: 'Comment fonctionne Afterpay',
  afterpayCont7: `Décision d'approbation instantanée`,
  afterpayCont8: 'Pas de formulaire de demande long et chronophage. Afterpay rend le paiement rapide et facile',
  afterpayCont9: 'Achetez maintenant. Payez plus tard.',
  afterpayCont10: 'Payez votre achat en quatre versements égaux, toutes les deux semaines',
  afterpayCont11: 'Rien de plus à payer',
  afterpayCont12: 'Effectuez vos paiements à temps et uniquement du prix de votre achat',
  afterpayCont13: 'Accéder au compte Afterpay',
  afterpaybtn: 'Charger plus de messages',
  afterpayFaqT1: `Qu'est-ce que le paiement échelonné par Afterpay ?`,
  afterpayFaqT2: 'Comment utiliser le paiement échelonné par Afterpay?',
  afterpayFaqT3: 'Où puis-je utiliser le paiement échelonné par Afterpay?',
  afterpayFaqT4: 'Puis-je utiliser le paiement échelonné par Afterpay si je suis un client international?',
  afterpayFaqT5: 'Chat iomment fonctionne le calendrier de paiement ?',
  afterpayFaqT6: `Que faire si je ne peux pas payer une échéance Afterpay?`,
  afterpayFaqT7: `Quand mes articles seront-ils livrés si j'utilise le paiement échelonné par Afterpay ?`,
  afterpayFaqT8: 'Y a-t-il une limite au montant que je peux dépenser pour une seule transaction ?',
  afterpayFaqT9: `Quels produits ne sont pas éligibles à l'achat avec le paiement échelonné par Afterpay?`,
  afterpayFaqT10: 'Comment retourner ou échanger un article acheté avec le paiement échelonné par Afterpay ?',
  afterpayFaqT11: `Où puis-je en savoir plus sur Afterpay?`,
  afterpayFaqC1: `Le paiement échelonné par Afterpay est un service qui vous permet d'effectuer des achats maintenant et de les payer en quatre paiements égaux effectués toutes les deux semaines, sans intérêts.`,
  afterpayFaqC2: `Il vous suffit de faire vos achats en ligne, d'ajouter des articles à votre panier et de procéder au paiement comme d'habitude. Lors du paiement, choisissez le paiement échelonné par Afterpay comme méthode de paiement. Les nouveaux clients devront s'inscrire sur Afterpay et fournir leurs informations de paiement comme d'habitude. Les clients déjà inscrits devront simplement se connecter pour effectuer leur achat. C'est aussi simple que ça !`,
  afterpayFaqC3: `Veuillez noter que tous les articles de votre panier doivent être éligibles au paiement échelonné par Afterpay. Un montant d'achat minimum peut s'appliquer et vous devez remplir des critères d'éligibilité supplémentaires pour être qualifié.`,
  afterpayFaqC4: "Sous réserve des exigences d'éligibilité et de disponibilité d'Afterpay, le paiement échelonné par Afterpay ne peut être appliqué qu'aux achats effectués sur le site Jomalone.com.",
  afterpayFaqC5: 'Le paiement échelonné par Afterpay est uniquement disponible pour les clients ayant une adresse de facturation aux États-Unis et un numéro de téléphone mobile américain. Les clients ayant une adresse de facturation internationale ne pourront pas créer un compte Afterpay.',
  afterpayFaqC6: `Tous les clients doivent effectuer leur premier paiement au moment de l'achat, et les trois paiements restants seront automatiquement prélevés toutes les deux semaines sur la méthode de paiement choisie. Si vous choisissez d'effectuer des paiements supplémentaires avant la date de paiement prévue, vous pouvez le faire via votre compte Afterpay. Vous pouvez vous connecter à votre compte Afterpay pour consulter votre calendrier de paiements et effectuer un paiement avant la date d'échéance si vous le souhaitez.`,
  afterpayFaqC7: `Si les fonds ne sont pas disponibles ou si vous ne effectuez pas votre paiement à l'échéance, des frais de retard peuvent s'appliquer. <a href="{payLink}">Cliquez ici</a> pour les termes complets.`,
  afterpayFaqC8: `Les commandes Afterpay sont livrées selon nos délais de livraison standard après que vous ayez terminé votre commande en ligne. <br/>Visitez notre page de livraison pour plus d'informations en cliquant <a href="{payLink}">ici</a>.`,
  afterpayFaqC9: `Oui, des limites de valeur de transaction s'appliquent aux achats effectués sur Jomalone.com avec le paiement échelonné par Afterpay.`,
  afterpayFaqC10: `Afterpay n'est pas disponible pour l'achat de cartes-cadeaux. Si vous achetez des cartes-cadeaux avec des produits éligibles à Afterpay, vous devrez passer deux commandes séparées.`,
  afterpayFaqC11: `Si vous avez changé d'avis, vous pouvez retourner votre achat Afterpay en magasin ou par courrier pour un remboursement. Veuillez vérifier que votre retour ou échange respecte notre Politique de retour ici. Afterpay sera informé de votre retour et traitera les remboursements appropriés. Veuillez ne pas retourner votre achat à Afterpay.`,
  afterpayFaqC12: `Si vous estimez qu'il y a un problème ou une préoccupation concernant le paiement de votre achat Afterpay, veuillez contacter le service client d'Afterpay à l'adresse <a href="{payLink}">info@afterpay.com</a>.`,
  afterpayFaqC13: `Si vous souhaitez en savoir plus sur Afterpay, visitez le site Afterpay www.afterpay.com pour une liste complète des questions fréquentes, des termes ainsi que la politique de confidentialité d'Afterpay que vous pouvez trouver à <a href="{payLink}">https://www.afterpay.com/privacy-policy</a>`,
  afterpayFaqC14: `Si vous avez des questions concernant votre compte Afterpay, veuillez contacter directement Afterpay via le formulaire en ligne à <a href="{payLink}">https://help.afterpay.com/hc/en-us</a>`,
  //klura
  tryIt: 'ESSAYEZ MAINTENANT!',
  kluraBan11: 'Achetez maintenant,',
  kluraBan12: 'payez plus tard avec',
  kluraBan2: `Nous avons collaboré avec Klarna pour vous offrir une meilleure expérience d'achat.`,
  kluraTxt1: `C'est simple et fluide`,
  kluraTxt2: 'Divisez votre achat en 4 paiements afin de répartir le coût de votre achat au fil du temps. Entrez la carte de crédit ou de débit de votre choix pour des paiements automatiques toutes les deux semaines. Aucun intérêt ni frais supplémentaires lorsque vous payez à temps.',
  kluraTxt3: 'Quatre paiements sans intérêt',
  kluraTxt4: `Klarna vous aide à améliorer votre expérience d'achat. Lorsque vous choisissez Klarna au moment du paiement, vous avez la possibilité d'acheter maintenant et de payer plus tard pour votre achat. C'est du shopping comme il se doit. Sans effort, sûr et amusant.`,
  kluraTxt5: 'Comment faire des achats avec Klarna.',
  kluraTxt6: 'Ajoutez des articles à votre panier et passez à la caisse.',
  kluraTxt7: 'Sélectionnez Klarna au moment du paiement pour payer votre achat comme vous le souhaitez.',
  kluraTxt8: `Gérez vos commandes et paiements dans l'application Klarna.`,
  kluraTxt9: 'La meilleure façon de faire des achats.',
  kluraTxt10: `Consultez tous vos achats au même endroit, payez vos soldes ouverts et bien plus encore dans l'application Klarna. Vous pouvez également vous connecter à votre compte Klarna à l'adresse <a href="https://app.klarna.com/login" target="_blank">https://app.klarna.com/login</a>. Si vous avez besoin d'aide, notre service client est là pour vous.Klarna`,
  kluraTxt11: 'Sûr et sécurisé.',
  kluraTxt12: 'Klarna utilise les dernières mesures de sécurité pour protéger vos informations et empêcher les achats non autorisés.',
  kluraTxt13: 'À propos de Klarna.',
  kluraTxt14: `Nous avons pour mission de remodeler le shopping, en améliorant l'expérience d'achat dans son intégralité, de la navigation à l'achat. Nous y parvenons en vous offrant les avantages de la commodité, des avantages et de l'inspiration.`,
  kluraTxt15: `Fondée à Stockholm, en Suède, Klarna est l'une des entreprises à la croissance la plus rapide d'Europe et l'un des principaux fournisseurs de paiement alternatif. Nous comptons 3,500 employés dans 17 pays et ouvrons la voie aux paiements alternatifs en proposant des solutions de paiement à 90 millions de consommateurs et 250,000 détaillants.`,
  kluraTxt17: `Nous sommes toujours là pour vous aider!`,
  kluraTxt18_1: 'Vous ne trouvez pas votre question ici?',
  kluraTxt18_2: `Consultez la page FAQ complète de Klarna.<br/>Vous pouvez contacter Klarna à tout moment à l'adresse <a href="https://www.klarna.com/us/customer-service/" target="_blank">https://www.klarna.com/us/customer-service/</a> ou en téléchargeant l' <a href="https://www.klarna.com/us/klarna-app/" target="_blank">application Klarna</a>.`,
  kluraFaq1: 'Que se passe-t-il si je fais un retour?',
  kluraFaq2: `Si vous devez retourner une partie ou la totalité de votre commande, Klarna vous enverra un nouveau relevé. Suivez simplement les instructions de retour du détaillant et assurez-vous de prendre note du numéro de suivi. Connectez-vous à votre compte Klarna, sélectionnez «Signaler un retour» et nous suspendrons votre relevé. Dès que le détaillant aura traité votre retour, nous vous enverrons une facture ajustée.`,
  kluraFaq3: `Il y a un problème avec ma commande. Dois-je quand même payer pour cela?`,
  kluraFaq4: `Vous n'avez pas besoin de payer pour les produits reçus endommagés, cassés ou défectueux. Suivez les instructions de litige du détaillant et «Signaler un problème» dans votre compte Klarna pour suspendre votre relevé jusqu'à ce que le problème soit résolu. Dès que le détaillant a enregistré votre retour, le remboursement sera traité dans un délai de 5 à 7 jours ouvrables.`,
  kluraFaq5: `Je n'ai pas reçu ma commande. Qu'advient-il de mon relevé?`,
  kluraFaq6: `Vous n'avez pas besoin de payer le relevé tant que vous n'avez pas reçu votre commande. Contactez le détaillant pour obtenir une mise à jour de la livraison, connectez-vous à votre compte Klarna et « Signaler un problème » pour suspendre votre relevé jusqu'à ce que vous ayez reçu votre commande.`,
  kluraFaq7: 'Mon relevé est incorrect. Que dois-je faire?',
  kluraFaq8: `Si votre relevé ne correspond pas aux détails de votre commande, veuillez contacter directement le détaillant pour corriger votre relevé. Connectez-vous à votre compte Klarna et « Signalez un problème » pour suspendre votre relevé jusqu'à ce que les détails aient été corrigés.`,
  //paypal
  paypalTit: 'Comment fonctionne PayPal',
  paypalTxt1: 'PAYEZ EN 4 VERSEMENTS SANS INTÉRÊT',
  paypalTxt2: `Payez en 4 fois sans intérêt pour les commandes entre 30 $ et 1500 $. Votre prêt durera au total 6 semaines. Le premier paiement est dû au moment de l'achat, suivi de 3 paiements supplémentaires, dus toutes les deux semaines. Paiement en 4 fois avec PayPal est une forme de crédit, donc réfléchissez bien à votre capacité de remboursement. Cela peut ne pas convenir à tout le monde. Voir les conditions ici.`,
  paypalTxt3: 'Comment utiliser le Paiement en 4 fois avec PayPal?',
  paypalTxt4: 'Passer une commande et passer à la caisse.',
  paypalTxt5: 'Sélectionnez PayPal comme méthode de paiement, connectez-vous à votre compte PayPal, et demandez à utiliser le Paiement en 4 fois avec PayPal.',
  paypalTxt6: `Si elle est approuvée, passez votre commande et c'est fait! Un acompte sera collecté et PayPal débitera automatiquement vos paiements suivants toutes les deux semaines.`,
  paypalTxt7: 'Ce dont vous avez besoin pour commencer:',
  paypalTxt8: 'Un mode de remboursement éligible pour les remboursements automatiques',
  paypalTxt9: 'Être majeur dans votre État de résidence aux États-Unis',
  paypalTxt10: 'Résider dans un État des États-Unis éligible, voir les conditions ci-dessous pour plus de détails',
  paypalTxt11: 'Un compte PayPal en bon état pour pouvoir demander Payez en 4',
  paypalTxt12: 'Comment ma transaction Paiement en 4 fois avec PayPal apparaîtra-t-elle dans mon activité PayPal?',
  paypalTxt13: `Chaque transaction PayPal Payez en 4 apparaîtra dans votre fil d'activité PayPal comme le montant total de l'achat sous le nom du commerçant concerné en tant qu'« achat ». Cela reflète le montant total de l'achat initial et ne reflète pas le plan de paiement PayPal Payez en 4 ni les paiements déjà effectués via votre mode de paiement éligible. En utilisant PayPal Payez en 4, PayPal paie le commerçant immédiatement et divise le montant total de l'achat en 4 paiements sans intérêt. L'acompte est prélevé à la date de l'achat et les trois paiements suivants sont automatiquement prélevés toutes les deux semaines. L'acompte et les trois paiements suivants apparaîtront également comme des transactions de paiement séparées dans votre activité PayPal et représenteront les paiements réels effectués pour le montant total de votre achat.`,
  paypalTxt14: `Qu'est-ce que le Paiement en 4 fois avec PayPal ?`,
  paypalTxt15: `Le Paiement en 4 fois avec PayPal est un prêt sans intérêt qui vous permet de diviser votre achat total de 30 $ à 1500 $ en 4 paiements, avec le premier paiement dû au moment de l'achat et les paiements suivants dus toutes les deux semaines. C'est un excellent moyen de répartir le coût des articles dans le temps, et cela peut vous aider à gérer votre budget plus efficacement. Veuillez noter que le Paiement en 4 fois avec PayPal est une forme de crédit, donc réfléchissez bien à votre capacité de remboursement.`,
  paypalTxt16: 'Est-ce que ma demande pour le Paiement en 4 fois avec PayPal aura un impact sur mon score de crédit?',
  paypalTxt17: `Dans le cadre de leur demande de paiement en 4, certains clients peuvent avoir besoin d'une vérification de crédit souple ; cependant, cela n'aura aucun impact sur leur cote de crédit.`,
  paypalTxt18: 'Y a-t-il des frais de retard associés au Paiement en 4 fois avec PayPal?',
  paypalTxt19: `l n'y a pas de frais de demande ni de frais de retard pour choisir de payer avec le Paiement en 4 fois avec PayPal.`,
  paypalTxt20: 'Pourquoi y a-t-il une autorisation en attente dans mon activité PayPal pour ma transaction Paiement en 4 fois avec PayPal?',
  paypalTxt21: `Lors d'un achat avec le Paiement en 4 fois avec PayPal, nous plaçons une autorisation préalable sur votre moyen de paiement éligible pour un montant équivalent au premier paiement ou à un quart du montant total de votre achat. Cela apparaîtra comme une autorisation en attente dans l'activité de votre compte PayPal et également dans l'institution financière associée à votre moyen de paiement. Une fois que vous aurez terminé votre achat avec le Paiement en 4 fois avec PayPal et que le commerçant aura expédié vos articles, l'autorisation en attente sera supprimée et le premier paiement sera prélevé sur votre moyen de paiement. Si vous ne complétez pas votre transaction avec le Paiement en 4 fois avec PayPal, l'autorisation en attente sera automatiquement annulée par PayPal dans les 72 heures suivant l'autorisation préalable.`,
  paypalTxt22: `Pourquoi mon paiement n'a-t-il pas été effectué?`,
  paypalTxt23: `Il existe plusieurs raisons pour lesquelles PayPal n'a pas pu finaliser votre paiement programmé, y compris s'il y a eu des fonds insuffisants sur votre carte de débit, carte de crédit ou compte bancaire, ou si votre carte n'a pas été confirmée. Si vous devez modifier ou mettre à jour votre moyen de paiement, vous pouvez le faire en vous connectant à votre compte PayPal, en accédant à "Paiement en 4 fois avec PayPal", puis en sélectionnant le plan Paiement en 4 fois avec PayPal que vous souhaitez modifier. Là, vous pouvez cliquer sur "Modifier" sous "Mode de paiement" pour mettre à jour vos informations ou choisir une nouvelle méthode de paiement..`,
  paypalTxt24: `J'ai encore des questions concernant le Paiement en 4 fois, comment puis-je les poser?`,
  paypalTxt25: `Visitez le <a href="https://www.paypal.com/us/cshelp/contact-us" target="_blank">Service client PayPal</a> pour savoir comment les contacter ou cliquez <a href="https://www.paypal.com/credit-presentment/lander/modal?payer_id=XJ6G8848FDW9L&offer=PAY_LATER_SHORT_TERM&buyer_country=US" target="_blank">here</a> pour plus d'informations sur comment acheter maintenant et payer plus tard avec PayPal.`,
  paypalTxt26: `Consultez la page FAQ complète de PayPal. <br/>Vous pouvez joindre PayPal à tout moment sur <a href="https://www.paypal.com/" target="_blank">https://www.paypal.com/</a> ou en téléchargeant l' <a href="https://www.paypal.com/mobile-app/" target="_blank">application PayPal</a>.`,
  //clearpay
  clearpayTit1: 'Comment fonctionne Clearpay',
  clearpayCont8: 'Pas de formulaires de demande longs et fastidieux. Clearpay rend le paiement rapide et facile',
  clearpayTit2: 'Afficher votre commande Clearpay',
  clearpayCont4: `Une fois votre commande traitée, vous pouvez vous connecter à votre compte Clearpay pour consulter vos commandes, effectuer des paiements et gérer les détails de votre compte.`,
  clearpayCont5: `Accéder au compte Clearpay`,
  clearpayFaq1: `Qu'est-ce que Clearpay?`,
  clearpayFaq2: 'Clearpay est un service qui vous permet de faire des achats maintenant et de les payer en quatre versements effectués toutes les 2 semaines sans aucun intérêt.',
  clearpayFaq3: 'Comment utiliser Clearpay?',
  clearpayFaq4: `Faites simplement vos achats en ligne, ajoutez des articles à votre panier et passez à la caisse comme d'habitude. Au moment du paiement, choisissez Versements par Clearpay comme mode de paiement. Les nouveaux clients devront s'inscrire sur Clearpay et fournir leurs informations de paiement comme d'habitude, les clients récurrents se connectent simplement pour effectuer leur achat. C'est aussi simple que ça!`,
  clearpayFaq5: `Veuillez noter que tous les articles de votre panier doivent être éligibles aux versements échelonnés par Afterpay. Un montant d'achat minimum peut s'appliquer et vous devez répondre à des conditions d'éligibilité supplémentaires pour être éligible.`,
  clearpayFaq6: 'Où puis-je utiliser Clearpay? ',
  clearpayFaq7: 'Clearpay ne peut être utilisé que pour les achats effectués sur le site stacees.com.',
  clearpayFaq8: 'Puis-je utiliser Clearpay si je suis un client international? ',
  clearpayFaq9: `Clearpay n'est proposé qu'à nos clients ayant une adresse de facturation au Royaume-Un.`,
  clearpayFaq10: 'Comment fonctionne le calendrier de paiements de Clearpay?',
  clearpayFaq11: `Les clients Clearpay effectuent leur premier paiement au moment de l'achat, les trois paiements restants sont déduits automatiquement toutes les 2 semaines de votre mode de paiement sélectionné.`,
  clearpayFaq12: 'Si vous souhaitez effectuer des paiements supplémentaires non programmés avant leur échéance, vous pouvez vous connecter à Clearpay pour effectuer vos paiements plus tôt. Clearpay ajustera ensuite le montant dû en conséquence.',
  clearpayFaq13: `Vous pouvez vous connecter à votre compte Clearpay à tout moment pour consulter votre calendrier de paiement ou effectuer un paiement avant la prochaine date d'échéance.`,
  clearpayFaq14: `Que se passe-t-il si je ne peux pas payer un acompte Clearpay?`,
  clearpayFaq15: `Dès que vous manquez un paiement, Clearpay vous empêchera immédiatement d'effectuer d'autres achats avec Clearpay.`,
  clearpayFaq16: `Clearpay vous donnera jusqu'à 23h le jour suivant pour effectuer votre remboursement, puis vous encourrez des frais de retard pour un paiement manqué qui n'est pas résolu.`,
  clearpayFaq17: `Il s'agira d'un montant initial de 6£ et de frais supplémentaires de 6£ si le paiement manqué n'est pas effectué dans les 7 jours.`,
  clearpayFaq18: 'Les frais de retard ne dépasseront pas 25% du total de la commande.',
  clearpayFaq19: `Veuillez consulter le contrat d'achat de paiement Clearpay <a href="{purchHref}">ici</a>.`,
  clearpayFaq20: `Quand mes articles seront-ils livrés si j'utilise Clearpay?`,
  clearpayFaq21: 'Les commandes Clearpay sont livrées dans nos délais de livraison standard stacees.com.',
  clearpayFaq22: 'Y a-t-il une limite au montant que je peux dépenser pour une seule transaction Clearpay?',
  clearpayFaq23: 'Oui, le montant total de votre achat (hors frais de livraison) doit être supérieur à 15£ et inférieur à 800£. ',
  clearpayFaq24: 'Puis-je utiliser un autre mode de paiement avec  Clearpay?',
  clearpayFaq25: 'Oui, vous pouvez utiliser des codes promotionnels et des cartes-cadeaux selon le cas avec Clearpay. ',
  clearpayFaq26: 'Quels produits ne sont pas éligibles à clearpay?',
  clearpayFaq27: `Clearpay n'est pas disponible pour les achats de cartes-cadeaux. Si votre commande contient des cartes-cadeaux et d'autres produits éligibles, vous devrez acheter vos cartes-cadeaux séparément.`,
  clearpayFaq28: 'Comment retourner ou échanger un article acheté avec Clearpay?',
  clearpayFaq29: `Les retours Clearpay suivent notre processus de retour standard. Veuillez noter que les remboursements pour Clearpay seront effectués depuis <a href="https://www.stacees.com" target="_blank">https://www.stacees.com</a> vers Clearpay. Nous ne pouvons pas fournir d'échange ou de remboursement en espèces. Dès que le remboursement est traité, il sera versé sur votre compte en ligne Clearpay. `,
  clearpayFaq30: 'Où puis-je en savoir plus sur clearpay?',
  clearpayFaq31: 'Visitez le site Web de Clearpay ici pour une liste complète des FAQ et consultez la politique de confidentialité de Clearpay ici.',
  clearpayFaq32: `*Votre calendrier de paiement vous sera fourni. Si vous ne parvenez pas à effectuer un paiement à temps, des frais de retard de 6£ vous seront facturés, auxquels s'ajouteront 6£ supplémentaires après 7 jours si le paiement n'est toujours pas effectué.`,
  clearpayFaq33: `* D'autres facteurs peuvent être pris en compte et peuvent entraîner une diminution de ce chiffre, comme le fait d'être un nouveau client ou votre historique de paiement client.`,
  
}
